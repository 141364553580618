<script setup lang="ts">
import { ref } from 'vue'

import axios from 'axios'

import { ErrorMessage, Field, useForm } from 'vee-validate'
import * as yup from 'yup'

const { $playa } = useNuxtApp()

const showForm = ref(false)
const success = ref(false)
const HotelNameInterestedArr = ref([])
const formData = ref({
  Address1: '',
  Address2: '',
  AddressType: null,
  AlternativeArrivalDate: '',
  ArrivalDate: '',
  City: '',
  Comments: '',
  CompanyName: '',
  ContactName: null,
  Country: '',
  DateEntered: '',
  DepartDate: '',
  Email: '',
  EventSpecs: null,
  EventType: '',
  Fax: null,
  FirstName: '',
  FlexibleDates: '',
  FormName: '',
  GroupAgeRange: '',
  GroupName: '',
  GroupSize: '',
  HotelBrandInterested: '',
  HotelNameInterested: '',
  IP: '',
  IsTravelAgent: '',
  interstedDestinationWeddings: '',
  LastMeetingDate: null,
  LastMeetingLocation: null,
  LastName: '',
  MaxNumberOfGuestRoom: null,
  NumberOfAttendees: null,
  NumberOfNights: null,
  Phone: '',
  Prefix: null,
  PromoCode: null,
  ReceiveNews: '',
  Resort: '',
  RoomRateBudget: null,
  SecondaryPhone: null,
  SourceCode: '',
  State: '',
  Title: null,
  ZipCode: '',
  TCAgree: '',
})

const formValidationSchema = yup.object({
  FirstName: yup.string().required(),
  LastName: yup.string().required(),
  Address1: yup.string().required(),
  City: yup.string().required(),
  State: yup.string().required(),
  ZipCode: yup.string().required(),
  Country: yup.string().required(),
  Email: yup.string().required(),
  GroupSize: yup.string().required(),
})

const formValidator = useForm({
  validationSchema: formValidationSchema,
})

function sendData() {
  const data = Object.assign({}, formData.value)

  for (let i = 0; i < HotelNameInterestedArr.value.length; i++) {
    const brand = HotelNameInterestedArr[i]
    if (i > 0)
      data.HotelNameInterested += ','

    data.HotelNameInterested += brand
  }

  if (data.TCAgree === true)
    data.TCAgree = 'yes'
  else
    data.TCAgree = 'no'

  if (data.interstedDestinationWeddings === true)
    data.DESTINATIONWEDDING = 'yes'
  else
    data.DESTINATIONWEDDING = 'no'

  axios
    .post(
        `${$playa.rest_api_base_url}/divinci/all-inclusive-resorts-groups`,
        data,
    )
    .then(() => {
      success.value = true
    })
}

const validateForm = formValidator.handleSubmit(sendData)
defineSlice({
  name: { label: 'Groups Short', group: 'global' },
  preview: 'SlicesGlobalFormsGroupsShort.jpg',
  slots: [],
})
</script>

<template>
  <div class="flex justify-center bg-grey-lighter py-8 w-full">
    <div
      id="groups-request-for-quote"
      class="master-content-wrapper pt pb sp50 w-full"
    >
      <div class="container gutters">
        <div class="form">
          <h4 class="uppercase cursor-pointer text-center" @click="showForm = !showForm">
            Click Here To Request A Quote
          </h4>

          <form
            v-if="showForm"
            class="mt-4"
            @submit.prevent="validateForm"
          >
            <template v-if="!success">
              <div
                class="flex justify-center flex-col flex-row md:flex-row items-stretch -mx-8"
              >
                <div
                  class="flex flex-col relative mb-10 md:mb-0 flex items-stretch md:w-1/2 lg:w-1/3 mx-4"
                >
                  <fieldset class="mt-4">
                    <label>Group Name</label>
                    <input
                      v-model="formData.GroupName"
                      class="w-full"
                      name="Group Name"
                      type="text"
                    >
                  </fieldset>
                </div>
                <div
                  class="flex flex-col relative mb-10 md:mb-0 flex items-stretch md:w-1/2 lg:w-1/3 mx-4"
                >
                  <fieldset class="mt-4">
                    <label>Email*</label>
                    <div class="relative">
                      <Field
                        v-model="formData.Email"
                        class="w-full"
                        type="email"
                        name="Email"
                      />
                      <ErrorMessage
                        class="absolute right-0 top-0 bottom-0 my-1 mx-1 text-xs text-red-800 bg-red-200 px-4 flex items-center rounded-lg"
                        name="Email"
                      />
                    </div>
                  </fieldset>
                </div>
              </div>
              <div
                class="flex justify-center flex-col flex-row md:flex-row items-stretch -mx-8"
              >
                <div
                  class="flex flex-col relative mb-10 md:mb-0 flex items-stretch md:w-1/2 lg:w-1/3 mx-4"
                >
                  <fieldset class="mt-4">
                    <label>First*</label>
                    <div class="relative">
                      <Field
                        v-model="formData.FirstName"
                        class="w-full"
                        type="text"
                        name="FirstName"
                      />
                      <ErrorMessage
                        class="absolute right-0 top-0 bottom-0 my-1 mx-1 text-xs text-red-800 bg-red-200 px-4 flex items-center rounded-lg"
                        name="FirstName"
                      />
                    </div>
                  </fieldset>

                  <fieldset class="mt-4">
                    <label>Street Address*</label>
                    <div class="relative">
                      <Field
                        v-model="formData.Address1"
                        class="w-full"
                        type="text"
                        name="Address1"
                      />
                      <ErrorMessage
                        class="absolute right-0 top-0 bottom-0 my-1 mx-1 text-xs text-red-800 bg-red-200 px-4 flex items-center rounded-lg"
                        name="Address1"
                      />
                    </div>
                  </fieldset>

                  <fieldset class="mt-4">
                    <label>City*</label>
                    <div class="relative">
                      <Field
                        v-model="formData.City"
                        class="w-full"
                        type="text"
                        name="City"
                      />
                      <ErrorMessage
                        class="absolute right-0 top-0 bottom-0 my-1 mx-1 text-xs text-red-800 bg-red-200 px-4 flex items-center rounded-lg"
                        name="City"
                      />
                    </div>
                  </fieldset>

                  <fieldset class="mt-4">
                    <label>ZIP / Postal Code*</label>
                    <div class="relative">
                      <Field
                        v-model="formData.ZipCode"
                        class="w-full"
                        type="text"
                        name="ZipCode"
                      />
                      <ErrorMessage
                        class="absolute right-0 top-0 bottom-0 my-1 mx-1 text-xs text-red-800 bg-red-200 px-4 flex items-center rounded-lg"
                        name="ZipCode"
                      />
                    </div>
                  </fieldset>

                  <fieldset class="mt-4">
                    <label>Type of Event</label>
                    <input
                      v-model="formData.EventType"
                      class="w-full"
                      name="Type of Event"
                      type="text"
                    >
                  </fieldset>
                </div>

                <div
                  class="flex flex-col relative mb-10 md:mb-0 flex items-stretch md:w-1/2 lg:w-1/3 mx-4"
                >
                  <fieldset class="mt-4">
                    <label>Last*</label>
                    <div class="relative">
                      <Field
                        v-model="formData.LastName"
                        class="w-full"
                        name="LastName"
                        type="text"
                      />
                      <ErrorMessage
                        class="absolute right-0 top-0 bottom-0 my-1 mx-1 text-xs text-red-800 bg-red-200 px-4 flex items-center rounded-lg"
                        name="LastName"
                      />
                    </div>
                  </fieldset>

                  <fieldset class="mt-4">
                    <label>Address Line 2</label>
                    <input
                      v-model="formData.Address2"
                      class="w-full"
                      type="text"
                      name="Address Line 2"
                    >
                  </fieldset>

                  <fieldset class="mt-4">
                    <label>State / Province / Region*</label>
                    <div class="relative">
                      <Field
                        v-model="formData.State"
                        class="w-full"
                        type="text"
                        name="State"
                      />
                      <ErrorMessage
                        class="absolute right-0 top-0 bottom-0 my-1 mx-1 text-xs text-red-800 bg-red-200 px-4 flex items-center rounded-lg"
                        name="State"
                      />
                    </div>
                  </fieldset>

                  <fieldset class="mt-4">
                    <label>Country*</label>
                    <div class="relative">
                      <Field
                        v-model="formData.Country"
                        as="select"
                        name="Country"
                        class="w-full"
                      >
                        <option selected="selected" />
                        <option value="Afghanistan">
                          Afghanistan
                        </option>
                        <option value="Albania">
                          Albania
                        </option>
                        <option value="Algeria">
                          Algeria
                        </option>
                        <option value="American Samoa">
                          American Samoa
                        </option>
                        <option value="Andorra">
                          Andorra
                        </option>
                        <option value="Angola">
                          Angola
                        </option>
                        <option value="Antigua and Barbuda">
                          Antigua and Barbuda
                        </option>
                        <option value="Argentina">
                          Argentina
                        </option>
                        <option value="Armenia">
                          Armenia
                        </option>
                        <option value="Australia">
                          Australia
                        </option>
                        <option value="Austria">
                          Austria
                        </option>
                        <option value="Azerbaijan">
                          Azerbaijan
                        </option>
                        <option value="Bahamas">
                          Bahamas
                        </option>
                        <option value="Bahrain">
                          Bahrain
                        </option>
                        <option value="Bangladesh">
                          Bangladesh
                        </option>
                        <option value="Barbados">
                          Barbados
                        </option>
                        <option value="Belarus">
                          Belarus
                        </option>
                        <option value="Belgium">
                          Belgium
                        </option>
                        <option value="Belize">
                          Belize
                        </option>
                        <option value="Benin">
                          Benin
                        </option>
                        <option value="Bermuda">
                          Bermuda
                        </option>
                        <option value="Bhutan">
                          Bhutan
                        </option>
                        <option value="Bolivia">
                          Bolivia
                        </option>
                        <option value="Bosnia and Herzegovina">
                          Bosnia and Herzegovina
                        </option>
                        <option value="Botswana">
                          Botswana
                        </option>
                        <option value="Brazil">
                          Brazil
                        </option>
                        <option value="Brunei">
                          Brunei
                        </option>
                        <option value="Bulgaria">
                          Bulgaria
                        </option>
                        <option value="Burkina Faso">
                          Burkina Faso
                        </option>
                        <option value="Burundi">
                          Burundi
                        </option>
                        <option value="Cambodia">
                          Cambodia
                        </option>
                        <option value="Cameroon">
                          Cameroon
                        </option>
                        <option value="Canada">
                          Canada
                        </option>
                        <option value="Cape Verde">
                          Cape Verde
                        </option>
                        <option value="Cayman Islands">
                          Cayman Islands
                        </option>
                        <option value="Central African Republic">
                          Central African Republic
                        </option>
                        <option value="Chad">
                          Chad
                        </option>
                        <option value="Chile">
                          Chile
                        </option>
                        <option value="China">
                          China
                        </option>
                        <option value="Colombia">
                          Colombia
                        </option>
                        <option value="Comoros">
                          Comoros
                        </option>
                        <option value="Congo, Democratic Republic of the">
                          Congo, Democratic Republic of the
                        </option>
                        <option value="Congo, Republic of the">
                          Congo, Republic of the
                        </option>
                        <option value="Costa Rica">
                          Costa Rica
                        </option>
                        <option value="Côte d&#039;Ivoire">
                          Côte d&#039;Ivoire
                        </option>
                        <option value="Croatia">
                          Croatia
                        </option>
                        <option value="Cuba">
                          Cuba
                        </option>
                        <option value="Curaçao">
                          Curaçao
                        </option>
                        <option value="Cyprus">
                          Cyprus
                        </option>
                        <option value="Czech Republic">
                          Czech Republic
                        </option>
                        <option value="Denmark">
                          Denmark
                        </option>
                        <option value="Djibouti">
                          Djibouti
                        </option>
                        <option value="Dominica">
                          Dominica
                        </option>
                        <option value="Dominican Republic">
                          Dominican Republic
                        </option>
                        <option value="East Timor">
                          East Timor
                        </option>
                        <option value="Ecuador">
                          Ecuador
                        </option>
                        <option value="Egypt">
                          Egypt
                        </option>
                        <option value="El Salvador">
                          El Salvador
                        </option>
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>
                        <option value="Eritrea">
                          Eritrea
                        </option>
                        <option value="Estonia">
                          Estonia
                        </option>
                        <option value="Ethiopia">
                          Ethiopia
                        </option>
                        <option value="Faroe Islands">
                          Faroe Islands
                        </option>
                        <option value="Fiji">
                          Fiji
                        </option>
                        <option value="Finland">
                          Finland
                        </option>
                        <option value="France">
                          France
                        </option>
                        <option value="French Polynesia">
                          French Polynesia
                        </option>
                        <option value="Gabon">
                          Gabon
                        </option>
                        <option value="Gambia">
                          Gambia
                        </option>
                        <option value="Georgia">
                          Georgia
                        </option>
                        <option value="Germany">
                          Germany
                        </option>
                        <option value="Ghana">
                          Ghana
                        </option>
                        <option value="Greece">
                          Greece
                        </option>
                        <option value="Greenland">
                          Greenland
                        </option>
                        <option value="Grenada">
                          Grenada
                        </option>
                        <option value="Guam">
                          Guam
                        </option>
                        <option value="Guatemala">
                          Guatemala
                        </option>
                        <option value="Guinea">
                          Guinea
                        </option>
                        <option value="Guinea-Bissau">
                          Guinea-Bissau
                        </option>
                        <option value="Guyana">
                          Guyana
                        </option>
                        <option value="Haiti">
                          Haiti
                        </option>
                        <option value="Honduras">
                          Honduras
                        </option>
                        <option value="Hong Kong">
                          Hong Kong
                        </option>
                        <option value="Hungary">
                          Hungary
                        </option>
                        <option value="Iceland">
                          Iceland
                        </option>
                        <option value="India">
                          India
                        </option>
                        <option value="Indonesia">
                          Indonesia
                        </option>
                        <option value="Iran">
                          Iran
                        </option>
                        <option value="Iraq">
                          Iraq
                        </option>
                        <option value="Ireland">
                          Ireland
                        </option>
                        <option value="Israel">
                          Israel
                        </option>
                        <option value="Italy">
                          Italy
                        </option>
                        <option value="Jamaica">
                          Jamaica
                        </option>
                        <option value="Japan">
                          Japan
                        </option>
                        <option value="Jordan">
                          Jordan
                        </option>
                        <option value="Kazakhstan">
                          Kazakhstan
                        </option>
                        <option value="Kenya">
                          Kenya
                        </option>
                        <option value="Kiribati">
                          Kiribati
                        </option>
                        <option value="North Korea">
                          North Korea
                        </option>
                        <option value="South Korea">
                          South Korea
                        </option>
                        <option value="Kosovo">
                          Kosovo
                        </option>
                        <option value="Kuwait">
                          Kuwait
                        </option>
                        <option value="Kyrgyzstan">
                          Kyrgyzstan
                        </option>
                        <option value="Laos">
                          Laos
                        </option>
                        <option value="Latvia">
                          Latvia
                        </option>
                        <option value="Lebanon">
                          Lebanon
                        </option>
                        <option value="Lesotho">
                          Lesotho
                        </option>
                        <option value="Liberia">
                          Liberia
                        </option>
                        <option value="Libya">
                          Libya
                        </option>
                        <option value="Liechtenstein">
                          Liechtenstein
                        </option>
                        <option value="Lithuania">
                          Lithuania
                        </option>
                        <option value="Luxembourg">
                          Luxembourg
                        </option>
                        <option value="Macedonia">
                          Macedonia
                        </option>
                        <option value="Madagascar">
                          Madagascar
                        </option>
                        <option value="Malawi">
                          Malawi
                        </option>
                        <option value="Malaysia">
                          Malaysia
                        </option>
                        <option value="Maldives">
                          Maldives
                        </option>
                        <option value="Mali">
                          Mali
                        </option>
                        <option value="Malta">
                          Malta
                        </option>
                        <option value="Marshall Islands">
                          Marshall Islands
                        </option>
                        <option value="Mauritania">
                          Mauritania
                        </option>
                        <option value="Mauritius">
                          Mauritius
                        </option>
                        <option value="Mexico">
                          Mexico
                        </option>
                        <option value="Micronesia">
                          Micronesia
                        </option>
                        <option value="Moldova">
                          Moldova
                        </option>
                        <option value="Monaco">
                          Monaco
                        </option>
                        <option value="Mongolia">
                          Mongolia
                        </option>
                        <option value="Montenegro">
                          Montenegro
                        </option>
                        <option value="Morocco">
                          Morocco
                        </option>
                        <option value="Mozambique">
                          Mozambique
                        </option>
                        <option value="Myanmar">
                          Myanmar
                        </option>
                        <option value="Namibia">
                          Namibia
                        </option>
                        <option value="Nauru">
                          Nauru
                        </option>
                        <option value="Nepal">
                          Nepal
                        </option>
                        <option value="Netherlands">
                          Netherlands
                        </option>
                        <option value="New Zealand">
                          New Zealand
                        </option>
                        <option value="Nicaragua">
                          Nicaragua
                        </option>
                        <option value="Niger">
                          Niger
                        </option>
                        <option value="Nigeria">
                          Nigeria
                        </option>
                        <option value="Northern Mariana Islands">
                          Northern Mariana Islands
                        </option>
                        <option value="Norway">
                          Norway
                        </option>
                        <option value="Oman">
                          Oman
                        </option>
                        <option value="Pakistan">
                          Pakistan
                        </option>
                        <option value="Palau">
                          Palau
                        </option>
                        <option value="Palestine, State of">
                          Palestine, State of
                        </option>
                        <option value="Panama">
                          Panama
                        </option>
                        <option value="Papua New Guinea">
                          Papua New Guinea
                        </option>
                        <option value="Paraguay">
                          Paraguay
                        </option>
                        <option value="Peru">
                          Peru
                        </option>
                        <option value="Philippines">
                          Philippines
                        </option>
                        <option value="Poland">
                          Poland
                        </option>
                        <option value="Portugal">
                          Portugal
                        </option>
                        <option value="Puerto Rico">
                          Puerto Rico
                        </option>
                        <option value="Qatar">
                          Qatar
                        </option>
                        <option value="Romania">
                          Romania
                        </option>
                        <option value="Russia">
                          Russia
                        </option>
                        <option value="Rwanda">
                          Rwanda
                        </option>
                        <option value="Saint Kitts and Nevis">
                          Saint Kitts and Nevis
                        </option>
                        <option value="Saint Lucia">
                          Saint Lucia
                        </option>
                        <option value="Saint Vincent and the Grenadines">
                          Saint Vincent and the Grenadines
                        </option>
                        <option value="Samoa">
                          Samoa
                        </option>
                        <option value="San Marino">
                          San Marino
                        </option>
                        <option value="Sao Tome and Principe">
                          Sao Tome and Principe
                        </option>
                        <option value="Saudi Arabia">
                          Saudi Arabia
                        </option>
                        <option value="Senegal">
                          Senegal
                        </option>
                        <option value="Serbia">
                          Serbia
                        </option>
                        <option value="Seychelles">
                          Seychelles
                        </option>
                        <option value="Sierra Leone">
                          Sierra Leone
                        </option>
                        <option value="Singapore">
                          Singapore
                        </option>
                        <option value="Sint Maarten">
                          Sint Maarten
                        </option>
                        <option value="Slovakia">
                          Slovakia
                        </option>
                        <option value="Slovenia">
                          Slovenia
                        </option>
                        <option value="Solomon Islands">
                          Solomon Islands
                        </option>
                        <option value="Somalia">
                          Somalia
                        </option>
                        <option value="South Africa">
                          South Africa
                        </option>
                        <option value="Spain">
                          Spain
                        </option>
                        <option value="Sri Lanka">
                          Sri Lanka
                        </option>
                        <option value="Sudan">
                          Sudan
                        </option>
                        <option value="Sudan, South">
                          Sudan, South
                        </option>
                        <option value="Suriname">
                          Suriname
                        </option>
                        <option value="Swaziland">
                          Swaziland
                        </option>
                        <option value="Sweden">
                          Sweden
                        </option>
                        <option value="Switzerland">
                          Switzerland
                        </option>
                        <option value="Syria">
                          Syria
                        </option>
                        <option value="Taiwan">
                          Taiwan
                        </option>
                        <option value="Tajikistan">
                          Tajikistan
                        </option>
                        <option value="Tanzania">
                          Tanzania
                        </option>
                        <option value="Thailand">
                          Thailand
                        </option>
                        <option value="Togo">
                          Togo
                        </option>
                        <option value="Tonga">
                          Tonga
                        </option>
                        <option value="Trinidad and Tobago">
                          Trinidad and Tobago
                        </option>
                        <option value="Tunisia">
                          Tunisia
                        </option>
                        <option value="Turkey">
                          Turkey
                        </option>
                        <option value="Turkmenistan">
                          Turkmenistan
                        </option>
                        <option value="Tuvalu">
                          Tuvalu
                        </option>
                        <option value="Uganda">
                          Uganda
                        </option>
                        <option value="Ukraine">
                          Ukraine
                        </option>
                        <option value="United Arab Emirates">
                          United Arab Emirates
                        </option>
                        <option value="United Kingdom">
                          United Kingdom
                        </option>
                        <option value="United States">
                          United States
                        </option>
                        <option value="Uruguay">
                          Uruguay
                        </option>
                        <option value="Uzbekistan">
                          Uzbekistan
                        </option>
                        <option value="Vanuatu">
                          Vanuatu
                        </option>
                        <option value="Vatican City">
                          Vatican City
                        </option>
                        <option value="Venezuela">
                          Venezuela
                        </option>
                        <option value="Vietnam">
                          Vietnam
                        </option>
                        <option value="Virgin Islands, British">
                          Virgin Islands, British
                        </option>
                        <option value="Virgin Islands, U.S.">
                          Virgin Islands, U.S.
                        </option>
                        <option value="Yemen">
                          Yemen
                        </option>
                        <option value="Zambia">
                          Zambia
                        </option>
                        <option value="Zimbabwe">
                          Zimbabwe
                        </option>
                      </Field>
                      <ErrorMessage
                        class="absolute right-0 top-0 bottom-0 my-1 mx-1 text-xs text-red-800 bg-red-200 px-4 flex items-center rounded-lg"
                        name="Country"
                      />
                    </div>
                  </fieldset>
                </div>
              </div>
              <div
                class="flex justify-center flex-col flex-row md:flex-row items-stretch -mx-8"
              >
                <div
                  class="flex flex-col relative mb-10 md:mb-0 flex items-stretch md:w-1/2 lg:w-1/3 mx-4"
                >
                  <fieldset class="mt-4">
                    <label>Group Size*</label>
                    <div class="relative">
                      <Field
                        v-model="formData.GroupSize"
                        name="GroupSize"
                        type="number"
                        class="w-full"
                      />
                      <ErrorMessage
                        class="absolute right-0 top-0 bottom-0 my-1 mx-1 text-xs text-red-800 bg-red-200 px-4 flex items-center rounded-lg"
                        name="GroupSize"
                      />
                    </div>
                  </fieldset>

                  <fieldset class="mt-4">
                    <label>Arrival Date</label>
                    <input
                      v-model="formData.ArrivalDate"
                      class="w-full"
                      name="Arrival Date"
                      type="text"
                    >
                  </fieldset>

                  <fieldset class="mt-4">
                    <label>Are Dates Flexible</label>
                    <label><input
                      v-model="formData.FlexibleDates"
                      class="mr-2"
                      name="Are Dates Flexible"
                      type="radio"
                      value="Yes"
                    >Yes</label>
                    <label><input
                      v-model="formData.FlexibleDates"
                      class="mr-2"
                      name="Are Dates Flexible"
                      type="radio"
                      value="No"
                    >No</label>
                  </fieldset>

                  <fieldset class="mt-6">
                    <label><input
                      v-model="formData.IsTravelAgent"
                      class="mr-2"
                      name="I am a Travel Agent"
                      type="checkbox"
                      value="The Royal"
                    >I am a Travel Agent
                    </label>
                  </fieldset>

                  <fieldset class="mt-4">
                    <label>Comments</label>
                    <textarea
                      v-model="formData.Comments"
                      class="w-full"
                      name="Comments"
                      rows="10"
                    />
                  </fieldset>

                  <input
                    type="submit"
                    class="btn text-white bg-playa-action font-bold uppercase mt-4 text-center"
                    value="Submit"
                  >
                </div>
                <div
                  class="flex flex-col relative mb-10 md:mb-0 items-stretch md:w-1/2 lg:w-1/3 mx-4"
                >
                  <fieldset class="mt-4">
                    <label>Group Age Range</label>
                    <input
                      v-model="formData.GroupAgeRange"
                      name="Group Age Range"
                      type="text"
                      class="w-full"
                    >
                  </fieldset>

                  <fieldset class="mt-4">
                    <label>Departure Date</label>
                    <input
                      v-model="formData.DepartDate"
                      name="Departure Date"
                      type="text"
                      class="w-full"
                    >
                  </fieldset>

                  <fieldset class="mt-4">
                    <label>Which hotel brands are you interested in?</label>
                    <label><input
                      v-model="HotelNameInterestedArr"
                      class="mr-2"
                      type="checkbox"
                      value="HILTON ALL-INCLUSIVE RESORTS"
                    >HILTON ALL-INCLUSIVE RESORTS</label>
                    <label><input
                      v-model="HotelNameInterestedArr"
                      class="mr-2"
                      type="checkbox"
                      value="HYATT ZILARA & HYATT ZIVA"
                    >HYATT ZILARA & HYATT ZIVA</label>
                    <label><input
                      v-model="HotelNameInterestedArr"
                      class="mr-2"
                      type="checkbox"
                      value="SANCTUARY CAP CANA"
                    >SANCTUARY CAP CANA</label>
                    <label><input
                      v-model="HotelNameInterestedArr"
                      class="mr-2"
                      type="checkbox"
                      value="JEWEL RESORTS"
                    >JEWEL RESORTS</label>
                    <label><input
                      v-model="HotelNameInterestedArr"
                      class="mr-2"
                      type="checkbox"
                      value="JEWEL GRANDE"
                    >JEWEL GRANDE</label>
                    <label><input
                      v-model="HotelNameInterestedArr"
                      class="mr-2"
                      type="checkbox"
                      value="TURQUOIZE HYATT ZIVA CANCUN"
                    >TURQUOIZE HYATT ZIVA CANCUN</label>
                  </fieldset>

                  <fieldset class="mt-8">
                    <label><input
                      v-model="formData.ReceiveNews"
                      class="mr-2"
                      name="I would like to receive emails about your latest offers and promotions"
                      type="checkbox"
                      value="The Royal"
                    >I would like to receive emails about your latest offers
                      and promotions
                    </label>
                  </fieldset>
                </div>
              </div>
            </template>

            <div v-if="success" v-cloak class="success">
              <h6 class="title text-lg font-bold">
                Form successfully submitted!
              </h6>
              <p>Thank you for your submission.</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
