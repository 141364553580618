<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    containerClasses() {
      return {
        'container': this.parentSlice === null,
        'md:text-center': this.fields.textCenter.value,
        'text-center': this.fields.textCenterMobile.checked,
        'md:text-left': !this.fields.textCenter.value,
        'text-left': !this.fields.textCenterMobile.checked,
      }
    },
  },

  mounted() {
    defineSlice({
      name: {
        label: 'Title Description',
        group: 'playa-collection',
        layouts: ['PlayaCares', 'PlayaCollection'],
      },
      tags: ['Title', 'Text'],
      fields: {
        title: { type: 'text', label: 'Title', name: 'title' },
        description: { type: 'wysiwyg', label: 'Description', name: 'description' },
        textCenter: {
          type: 'checkbox',
          label: 'Center Paragraph',
          default: true,
          name: 'textCenter',
        },
        textCenterMobile: {
          type: 'checkbox',
          label: 'Center Paragraph On Mobile',
          default: true,
          name: 'textCenterMobile',
        },
      },
      slots: [{ name: 'default', allowedElements: [] }],
    })
  },
}
</script>

<template>
  <div class="w-full py-12 px-8 xl:px-0 flex justify-center">
    <div :class="containerClasses">
      <h1 v-if="fields.title.value" class="mb-6 text-4xl font-sans-lato font-light">
        {{ fields.title.value }}
      </h1>
      <div class="mb-4" v-html="fields.description.value" />
      <slot />
    </div>
  </div>
</template>
