<script>
import axios from 'axios'

export default {
  data() {
    return {
      searching: false,
      zipcode: '',
      radius: '5',
      country: '',
      state: '',
      searchBy: 'zip',
      countriesAndStates: {
        AR: ['Buenos Aires', 'Cordoba', 'Corrientes', 'Mendoza'],
        AU: ['Queensland', 'Quintana Roo', 'South Australia'],
        BO: ['Murillo'],
        BR: [
          'Minas Gerais',
          'Piaui',
          'Rio de Janeiro',
          'Rio Grande do Sul',
          'Sao Paulo',
        ],
        CA: [
          'Alberta',
          'British Columbia',
          'Hamilton',
          'Manitoba',
          'New Brunswick',
          'New South Wales',
          'Newfoundland',
          'Nova Scotia',
          'Ontario',
          'Prince Edward Island',
          'Quebec',
          'Saskatchewan',
          'Victoria',
          'Yukon',
        ],
        CL: ['Osorno', 'Santiago'],
        FR: ['Dieppe'],
        MX: [
          'Aguascalientes',
          'Baja California Sur',
          'Campeche',
          'Chiapas',
          'Chihuahua',
          'Coahuila',
          'Colima',
          'Durango',
          'Guanajuato',
          'Hidalgo',
          'Jalisco',
          'Mexico, D. F.',
          'Michoacan',
          'Nuevo Leon',
          'Oaxaca',
          'Puebla',
          'Queretaro',
          'San Luis Potosi',
          'Santa Fe',
          'Sonora',
          'State of Mexico',
          'Tamaulipas',
          'Tlaxcala',
          'Veracruz',
          'YC',
          'Yucatan',
        ],
        US: [
          'AK',
          'AL',
          'AR',
          'AZ',
          'CA',
          'CO',
          'CT',
          'DC',
          'DE',
          'FL',
          'GA',
          'IA',
          'ID',
          'IL',
          'IN',
          'KS',
          'KY',
          'LA',
          'MA',
          'MD',
          'ME',
          'MI',
          'MN',
          'MO',
          'MS',
          'MT',
          'NC',
          'ND',
          'NE',
          'NH',
          'NJ',
          'NL',
          'NM',
          'NV',
          'NY',
          'OH',
          'OK',
          'OR',
          'PA',
          'RI',
          'SC',
          'SD',
          'TN',
          'TX',
          'UT',
          'VA',
          'VT',
          'WA',
          'WI',
          'WS',
          'WV',
          'WY',
        ],
      },
      error: {
        show: false,
        message: '',
      },
      results: [],
    }
  },
  computed: {
    urlEndpoint() {
      if (this.searchBy === 'zip')
        return `https://services.myplayaresorts.com/agentsearchservices/Agentsearchservice.svc/AgentSearchByzip/${this.zipcode}/${this.radius}`

      return `https://services.myplayaresorts.com/agentsearchservices/Agentsearchservice.svc/AgentSearchByStateCountry/${this.state}/${this.country}`
    },
    stateOptions() {
      if (this.country !== '')
        return this.countriesAndStates[this.country]

      return null
    },
    canSubmit() {
      if (this.searchBy === 'zip') {
        if (this.zipcode !== '' && this.radius !== '')
          return true
      }
      else if (this.country !== '' && this.state !== '') {
        return true
      }
      return false
    },
    translation() {
      return this.lang.playaresorts
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Agent Search', group: 'global', layouts: ['Blocks'] },
      tags: ['Form'],
      preview: 'SlicesGlobalFormsAgentSearch.jpg',
      slots: [],
    })
  },
  methods: {
    submit() {
      this.searching = true

      axios
        .get(this.urlEndpoint)
        .then((response) => {
          this.searching = false
          this.results = response.data

          if (this.results.length < 1) {
            this.showError(
              'We were unable to locate any agent near that location',
            )
          }
        })
        .catch(() => {
          this.searching = false
          this.showError(
            'We were unable to locate any agent near that location',
          )
        })
    },
    clearResults() {
      this.results = []
    },
    showError(message) {
      this.error.show = true
      this.error.message = message
    },
    closeError() {
      this.error.show = false
      this.error.message = null
    },
  },
}
</script>

<template>
  <div class="flex justify-center py-8">
    <div
      id="groups-request-for-quote"
      class="master-content-wrapper pt pb sp50"
    >
      <div class="container gutters">
        <div class="form">
          <div class="flex flex-col items-center text-center">
            <div v-if="searching" id="fata-searching" />

            <template v-if="results.length <= 0">
              <div
                v-if="error.show"
                v-cloak
                class="border w-3/5 relative p-10 text-left mb-8"
              >
                <button
                  type="button"
                  class="btn tiny ghost absolute right-0 top-0"
                  @click="closeError"
                >
                  x
                </button>
                <h6>{{ $t('thereWasAnError') }}:</h6>
                <p>{{ error.message }}</p>
              </div>
              <fieldset class="mb-8">
                <label>
                  <input v-model="searchBy" type="radio" value="zip">
                  {{ $t('searchByPostalCode') }}
                </label>
                <label>
                  <input
                    v-model="searchBy"
                    type="radio"
                    value="country/state"
                  >
                  {{ $t('searchByCountryAndState') }}
                </label>
              </fieldset>

              <template v-if="searchBy === 'country/state'">
                <fieldset class="mb-8 w-1/2">
                  <label>{{ $t('selectACountry') }}</label>
                  <select v-model="country" class="w-full border">
                    <option value disabled>
                      {{ $t('selectACountry') }}
                    </option>
                    <option
                      v-for="(c, countryCode) in countriesAndStates"
                      :key="countryCode"
                      :value="countryCode"
                    >
                      {{ countryCode }}
                    </option>
                  </select>
                </fieldset>
                <fieldset class="mb-8 w-1/2">
                  <label>{{ $t('selectAState') }}</label>
                  <select v-model="state" class="w-full border">
                    <option value disabled>
                      {{ $t('selectAState') }}
                    </option>
                    <option
                      v-for="st in stateOptions"
                      :key="st"
                      :value="st"
                    >
                      {{ st }}
                    </option>
                  </select>
                </fieldset>
              </template>

              <template v-if="searchBy === 'zip'">
                <fieldset class="mb-8 w-1/2">
                  <label>{{ $t('provideAZip') }}</label>
                  <input
                    v-model="zipcode"
                    class="w-full border"
                    type="text"
                  >
                </fieldset>
                <fieldset class="mb-8 w-1/2">
                  <label>{{ $t('provideARadius') }}</label>
                  <select v-model="radius" class="w-full border">
                    <option value="5">
                      5 Miles
                    </option>
                    <option value="10">
                      10 Miles
                    </option>
                    <option value="15">
                      15 Miles
                    </option>
                    <option value="20">
                      20 Miles
                    </option>
                    <option value="25">
                      25 Miles
                    </option>
                    <option value="50">
                      50 Miles
                    </option>
                  </select>
                </fieldset>
              </template>

              <button
                type="submit"
                class="btn raised inverse"
                :disabled="!canSubmit"
                @click="submit"
              >
                {{ $t('search') }}
              </button>
            </template>

            <div v-if="results.length > 0" class="pt-8 w-full">
              <h2 class="pb-8">
                {{ $t('results') }}
              </h2>
              <button class="btn raised inverse mb-8" @click="clearResults()">
                {{ $t('searchAgain') }}
              </button>
              <div class="pt-8 flex flex-wrap w-full">
                <div
                  v-for="result in results"
                  :key="result"
                  class="w-full w-1/4 text-left"
                >
                  <div class="border p-4 m-4">
                    <h5>{{ result.Agency }}</h5>
                    <h6>{{ result.City }}</h6>
                    <p>
                      <strong>{{ result.FirstName }}
                        {{ result.LastName }}</strong>
                      <br>
                      {{ $t('phone') }}: {{ result.Telephone }}
                      <br>
                      {{ $t('email') }}: {{ result.Email }}
                      <br>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
