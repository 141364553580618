<script>
import axios from 'axios'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      sproutLoudToken: '0',
    }
  },
  computed: {
    overrideStyles() {
      if (this.fields.includedBackground) {
        return {
          backgroundColor: this.fields.includedBackground.value,
          color: this.fields.includedForeground.value,
        }
      }
      return {}
    },
    hasAnImage() {
      if (this.fields.topImage.url || this.fields.topImage2.enabled || this.fields.topImage3.enabled || this.fields.topImage4.enabled || this.fields.topImage5.enabled)
        return true

      return false
    },
  },
  mounted() {
    defineSlice({
      slots: [{ name: 'default' }],
      fields: {
        topImage: { type: 'media', label: 'Top Image 1', breakpoints: { lg: { width: 561, height: 300 }, sm: { width: 300, height: 150 } } },
        topImage2: { type: 'media', label: 'Top Image 2', enabled: false, breakpoints: { lg: { width: 561, height: 300 }, sm: { width: 300, height: 150 } } },
        topImage3: { type: 'media', label: 'Top Image 3', enabled: false, breakpoints: { lg: { width: 561, height: 300 }, sm: { width: 300, height: 150 } } },
        topImage4: { type: 'media', label: 'Top Image 4', enabled: false, breakpoints: { lg: { width: 561, height: 300 }, sm: { width: 300, height: 150 } } },
        topImage5: { type: 'media', label: 'Top Image 5', enabled: false, breakpoints: { lg: { width: 561, height: 300 }, sm: { width: 300, height: 150 } } },
        included: { type: 'text', label: 'Included Banner Text', default: '"Included"', enabled: false },
        includedBackground: { type: 'color', label: 'Included Banner BG Color' },
        includedForeground: { type: 'color', label: 'Included Banner FG Color' },
        copyImage: { type: 'media', label: 'Image in Copy', enabled: false },
        title: { type: 'text', label: 'Title', editorLabel: true },
        copy: { type: 'wysiwyg', label: 'Copy' },
        button: { type: 'link', label: 'Button Link', enabled: false },
      },
      name: { label: 'Sproutloud Sso Block', group: 'Agents Portal', layouts: ['AgentCashPlus'] },
      tags: ['Sproutloud', 'SSO'],
      templates: [{ label: 'Sproutloud Sso Block', fields: { button: { value: { text: 'GET STARTED', target: '_self', relationship: null, href: '\/new-page' } }, copy: { value: '<p>Want to personalize your marketing efforts? Visit our custom marketing center for personalized flyers and more.<\/p>' }, title: { value: 'CUSTOMIZED MARKETING CENTER' }, topImage: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Agents_Portal\/marketing-resource-center.jpg' } } }],
    })

    $fetch('/sproutloud/sso')
      .then((response) => {
        this.sproutLoudToken = response
      })
      .catch((error) => {
        console.log(error)
      })
  },
}
</script>

<template>
  <div class="flex justify-stretch text-center">
    <div class="md:mx-4 bg-white shadow rounded relative w-full">
      <div class="flex flex-col overflow-hidden">
        <div>
          <div class="relative">
            <div
              v-if="fields.topImage.value && fields.topImage.value.length"
              class="h-[300px] w-full overflow-hidden aspect-video"
            >
              <VoixMedia
                v-if="fields.topImage.value"
                :field="fields.topImage"
                class="w-full h-full object-cover"
              />
            </div>
          </div>

          <div class="p-8 pb-12">
            <VoixMedia
              v-if="fields.copyImage.enabled"
              :field="fields.copyImage"
              class="w-3/4 mb-4 mx-auto"
            />
            <h2 v-if="fields.title.value" class="mb-2 uppercase text-2xl">
              {{ fields.title.value }}
            </h2>
            <div class="wysiwyg mb-4" v-html="fields.copy.value" />
          </div>
        </div>

        <a
          v-if="sproutLoudToken === '0'"
          :href="fields.button.value?.href"
          :target="fields.button.value?.target"
          class="btn bg-playa-action text-abs-white w-full absolute bottom-0 left-0 right-0 mx-0 rounded-b"
        >
          {{ fields.button.value?.text }}
        </a>

        <form v-else method="POST" action="https://app.sproutloud.com/seamless_login" target="_blank">
          <input v-model="sproutLoudToken" type="hidden" name="login_data">
          <input
            type="submit"
            class="btn bg-playa-action text-abs-white w-full absolute bottom-0 left-0 right-0 mx-0 rounded-b"
            href="javascript:void(0)"
            :value="fields.button.value?.text"
          >
        </form>
      </div>
    </div>
  </div>
</template>
