<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    hrefLink() {
      if (typeof deviseSettings !== 'undefined') {
        return (Object.prototype.hasOwnProperty.call(deviseSettings, '$mbrAgentData')) ? this.fields.link.value?.href : '#'
      }
      return this.fields.link.value?.href ? this.fields.link.value?.href : '#'
    },
    text() {
      return this.fields.link.value?.text ? this.fields.link.value?.text : this.fields.loggedOutText.value
    },
    colors() {
      if (this.fields.fgColor.value) {
        if (this.fields.ghost.value) {
          return {
            color: this.fields.fgColor.value,
            borderColor: this.fields.fgColor.value,
          }
        }
        return {
          color: this.fields.fgColor.value,
          backgroundColor: this.fields.bgColor.value,
        }
      }
      return {}
    },
  },
  mounted() {
    defineSlice({

      preview: 'SlicesAgentsPortalLoginRequiredButton.jpg',
      description: 'Only link when an agent is logged in.',
      fields: { link: { type: 'link', label: 'Link' }, ghost: { type: 'checkbox', label: 'Ghost?', default: true }, loggedOutText: { type: 'text', label: 'Logged Out Text', default: '"Login For Details"' }, fgColor: { type: 'color', label: 'Text Color' }, bgColor: { type: 'color', label: 'Background Color' } },
      name: { label: 'Login Required Button', group: 'Agents Portal', layouts: ['AgentCashPlus'] },
      tags: ['Link', 'Button'],
      templates: [{ label: 'Login Required Button', fields: { link: { value: { text: 'COMIENZA A APRENDER', target: '_self', relationship: null, href: '\/new-page' } } } }],
    })
  },
}
</script>

<template>
  <a
    class="btn inline-block m-2"
    :class="{ 'btn-ghost': fields.ghost.value }"
    :style="colors"
    :href="hrefLink"
    :target="fields.link.value?.target"
  >
    {{ text }}
  </a>
</template>
