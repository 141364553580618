<script>
import AC from '@/components/icons/IconsAC'
import Alarm from '@/components/icons/IconsAlarm'
import Balcony from '@/components/icons/IconsBalcony'
import Bar from '@/components/icons/IconsBar'
import Bath from '@/components/icons/IconsBath'
import Bathroom from '@/components/icons/IconsBathroom'
import Bed from '@/components/icons/IconsBed'
import BluetoothSpeaker from '@/components/icons/IconsBluetoothSpeaker'
import Butler from '@/components/icons/IconsButler'
import Coffee from '@/components/icons/IconsCoffee'
import Hairdryer from '@/components/icons/IconsHairdryer'
import IroningBoard from '@/components/icons/IconsIroningBoard'
import Liquor from '@/components/icons/IconsLiquor'
import MagicBox from '@/components/icons/IconsMagicBox'
import Mirror from '@/components/icons/IconsMirror'
import Patio from '@/components/icons/IconsPatio'
import PhoneAmenity from '@/components/icons/IconsPhoneAmenity'
import Radio from '@/components/icons/IconsRadio'
import Robe from '@/components/icons/IconsRobe'
import Safe from '@/components/icons/IconsSafe'
import Showers from '@/components/icons/IconsShowers'
import Slippers from '@/components/icons/IconsSlippers'
import Transfers from '@/components/icons/IconsTransfers'
import Turndown from '@/components/icons/IconsTurndown'
import Tv from '@/components/icons/IconsTv'
import TwentyFourHourRoomService from '@/components/icons/IconsTwentyFourHourRoomService'
import Wifi from '@/components/icons/IconsWifi'

export default {
  name: 'Amenaties',
  components: {
    AC,
    Alarm,
    Balcony,
    Bar,
    Bath,
    Bathroom,
    Bed,
    BluetoothSpeaker,
    Butler,
    Coffee,
    Hairdryer,
    IroningBoard,
    Liquor,
    MagicBox,
    Mirror,
    Patio,
    PhoneAmenity,
    Radio,
    Robe,
    Safe,
    Showers,
    Slippers,
    Transfers,
    Turndown,
    Tv,
    TwentyFourHourRoomService,
    Wifi,
  },
  props: {
    devise: { type: Object },
    height: {
      type: Number,
      default: 50,
    },
    width: {
      type: Number,
      default: 50,
    },
    iconHeight: {
      type: Number,
      default: 50,
    },
    iconWidth: {
      type: Number,
      default: 50,
    },
    color: {
      type: String,
      default: 'rgb(96, 111, 123)',
    },
    marginX: {
      type: Number,
      default: 0,
    },
    marginY: {
      type: Number,
      default: 0,
    },
    showIcons: {
      type: Object,
      default: () => {
        return {
          bath: true,
          wifi: true,
          roomService: true,
          bed: true,
          bar: true,
          tv: true,
          magicBox: true,
          robe: true,
          mirror: true,
          bathroom: true,
          safe: true,
          patio: true,
          phone: true,
          coffee: true,
          ac: true,
          hairdryer: true,
          ironingBoard: true,
          alarm: true,
          balcony: true,
          radio: true,
          turndown: true,
          bluetooth: true,
          butler: true,
          liquor: true,
          showers: true,
          slippers: true,
          transfers: true,
        }
      },
    },
  },
  data() {
    return {}
  },
  computed: {
    iconStyles() {
      return {
        marginLeft: `${this.marginX}px`,
        marginRight: `${this.marginX}px`,
        marginTop: `${this.marginY}px`,
        marginBottom: `${this.marginY}px`,
        textAlign: 'center',
        fontSize: '13px',
        width: `${this.width}px`,
        height: `${this.height}px`,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }
    },
  },
}
</script>

<template>
  <div>
    <div id="amenities" class="flex justify-center items-start flex-wrap">
      <div v-if="showIcons.bath" :style="iconStyles">
        <div>
          <Bath
            :color="color"
            :w="iconWidth * 1.1"
            :h="iconHeight * 1.1"
          />
        </div>
        <div
          class="mt-1"
          v-html="$t('double-jetted-tub')"
        />
      </div>
      <div v-if="showIcons.wifi" :style="iconStyles">
        <div>
          <Wifi
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.8"
          />
        </div>
        <div class="mt-1" v-html="$t('wifi')" />
      </div>
      <div v-if="showIcons.roomService" :style="iconStyles">
        <div>
          <TwentyFourHourRoomService
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.8"
          />
        </div>
        <div class="mt-1" v-html="$t('room-service')" />
      </div>
      <div v-if="showIcons.bed" :style="iconStyles">
        <div>
          <Bed
            :color="color"
            :w="iconWidth * 0.9"
            :h="iconHeight * 0.9"
          />
        </div>
        <div class="mt-1" v-html="$t('pillow-menu')" />
      </div>
      <div v-if="showIcons.bar" :style="iconStyles">
        <div>
          <Bar
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.9"
          />
        </div>
        <div class="mt-1" v-html="$t('mini-bar')" />
      </div>
      <div v-if="showIcons.tv" :style="iconStyles">
        <div>
          <Tv
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.8"
          />
        </div>
        <div
          class="mt-1"
          v-html="$t('flat-screen-tv')"
        />
      </div>
      <div v-if="showIcons.magicBox" :style="iconStyles">
        <div>
          <MagicBox
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.8"
          />
        </div>
        <div class="mt-1" v-html="$t('magic-box')" />
      </div>
      <div v-if="showIcons.robe" :style="iconStyles">
        <div>
          <Robe
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.8"
          />
        </div>
        <div class="mt-1" v-html="$t('robes')" />
      </div>
      <div v-if="showIcons.mirror" :style="iconStyles">
        <div>
          <Mirror
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.8"
          />
        </div>
        <div class="mt-1" v-html="$t('mirror')" />
      </div>
      <div v-if="showIcons.bathroom" :style="iconStyles">
        <div>
          <Bathroom
            :color="color"
            :w="iconWidth * 0.9"
            :h="iconHeight * 0.9"
          />
        </div>
        <div
          class="mt-1"
          v-html="$t('bathroom-amenaties')"
        />
      </div>
      <div v-if="showIcons.safe" :style="iconStyles">
        <div>
          <Safe
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.8"
          />
        </div>
        <div class="mt-1" v-html="$t('in-room-safe')" />
      </div>

      <div v-if="showIcons.patio" :style="iconStyles">
        <div>
          <Patio
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.8"
          />
        </div>
        <div
          class="mt-1"
          v-html="$t('furnished-patio')"
        />
      </div>
      <div v-if="showIcons.phone" :style="iconStyles">
        <div>
          <PhoneAmenity
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.8"
          />
        </div>
        <div class="mt-1" v-html="$t('telephone')" />
      </div>
      <div v-if="showIcons.coffee" :style="iconStyles">
        <div>
          <Coffee
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.8"
          />
        </div>
        <div class="mt-1" v-html="$t('coffee-maker')" />
      </div>
      <div v-if="showIcons.ac" :style="iconStyles">
        <div>
          <AC
            :color="color"
            :w="iconWidth * 1"
            :h="iconHeight * 1"
          />
        </div>
        <div
          class="mt-1"
          v-html="$t('air-conditioning')"
        />
      </div>
      <div v-if="showIcons.hairdryer" :style="iconStyles">
        <div>
          <Hairdryer
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.8"
          />
        </div>
        <div class="mt-1" v-html="$t('hairdryer')" />
      </div>
      <div v-if="showIcons.ironingBoard" :style="iconStyles">
        <div>
          <IroningBoard
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.8"
          />
        </div>
        <div
          class="mt-1"
          v-html="$t('iron-and-ironing-board')"
        />
      </div>
      <div v-if="showIcons.alarm" :style="iconStyles">
        <div>
          <Alarm
            :color="color"
            :w="iconWidth * 0.9"
            :h="iconHeight * 0.9"
          />
        </div>
        <div class="mt-1" v-html="$t('alarm-clock')" />
      </div>
      <div v-if="showIcons.balcony" :style="iconStyles">
        <div>
          <Balcony
            :color="color"
            :w="iconWidth * 0.9"
            :h="iconHeight * 0.9"
          />
        </div>
        <div
          class="mt-1"
          v-html="$t('private-balcony')"
        />
      </div>
      <div v-if="showIcons.radio" :style="iconStyles">
        <div>
          <Radio
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.8"
          />
        </div>
        <div class="mt-1" v-html="$t('radio')" />
      </div>
      <div v-if="showIcons.turndown" :style="iconStyles">
        <div>
          <Turndown
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.8"
          />
        </div>
        <div
          class="mt-1"
          v-html="$t('turndown-service')"
        />
      </div>
      <div v-if="showIcons.bluetooth" :style="iconStyles">
        <div>
          <BluetoothSpeaker
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.8"
          />
        </div>
        <div
          class="mt-1"
          v-html="$t('bluetooth-speaker')"
        />
      </div>
      <div v-if="showIcons.butler" :style="iconStyles">
        <div>
          <Butler
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.8"
          />
        </div>
        <div
          class="mt-1"
          v-html="$t('butler-service')"
        />
      </div>
      <div v-if="showIcons.liquor" :style="iconStyles">
        <div>
          <Liquor
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.8"
          />
        </div>
        <div class="mt-1" v-html="$t('beer-wine')" />
      </div>
      <div v-if="showIcons.showers" :style="iconStyles">
        <div>
          <Showers
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.8"
          />
        </div>
        <div
          class="mt-1"
          v-html="$t('rainfall-showers')"
        />
      </div>
      <div v-if="showIcons.slippers" :style="iconStyles">
        <div>
          <Slippers
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.8"
          />
        </div>
        <div
          class="mt-1"
          v-html="$t('robes-slippers')"
        />
      </div>
      <div v-if="showIcons.transfers" :style="iconStyles">
        <div>
          <Transfers
            :color="color"
            :w="iconWidth * 0.8"
            :h="iconHeight * 0.8"
          />
        </div>
        <div class="mt-1" v-html="$t('transfers')" />
      </div>
    </div>
  </div>
</template>
