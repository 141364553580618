<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    embedUrl() {
      const autoplay = this.fields.autoplay.value ? '1' : '0'
      const loop = this.fields.loop.value ? '1' : '0'
      const mute = this.fields.mute.value ? '1' : '0'

      return `https://www.youtube.com/embed/${this.fields.videoId.value}?rel=0&autoplay=${autoplay}&loop=${loop}&mute=${mute}`
    },
  },
  mounted() {
    defineSlice({
      name: {
        label: 'Youtube',
        group: 'global',
        layouts: ['*'],
      },
      tags: ['Video'],
      fields: {
        videoId: { type: 'text', label: 'Youtube ID', name: 'videoId' },
        autoplay: {
          type: 'checkbox',
          label: 'Autoplay?',
          default: true,
          name: 'autoplay',
          description: 'Note: Autoplay will only work if the video is muted.',
        },
        loop: { type: 'checkbox', label: 'Loop?', default: true, name: 'loop' },
        mute: { type: 'checkbox', label: 'Mute?', default: true, name: 'mute' },
      },
      slots: [],
    })
  },
}
</script>

<template>
  <div class="flex justify-center w-full">
    <div class="py-14 container">
      <div class="yt-container">
        <iframe
          id="ytplayer"
          type="text/html"
          :src="embedUrl"
          frameborder="0"
          class="w-full aspect-video"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.yt-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.yt-container iframe,
.yt-container object,
.yt-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
