<script setup lang="ts">
import type { VoixCheckboxFieldInterface, VoixColorFieldInterface } from '@voix/types'

const props = defineProps<{
  fields: {
    showFlightBookingWidget: VoixCheckboxFieldInterface
    shiftOverHero: VoixCheckboxFieldInterface
    accentColor: VoixColorFieldInterface
  }
}>()

provide('accentColor', props.fields.accentColor)
defineSlice({
  name: { label: 'Inline Booking Widget', group: 'global', layouts: ['HyattZilara', 'Blocks', 'HyattZiva'] },
  tags: ['BookingWidget'],
  preview: 'SlicesGlobalBookingInlineBookingWidget.jpg',
  fields: {
    showFlightBookingWidget: { type: 'checkbox', label: 'Show Flight Booking Widget', default: false, name: 'showFlightBookingWidget' },
    shiftOverHero: { type: 'checkbox', label: 'Shift Over Hero', default: false, name: 'shiftOverHero' },
    accentColor: { type: 'color', label: 'Accent Color', default: '#1d3091', name: 'accentColor' },
  },
  slots: [],
})
</script>

<template>
  <div class="flex justify-center">
    <div class="container mx-auto flex justify-center">
      <PlayaBooking v-if="!fields.showFlightBookingWidget.value" :inline="true" class="bg-white w-2/3">
        <button>
          Book Now
        </button>
      </PlayaBooking>

      <FlightBookingForm
        v-else
        class="py-8 hidden lg:block"
        :shift-over-hero="fields.shiftOverHero.value"
      />
    </div>
  </div>
</template>
