<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    wysiwygWidth() {
      return {
        'text-left': !this.fields.textCenter.value || this.fields.twoColumns.value,
        'text-sm': this.fields.twoColumns.value,
        'w-4/5': !this.fields.narrow.value && !this.fields.twoColumns.value,
        'md:w-1/2': this.fields.narrow.value || this.fields.twoColumns.value,
        'w-3/4': this.fields.narrow.value && !this.fields.twoColumns.value,
      }
    },
    backgroundStyles() {
      if (this.fields.backgroundColor.enabled) {
        return {
          backgroundColor: this.fields.backgroundColor.value,
        }
      }
      return {}
    },
  },
  mounted() {
    defineSlice({
      fields: {
        title: { type: 'text', label: 'Title', editorLabel: true, enabled: true },
        pretitle: { type: 'text', label: 'Pre-title', enabled: false },
        subtitle: { type: 'text', label: 'Sub-title', enabled: false },
        introduction: { type: 'wysiwyg', label: 'Introduction' },
        textCenter: { type: 'checkbox', label: 'Center Paragraph', default: true },
        narrow: { type: 'checkbox', label: 'Narrow Paragraph', default: false },
        twoColumns: {
          type: 'checkbox',
          label: 'Title and Description in columns?',
          default: false,
        },
        backgroundColor: {
          type: 'select',
          label: 'Background Color',
          options: { '#ffcf85': 'Goooooold', '#FFB4B4': 'Coral', '#92dbd0': 'Teal' },
          enabled: true,
        },
        card1Image: {
          type: 'media',
          label: 'Card 1 Image',
          breakpoints: { lg: { width: 500, height: 450 } },
        },
        card1Title: { type: 'text', label: 'Card 1 Title' },
        card1Description: { type: 'wysiwyg', label: 'Card 1 Description' },
        card1Link: { type: 'link', label: 'Card 1 Link', enabled: false },
        card2Image: {
          type: 'media',
          label: 'Card 2 Image',
          breakpoints: { lg: { width: 500, height: 450 } },
        },
        card2Title: { type: 'text', label: 'Card 2 Title' },
        card2Description: { type: 'wysiwyg', label: 'Card 2 Description' },
        card2Link: { type: 'link', label: 'Card 2 Link', enabled: false },
        card3Image: {
          type: 'media',
          label: 'Card 3 Image',
          breakpoints: { lg: { width: 500, height: 450 } },
        },
        card3Title: { type: 'text', label: 'Card 3 Title' },
        card3Description: { type: 'wysiwyg', label: 'Card 3 Description' },
        card3Link: { type: 'link', label: 'Card 3 Link', enabled: false },
        card4Image: {
          type: 'media',
          label: 'Card 4 Image',
          breakpoints: { lg: { width: 500, height: 450 } },
        },
        card4Title: { type: 'text', label: 'Card 4 Title' },
        card4Description: { type: 'wysiwyg', label: 'Card 4 Description' },
        card4Link: { type: 'link', label: 'Card 4 Link', enabled: false },
        card5Image: {
          type: 'media',
          label: 'Card 5 Image',
          breakpoints: { lg: { width: 500, height: 450 } },
        },
        card5Title: { type: 'text', label: 'Card 5 Title' },
        card5Description: { type: 'wysiwyg', label: 'Card 5 Description' },
        card5Link: { type: 'link', label: 'Card 5 Link', enabled: false },
        card6Image: {
          type: 'media',
          label: 'Card 6 Image',
          breakpoints: { lg: { width: 500, height: 450 } },
        },
        card6Title: { type: 'text', label: 'Card 6 Title' },
        card6Description: { type: 'wysiwyg', label: 'Card 6 Description' },
        card6Link: { type: 'link', label: 'Card 6 Link', enabled: false },
      },
      name: {
        label: 'Tall Six Image Cards',
        group: 'Wyndham',
        layouts: ['WyndhamCancun', 'Wyndham', 'WyndhamPlaya'],
      },
      tags: ['Card', 'Image'],
      templates: [
        {
          label: 'Tall Six Image Cards',
          fields: {
            backgroundColor: { value: '#92dbd0' },
            card1Description: {
              value:
                '<p><strong>Beach Bar<\/strong><br>Enjoy the ocean view while our bartenders make you a margarita or a cool-off drink.<br><br><strong>Location:&nbsp;<\/strong>Beachside<br><\/p>',
            },
            card1Image: {
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/wyndham_cancun\/dining\/wyndham-alltra-cancun-social-media-shots-83.jpg',
            },
            card1Title: { value: 'Las Olas Bar' },
            card2Description: {
              value:
                '<p><strong>Bar<\/strong><br>You can\u2019t miss this lobby bar hot spot, featuring live music.<br><br><strong>Location:<\/strong>&nbsp;Lobby<br><\/p>',
            },
            card2Image: {
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/wyndham_cancun\/wyndham-alltra-cancun-entertainment-vertical-01.jpg',
            },
            card2Title: { value: 'Kaa Bar & Lounge' },
            card3Description: {
              value:
                '<p><strong>Vip Lounge<br><\/strong>Chill out and help yourself to a selection of hors d\u2019oeuvres, delicious snacks and a DIY open bar.<br><br><strong>Location:<\/strong>&nbsp;6TH Floor<br><\/p>',
            },
            card3Image: {
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/wyndham_cancun\/dining\/lv-3x2-flat-food-02.jpg',
            },
            card3Title: { value: 'Alltra VIP Lounge' },
            card4Description: {
              value:
                '<p><strong>Coffee Bar<br><\/strong>Refuel with a classic cup or a creative caffeinated concoction from this causal, cool coffee house.<\/p><p><br><strong>Location: <\/strong>Ground Floor<br><\/p><p><\/p>',
            },
            card4Image: {
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/wyndham_cancun\/dining\/alltra-coffee.jpg',
            },
            card4Title: { value: 'Caf\u00E9 M\u00E9xico' },
            card5Description: {
              value:
                '<p><strong>Dessert Parlor<\/strong><br>Kick back and relax with pastries, sweets, and other delicious dessert items.<br><br><strong>Location:<\/strong> Ground Floor<br><\/p>',
            },
            card5Image: {
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/wyndham_cancun\/wyndham-alltra-cancun-cravings-02.jpg',
            },
            card5Title: { value: 'Cravings' },
            card6Description: {
              value:
                '<p><strong>Bar<br><\/strong>Join new amigos and old for icy brews, colorful cocktails and other refreshments poured poolside.<\/p><p><br><strong>Location:<\/strong> Pool<br><br><\/p>',
            },
            card6Image: {
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/wyndham_cancun\/dining\/2021\/wyndham-alltra-cancun-agave-poolside-bar-couple-service-2.jpg',
            },
            card6Title: { value: 'Agave Bar' },
            title: { value: 'Bars & Lounges' },
          },
        },
      ],
    })
  },
}
</script>

<template>
  <div class="relative py-12" :style="backgroundStyles">
    <div class="relative z-10">
      <div
        v-if="fields.title.enabled"
        class="flex justify-center title-paragraph relative mb-8"
      >
        <div
          class="container text-center items-center flex flex-col relative z-10"
          :class="{ 'lg:flex-row': fields.twoColumns.value }"
        >
          <div
            class="titles flex flex-col items-center"
            :class="{
              'w-full lg:w-3/4 mb-8': !fields.twoColumns.value,
              'lg:w-1/2 lg:pr-12': fields.twoColumns.value,
            }"
          >
            <div
              v-if="fields.pretitle.enabled"
              class="font-bold uppercase text-grey mb-2"
              v-html="fields.pretitle.value"
            />
            <h1 class="text-3xl uppercase title font-light" v-html="fields.title.value" />
            <div
              v-if="fields.subtitle.enabled"
              class="mt-2"
              v-html="fields.subtitle.value"
            />
          </div>
          <div :class="wysiwygWidth" class="wysiwyg" v-html="fields.introduction.value" />
        </div>
      </div>

      <collection-six-image-cards :fields="fields" />
    </div>

    <div class="absolute inset-0 z-0 overflow-hidden">
      <wyndham-curvy-lines
        class="text-white absolute inset-0"
        style="transform: scale(5.5)"
      />
    </div>
  </div>
</template>
