<script setup lang="ts">
import type {
  VoixCheckboxFieldInterface,
  VoixMediaFieldInterface,
  VoixNumberFieldInterface,
  VoixSelectFieldInterface,
  VoixTextFieldInterface,
  VoixWysiwygFieldInterface,
} from '@voix/types'
import { findLastKey } from 'lodash'

const props = defineProps<{
  fields: {
    media: VoixMediaFieldInterface
    showLogo: VoixCheckboxFieldInterface
  }
}>()
defineSlice({
  name: {
    label: 'Kimpton Hero',
    group: 'Kimpton',
    layouts: ['Kimpton'],
  },
  tags: ['Hero', 'Media'],
  preview: 'SlicesKimptonHero.jpg',

  fields: {
    media: {
      type: 'media',
      label: 'Media',
      breakpoints: {
        'default': { width: 1400 },
        '2xl': { width: 2000 },
      },
    },
    showLogo: { type: 'checkbox', label: 'Show Logo', default: false },
  },
})
</script>

<template>
  <div class="relative h-screen">
    <VoixMedia :field="props.fields.media" class="w-full h-full object-cover" />

    <div
      v-if="fields.showLogo.value"
      class="absolute inset-0 flex justify-center items-center"
    >
      <LogosKimpton class="w-1/2 invert" />
    </div>
  </div>
</template>
