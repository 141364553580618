<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    // calculates the number of slides in the swiper
    slides() {
      if (this.$voix.breakpoint.isLessThan('lg'))
        return 1

      return 4
    },
  },
  mounted() {
    defineSlice({
      slots: [{ name: 'default' }],
      description: 'Container and scripts to hold credibility logos.',
      fields: {
        image: {
          type: 'media',
          label: 'Image',
          breakpoints: { default: { width: 1400 } },
        },
      },
      name: { label: 'Awards Slider', group: 'Playa Collection', layouts: ['*'] },
      tags: ['Container', 'Slider'],
      templates: [{ label: 'Awards Slider', fields: [] }],
    })
  },
}
</script>

<template>
  <VoixMedia v-slot="slotProps" :field="fields.image" background>
    <div
      class="flex justify-center z-10 relative overflow-hidden credibility-slider bg-cover bg-center"
      :style="{ backgroundImage: `url(${slotProps.image})` }"
    >
      <div class="md:p-12 md:px-10 py-8 container text-center flex flex-col items-center">
        <div class="relative shadow-lg md:w-4/5 md:px-8 bg-abs-white w-full">
          <GeneralSwiper
            :looping="true"
            :pagination="false"
            :slides-per-view="slides"
            :space-between="80"
            arrow-color="#666"
            class="p-12 pb-8"
            slide-class="px-8 flex justify-center"
          >
            <VoixSlice
              v-for="s in slice.elements"
              :key="s.id"
              class="w-full lg:w-3/4"
              :slice="s"
            />

            <template #previous>
              <IconsLeftArrow
                v-if="slice.elements && slice.elements.length > 4"
                color="#6e7275"
                :w="20"
                :h="20"
              />
              <div v-else />
            </template>
            <template #next>
              <IconsRightArrow
                v-if="slice.elements && slice.elements.length > 4"
                color="#6e7275"
                :w="20"
                :h="20"
              />
              <div v-else />
            </template>
          </GeneralSwiper>
        </div>
      </div>
    </div>
  </VoixMedia>
</template>
