<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      name: {
        label: 'Seadust TItle Paragraph',
        group: 'seadust',
        layouts: ['Blocks'],
      },
      tags: ['Title', 'Paragraph'],
      fields: {
        title: { type: 'text', label: 'Title', name: 'title' },
        content: { type: 'wysiwyg', label: 'Content', name: 'content' },
      },
      slots: [],
    })
  },
}
</script>

<template>
  <div class="introduction-inner">
    <section class="introduction-inner__container">
      <div v-if="fields.title.value" class="introduction-inner__group">
        <h2 class="introduction-inner__title" v-html="fields.title.value" />
      </div>
      <p
        v-if="fields.content.value"
        class="introduction-inner__paragraph"
        v-html="fields.content.value"
      />
    </section>
  </div>
</template>
