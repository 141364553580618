<script>
import { usePageStore } from '@voix/store/pageStore'
import anime from 'animejs/lib/anime.es.js'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isVisible: false,
    }
  },
  mounted() {
    defineSlice({
      fields: {
        image1: {
          type: 'media',
          label: 'Image 1 (Portrait)',
          breakpoints: { lg: { width: 285, height: 322 } },
        },
        image2: {
          type: 'media',
          label: 'Image 2 (Landscape)',
          breakpoints: { lg: { width: 391, height: 290 } },
        },
        logoImage: { type: 'media', label: 'Logo Image (Above or instead of title)' },
        title: { type: 'text', label: 'Title', editorLabel: true },
        description: { type: 'wysiwyg', label: 'Description' },
      },
      name: {
        label: 'Two Pane Text Image',
        group: 'Playa Collection',
        layouts: ['PlayaCollection', 'HiltonYucatan'],
      },
      tags: ['Text', 'Image'],
      templates: [
        {
          label: 'Two Pane Text Image',
          fields: {
            description: {
              value:
                '<p>The adults-only Yucatan Playa del Carmen is an intimate and chic boutique resort located on the most desirable part of Fifth Avenue and near some of the Riviera Maya\u2019s top excursions and attractions. Take in spirited art and design at every turn and enjoy sophisticated amenities like modern suites, indulgent food and drinks, and a vibrant rooftop pool included in your stay. Plus, beachgoers will enjoy the option of VIP access to Hilton Playa del Carmen\u2019s stunning, private beachfront just minutes away.<\/p><p><a href="https:\/\/www.playaresorts.com\/press-releases\/yucatan-resort-playa-del-carmen" rel="noopener noreferrer nofollow">Read The Full Story<\/a><\/p>',
            },
            image1: {
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Hilton_Yucatan\/czmpd-exterior-02.jpg',
            },
            image2: {
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Hilton_Yucatan\/czmpd-yucatan-resort-rooftop-pool-snack-bar-01.JPG',
            },
            title: { value: 'THE YUCATAN RESORT' },
          },
        },
      ],
    })

    anime({
      targets: ['.two-pane-image1', '.two-pane-image2'],
      translateX: 5000,
      opacity: 0,
      duration: 2000,
    })
  },
  methods: {
    onVisibilityChange(entry) {
      if (entry.intersectionRatio * 100 > 0) {
        this.isVisible = true
        anime({
          targets: ['.two-pane-image1', '.two-pane-image2'],
          translateX: 0,
          opacity: 1,
          duration: 3000,
          easing: 'easeOutExpo',
          delay: anime.stagger(200),
        })
      }
      else {
        this.isVisible = false
        anime({
          targets: ['.two-pane-image1', '.two-pane-image2'],
          translateX: 5000,
          opacity: 0,
          duration: 2000,
        })
      }
    },
  },
}
</script>

<template>
  <div
    v-voix-visibility="{ callback: onVisibilityChange, threshold: [0, 0.25] }"
    class="w-full flex justify-center pb-6 lg:pb-16"
  >
    <div class="container flex flex-col items-center lg:flex-row py-8 lg:py-14">
      <div class="w-full lg:w-1/2 pr-8 py-12 text-center lg:text-left px-8">
        <VoixMedia :field="fields.logoImage" style="max-height: 80px" />

        <h3 v-if="fields.title.value" class="font-sans-lato font-light text-3xl mb-4">
          {{ fields.title.value }}
        </h3>
        <div v-html="fields.description.value" />
      </div>
      <div class="relative w-full lg:w-1/2 mx-8 lg:mx-0" style="min-height: 350px">
        <div
          class="absolute top-0 right-0 -mr-16 mt-48"
          style="
            width: 482px;
            height: 287px;
            background-image: url('/imgs/collection/beach-tb-fade.png');
          "
        />
        <div class="absolute top-0 left-0 mt-24 two-pane-image1 shadow">
          <VoixMedia :field="fields.image1" class="block" />
        </div>
        <div class="absolute top-0 left-0 ml-48 mt-0 two-pane-image2 shadow-lg">
          <VoixMedia :field="fields.image2" class="block" />
        </div>
      </div>
    </div>
  </div>
</template>
