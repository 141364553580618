<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      description: 'Magazine Layout',
      fields: {
        image: {
          type: 'media',
          label: 'Background Image',
          breakpoints: {
            lg: { width: 1400, height: 800 },
            sm: { width: 500, height: 500 },
          },
        },
        title: { type: 'text', label: 'Title', default: 'Title' },
        copy: { type: 'wysiwyg', label: 'Copy', default: 'Copy' },
        copyPosition: {
          type: 'select',
          label: 'Copy Position',
          options: { top: 'Top', bottom: 'Bottom' },
        },
        opacity: { type: 'text', label: 'Gradient Opacity 0.0 - 1.0', default: '"1.0"' },
      },
      name: {
        label: 'Infinity Magazine Layout',
        group: 'Infinity Luxury',
        layouts: ['InfinityLuxury'],
      },
      tags: ['Layout'],
      templates: [
        {
          label: 'Magazine Layout',
          fields: {
            copyPosition: { value: 'top' },
            image: {
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/seadust\/home\/seadust-cancun-beach-family.jpg',
            },
          },
        },
      ],
    })
  },
}
</script>

<template>
  <div class="flex justify-center">
    <div class="max-w-[1440px] w-full sans-montserrat">
      <div class="font-sans-montserrat relative lg:h-[800px]">
        <VoixMedia
          :field="fields.image"
          class="hidden lg:block object-cover w-full h-full"
        />

        <div
          class="lg:absolute left-0 right-0 flex flex-col items-center text-center text-white"
          :class="{
            'bottom-0 lg:pt-36 lg:pb-12': fields.copyPosition.value === 'bottom',
            'bottom-0 lg:bottom-auto lg:top-0 lg:pb-36 lg:pt-12':
              fields.copyPosition.value === 'top',
          }"
        >
          <div
            class="absolute z-0 top-0 left-0 right-0 lg:bottom-0 from-black to-black/0 w-full aspect-square lg:aspect-auto"
            :class="{
              'bg-gradient-to-t': fields.copyPosition.value === 'bottom',
              'bg-gradient-to-t lg:bg-gradient-to-b': fields.copyPosition.value === 'top',
            }"
            :style="{
              opacity: fields.opacity.value,
            }"
          />

          <VoixMedia :field="fields.image" class="lg:hidden w-full" />

          <div class="bg-black lg:bg-transparent flex flex-col items-center">
            <div
              class="-mt-24 lg:-mt-0 relative z-10 text-4xl lg:text-6xl uppercase font-thin px-4"
            >
              {{ fields.title.value }}
            </div>
            <div
              class="relative z-10 mt-6 max-w-xl font-thin px-4"
              v-html="fields.copy.value"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
