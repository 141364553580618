<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  mounted() {
    defineSlice({
      name: {
        label: 'Full Width Image Card On Bottom',
        group: 'wyndham',
        layouts: ['Wyndham', 'WyndhamCancun', 'WyndhamPlaya', 'AgentCashPlus'],
      },
      tags: ['Card', 'Image'],
      fields: {
        image: {
          type: 'media',
          label: 'Image',
          breakpoints: {
            default: { width: 400, height: 300 },
            lg: { width: 1200, height: 600 },
          },
          name: 'image',
        },
        title: {
          type: 'text',
          label: 'Title',
          name: 'title',
        },
        subtitle: {
          type: 'text',
          label: 'Subtitle',
          name: 'subtitle',
        },
        description: {
          type: 'wysiwyg',
          label: 'Description',
          name: 'description',
        },
        mobileDescription: {
          type: 'wysiwyg',
          label: 'Mobile Description',
          enabled: 'true,',
          name: 'mobileDescription',
        },
        link: {
          type: 'link',
          label: 'Link',
          enabled: 'false,',
          name: 'link',
        },
      },
      slots: [],
    })
  },
}
</script>

<template>
  <div class="flex justify-center mt-32 mb-24">
    <div class="container">
      <div class="flex justify-center">
        <VoixMedia
          v-if="fields.image.media && fields.image.media.length > 0"
          class="w-full"
          :field="fields.image"
        />
      </div>
      <div class="flex justify-center -mt-16">
        <div
          class="max-w-4xl flex flex-col lg:flex-row bg-grey-lightest text-grey-darkest p-8 py-10"
        >
          <div class="text-3xl Kanit lg:w-2/5 pr-4" v-html="fields.title.value" />
          <div class="lg:w-3/5">
            <div
              v-if="fields.subtitle.value"
              class="font-bold Kanit mb-4"
              v-html="fields.subtitle.value"
            />
            <template v-if="fields.mobileDescription.enabled">
              <div
                class="text-sm mb-6 md:hidden"
                v-html="fields.mobileDescription.value"
              />
              <div
                class="text-sm mb-6 hidden md:block"
                v-html="fields.description.value"
              />
            </template>
            <div v-else class="text-sm mb-6" v-html="fields.description.value" />
            <a
              v-if="fields.link.enabled && fields.link.value"
              :href="fields.link.href"
              :target="fields.link.value.target"
              class="btn btn-ghost inline-block"
              v-html="fields.link.value.text"
            />
            <div><slot /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
