<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isVisible: false,
    }
  },
  mounted() {
    defineSlice({
      description: 'Image with signature title and introduction',
      fields: {
        image: {
          label: 'Image',
          type: 'media',
          breakpoints: { default: { width: 250, height: 300 } },
        },
        smallTitle: { label: 'Small title', type: 'text' },
        largeTitle: { label: 'Large title', type: 'text' },
        h1Title: { label: 'H1 title', type: 'text', enabled: false },
        copy: { label: 'Copy', type: 'wysiwyg' },
        copyPosition: {
          label: 'Copy position',
          type: 'select',
          options: { left: 'Left', right: 'Right' },
        },
      },
      name: {
        label: 'Signature Title',
        group: 'Hyatt',
        layouts: ['HyattZilara', 'HyattZiva', 'AgentCashPlus'],
      },
      tags: ['Wysiwyg', 'Image'],
      templates: [
        {
          label: 'Signature Title',
          fields: {
            copy: {
              value:
                '<p>From the lush gardens to the bespoke Zen Spa, beauty and romance lie ahead at every inch of this sophisticated adults-only paradise, reminiscent of a modern hacienda. Set beside the crystal-clear Caribbean Sea on a sprawling sugar-white sand beach, Hyatt Zilara Riviera Maya welcomes guests to savor the moment in a breathtaking oceanfront retreat, elevated by Hyatt\u2019s renowned all-inclusive luxury.<\/p>',
            },
            copyPosition: { value: 'right' },
            h1Title: { value: 'Hyatt Zilara Riviera Maya' },
            image: {
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/hyatt_zilara_rm\/hzlrm-bag.jpg',
            },
            largeTitle: { value: '' },
            smallTitle: { value: '' },
          },
        },
      ],
    })
  },
  methods: {
    visibilityChanged(entry) {
      if (entry.intersectionRatio * 100 > 0)
        this.isVisible = true
    },
  },
}
</script>

<template>
  <div
    v-voix-visibility="{ callback: visibilityChanged, threshold: 1 }"
    class="my-6 md:my-0 container mx-auto flex justify-center items-center md:space-x-8"
  >
    <div v-if="fields.image.value" class="hidden md:block w-1/5">
      <VoixMedia
        :field="fields.image"
        class="w-full transition-all duration-[1.5s] ease-out"
        :class="{
          '-translate-x-64 opacity-0': !isVisible,
          'translate-x-0 opacity-100': isVisible,
        }"
      />
    </div>
    <div class="md:w-2/5 mx-12 md:mx-0">
      <div class="relative font-cursive-allison md:text-right">
        <div
          class="text-[110px] md:text-[130px] leading-[0.75em] text-gray-300 px-12 md:pl-0 md:pr-16 text-right lg:text-left"
        >
          {{ fields.largeTitle.value }}
        </div>
        <span class="text-5xl absolute top-0 left-0 mt-2 md:pl-20">{{
          fields.smallTitle.value
        }}</span>
      </div>
      <div class="mt-8">
        <h1
          v-if="fields.h1Title.enabled"
          class="text-center md:text-left text-2xl font-normal text-gray-700"
        >
          {{ fields.h1Title.value }}
        </h1>
        <div
          class="wysiwyg font-light text-center md:text-left"
          v-html="fields.copy.value"
        />
      </div>
    </div>
  </div>
</template>
