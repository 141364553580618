<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    tableClasses() {
      return {
        'striped': this.fields.striped.value,
        'w-full': this.fields.fullWidth.value,
        'w-3/4': !this.fields.fullWidth.value,
        'text-xs': this.fields.smallText.value,
      }
    },
  },
  mounted() {
    defineSlice({
      slots: [{ name: 'default' }],
      description: 'Table container',
      fields: {
        striped: { type: 'checkbox', label: 'Striped Rows?', default: false },
        fullWidth: { type: 'checkbox', label: 'Full Width', default: true },
        smallText: { type: 'checkbox', label: 'Small Text', default: true },
      },
      name: { label: 'Table', group: 'Global', layouts: ['*'] },
      tags: ['Table'],
      templates: [{ label: 'Table', fields: [] }],
    })
  },
}
</script>

<template>
  <div class="container">
    <div class="flex justify-center">
      <div class="table sm:overflow-x-scroll" :class="tableClasses">
        <VoixSlice v-for="s in slice.elements" :key="s.id" :slice="s" class="w-32" />
      </div>
    </div>
  </div>
</template>
