<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({})

const activeSection = ref(0)
const activeItem = ref(0)
const sections = ref([
  {
    name: t('arrivalsTitle'),
    image: '/imgs/safe-stay/arrivals.jpg',
    description: t('arrivalsDescription'),
    items: [
      {
        name: t('arrivalsSectionTitle1'),
        description: t('arrivalsSectionDescription1'),
      },
      {
        name: t('arrivalsSectionTitle2'),
        description: t('arrivalsSectionDescription2'),
      },
      {
        name: t('arrivalsSectionTitle3'),
        description: t('arrivalsSectionDescription3'),
      },
      {
        name: t('arrivalsSectionTitle4'),
        description: t('arrivalsSectionDescription4'),
      },
      {
        name: t('arrivalsSectionTitle5'),
        description: t('arrivalsSectionDescription5'),
      },
      {
        name: t('arrivalsSectionTitle6'),
        description: t('arrivalsSectionDescription6'),
      },
    ],
  },
  {
    name: t('accommodationsTitle'),
    image: '/imgs/safe-stay/room.jpg',
    description: t('accommodationsDescription'),
    items: [
      {
        name: t('accommodationsSectionTitle1'),
        description: t('accommodationsSectionDescription1'),
      },
      {
        name: t('accommodationsSectionTitle2'),
        description: t('accommodationsSectionDescription2'),
      },
      {
        name: t('accommodationsSectionTitle3'),
        description: t('accommodationsSectionDescription3'),
      },
      {
        name: t('accommodationsSectionTitle4'),
        description: t('accommodationsSectionDescription4'),
      },
      {
        name: t('accommodationsSectionTitle5'),
        description: t('accommodationsSectionDescription5'),
      },
      {
        name: t('accommodationsSectionTitle6'),
        description: t('accommodationsSectionDescription6'),
      },
    ],
  },
  {
    name: t('diningTitle'),
    image: '/imgs/safe-stay/dining.jpg',
    description: t('diningDescription'),
    items: [
      {
        name: t('diningSectionTitle1'),
        description: t('diningSectionDescription1'),
      },
      {
        name: t('diningSectionTitle2'),
        description: t('diningSectionDescription2'),
      },
      {
        name: t('diningSectionTitle3'),
        description: t('diningSectionDescription3'),
      },
      {
        name: t('diningSectionTitle4'),
        description: t('diningSectionDescription4'),
      },
      {
        name: t('diningSectionTitle5'),
        description: t('diningSectionDescription5'),
      },
      {
        name: t('diningSectionTitle6'),
        description: t('diningSectionDescription6'),
      },
      {
        name: t('diningSectionTitle7'),
        description: t('diningSectionDescription7'),
      },
    ],
  },
  {
    name: t('activitiesTitle'),
    image: '/imgs/safe-stay/activities.jpg',
    description: t('activitiesDescription'),
    items: [
      {
        name: t('activitiesSectionTitle1'),
        description: t('activitiesSectionDescription1'),
      },
      {
        name: t('activitiesSectionTitle2'),
        description: t('activitiesSectionDescription2'),
      },
      {
        name: t('activitiesSectionTitle3'),
        description: t('activitiesSectionDescription3'),
      },
      {
        name: t('activitiesSectionTitle4'),
        description: t('activitiesSectionDescription4'),
      },
      {
        name: t('activitiesSectionTitle5'),
        description: t('activitiesSectionDescription5'),
      },
      {
        name: t('activitiesSectionTitle6'),
        description: t('activitiesSectionDescription6'),
      },
    ],
  },
])

const section = computed(() => {
  return sections.value[activeSection.value]
})

const items = computed(() => {
  return section.value.items
})

function changeSection(newSectionKey) {
  const section
    = typeof newSectionKey === 'object' ? newSectionKey.target.value : newSectionKey
  activeItem.value = 0
  activeSection.value = section
}

function previousItem() {
  if (activeItem.value === 0) {
    activeItem.value = items.value.length - 1
    return activeItem.value
  }

  activeItem.value -= 1
  return activeItem.value
}

function nextItem() {
  if (activeItem.value === items.value.length - 1) {
    activeItem.value = 0
    return activeItem.value
  }

  activeItem.value += 1
  return activeItem.value
}
defineSlice({
  name: { label: 'Safe Stay', group: 'global', layouts: ['Blocks', 'AgentCashPlus'] },
  tags: ['Text', 'Info'],
  fields: null,
  slots: [],
})
</script>

<template>
  <div class="flex justify-center">
    <div class="container">
      <div class="hidden lg:flex flex-col lg:flex-row justify-center mb-8">
        <button
          v-for="(section, key) in sections"
          :key="section.name"
          class="rounded-full uppercase bg-grey-lighter px-8 py-4 mb-1 text-sm font-bold text-grey-darker mx-4 focus:outline-none outline-none"
          :class="{
            'text-white bg-playa-action': activeSection === key,
          }"
          @click="changeSection(key)"
        >
          {{ section.name }}
        </button>
      </div>

      <div class="flex justify-center">
        <div class="px-4 wysiwyg md:w-3/4 w-full mb-8">
          <div class="text-center">
            <p class="mb-2 leading-loose lg:px-0">
              {{ section.description }}
            </p>
          </div>
        </div>
      </div>

      <div class="lg:hidden">
        <div class="relative">
          <select
            class="w-full mb-2 bg-playa-action text-white appearance-none px-6 py-3 text-sm rounded-none font-bold"
            @input="changeSection"
          >
            <option>EXPLORE OUR STAY SAFE PROCEDURES</option>
            <option v-for="(section, key) in sections" :key="section.name" :value="key">
              {{ section.name }}
            </option>
          </select>

          <div class="absolute right-0 top-0 mr-6 mt-3 text-white">
            &#9662;
          </div>
        </div>
      </div>

      <!-- Main Interface -->
      <div class="flex flex-col lg:flex-row">
        <div class="w-full lg:w-3/4 flex justify-end">
          <div>
            <img
              :src="section.image"
              alt="graphic about the section we are currently in"
            >
          </div>
        </div>
        <div class="bg-grey-lightestest w-full lg:w-1/4">
          <div class="flex justify-between my-4 px-8">
            <div
              class="border-grey-lighter border w-10 h-10 rounded-full flex justify-center items-center text-xl text-grey-dark cursor-pointer"
              @click="previousItem"
            >
              ←
            </div>
            <div class="text-center">
              <div class="font-sans text-base font-bold text-playa-action mb-1">
                {{ activeItem + 1 }}
              </div>
              <div class="font-normal uppercase text-xs text-grey-dark">
                of {{ items.length }}
              </div>
            </div>
            <div
              class="border-grey-light border w-10 h-10 rounded-full flex justify-center items-center text-xl text-grey-dark cursor-pointer"
              @click="nextItem"
            >
              →
            </div>
          </div>
          <ul>
            <li
              v-for="(item, key) in items"
              :key="item.name"
              class="grey-dark font-bold border-grey-lighter border-b-2 text-sm cursor-pointer"
              @click="activeItem = key"
            >
              <div
                class="pt-2 pb-2 px-8 uppercase relative"
                :class="{
                  'bg-playa-action text-white': key === activeItem,
                }"
              >
                {{ item.name }}
                <div
                  class="absolute right-0 top-0 mr-6 mt-2 transition-transform duration-500"
                  :class="{
                    '-rotate-90': key !== activeItem,
                  }"
                >
                  &#9662;
                </div>
              </div>

              <div
                v-if="key === activeItem"
                class="font-normal text-base leading-loose px-8 pt-4 pb-10"
              >
                {{ item.description }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
