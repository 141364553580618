<script setup lang="ts">
import type {
  VoixCheckboxFieldInterface,
  VoixTextFieldInterface,
  VoixWysiwygFieldInterface,
} from '@voix/types'

const props = defineProps<{
  fields: {
    title: VoixTextFieldInterface
    copy: VoixWysiwygFieldInterface
    columns: VoixCheckboxFieldInterface
    openOnStart: VoixCheckboxFieldInterface
  }
}>()

const open = ref(false)

if (props.fields.openOnStart.value)
  open.value = true
defineSlice({
  name: { label: 'Accordion Copy', group: 'sandpiper', layouts: ['Sandpiper'] },
  tags: ['Text', 'Wysiwyg'],
  preview: 'SlicesSandpiperAccordionCopy.jpg',
  fields: {
    title: { type: 'text', label: 'Title', name: 'title' },
    copy: { type: 'wysiwyg', label: 'Copy', name: 'copy' },
    columns: { type: 'checkbox', label: 'Columns?', name: 'columns' },
    openOnStart: { type: 'checkbox', label: 'Open on Start?', name: 'openOnStart' },
  },
})
</script>

<template>
  <div class="px-4 md:container py-12">
    <button
      class="group w-full pb-1 duration-200 flex justify-between items-center border-b border-gray-300"
      @click="open = !open"
    >
      <span class="text-lg font-medium group-hover:translate-x-1 duration-300">{{
        fields.title.value
      }}</span>
      <Icon
        class="w-8 h-8 group-hover:-translate-x-1 duration-300"
        :class="{ 'rotate-90': open }"
        name="heroicons:chevron-right-20-solid"
      />
    </button>

    <div
      class="overflow-hidden duration-300 leading-8 pt-4"
      :class="{
        'max-h-0': !open,
        'max-h-[2000px]': open,
      }"
    >
      <VoixWysiwyg :field="fields.copy" :class="{ 'columns-2': fields.columns.value }" />
    </div>
  </div>
</template>
