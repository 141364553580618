<script>
export default {
  name: 'InlineHtml5Video',
  props: {
    devise: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    size() {
      if (this.$voix.breakpoint === 'mobile')
        return 360
      else if (this.$voix.breakpoint === 'tablet')
        return 720

      return 1080
    },

    videoUrl() {
      if (this.devise.fields.legacyVimeoId.value) {
        return (
          `https://playa-cms-assets.s3.amazonaws.com/media/videos/v42021/${
             this.devise.fields.legacyVimeoId.value
             }-${
             this.size
             }.mp4`
        )
      }
      return ''
    },

    videoStyles() {
      if (this.devise.fields.maxWidth.enabled)
        return { maxWidth: `${this.devise.fields.maxWidth.value}px` }

      return {}
    },
  },
}
</script>

<template>
  <video
    class="w-full"
    :style="videoStyles"
    :poster="devise.fields.poster.media.large"
    controls
    controlsList="nodownload"
  >
    <source :src="videoUrl" type="video/mp4">
  </video>
</template>
