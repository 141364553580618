<script setup lang="ts">
import type {
  SliceInterface,
  VoixCheckboxFieldInterface,
  VoixTextFieldInterface,
  VoixWysiwygFieldInterface,
} from '@voix/types'

const props = defineProps<{
  slice: SliceInterface
  fields: {
    copy: VoixWysiwygFieldInterface
    tableTitle: VoixTextFieldInterface
    tableLeftHeader: VoixTextFieldInterface
    tableCentertHeader: VoixTextFieldInterface
    tableRightHeader: VoixTextFieldInterface
    tableTitleEnabled: VoixCheckboxFieldInterface
    tableLeftColumnEnabled: VoixCheckboxFieldInterface
    tableCenterColumnEnabled: VoixCheckboxFieldInterface
    tableRightColumnEnabled: VoixCheckboxFieldInterface
    tableHeadersEnabled: VoixCheckboxFieldInterface
    tableDefaultDisplay: VoixCheckboxFieldInterface
  }
}>()

defineSlice({
  name: {
    label: 'Privacy Policy Table',
    group: 'Privacy Policy',
    layouts: ['Blocks'],
  },
  tags: ['Table'],
  description: 'Privacy Policy Table Slice',
  preview: 'SlicesPlayaresortsPrivacyPolicyTable.jpg',
  slots: [
    {
      name: 'default',
      allowedElements: ['Privacy Policy Table Row'],
    },
  ],
  fields: {
    copy: {
      type: 'wysiwyg',
      label: 'Privacy Policy Copy',
    },
    tableTitle: {
      type: 'text',
      label: 'Table Title',
    },
    tableLeftHeader: {
      type: 'text',
      label: 'Table Left Header',
    },
    tableCentertHeader: {
      type: 'text',
      label: 'Table Center Header',
    },
    tableRightHeader: {
      type: 'text',
      label: 'Table Right Header',
    },
    tableTitleEnabled: {
      type: 'checkbox',
      label: 'Enable Title',
      group: 'Table Settings',
      default: true,
    },
    tableLeftColumnEnabled: {
      type: 'checkbox',
      label: 'Enable Left Column',
      group: 'Table Settings',
      default: true,
    },
    tableCenterColumnEnabled: {
      type: 'checkbox',
      label: 'Enable Center Column',
      group: 'Table Settings',
      default: true,
    },
    tableRightColumnEnabled: {
      type: 'checkbox',
      label: 'Enable Right Column',
      group: 'Table Settings',
      default: true,
    },
    tableHeadersEnabled: {
      type: 'checkbox',
      label: 'Enable Headers',
      group: 'Table Settings',
      default: true,
    },
    tableDefaultDisplay: {
      type: 'checkbox',
      label: 'Default Display',
      group: 'Table Settings',
      description: 'Display the table by default. Requires page refresh.',
      default: false,
    },
  },
  templates: [
    {
      label: 'Privacy Policy Table',
      fields: {
        copy: {
          value: `<p>Please click below to see what information we collect through your use of our Sites.</p>`,
        },
        tableTitle: {
          value: 'More Information',
        },
        tableLeftHeader: {
          value: 'Left Column Header',
        },
        tableCentertHeader: {
          value: 'Center Column Header',
        },
        tableRightHeader: {
          value: 'Right Column Header',
        },
      },
    },
  ],
})

// State
const displayTable = ref<boolean>(false)
const tableHeaders = ref<HTMLTableRowElement | null>(null)

// Computed
const tableHeadersHeight = computed(() => {
  if (tableHeaders.value) {
    return `${tableHeaders.value.offsetHeight}px`
  }
  return 0
})

// Methods
function toggleTableDisplay() {
  displayTable.value = !displayTable.value
}

// Lifecycle
onMounted(() => {
  displayTable.value = props.fields.tableDefaultDisplay.value as boolean
})
</script>

<template>
  <div class="flex flex-col justify-center items-center p-8 container select-none">
    <!-- Copy -->
    <VoixWysiwyg :field="fields.copy" />

    <!-- Title -->
    <div
      v-if="fields.tableTitleEnabled.value"
      class="flex items-center justify-between w-full mt-8 border-[1px] border-gray-600 bg-gray-200 p-2 font-bold cursor-pointer"
      :class="{
        'border-x-[1px] border-t-[1px] border-b-[0]':
          !fields.tableHeadersEnabled.value && displayTable,
      }"
      @click="toggleTableDisplay"
    >
      <!-- Single Title -->
      <div class="flex items-center justify-between w-full text-base lg:text-lg">
        {{ fields.tableTitle.value }}

        <Icon
          v-show="!displayTable"
          class="w-8 h-8"
          name="heroicons:chevron-up-20-solid"
        />
        <Icon
          v-show="displayTable"
          class="w-8 h-8"
          name="heroicons:chevron-down-20-solid"
        />
      </div>
    </div>

    <!-- Table -->
    <div
      class="w-full h-0 overflow-auto"
      :class="{
        'mt-8 border-y-[1px] border-x-[0] border-gray-600 cursor-pointer': !fields
          .tableTitleEnabled.value,
        'h-auto': displayTable || (displayTable && !fields.tableTitleEnabled.value),
        'h-table-header': !displayTable && !fields.tableTitleEnabled.value,
        'overflow-hidden': !displayTable && !fields.tableTitleEnabled.value,
      }"
      @click="toggleTableDisplay"
    >
      <table class="w-full transition-all ease-in-out duration-200">
        <tbody>
          <!-- Headers -->
          <tr
            v-if="fields.tableHeadersEnabled.value"
            ref="tableHeaders"
            class="bg-gray-200 p-8 text-left"
          >
            <th
              v-if="fields.tableLeftColumnEnabled.value"
              class="border-b-[1px] border-x-[1px] border-gray-600 p-2"
            >
              {{ fields.tableLeftHeader.value }}
            </th>
            <th
              v-if="fields.tableCenterColumnEnabled.value"
              class="border-b-[1px] border-x-[1px] border-gray-600 p-2"
            >
              {{ fields.tableCentertHeader.value }}
            </th>
            <th
              v-if="fields.tableRightColumnEnabled.value"
              class="border-gray-600 p-2"
              :class="{
                'border-b-[1px] border-x-[1px]': fields.tableTitleEnabled.value,
                'relative border-b-[0] border-x-[0] border-r-[1px]': !fields
                  .tableTitleEnabled.value,
              }"
            >
              <span>
                {{ fields.tableRightHeader.value }}
              </span>

              <Icon
                v-if="!displayTable && !fields.tableTitleEnabled.value"
                class="w-8 h-8"
                :class="{
                  'absolute bottom-[25%] right-2 ': fields.tableCenterColumnEnabled.value,
                  'absolute bottom-1 right-2': !fields.tableCenterColumnEnabled.value,
                }"
                name="heroicons:chevron-up-20-solid"
              />
              <Icon
                v-if="displayTable && !fields.tableTitleEnabled.value"
                class="w-8 h-8"
                :class="{
                  'absolute bottom-[25%] right-2 ': fields.tableCenterColumnEnabled.value,
                  'absolute bottom-1 right-2': !fields.tableCenterColumnEnabled.value,
                }"
                name="heroicons:chevron-down-20-solid"
              />
            </th>
          </tr>

          <!-- Rows -->
          <VoixSlice
            v-for="(s, key) in slice.elements"
            :key="key"
            :left-column-copy-enabled="fields.tableLeftColumnEnabled.value"
            :center-column-copy-enabled="fields.tableCenterColumnEnabled.value"
            :right-column-copy-enabled="fields.tableRightColumnEnabled.value"
            class="transition-all ease-in-out duration-200"
            :class="{ 'opacity-1': displayTable, 'opacity-0': !displayTable }"
            :slice="s as SliceInterface"
            :slice-index="key"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
.h-table-header {
  height: v-bind(tableHeadersHeight);
}
</style>
