<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  mounted() {
    defineSlice({
      name: { label: 'Title Subtitle', group: 'global', layouts: ['*'] },
      tags: ['Title', 'Subtitle'],
      fields: {
        title: {
          type: 'text',
          label: 'Title',
          editorLabel: true,
          enabled: false,
          name: 'title',
        },
        subtitle: { type: 'text', label: 'Sub-title', enabled: false, name: 'subtitle' },
      },
      slots: [],
    })
  },
}
</script>

<template>
  <div
    v-if="fields.title.enabled || fields.subtitle.enabled"
    class="relative mt-8 md:mt-0 flex justify-center"
  >
    <div
      class="px-8 md:px-16 container flex flex-col justify-center items-center text-center"
    >
      <h1
        v-if="fields.title.enabled"
        class="mb-8 font-light text-4xl"
        v-html="fields.title.value"
      />
      <h3
        v-if="fields.subtitle.enabled"
        class="mb-8 run-through"
        v-html="fields.subtitle.value"
      />
    </div>
  </div>
</template>
