<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      description: 'Slice Image',
      fields: {
        image: { type: 'media', label: 'Image', breakpoints: { default: { width: 200, height: 200 } } },
        name: { type: 'text', label: 'Name' },
        info: { type: 'wysiwyg', label: 'Information' },
      },
      name: { label: 'Staff', group: 'Agents Portal', layouts: ['AgentCashPlus'] },
      tags: [],
      templates: [{
        label: 'Staff',
        fields: {
          image: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Agents_Portal\/2021\/rose2021.jpg' },
          info: { value: '<p>Vice President,<br>Sales-Canada<br>rcosentino@playaresorts.com<\/p>' },
          name: { value: 'Rose Cosentino' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="flex flex-col items-center">
    <div class="rounded-full overflow-hidden h-40 w-40">
      <VoixMedia :field="fields.image" class="w-full h-full object-cover" />
    </div>
    <div class="mt-3 flex flex-col items-center text-sm">
      <div class="font-bold">
        {{ fields.name.value }}
      </div>
    </div>
    <div v-html="fields.info.value" />
  </div>
</template>
