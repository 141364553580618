<script setup lang="ts">
import { ref } from 'vue'
import { usePageStore } from '@voix/store/pageStore'
import axios from 'axios'

import { useI18n } from 'vue-i18n'

const { t } = useI18n({})
const { $playa } = useNuxtApp()
const pageStore = usePageStore()

const date = ref(null)
const showForm = ref(false)
const form = ref({
  firstName: '',
  lastName: '',
  phoneNumber: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  PrefHilton: 'No',
  PrefPJ: 'No',
  PrefHyatt: 'No',
  PrefSCC: 'No',
  PrefJewel: 'No',
  PrefJG: 'No',
  PrefWeddings: 'No',
  OptedIn: 'No',
  IsAgent: 'No',
})
const success = ref({
  show: false,
  message: 'Success',
})
const error = ref({
  message: 'Success',
})
const shouldShowError = ref(false)

const validationErrors = ref([])

function submit() {
  if (formValidates()) {
    const formData = Object.assign({}, form.value)

    formData.formId = '4C2CFAFB-F72B-4A62-A824-FC4D527C1335'

    axios
      .post(
        `${$playa.rest_api_base_url}/divinci/web-brochure-request`,
        formData,
      )
      .then(() => {
        showSuccess()
      })
      .catch(() => {
        showError(t('there-was-a-problem'))
      })
  }
}

function formValidates() {
  // reset
  shouldShowError.value = false
  validationErrors.value = []

  if (form.value.OptedIn === 'No') {
    validationErrors.value.push(
      'Please agree to receive electronic communications.',
    )
  }
  if (form.value.firstName === null || form.value.firstName === '')
    validationErrors.value.push('First name is required.')

  if (form.value.lastName === null || form.value.lastName === '')
    validationErrors.value.push('Last name is required.')

  if (form.value.address1 === null || form.value.address1 === '')
    validationErrors.value.push('Street Address is required.')

  if (form.value.city === null || form.value.city === '')
    validationErrors.value.push('City is required.')

  if (form.value.state === null || form.value.state === '')
    validationErrors.value.push('State is required.')

  if (form.value.zip === null || form.value.zip === '')
    validationErrors.value.push('Zip is required.')

  if (form.value.country === '')
    validationErrors.value.push('Please select a country.')

  if (validationErrors.value.length > 0) {
    error.value.message = 'There were validation errors.'
    shouldShowError.value = true
  }

  return validationErrors.value.length === 0
}

function showError(msg) {
  error.value.message = msg
  shouldShowError.value = true
}

function showSuccess() {
  success.value.show = true
  success.value.message = t('thank-you')
}

form.value.Language_Code = pageStore.currentPage?.language_code
defineSlice({
  name: { label: 'Brochure Request', group: 'global', layouts: ['Blocks'] },
  tags: ['Form'],
  preview: 'SlicesGlobalFormsBrochureRequest.jpg',
  slots: [],
})
</script>

<template>
  <div class="flex justify-center py-8">
    <div
      id="groups-request-for-quote"
      class="master-content-wrapper pt pb sp50"
    >
      <div class="container gutters">
        <div class="form">
          <div>
            <form class="my-8">
              <template v-if="!success.show">
                <div
                  class="flex justify-center flex-col flex-row md:flex-row items-stretch -mx-8"
                >
                  <div
                    class="flex flex-col relative mb-10 md:mb-0 flex items-stretch md:w-1/2 lg:w-1/3 mx-4"
                  >
                    <div class="general-form">
                      <div class="mt-8">
                        <label for="firstName">First*</label>
                        <input
                          id="firstName"
                          v-model="form.firstName"
                          class="border w-full"
                          type="text"
                          name="First"
                        >
                      </div>

                      <div class="mt-8">
                        <label for="phone">Phone</label>
                        <input
                          id="phone"
                          v-model="form.phoneNumber"
                          class="border w-full"
                          name="Phone"
                          type="text"
                        >
                      </div>

                      <div class="mt-8">
                        <label for="address1">Street Address*</label>
                        <input
                          id="address1"
                          v-model="form.address1"
                          class="border w-full"
                          type="text"
                          name="Street Address"
                        >
                      </div>

                      <div class="mt-8">
                        <label for="city">City*</label>
                        <input
                          id="city"
                          v-model="form.city"
                          class="border w-full"
                          type="text"
                          name="City"
                        >
                      </div>

                      <div class="mt-8">
                        <label for="zip">ZIP / Postal Code*</label>
                        <input
                          id="zip"
                          v-model="form.zip"
                          class="border w-full"
                          type="text"
                          name="ZIP / Postal Code"
                        >
                      </div>

                      <div class="mt-8">
                        <div class="uppercase font-bold text-xs mb-4">
                          Which hotel brands are you interested in?
                        </div>
                        <label><input
                          v-model="form.PrefHilton"
                          class="mr-2"
                          true-value="Yes"
                          false-value="No"
                          type="checkbox"
                        >HILTON ALL-INCLUSIVE RESORTS</label>
                        <label><input
                          v-model="form.PrefHyatt"
                          class="mr-2"
                          true-value="Yes"
                          false-value="No"
                          type="checkbox"
                        >HYATT ZILARA & HYATT ZIVA</label>
                        <label><input
                          v-model="form.PrefSCC"
                          class="mr-2"
                          true-value="Yes"
                          false-value="No"
                          type="checkbox"
                        >SANCTUARY CAP CANA</label>
                        <label><input
                          v-model="form.PrefJewel"
                          class="mr-2"
                          true-value="Yes"
                          false-value="No"
                          type="checkbox"
                        >JEWEL RESORTS</label>
                        <label><input
                          v-model="form.PrefJG"
                          class="mr-2"
                          true-value="Yes"
                          false-value="No"
                          type="checkbox"
                        >JEWEL GRANDE</label>
                      </div>
                    </div>

                    <div
                      v-if="shouldShowError"
                      v-cloak
                      class="mt-6 relative pt4 pb-4 text-red"
                    >
                      <h4>There was an error:</h4>
                      <ul class="list">
                        <li v-for="error in validationErrors">
                          {{ error }}
                        </li>
                      </ul>
                    </div>

                    <button
                      type="button"
                      class="btn raised clear mt-8"
                      @click="submit"
                    >
                      Submit
                    </button>
                  </div>

                  <div
                    class="flex flex-col relative mb-10 md:mb-0 flex items-stretch md:w-1/2 lg:w-1/3 mx-4"
                  >
                    <div class="general-form">
                      <div class="mt-8">
                        <label for="lastName">Last*</label>
                        <input
                          id="lastName"
                          v-model="form.lastName"
                          class="border w-full"
                          name="Last"
                          type="text"
                        >
                      </div>

                      <div class="mt-8">
                        <label for="address2">Address Line 2</label>
                        <input
                          id="address2"
                          v-model="form.address2"
                          class="border w-full"
                          type="text"
                          name="Address Line 2"
                        >
                      </div>

                      <div class="mt-8">
                        <label for="state">State / Province / Region*</label>
                        <input
                          id="state"
                          v-model="form.state"
                          class="border w-full"
                          type="text"
                          name="State / Province / Region"
                        >
                      </div>

                      <div class="mt-8">
                        <label for="country">Country*</label>
                        <select
                          id="country"
                          v-model="form.country"
                          class="border w-full"
                          name="Country"
                        >
                          <option value selected="selected" disabled>
                            Select Your Country
                          </option>
                          <option value="US">
                            United States
                          </option>
                          <option value="CA">
                            Canada
                          </option>
                          <option value="GB">
                            United Kingdom
                          </option>
                          <option value="AF">
                            Afghanistan
                          </option>
                          <option value="AX">
                            Aland Islands
                          </option>
                          <option value="AL">
                            Albania
                          </option>
                          <option value="DZ">
                            Algeria
                          </option>
                          <option value="AS">
                            American Samoa
                          </option>
                          <option value="AD">
                            Andorra
                          </option>
                          <option value="AO">
                            Angola
                          </option>
                          <option value="AI">
                            Anguilla
                          </option>
                          <option value="AQ">
                            Antarctica
                          </option>
                          <option value="AG">
                            Antigua &amp; Barbuda
                          </option>
                          <option value="AR">
                            Argentina
                          </option>
                          <option value="AM">
                            Armenia
                          </option>
                          <option value="AW">
                            Aruba
                          </option>
                          <option value="AU">
                            Australia
                          </option>
                          <option value="AT">
                            Austria
                          </option>
                          <option value="AZ">
                            Azerbaijan
                          </option>
                          <option value="BS">
                            Bahamas
                          </option>
                          <option value="BH">
                            Bahrain
                          </option>
                          <option value="BD">
                            Bangladesh
                          </option>
                          <option value="BB">
                            Barbados
                          </option>
                          <option value="BY">
                            Belarus
                          </option>
                          <option value="BE">
                            Belgium
                          </option>
                          <option value="BZ">
                            Belize
                          </option>
                          <option value="BJ">
                            Benin
                          </option>
                          <option value="BM">
                            Bermuda
                          </option>
                          <option value="BT">
                            Bhutan
                          </option>
                          <option value="BO">
                            Bolivia
                          </option>
                          <option value="BQ">
                            Bonaire
                          </option>
                          <option value="BA">
                            Bosnia And Herzegowina
                          </option>
                          <option value="BW">
                            Botswana
                          </option>
                          <option value="BV">
                            Bouvet Island
                          </option>
                          <option value="BR">
                            Brazil
                          </option>
                          <option value="IO">
                            British Indian Ocean Territory
                          </option>
                          <option value="VG">
                            British Virgin Islands
                          </option>
                          <option value="BN">
                            Brunei Darussalam
                          </option>
                          <option value="BG">
                            Bulgaria
                          </option>
                          <option value="BF">
                            Burkina Faso
                          </option>
                          <option value="BI">
                            Burundi
                          </option>
                          <option value="KH">
                            Cambodia
                          </option>
                          <option value="CM">
                            Cameroon
                          </option>
                          <option value="CV">
                            Cape Verde
                          </option>
                          <option value="KY">
                            Cayman Islands
                          </option>
                          <option value="CF">
                            Central African Republic
                          </option>
                          <option value="TD">
                            Chad
                          </option>
                          <option value="CD">
                            Channel Islands
                          </option>
                          <option value="CL">
                            Chile
                          </option>
                          <option value="CN">
                            China
                          </option>
                          <option value="CX">
                            Christmas Island
                          </option>
                          <option value="CC">
                            Cocos (keeling) Islands
                          </option>
                          <option value="CO">
                            Colombia
                          </option>
                          <option value="KM">
                            Comoros
                          </option>
                          <option value="CG">
                            Congo
                          </option>
                          <option value="CK">
                            Cook Islands
                          </option>
                          <option value="CR">
                            Costa Rica
                          </option>
                          <option value="CI">
                            Cote D'ivoire/ivory Coast
                          </option>
                          <option value="HR">
                            Croatia
                          </option>
                          <option value="CU">
                            Cuba
                          </option>
                          <option value="BW">
                            Curacoa
                          </option>
                          <option value="CY">
                            Cyprus
                          </option>
                          <option value="CZ">
                            Czech Republic
                          </option>
                          <option value="CI">
                            Cã´te D'ivoire
                          </option>
                          <option value="CD">
                            Democratic Republic Of The Congo
                          </option>
                          <option value="DK">
                            Denmark
                          </option>
                          <option value="DJ">
                            Djibouti
                          </option>
                          <option value="DM">
                            Dominica
                          </option>
                          <option value="DO">
                            Dominican Republic
                          </option>
                          <option value="TP">
                            East Timor
                          </option>
                          <option value="EC">
                            Ecuador
                          </option>
                          <option value="EG">
                            Egypt
                          </option>
                          <option value="SV">
                            El Salvador
                          </option>
                          <option value="EN">
                            England
                          </option>
                          <option value="GQ">
                            Equatorial Guinea
                          </option>
                          <option value="ER">
                            Eritrea
                          </option>
                          <option value="EE">
                            Estonia
                          </option>
                          <option value="ET">
                            Ethiopia
                          </option>
                          <option value="FO">
                            Faeroe Islands
                          </option>
                          <option value="FK">
                            Falkland Islands
                          </option>
                          <option value="ZZ">
                            Fictitious Points
                          </option>
                          <option value="FJ">
                            Fiji
                          </option>
                          <option value="FI">
                            Finland
                          </option>
                          <option value="FR">
                            France
                          </option>
                          <option value="GF">
                            French Guiana
                          </option>
                          <option value="PF">
                            French Polynesia
                          </option>
                          <option value="TF">
                            French Southern Territories
                          </option>
                          <option value="GA">
                            Gabon
                          </option>
                          <option value="GM">
                            Gambia
                          </option>
                          <option value="GE">
                            Georgia
                          </option>
                          <option value="DE">
                            Germany
                          </option>
                          <option value="GH">
                            Ghana
                          </option>
                          <option value="GI">
                            Gibralter
                          </option>
                          <option value="GR">
                            Greece
                          </option>
                          <option value="GL">
                            Greenland
                          </option>
                          <option value="GD">
                            Grenada
                          </option>
                          <option value="GP">
                            Guadeloupe
                          </option>
                          <option value="GU">
                            Guam
                          </option>
                          <option value="GT">
                            Guatemala
                          </option>
                          <option value="GG">
                            Guernsey
                          </option>
                          <option value="GN">
                            Guinea
                          </option>
                          <option value="GW">
                            Guinea-bissau
                          </option>
                          <option value="GY">
                            Guyana
                          </option>
                          <option value="HT">
                            Haiti
                          </option>
                          <option value="HM">
                            Heard And Mcdonald Islands
                          </option>
                          <option value="HN">
                            Honduras
                          </option>
                          <option value="HK">
                            Hong Kong
                          </option>
                          <option value="HU">
                            Hungary
                          </option>
                          <option value="IS">
                            Iceland
                          </option>
                          <option value="IN">
                            India
                          </option>
                          <option value="ID">
                            Indonesia
                          </option>
                          <option value="IR">
                            Iran
                          </option>
                          <option value="IQ">
                            Iraq
                          </option>
                          <option value="IE">
                            Ireland
                          </option>
                          <option value="IM">
                            Isle Of Man
                          </option>
                          <option value="IL">
                            Israel
                          </option>
                          <option value="IT">
                            Italy
                          </option>
                          <option value="JM">
                            Jamaica
                          </option>
                          <option value="JP">
                            Japan
                          </option>
                          <option value="JO">
                            Jordan
                          </option>
                          <option value="KZ">
                            Kazakstan
                          </option>
                          <option value="KE">
                            Kenya
                          </option>
                          <option value="KI">
                            Kiribati
                          </option>
                          <option value="KW">
                            Kuwait
                          </option>
                          <option value="KG">
                            Kyrgystan
                          </option>
                          <option value="LA">
                            Lao People's Democratic Republic
                          </option>
                          <option value="LV">
                            Latvia
                          </option>
                          <option value="LB">
                            Lebanon
                          </option>
                          <option value="LS">
                            Lesotho
                          </option>
                          <option value="LR">
                            Liberia
                          </option>
                          <option value="LY">
                            Libyan Arab Jamahiriya
                          </option>
                          <option value="LI">
                            Liechtenstein
                          </option>
                          <option value="LT">
                            Lithuania
                          </option>
                          <option value="LU">
                            Luxembourg
                          </option>
                          <option value="MO">
                            Macau
                          </option>
                          <option value="MK">
                            Macedonia (fyrom)
                          </option>
                          <option value="MG">
                            Madagascar
                          </option>
                          <option value="MW">
                            Malawi
                          </option>
                          <option value="MY">
                            Malaysia
                          </option>
                          <option value="MV">
                            Maldives
                          </option>
                          <option value="ML">
                            Mali
                          </option>
                          <option value="MT">
                            Malta
                          </option>
                          <option value="MH">
                            Marshall Islands
                          </option>
                          <option value="MQ">
                            Martinique
                          </option>
                          <option value="MR">
                            Mauritania
                          </option>
                          <option value="MU">
                            Mauritius
                          </option>
                          <option value="YT">
                            Mayotte
                          </option>
                          <option value="MX">
                            Mexico
                          </option>
                          <option value="FM">
                            Micronesia
                          </option>
                          <option value="MD">
                            Moldova
                          </option>
                          <option value="MC">
                            Monaco
                          </option>
                          <option value="MN">
                            Mongolia
                          </option>
                          <option value="MS">
                            Montserrat
                          </option>
                          <option value="MA">
                            Morocco
                          </option>
                          <option value="MZ">
                            Mozambique
                          </option>
                          <option value="MM">
                            Myanmar
                          </option>
                          <option value="NA">
                            Namibia
                          </option>
                          <option value="NR">
                            Nauru
                          </option>
                          <option value="NP">
                            Nepal
                          </option>
                          <option value="NL">
                            Netherlands
                          </option>
                          <option value="AN">
                            Netherlands Antilles
                          </option>
                          <option value="NC">
                            New Caledonia
                          </option>
                          <option value="NZ">
                            New Zealand
                          </option>
                          <option value="NI">
                            Nicaragua
                          </option>
                          <option value="NE">
                            Niger
                          </option>
                          <option value="NG">
                            Nigeria
                          </option>
                          <option value="NU">
                            Niue
                          </option>
                          <option value="XX">
                            No Country
                          </option>
                          <option value="NF">
                            Norfolk Island
                          </option>
                          <option value="KR">
                            North Korea
                          </option>
                          <option value="MP">
                            Northern Mariana Islands
                          </option>
                          <option value="NO">
                            Norway
                          </option>
                          <option value="OM">
                            Oman
                          </option>
                          <option value="PK">
                            Pakistan
                          </option>
                          <option value="PW">
                            Palau
                          </option>
                          <option value="PS">
                            Palestine, State Of
                          </option>
                          <option value="PA">
                            Panama
                          </option>
                          <option value="PG">
                            Papua New Guinea
                          </option>
                          <option value="PY">
                            Paraguay
                          </option>
                          <option value="PE">
                            Peru
                          </option>
                          <option value="PH">
                            Philippines
                          </option>
                          <option value="PN">
                            Pitcairn
                          </option>
                          <option value="PL">
                            Poland
                          </option>
                          <option value="PT">
                            Portugal
                          </option>
                          <option value="PR">
                            Puerto Rico
                          </option>
                          <option value="QA">
                            Qatar
                          </option>
                          <option value="RE">
                            Reunion
                          </option>
                          <option value="RO">
                            Romania
                          </option>
                          <option value="RU">
                            Russia
                          </option>
                          <option value="RW">
                            Rwanda
                          </option>
                          <option value="SX">
                            Saint Maarten
                          </option>
                          <option value="WS">
                            Samoa
                          </option>
                          <option value="SM">
                            San Marino
                          </option>
                          <option value="ST">
                            Sao Tome And Principe
                          </option>
                          <option value="SA">
                            Saudi Arabia
                          </option>
                          <option value="SN">
                            Senegal
                          </option>
                          <option value="SQ">
                            Serbia And Montenegro
                          </option>
                          <option value="SC">
                            Seychelles
                          </option>
                          <option value="SL">
                            Sierra Leone
                          </option>
                          <option value="SG">
                            Singapore
                          </option>
                          <option value="SK">
                            Slovakia
                          </option>
                          <option value="SI">
                            Slovenia
                          </option>
                          <option value="SB">
                            Solomon Islands
                          </option>
                          <option value="SO">
                            Somalia
                          </option>
                          <option value="ZA">
                            South Africa
                          </option>
                          <option value="GS">
                            South Georgia And The South Sandwich Islands
                          </option>
                          <option value="KP">
                            South Korea
                          </option>
                          <option value="ES">
                            Spain &amp; Canary Islands
                          </option>
                          <option value="LK">
                            Sri Lanka
                          </option>
                          <option value="SH">
                            St. Helena
                          </option>
                          <option value="KN">
                            St. Kitts And Nevis
                          </option>
                          <option value="LC">
                            St. Lucia
                          </option>
                          <option value="PM">
                            St. Pierre And Miquelon
                          </option>
                          <option value="VC">
                            St. Vincent And The Grenadines
                          </option>
                          <option value="SD">
                            Sudan
                          </option>
                          <option value="SR">
                            Suriname
                          </option>
                          <option value="SJ">
                            Svalbard And Jan Mayen Islands
                          </option>
                          <option value="SZ">
                            Swaziland
                          </option>
                          <option value="SE">
                            Sweden
                          </option>
                          <option value="CH">
                            Switzerland
                          </option>
                          <option value="SY">
                            Syrian Arab Republic
                          </option>
                          <option value="TW">
                            Taiwan
                          </option>
                          <option value="TJ">
                            Tajikistan
                          </option>
                          <option value="TZ">
                            Tanzania
                          </option>
                          <option value="TH">
                            Thailand
                          </option>
                          <option value="TG">
                            Togo
                          </option>
                          <option value="TK">
                            Tokelau
                          </option>
                          <option value="TO">
                            Tonga
                          </option>
                          <option value="TT">
                            Trinidad And Tobago
                          </option>
                          <option value="TN">
                            Tunisia
                          </option>
                          <option value="TR">
                            Turkey
                          </option>
                          <option value="TM">
                            Turkmenistan
                          </option>
                          <option value="TC">
                            Turks And Caicos Islands
                          </option>
                          <option value="TV">
                            Tuvalu
                          </option>
                          <option value="VI">
                            U.s. Virgin Islands
                          </option>
                          <option value="UG">
                            Uganda
                          </option>
                          <option value="UA">
                            Ukraine
                          </option>
                          <option value="AE">
                            United Arab Emirates
                          </option>
                          <option value="UM">
                            United States Minor Outlying Islands
                          </option>
                          <option value="UY">
                            Uruguay
                          </option>
                          <option value="UZ">
                            Uzbekistan
                          </option>
                          <option value="VU">
                            Vanuatu
                          </option>
                          <option value="VA">
                            Vatican City State
                          </option>
                          <option value="VE">
                            Venezuela
                          </option>
                          <option value="VN">
                            Viet Nam
                          </option>
                          <option value="WF">
                            Wallis And Futuna Islands
                          </option>
                          <option value="EH">
                            Western Sahara
                          </option>
                          <option value="YE">
                            Yemen
                          </option>
                          <option value="YU">
                            Yugoslavia
                          </option>
                          <option value="ZR">
                            Zaire
                          </option>
                          <option value="ZM">
                            Zambia
                          </option>
                          <option value="ZW">
                            Zimbabwe
                          </option>
                        </select>
                      </div>

                      <div class="mt-8">
                        <label><input
                          v-model="form.PrefWeddings"
                          class="mr-2"
                          true-value="Yes"
                          false-value="No"
                          name="Are you interested in destination weddings?"
                          type="checkbox"
                        >
                          Are you interested in destination weddings?</label>
                      </div>

                      <div class="mt-6">
                        <label>
                          <input
                            v-model="form.OptedIn"
                            class="mr-2"
                            type="checkbox"
                            true-value="Yes"
                            false-value="No"
                          >
                          <template v-if="form.country === 'Canada'">
                            I consent to the use of my e-mail address and name
                            by Playa Hotels & Resorts N.V., and its affiliates
                            and subsidiaries (Playa) for the purposes of sending
                            direct marketing communications regarding special
                            offers and promotions. I am aware that I may
                            withdraw my consent at any time by clicking on the
                            unsubscribe link at the end of each communication. I
                            understand that withdrawing my consent does not
                            affect the lawfulness of the processing of my
                            personal data based on my consent before the
                            withdrawal. For more information on how Playa
                            processes your personal data, and your data
                            protection rights, please consult our privacy notice
                            at
                            <a
                              style="color: white; text-decoration: underline"
                              href="https://www.playaresorts.com/privacy-policy"
                              target="_blank"
                            >https://www.playaresorts.com/privacy-policy.</a>
                          </template>
                          <template v-else>
                            I consent to the use of my e-mail address and name
                            by Playa Hotels & Resorts N.V., and its affiliates
                            and subsidiaries (Playa) for the purposes of sending
                            direct marketing communications regarding special
                            offers and promotions. I am aware that I may
                            withdraw my consent at any time by clicking on the
                            unsubscribe link at the end of each communication. I
                            understand that withdrawing my consent does not
                            affect the lawfulness of the processing of my
                            personal data based on my consent before the
                            withdrawal. For more information on how Playa
                            processes your personal data, and your data
                            protection rights, please consult our privacy notice
                            at
                            <a
                              style="color: white; text-decoration: underline"
                              href="https://www.playaresorts.com/privacy-policy"
                              target="_blank"
                            >https://www.playaresorts.com/privacy-policy.</a>
                          </template>
                        </label>
                      </div>

                      <div class="mt-8">
                        <label><input
                          v-model="form.IsAgent"
                          class="mr-2"
                          true-value="Yes"
                          false-value="No"
                          name="I am a Travel Agent"
                          type="checkbox"
                        >I am a Travel Agent</label>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <div v-if="success.show" v-cloak class="success">
                <h3 class="text-center mb-6">
                  Form successfully submitted!
                </h3>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
