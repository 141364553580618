<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Styled Number With Text', group: 'global', layouts: ['Blocks'] },
      tags: ['Text'],
      fields: {
        leftText: { type: 'text', label: 'Left Text', default: 5, name: 'leftText' },
        middleText: {
          type: 'text',
          label: 'Middle Text',
          default: 'PRIZES',
          name: 'middleText',
        },
        rightText: {
          type: 'text',
          label: 'Right Text',
          default: 'Over 5 Days',
          name: 'rightText',
        },
      },
      slots: [],
    })
  },
}
</script>

<template>
  <div id="voix-slice-3573" class="flex justify-center title-paragraph relative" style="">
    <div class="container mx-auto text-center items-center flex flex-col relative z-10">
      <div class="mx-auto mb-8 titles flex flex-row items-center text-white">
        <div class="text-9xl -mr-5 font-bold" style="color: #3fc2cc">
          {{ fields.leftText.value }}
        </div>
        <div class="text-5xl mr-2 font-bold" style="color: #16467a">
          {{ fields.middleText.value }}
        </div>
        <div class="text-3xl p-1 font-normal" style="background-color: #3fc2cc">
          {{ fields.rightText.value }}
        </div>
      </div>
    </div>
  </div>
</template>
