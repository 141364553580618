<script>
import { SwiperSlide } from 'swiper/vue'

export default {
  components: {
    SwiperSlide,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  mounted() {
    defineSlice({
      slots: [{ name: 'default' }],
      description:
        'Slider of images flanking a title, subtitle, description and sub-slices',
      fields: {
        tagline: {
          type: 'text',
          label: 'Left Right Block Suite',
          enabled: false,
        },
        title: { type: 'text', label: 'Title', editorLabel: true },
        copy: { type: 'wysiwyg', label: 'Copy', enabled: false },
        left: { type: 'checkbox', label: 'Text on left', default: true },
        image: {
          type: 'media',
          label: 'Image 1',
          breakpoints: {
            md: { width: 434, height: 300 },
            lg: { width: 651, height: 450 },
            xl: { width: 940, height: 650 },
          },
        },
        image2: {
          type: 'media',
          label: 'Image 2',
          enabled: false,
          breakpoints: {
            md: { width: 434, height: 300 },
            lg: { width: 651, height: 450 },
            xl: { width: 940, height: 650 },
          },
        },
        image3: {
          type: 'media',
          label: 'Image 3',
          enabled: false,
          breakpoints: {
            md: { width: 434, height: 300 },
            lg: { width: 651, height: 450 },
            xl: { width: 940, height: 650 },
          },
        },
        image4: {
          type: 'media',
          label: 'Image 4',
          enabled: false,
          breakpoints: {
            md: { width: 434, height: 300 },
            lg: { width: 651, height: 450 },
            xl: { width: 940, height: 650 },
          },
        },
        image5: {
          type: 'media',
          label: 'Image 5',
          enabled: false,
          breakpoints: {
            md: { width: 434, height: 300 },
            lg: { width: 651, height: 450 },
            xl: { width: 940, height: 650 },
          },
        },
        image6: {
          type: 'media',
          label: 'Image 6',
          enabled: false,
          breakpoints: {
            md: { width: 434, height: 300 },
            lg: { width: 651, height: 450 },
            xl: { width: 940, height: 650 },
          },
        },
        image7: {
          type: 'media',
          label: 'Image 7',
          enabled: false,
          breakpoints: {
            md: { width: 434, height: 300 },
            lg: { width: 651, height: 450 },
            xl: { width: 940, height: 650 },
          },
        },
        image8: {
          type: 'media',
          label: 'Image 8',
          enabled: false,
          breakpoints: {
            md: { width: 434, height: 300 },
            lg: { width: 651, height: 450 },
            xl: { width: 940, height: 650 },
          },
        },
      },
      name: { label: 'Left Right Block Suite', group: 'Playaresorts', layouts: ['*'] },
      tags: ['Slider'],
      templates: [
        {
          label: 'Left Right Block Suite',
          fields: {
            copy: {
              enabled: true,
              value:
                '<p>Located in the Colonial section of the resort (main buildings). Comes with a king-size bed or 2 queen beds, full bathroom with shower, jetted bathtub, vanity sinks, hairdryer, bathrobes and slippers, and furnished living room and terrace.<\/p><p><em>Junior Suite Ocean View suites may vary in size and d\u00E9cor.<\/em><\/p>',
            },
            image: {
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/styled\/sanctuarycapcana.com\/Sanctuary\/Sanctuary_Rooms\/Sanctuary-Cap-Cana-Junior-Suite-Ocean-View-e29ccead5154b62eadbc907b6103ff21.jpg',
            },
            image2: {
              enabled: true,
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/styled\/sanctuarycapcana.com\/Sanctuary\/Sanctuary_Rooms\/Sanctuary-Cap-Cana-Junior-Suite-Ocean-View-Living-Area-e29ccead5154b62eadbc907b6103ff21.jpg',
            },
            image3: {
              enabled: true,
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/styled\/sanctuarycapcana.com\/Sanctuary\/Sanctuary_Rooms\/Sanctuary-Cap-Cana-Junior-Suite-Ocean-View-e29ccead5154b62eadbc907b6103ff21.jpg',
            },
            image4: {
              enabled: true,
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Sanctuary\/2023\/suites\/sanctuary-cap-cana-beach-front-junior-suite-5-web.jpg',
            },
            title: { value: 'JUNIOR SUITE OCEAN VIEW' },
          },
        },
      ],
    })
  },
}
</script>

<template>
  <div class="relative block">
    <div class="playa-left-right-block-suite">
      <div class="flex justify-center bg-cover bg-center w-full">
        <div class="p-2 py-10 container">
          <div
            class="left-right-block-suite flex flex-col-reverse md:flex-row items-stretch md:mx-8 bg-abs-white shadow"
            :class="{ 'md:flex-row-reverse': fields.left.value === true }"
          >
            <div class="md:w-1/2 p-10 text-center md:text-left">
              <p v-if="fields.tagline.enabled" class="text-lg pb-1 prose">
                {{ fields.tagline.value }}
              </p>
              <h4 class="title">
                {{ fields.title.value }}
              </h4>
              <div
                v-if="fields.copy.enabled"
                class="my-4 prose"
                v-html="fields.copy.value"
              />

              <div class="mt-8">
                <slot />
              </div>
            </div>
            <div
              class="md:w-1/2 text-center overflow-hidden bg-gray-200 flex items-center"
            >
              <general-swiper class="w-full h-full flex">
                <SwiperSlide class="h-full w-full">
                  <VoixMedia :field="fields.image" class="w-full h-full object-cover" />
                </SwiperSlide>
                <SwiperSlide v-if="fields.image2.enabled" class="h-full w-full">
                  <VoixMedia :field="fields.image2" class="w-full h-full object-cover" />
                </SwiperSlide>
                <SwiperSlide v-if="fields.image3.enabled" class="h-full w-full">
                  <VoixMedia :field="fields.image3" class="w-full h-full object-cover" />
                </SwiperSlide>
                <SwiperSlide v-if="fields.image4.enabled" class="h-full w-full">
                  <VoixMedia :field="fields.image4" class="w-full h-full object-cover" />
                </SwiperSlide>
                <SwiperSlide v-if="fields.image5.enabled" class="h-full w-full">
                  <VoixMedia :field="fields.image5" class="w-full h-full object-cover" />
                </SwiperSlide>
                <SwiperSlide v-if="fields.image6.enabled" class="h-full w-full">
                  <VoixMedia :field="fields.image6" class="w-full h-full object-cover" />
                </SwiperSlide>
                <SwiperSlide v-if="fields.image7.enabled" class="h-full w-full">
                  <VoixMedia :field="fields.image7" class="w-full h-full object-cover" />
                </SwiperSlide>
                <SwiperSlide v-if="fields.image8.enabled" class="h-full w-full">
                  <VoixMedia :field="fields.image8" class="w-full h-full object-cover" />
                </SwiperSlide>
              </general-swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.swiper,
.swiper-slide {
  width: 100%;
  height: 100%;
}
</style>
