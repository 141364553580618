<script setup lang="ts">
import { computed, ref } from 'vue'
import axios from 'axios'
import { usePageStore } from '@voix/store/pageStore'

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})
const { $playa } = useNuxtApp()
const pageStore = usePageStore()

const allPosts: { data: Array<{ id: string }> } = ref({ data: [] })
const postsData = ref({ current_page: 1 })
const tag = ref(null)
const loading = ref(false)

function loadNextPage() {
  if (!loading.value) {
    loading.value = true
    const nextPage = postsData.value.current_page + 1
    const category = props.fields.postType.value
      ? props.fields.postType.value
      : 'Blog Post'
    let url = `${$playa.rest_api_base_url}/sites/${pageStore.currentPage?.site_id}/blog-posts?category=${category}&page=${nextPage}`

    if (tag.value)
      url = `${url}&tag=${tag.value}`

    axios
      .get(url)
      .then((response) => {
        postsData.value = response.data
        allPosts.value = allPosts.value.concat(response.data.data)
        loading.value = false
      })
      .catch(() => {
        alert('Something went wrong. Please try again or contact administration.')
        loading.value = false
      })
  }
}

const posts = computed(() => {
  if (!props.fields.showAll.value)
    return { data: allPosts.value.data.slice(0, 4) }

  return allPosts.value
})

const category = props.fields.postType.value ? props.fields.postType.value : 'Blog Post'
axios
  .get(
    `${$playa.rest_api_base_url}/sites/${pageStore.currentPage?.site_id}/blog-posts?category=${category}`,
  )
  .then((response) => {
    allPosts.value = response.data
  })

defineSlice({
  name: { label: 'All', group: 'playaresorts', layouts: ['PlayaCares', 'Blocks'] },
  tags: ['Blog'],
  fields: {
    postType: {
      type: 'select',
      label: 'Post Type',
      options: {
        'Blog Post': 'General Blog Posts',
        'Playacares Post': 'All Playa Cares Posts',
        'Dominican Republic Post': 'PC Dominican Republic Posts',
        'Jamaica Post': 'PC Jamaica Posts',
        'Mexico Post': 'PC Mexico Posts',
        'USA Post': 'PC USA Posts',
      },
      name: 'postType',
    },
    showAll: {
      type: 'checkbox',
      label: 'Show All',
      instructions: 'Show all (on) or only 4 (off)',
      default: true,
      name: 'showAll',
    },
    viewMoreBtn: {
      type: 'link',
      label: 'View More',
      instructions: 'Only when show all is off',
      name: 'viewMoreBtn',
    },
  },
  slots: [],
})
</script>

<template>
  <div>
    <div class="flex flex-wrap flex-col md:flex-row">
      <div v-for="post in posts.data" :key="post.id" class="w-full md:w-1/4 p-4 flex">
        <div class="border-2">
          <img
            v-if="post.thumbnail"
            :src="post.thumbnail"
            :alt="`${post.title} Thumbnail`"
          >
          <div class="p-8">
            <p class="light-gray">
              {{ post.pretty_date }}
            </p>
            <div class="font-light mb-4 text-4xl uppercase title">
              {{ post.title }}
            </div>
            <p class="pb-4">
              {{ post.short_description }}
            </p>
            <a :href="post.permalink" class="btn inline-block">Read More</a>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center py-4">
      <div
        v-if="posts.current_page < posts.last_page && fields.showAll.value"
        class="text-center pl pr sp200"
      >
        <button
          type="button"
          data-category="all-ages"
          class="btn raised block mt sp50"
          @click.prevent="loadNextPage"
        >
          More
        </button>
      </div>
      <div v-if="!fields.showAll.value" class="text-center pl pr sp200">
        <a :href="fields.viewMoreBtn.value.href" class="btn raised block mt sp50">
          {{ fields.viewMoreBtn.value.text }}
        </a>
      </div>
    </div>
  </div>
</template>
