<script>
import Icons from '../../mixins/Icons'

export default {
  name: 'IconTheater',
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len */ -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 57.7 60.9"
    aria-labelledby="IconTheater"
    role="presentation"
  >
    <title id="IconTheater">Icon of a Theater</title>
    <g id="Layer_4_2_">
      <path
        :fill="color"
        class="st3"
        d="M13.6,24.5c0-0.8,0.6-1.5,1.4-1.6c0.8,0,1.5,0.6,1.6,1.4c0,0.8-0.6,1.5-1.4,1.6C14.3,26,13.6,25.4,13.6,24.5"
      />
      <g>
        <rect
          :fill="color"
          x="12.9"
          y="27.2"
          class="st3"
          width="4.1"
          height="1.4"
        />
      </g>
      <path
        :fill="color"
        class="st3"
        d="M22.2,24.5c0-0.8,0.6-1.5,1.4-1.6c0.8,0,1.5,0.6,1.6,1.4c0,0.8-0.6,1.5-1.4,1.6C23,26,22.3,25.4,22.2,24.5"
      />
      <g>
        <rect
          :fill="color"
          x="21.6"
          y="27.2"
          class="st3"
          width="4.1"
          height="1.4"
        />
      </g>
      <path
        :fill="color"
        class="st3"
        d="M30.9,24.5c0-0.8,0.6-1.5,1.4-1.6c0.8,0,1.5,0.6,1.6,1.4c0,0.8-0.6,1.5-1.4,1.6C31.6,26,30.9,25.4,30.9,24.5"
      />
      <g>
        <rect
          :fill="color"
          x="30.3"
          y="27.2"
          class="st3"
          width="4.1"
          height="1.4"
        />
      </g>
      <path
        :fill="color"
        class="st3"
        d="M39.6,24.5c0-0.8,0.6-1.5,1.4-1.6c0.8,0,1.5,0.6,1.6,1.4c0,0.8-0.6,1.5-1.4,1.6C40.3,26,39.6,25.4,39.6,24.5"
      />
      <g>
        <rect
          :fill="color"
          x="39"
          y="27.2"
          class="st3"
          width="4.1"
          height="1.4"
        />
      </g>
      <path
        :fill="color"
        class="st3"
        d="M13.6,33.2c0-0.8,0.6-1.5,1.4-1.6c0.8,0,1.5,0.6,1.6,1.4c0,0.8-0.6,1.5-1.4,1.6C14.3,34.6,13.6,34,13.6,33.2"
      />
      <g>
        <rect
          :fill="color"
          x="12.9"
          y="35.8"
          class="st3"
          width="4.1"
          height="1.4"
        />
      </g>
      <path
        :fill="color"
        class="st3"
        d="M22.2,33.2c0-0.8,0.6-1.5,1.4-1.6c0.8,0,1.5,0.6,1.6,1.4c0,0.8-0.6,1.5-1.4,1.6C23,34.6,22.3,34,22.2,33.2"
      />
      <g>
        <rect
          :fill="color"
          x="21.6"
          y="35.8"
          class="st3"
          width="4.1"
          height="1.4"
        />
      </g>
      <path
        :fill="color"
        class="st3"
        d="M30.9,33.2c0-0.8,0.6-1.5,1.4-1.6c0.8,0,1.5,0.6,1.6,1.4c0,0.8-0.6,1.5-1.4,1.6C31.6,34.6,30.9,34,30.9,33.2"
      />
      <g>
        <rect
          :fill="color"
          x="30.3"
          y="35.8"
          class="st3"
          width="4.1"
          height="1.4"
        />
      </g>
      <path
        :fill="color"
        class="st3"
        d="M39.6,33.2c0-0.8,0.6-1.5,1.4-1.6c0.8,0,1.5,0.6,1.6,1.4c0,0.8-0.6,1.5-1.4,1.6C40.3,34.6,39.6,34,39.6,33.2"
      />
      <g>
        <rect
          :fill="color"
          x="39"
          y="35.8"
          class="st3"
          width="4.1"
          height="1.4"
        />
      </g>
      <path
        :fill="color"
        class="st3"
        d="M13.6,41.8c0-0.8,0.6-1.5,1.4-1.6c0.8,0,1.5,0.6,1.6,1.4c0,0.8-0.6,1.5-1.4,1.6C14.3,43.2,13.6,42.6,13.6,41.8
"
      />
      <g>
        <rect
          :fill="color"
          x="12.9"
          y="44.5"
          class="st3"
          width="4.1"
          height="1.4"
        />
      </g>
      <path
        :fill="color"
        class="st3"
        d="M22.2,41.8c0-0.8,0.6-1.5,1.4-1.6c0.8,0,1.5,0.6,1.6,1.4c0,0.8-0.6,1.5-1.4,1.6C23,43.2,22.3,42.6,22.2,41.8"
      />
      <g>
        <rect
          :fill="color"
          x="21.6"
          y="44.5"
          class="st3"
          width="4.1"
          height="1.4"
        />
      </g>
      <path
        :fill="color"
        class="st3"
        d="M30.9,41.8c0-0.8,0.6-1.5,1.4-1.6c0.8,0,1.5,0.6,1.6,1.4c0,0.8-0.6,1.5-1.4,1.6C31.6,43.2,30.9,42.6,30.9,41.8
"
      />
      <g>
        <rect
          :fill="color"
          x="30.3"
          y="44.5"
          class="st3"
          width="4.1"
          height="1.4"
        />
      </g>
      <path
        :fill="color"
        class="st3"
        d="M39.6,41.8c0-0.8,0.6-1.5,1.4-1.6c0.8,0,1.5,0.6,1.6,1.4c0,0.8-0.6,1.5-1.4,1.6C40.3,43.2,39.6,42.6,39.6,41.8
"
      />
      <g>
        <rect
          :fill="color"
          x="39"
          y="44.5"
          class="st3"
          width="4.1"
          height="1.4"
        />
      </g>
      <path
        :fill="color"
        class="st3"
        d="M13.6,50.4c0-0.8,0.6-1.5,1.4-1.6c0.8,0,1.5,0.6,1.6,1.4c0,0.8-0.6,1.5-1.4,1.6C14.3,51.9,13.6,51.2,13.6,50.4
"
      />
      <g>
        <rect
          :fill="color"
          x="12.9"
          y="53.1"
          class="st3"
          width="4.1"
          height="1.4"
        />
      </g>
      <path
        :fill="color"
        class="st3"
        d="M22.2,50.4c0-0.8,0.6-1.5,1.4-1.6c0.8,0,1.5,0.6,1.6,1.4c0,0.8-0.6,1.5-1.4,1.6C23,51.9,22.3,51.2,22.2,50.4"
      />
      <g>
        <rect
          :fill="color"
          x="21.6"
          y="53.1"
          class="st3"
          width="4.1"
          height="1.4"
        />
      </g>
      <path
        :fill="color"
        class="st3"
        d="M30.9,50.4c0-0.8,0.6-1.5,1.4-1.6c0.8,0,1.5,0.6,1.6,1.4c0,0.8-0.6,1.5-1.4,1.6C31.6,51.9,30.9,51.2,30.9,50.4
"
      />
      <g>
        <rect
          :fill="color"
          x="30.3"
          y="53.1"
          class="st3"
          width="4.1"
          height="1.4"
        />
      </g>
      <path
        :fill="color"
        class="st3"
        d="M39.6,50.4c0-0.8,0.6-1.5,1.4-1.6c0.8,0,1.5,0.6,1.6,1.4c0,0.8-0.6,1.5-1.4,1.6C40.3,51.9,39.6,51.2,39.6,50.4
"
      />
      <g>
        <rect
          :fill="color"
          x="39"
          y="53.1"
          class="st3"
          width="4.1"
          height="1.4"
        />
      </g>
      <path
        :fill="color"
        class="st3"
        d="M23.4,8.3c0-0.8,0.6-1.4,1.4-1.5c0.8,0,1.4,0.6,1.5,1.4c0,0.8-0.6,1.4-1.4,1.5C24.1,9.7,23.4,9.1,23.4,8.3"
      />
      <path
        :fill="color"
        class="st3"
        d="M29.8,8.3c0-0.8,0.6-1.4,1.4-1.5c0.8,0,1.4,0.6,1.5,1.4c0,0.8-0.6,1.4-1.4,1.5C30.5,9.7,29.8,9.1,29.8,8.3"
      />
      <rect
        :fill="color"
        x="19.9"
        y="11.3"
        class="st3"
        width="16.3"
        height="5.5"
      />
    </g>
  </svg>
</template>
