<script>
import { mapActions } from 'pinia'
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  mounted() {
    defineSlice({
      name: { label: 'Booking Dates Limiter', group: 'global', layouts: ['*'] },
      tags: ['BookingWidget'],
      fields: { min: { type: 'datetime', label: 'Min Booking Date', name: 'min' }, max: { type: 'datetime', label: 'Max Booking Date', name: 'max' } },
      slots: [],
    })

    this.setBookingDateLimits({
      min: this.fields.min.value,
      max: this.fields.max.value,
    })
  },
  methods: {
    ...mapActions(useBookingWidgetStore, ['setBookingDateLimits']),
  },
}
</script>

<template>
  <div />
</template>
