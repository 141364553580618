<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  mounted() {
    defineSlice({
      name: { label: 'Image Card', group: 'global', layouts: ['*'] },
      tags: ['Image', 'Card'],
      fields: {
        title: { type: 'text', label: 'Title', editorLabel: true, name: 'title' },
        content: { type: 'wysiwyg', label: 'Content', name: 'content' },
        image: {
          type: 'media',
          label: 'Image',
          breakpoints: {
            lg: { width: 511, height: 360 },
            default: { width: 251, height: 220 },
          },
          name: 'image',
        },
        horizontal: {
          type: 'checkbox',
          label: 'Horizontal?',
          default: false,
          name: 'horizontal',
        },
      },
      slots: [{ name: 'default', allowedElements: [] }],
    })
  },
}
</script>

<template>
  <div>
    <div class="bg-abs-white shadow rounded h-full text-center">
      <div
        class="w-full"
        :class="{ 'flex flex-col items-center': fields.horizontal.value }"
      >
        <div :class="{ 'md:w-1/2': fields.horizontal.value }">
          <VoixMedia
            v-if="fields.image.media && fields.image.media.length > 0"
            :field="fields.image"
            class="w-full"
          />
        </div>

        <div class="p-8" :class="{ 'md:w-1/2': fields.horizontal.value }">
          <h2 v-if="fields.title.value" class="mb-6 font-thin text-xl">
            {{ fields.title.value }}
          </h2>
          <div
            v-if="fields.content.value"
            class="wysiwyg"
            v-html="fields.content.value"
          />

          <VoixSlice
            v-for="s in slice.elements"
            :key="s.id"
            class="mt-8 text-center"
            :slice="s"
          />
        </div>
      </div>
    </div>
  </div>
</template>
