<script setup lang="ts">
defineSlice({
  name: { label: 'Room Type Filter', group: 'Sandpiper Rooms', layouts: ['Sandpiper'] },
  slots: [{ name: 'default', allowedElements: ['SlicesSandpiperRoomType'] }],
  description: 'A container for room type cards with filters at the top',
  preview: 'SlicesSandpiperRoomTypeFilter.jpg',
  fields: {

  },
  templates: [{
    label: 'Main Example',
    fields: {

    },
  }],
})

const filters = [
  'Family',
  'Double Bed',
  'Ocean View',
  'King Size Bed',
]

const selectedFilters = ref<string[]>([])

function toggleFilter(filter: string) {
  if (selectedFilters.value.includes(filter))
    selectedFilters.value = selectedFilters.value.filter(f => f !== filter)
  else
    selectedFilters.value = [...selectedFilters.value, filter]
}

provide('selectedRoomTypeFilters', selectedFilters)
</script>

<template>
  <div class="py-12">
    <div class="container mx-auto">
      <ul class="flex flex-wrap">
        <li v-for="filter in filters" :key="filter" class="mr-2 mt-2">
          <button
            class="px-4 py-2 rounded-sm font-bold border text-sm font-sandpiper-display uppercase"
            :class="{
              'bg-shocking text-white': selectedFilters.includes(filter),
              'bg-white text-shocking border-shocking': !selectedFilters.includes(filter),
            }"
            @click="toggleFilter(filter)"
          >
            {{ filter }}
          </button>
        </li>
      </ul>

      <div class="mt-8 lg:grid lg:grid-cols-3 lg:gap-12">
        <slot />
      </div>
    </div>
  </div>
</template>
