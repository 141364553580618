<script>
import anime from 'animejs/lib/anime.es.js'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isVisible: false,
    }
  },
  mounted() {
    defineSlice({
      fields: {
        testimonial: {
          type: 'wysiwyg',
          label: 'Testimonial',
        },
      },
      name: {
        label: 'Playa Collection Testimonial',
        group: 'Playa Collection',
        layouts: ['PlayaCollection'],
      },
      tags: ['Text', 'Wysiwyg'],
      templates: [],
    })

    anime({
      targets: ['.two-pane-image1', '.two-pane-image2'],
      translateX: 5000,
      opacity: 0,
      duration: 2000,
    })
  },
  methods: {
    onVisibilityChange(entry) {
      if (entry.intersectionRatio * 100 > 0) {
        this.isVisible = true
        anime({
          targets: [`.testimonial-${this.slice.id}`],
          translateX: 0,
          opacity: 1,
          duration: 3000,
          easing: 'easeOutExpo',
          delay: anime.stagger(200),
        })
      }
      else {
        this.isVisible = false
        anime({
          targets: [`.testimonial-${this.slice.id}`],
          translateX: 5000,
          opacity: 0,
          duration: 2000,
        })
      }
    },
  },
}
</script>

<template>
  <div
    v-voix-visibility="{ callback: onVisibilityChange, threshold: [0, 0.25] }"
    class="overflow-hidden py-6 lg:py-10 flex justify-center title-paragraph bg-grey-light"
  >
    <div
      class="p-6 px-6 lg:px-12 mx-6 lg:mx-12 container text-center flex flex-col items-center bg-white w-full lg:w-3/5"
      :class="[`testimonial-${slice.id}`]"
    >
      <div class="pt-4 wysiwyg testimonial" v-html="fields.testimonial.value" />
    </div>
  </div>
</template>
