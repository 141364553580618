<script>
import Shuffle from 'shufflejs'

import imagesLoaded from 'imagesloaded'

import { usePageStore } from '@voix/store/pageStore'

export default {
  provide() {
    return {
      loadZoom: this.loadZoom,
      loadSidebar: this.loadSidebar,
    }
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      shuffle: null,
      currentlySelectedSlice: {
        content: null,
        largeImage: null,
        title: null,
        show: false,
      },
      currentlySelectedImage: {
        image: null,
        show: false,
      },
      currentCategory: 'all',
      categories: {
        'category-resort': 'Resort',
        'category-activities': 'Activities',
        'category-pools': 'Pools',
        'category-rooms': 'Rooms',
        'category-dining': 'Dining',
        'category-celebrations': 'Celebrations',
        'category-meetings': 'Meetings',
      },
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
  },
  mounted() {
    defineSlice({
      name: {
        label: 'Grid Gallery',
        group: 'global',
        layouts: ['*'],
      },
      tags: ['Gallery'],
      preview: 'SlicesGlobalGalleriesGridGallery.jpg',
      fields: {
        showCategories: {
          label: 'Show Categories',
          type: 'checkbox',
          name: 'showCategories',
        },
      },
      slots: [
        {
          name: 'default',
          allowedElements: ['Grid Gallery Image', 'Grid Gallery Content Box'],
        },
      ],
    })

    // TODO: This is a hack to get the images to load properly
    setTimeout(() => {
      this.shuffle = new Shuffle(this.$el.getElementsByClassName('gallery')[0], {
        itemSelector: '.grid-item',
        sizer: this.$el.getElementsByClassName('grid-spacer')[0],
        gutter: 0,
        percntPosition: true,
      })

      this.shuffle.layout()
    }, 700)
  },

  methods: {
    loadSidebar(data) {
      this.currentlySelectedSlice.title = data.title.text
      this.currentlySelectedSlice.content = data.content.text
      this.currentlySelectedSlice.largeImage = data.largeImage.url
      this.currentlySelectedSlice.show = true
    },
    unloadAll() {
      this.unloadSidebar()
      this.unloadZoom()
    },
    unloadSidebar() {
      this.currentlySelectedSlice.show = false
    },
    loadZoom(image) {
      if (this.$voix.breakpoint !== 'default') {
        this.currentlySelectedImage.image = image
        this.currentlySelectedImage.show = true
      }
    },
    unloadZoom() {
      this.currentlySelectedImage.image = null
      this.currentlySelectedImage.show = false
    },
    loadCategory(category) {
      this.currentCategory = category
      if (category === 'all')
        this.shuffle.filter()
      else this.shuffle.filter(category)
    },
    btnClasses(key) {
      if (this.currentPage.site_id === 12) {
        if (this.currentCategory === key)
          return 'bg-collection-teal text-white py-4 px-6 border-grey-light border-2 inline-block'
        else
          return 'bg-white py-4 px-6 text-grey-dark border-grey-light border-2 inline-block'
      }
      if (this.currentPage.site_id !== 12) {
        if (this.currentCategory === key)
          return 'btn btn-primary btn-sm rounded-sm'
        else return 'btn btn-ghost btn-sm rounded-sm'
      }
    },
  },
}
</script>

<template>
  <div v-if="slice.elements.length > 0" class="grid-gallery">
    <ClientOnly>
      <div>
        <ul
          v-if="fields.showCategories.value"
          class="flex justify-start md:justify-center flex-wrap items-stretch pt-8 pb-2 space-y-2 space-x-2"
        >
          <li>
            <a
              class="block ml-2 mt-2"
              href="#"
              :class="btnClasses('all')"
              style="padding-top: 10px; padding-bottom: 10px"
              @click.prevent="loadCategory('all')"
            >
              All
            </a>
          </li>
          <li v-for="(category, key) in categories" :key="key">
            <a
              class="block"
              href="#"
              :class="btnClasses(key)"
              @click.prevent="loadCategory(key)"
            >
              {{ category }}
            </a>
          </li>
        </ul>

        <div class="gallery" :class="{ 'shift-gallery': currentlySelectedSlice.show }">
          <div class="grid-spacer w-full md:w-1/4 aspect-square" />

          <VoixSlice
            v-for="element in slice.elements"
            :key="element.id"
            class="grid-item"
            :slice="element"
            :load-zoom="loadZoom"
            @load-sidebar="loadSidebar"
          />
        </div>

        <!-- Blocker -->
        <transition name="fast-fade">
          <div
            v-if="currentlySelectedSlice.show || currentlySelectedImage.show"
            class="fixed inset-0 opacity-75 bg-sanctuary-action z-100"
            @click="unloadAll"
          />
        </transition>

        <!-- Sidebar slideout dude -->
        <transition name="grid-slide">
          <div
            v-if="currentlySelectedSlice.show"
            class="fixed top-0 bottom-0 right-0 w-full md:w-3/5 z-100 bg-grey-light overflow-scroll"
          >
            <img :src="currentlySelectedSlice.largeImage" class="w-full">
            <div
              class="cursor-pointer bg-sanctuary-bright-blue text-center rounded-full p-4 text-white text-5xl flex align-center justify-center font-extrabold font-sans absolute top-0 right-0 z-20 mr-12 mt-8"
              style="width: 90px; height: 90px"
              @click="unloadSidebar"
            >
              ✖
            </div>
            <div class="p-8 md:p-12">
              <h2 class="text-center text-grey-darkest text-4xl mb-8">
                {{ currentlySelectedSlice.title }}
              </h2>
              <div class="text-grey-darkest" v-html="currentlySelectedSlice.content" />
            </div>
          </div>
        </transition>

        <!-- Image Zoom -->
        <transition name="fade">
          <div
            v-if="currentlySelectedImage.show"
            class="fixed inset-0 pointer-events-none w-full z-100 flex flex-col item-center justify-center max-w-screen max-h-screen"
          >
            <div class="p-24">
              <div class="relative">
                <h2
                  v-if="
                    currentlySelectedImage.image.alt
                      && currentlySelectedImage.image.alt !== ''
                  "
                  class="text-abs-white mb-4 p-4 rounded text-center"
                  style="background-color: rgba(0, 0, 0, 0.5)"
                >
                  {{ currentlySelectedImage.image.alt }}
                </h2>
                <div class="flex justify-center">
                  <div class="relative">
                    <div class="w-full h-[80vh]">
                      <NuxtImg
                        class="w-full"
                        :provider="currentlySelectedImage.image.provider"
                        :src="currentlySelectedImage.image.url"
                        :modifiers="{ q: 90, f: 'webp', fit: 'cover' }"
                        :width="currentlySelectedImage.image.width"
                      />
                    </div>
                    <div
                      class="cursor-pointer pointer-events-auto text-center rounded-full p-6 flex items-center justify-center absolute top-0 right-0 z-20 bg-black -mt-6 -mr-6"
                      style="width: 50px; height: 50px"
                      @click="unloadZoom"
                    >
                      <div
                        class="text-white text-2xl flex items-center justify-center font-extrabold font-sans"
                      >
                        ✖
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </ClientOnly>
  </div>
</template>
