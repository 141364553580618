<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      preview: 'SlicesWeddingsTitleParagraphDivider.jpg',
      description: 'Weddings Title Paragraph Divider',
      fields: {
        image: {
          type: 'select',
          label: 'Image',
          options: {
            none: 'None',
            rings: 'Rings',
          },
          default: 'none',
        },
        title: {
          type: 'text',
          label: 'Title',
          enabled: true,
          default: 'Wedding Packages',
        },
        subtitle: {
          type: 'text',
          label: 'Sub-Title',
          enabled: true,
        },
        paragraph: {
          type: 'wysiwyg',
          label: 'Paragraph',
          enabled: true,
        },
      },
      name: {
        label: 'Weddings Title Paragraph Divider',
        group: 'Weddings',
        layouts: ['*'],
      },
      tags: ['Title', 'Paragraph', 'Divider'],
      templates: [],
    })
  },
}
</script>

<template>
  <div class="container mx-auto flex flex-col items-center">
    <div class="w-full md:w-5/6 md:px-16 mx-auto flex flex-col items-center">
      <img
        v-if="fields.image.value === 'rings'"
        class="w-48 mb-4"
        src="/imgs/weddings/rings.png"
      >
      <div
        v-if="fields.title.enabled"
        class="text-center font-cursive font-light mb-1 text-5xl md:text-6xl"
        v-html="fields.title.value"
      />
      <div
        v-if="fields.subtitle.enabled"
        class="text-center text-2xl mt-4"
        v-html="fields.subtitle.value"
      />
      <div
        v-if="fields.paragraph.enabled"
        class="text-center text-lg mt-4"
        v-html="fields.paragraph.value"
      />
    </div>
  </div>
</template>
