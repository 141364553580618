<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  mounted() {
    defineSlice({
      name: { label: 'Title List Column', group: 'global', layouts: ['PlayaCares', 'HyattZiva', 'AgentCashPlus', 'Playaresorts'] },
      tags: ['Container', 'Columns'],
      preview: 'SlicesGlobalContainersTitleListColumn.jpg',
      fields: { title: { type: 'text', label: 'Title', editorLabel: true, default: 'Title', name: 'title' } },
      slots: [{ name: 'default', allowedElements: [] }],
    })
  },
}
</script>

<template>
  <div class="flex flex-col items-stretch md:w-1/2 lg:w-1/2">
    <div class="text-xl font-bold mb-4">
      {{ fields.title.value }}
    </div>
    <slot />
  </div>
</template>
