<script>
import Icons from '../../mixins/Icons'

export default {
  name: 'IconClassroom',
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len */ -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 57.7 60.9"
    aria-labelledby="IconClassroom"
    role="presentation"
  >
    <title id="IconClassroom">Icon of a Classroom</title>
    <g id="Layer_5_2_">
      <g>
        <path
          :fill="color"
          class="st3"
          d="M23,8.1c0-0.8,0.6-1.5,1.4-1.5c0.8,0,1.5,0.6,1.5,1.4c0,0.8-0.6,1.5-1.4,1.5C23.7,9.5,23,8.9,23,8.1"
        />
        <path
          :fill="color"
          class="st3"
          d="M29.5,8.1c0-0.8,0.6-1.5,1.4-1.5c0.8,0,1.5,0.6,1.5,1.4c0,0.8-0.6,1.5-1.4,1.5C30.3,9.5,29.6,8.9,29.5,8.1"
        />
        <rect
          :fill="color"
          x="19.4"
          y="11.2"
          class="st3"
          width="16.6"
          height="5.6"
        />
        <rect
          :fill="color"
          x="8.5"
          y="22.9"
          class="st3"
          width="16.6"
          height="5.6"
        />
        <rect
          :fill="color"
          x="8.5"
          y="34.7"
          class="st3"
          width="16.6"
          height="5.6"
        />
        <path
          :fill="color"
          class="st3"
          d="M21.5,31c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5C20.8,29.6,21.5,30.2,21.5,31"
        />
        <path
          :fill="color"
          class="st3"
          d="M15,31c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5C14.3,29.6,14.9,30.2,15,31"
        />
        <path
          :fill="color"
          class="st3"
          d="M21.5,42.7c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5
C20.8,41.3,21.5,41.9,21.5,42.7"
        />
        <path
          :fill="color"
          class="st3"
          d="M15,42.7c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5C14.3,41.3,14.9,41.9,15,42.7"
        />
        <path
          :fill="color"
          class="st3"
          d="M21.5,54.5c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5
C20.8,53.1,21.5,53.7,21.5,54.5"
        />
        <path
          :fill="color"
          class="st3"
          d="M15,54.5c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5C14.3,53.1,14.9,53.7,15,54.5"
        />
        <rect
          :fill="color"
          x="8.5"
          y="46.4"
          class="st3"
          width="16.6"
          height="5.6"
        />
        <rect
          :fill="color"
          x="30.3"
          y="22.9"
          class="st3"
          width="16.6"
          height="5.6"
        />
        <rect
          :fill="color"
          x="30.3"
          y="34.7"
          class="st3"
          width="16.6"
          height="5.6"
        />
        <rect
          :fill="color"
          x="30.3"
          y="46.4"
          class="st3"
          width="16.6"
          height="5.6"
        />
        <path
          :fill="color"
          class="st3"
          d="M43.3,31c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5C42.6,29.6,43.3,30.2,43.3,31"
        />
        <path
          :fill="color"
          class="st3"
          d="M36.8,31c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5C36.1,29.6,36.7,30.2,36.8,31"
        />
        <path
          :fill="color"
          class="st3"
          d="M43.3,42.7c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5
C42.6,41.3,43.3,41.9,43.3,42.7"
        />
        <path
          :fill="color"
          class="st3"
          d="M36.8,42.7c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5
C36.1,41.3,36.7,41.9,36.8,42.7"
        />
        <path
          :fill="color"
          class="st3"
          d="M43.3,54.5c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5
C42.6,53.1,43.3,53.7,43.3,54.5"
        />
        <path
          :fill="color"
          class="st3"
          d="M36.8,54.5c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5
C36.1,53.1,36.7,53.7,36.8,54.5"
        />
      </g>
    </g>
  </svg>
</template>
