<script>
import { usePageStore } from '@voix/store/pageStore'
import axios from 'axios'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showModal: false,
      locations: [],
      showVimeoModal: false,
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign(
        {},
        {
          fields: this.fields,
          page: this.currentPage,
          slice: this.slice,
        },
      )
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Video Takeover', group: 'Sanctuary', layouts: ['HiltonYucatan'] },
      tags: ['Video', 'Overlay'],
      description:
        'When you provide a Vimeo ID this widget fills the screen in height and width with the video',
      fields: {
        cfId: { type: 'text', label: 'CloudFlare ID' },
        height: { type: 'number', label: 'Ratio Y', default: '"9"' },
        width: { type: 'number', label: 'Ratio X', default: '"16"' },
        showBooking: { type: 'checkbox', label: 'Show Booking Widget?', default: true },
        title: { type: 'text', label: 'Title' },
        description: { type: 'textarea', label: 'Brief Description' },
        modalVideo: { type: 'text', label: 'Modal CloudFlare ID', enabled: false },
        link: { type: 'link', label: 'Link' },
        containerHeight: {
          type: 'text',
          label: 'Set the height of the video',
          default: '"100vh"',
        },
        dimmer: { type: 'checkbox', label: 'Dim Video?' },
        bottomFade: { type: 'checkbox', label: 'Bottom Fade?' },
        image: {
          type: 'media',
          label: 'Mobile Image',
          breakpoints: { lg: { width: 400, height: 800 } },
        },
      },
      templates: [
        {
          label: 'Video Takeover',
          fields: {
            bottomFade: { value: false },
            cfId: { value: '37d0400309ea25feed48f7c8b2d3be6b' },
            dimmer: { value: false },
            image: {
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Hilton_Yucatan\/things_to_do\/yucatan-rooftop-pool-02.jpg',
            },
          },
        },
      ],
    })

    const params = `?site_id=${this.currentPage?.site_id}`

    axios.get(`/api/resorts${params}`).then((response) => {
      this.locations = response.data
    })
  },
}
</script>

<template>
  <div class="relative bg-black">
    <hero :devise="devise" :full-height="true">
      <div class="relative">
        <div
          class="w-full relative hero-slide overflow-hidden"
          :style="{ height: fields.containerHeight.value }"
        >
          <div class="hidden md:block video-background">
            <div class="video-foreground pointer-events-none">
              <iframe
                :src="`https://iframe.videodelivery.net/${fields.cfId.value}?muted=true&autoplay=true&controls=false`"
                class="w-full"
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
                frameborder="0"
              />
            </div>

            <div
              v-if="fields.dimmer.value"
              class="absolute z-10 inset-0 bg-black opacity-50 pointer-events-none"
            />
            <div
              v-if="fields.bottomFade.value"
              class="absolute z-20 inset-0-x bottom-0 pointer-events-none"
              style="
                height: 33vh;
                background: linear-gradient(
                  0deg,
                  rgba(255, 255, 255, 1) 0%,
                  rgba(255, 255, 255, 0) 100%
                );
              "
            />

            <div class="absolute z-30 top-0 left-0 right-0 flex justify-center">
              <div class="container text-white flex justify-start">
                <div class="w-full py-16 px-8 md:px-12" style="max-width: 800px">
                  <div class="text-6xl uppercase font-bold mb-4 tracking-wider">
                    {{ fields.title.value }}
                  </div>
                  <div class="text-2xl font-light w-4/5 leading-loose">
                    {{ fields.description.value }}
                  </div>
                  <div
                    v-if="fields.modalVideo.enabled"
                    class="text-lg font-light w-4/5 leading-loose mt-8"
                  >
                    <a
                      href="#"
                      class="border border-white text-white px-8 py-4"
                      @click.prevent="showModal = true"
                    >{{ fields.link.value?.text }}</a>
                  </div>
                  <div
                    v-else-if="fields.link.value?.href"
                    class="text-lg font-light w-4/5 leading-loose mt-8"
                  >
                    <a
                      :href="fields.link.value?.href"
                      class="border border-white text-white px-8 py-4"
                    >{{ fields.link.value?.text }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="md:hidden absolute inset-0 w-full h-full bg-cover bg-center"
            :style="{ backgroundImage: `url(${fields.image.value?.[0]?.url})` }"
          />
        </div>
        <transition name="fade">
          <div v-if="showModal">
            <div class="absolute z-40 inset-0 bg-black opacity-75" />

            <div class="absolute z-50 inset-0 flex justify-center items-center">
              <div
                class="relative w-full h-screen m-8"
                style="max-height: 500px; max-width: 900px"
              >
                <div
                  class="absolute top-0 right-0 -mr-6 -mt-6 cursor-pointer"
                  @click="showModal = false"
                >
                  <svg
                    class="w-6 h-6 text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
                <iframe
                  style="max-height: 500px; max-width: 900px"
                  :src="`https://iframe.videodelivery.net/${fields.cfId.value}?autoplay=true`"
                  class="w-full h-screen"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowfullscreen
                  frameborder="0"
                />
              </div>
            </div>
          </div>
        </transition>
      </div>
    </hero>
    <div v-if="fields.showBooking.value" class="absolute z-40 bottom-0 left-0 right-0">
      <PlayaBooking :inline="true" />
    </div>
  </div>
</template>
