<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    showSvg() {
      if (this.$voix.breakpoint === 'default' || this.$voix.breakpoint === 'sm')
        return false

      return true
    },
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage })
    },
  },
  mounted() {
    defineSlice({
      name: {
        label: 'Left Right Block Suite',
        group: 'hyatt',
        layouts: ['HyattZilara', 'HyattZiva', 'Blocks', 'AgentCashPlus'],
      },
      tags: ['Content', 'Wysiwyg'],
      fields: {
        tourUrl: { type: 'text', label: 'Tour URL', enabled: false, name: 'tourUrl' },
        title: { type: 'text', label: 'Title', editorLabel: true, name: 'title' },
        subtitle: { type: 'text', label: 'Sub-Title', name: 'subtitle' },
        description: { type: 'wysiwyg', label: 'Description', name: 'description' },
        imagesOnLeft: {
          type: 'checkbox',
          label: 'Images on Left?',
          instructions: 'Images on right by default',
          default: false,
          name: 'imagesOnLeft',
        },
        image1: {
          type: 'media',
          label: 'Image 1',
          breakpoints: {
            lg: { width: 630, height: 420 },
            md: { width: 401, height: 280 },
            sm: { width: 251, height: 220 },
          },
          name: 'image1',
        },
        image2: {
          type: 'media',
          label: 'Image 2',
          breakpoints: {
            lg: { width: 630, height: 420 },
            md: { width: 400, height: 280 },
            sm: { width: 250, height: 220 },
          },
          name: 'image2',
        },
        image3: {
          type: 'media',
          label: 'Image 3',
          breakpoints: {
            lg: { width: 630, height: 420 },
            md: { width: 400, height: 280 },
            sm: { width: 250, height: 220 },
          },
          name: 'image3',
        },
        image4: {
          type: 'media',
          label: 'Image 4',
          breakpoints: {
            lg: { width: 630, height: 420 },
            md: { width: 400, height: 280 },
            sm: { width: 250, height: 220 },
          },
          name: 'image4',
        },
        image5: {
          type: 'media',
          label: 'Image 5',
          breakpoints: {
            lg: { width: 630, height: 420 },
            md: { width: 400, height: 280 },
            sm: { width: 250, height: 220 },
          },
          name: 'image5',
        },
        bgsvg: {
          type: 'select',
          label: 'Content Background Graphic',
          options: {
            'scribble-circle': 'Scribble Circle',
            'spider-web': 'Spider Web',
            'spiral-coral': 'Spiral Coral',
            'spiral-coral-web': 'Spiral Coral Web',
          },
          name: 'bgsvg',
        },
        included: {
          type: 'text',
          label: 'Included Banner Text',
          default: 'Included',
          enabled: false,
          name: 'included',
        },
        includedBackground: {
          type: 'color',
          label: 'Included Banner BG Color',
          name: 'includedBackground',
        },
        includedForeground: {
          type: 'color',
          label: 'Included Banner FG Color',
          name: 'includedForeground',
        },
      },
      slots: [{ name: 'default', allowedElements: [] }],
    })
  },
}
</script>

<template>
  <div>
    <div
      class="flex justify-center p-0 md:p-8 relative overflow-hidden w-full bg-abs-white"
    >
      <div
        class="py-4 md:p-8 container text-center flex flex-col-reverse md:flex-row items-center bg-zilara-cancun-grey-dark"
        :class="{ 'md:flex-row-reverse': fields.imagesOnLeft.value === true }"
      >
        <div class="text-left md:w-2/5 p-8 relative z-10">
          <div class="relative z-10 text-center md:text-left">
            <div class="font-light mb-1 text-3xl text-playa-action bold">
              {{ fields.title.value }}
            </div>
            <div class="font-thin mb-4">
              {{ fields.subtitle.value }}
            </div>
            <div class="leading-loose" v-html="fields.description.value" />

            <div class="mt-8">
              <slot />
            </div>
          </div>
          <div
            v-if="$voix.breakpoint !== 'default' && fields.bgsvg.value !== null"
            class="text-abs-white absolute z-0 inset-0 opacity-50"
          >
            <background-elements-scribble-circle
              v-if="showSvg && fields.bgsvg.value === 'scribble-circle'"
              w="200%"
              h="200%"
              style="margin-top: -50%; margin-left: -50%"
            />
            <background-elements-spider-web
              v-if="showSvg && fields.bgsvg.value === 'spider-web'"
              w="200%"
              h="200%"
              style="margin-top: -50%; margin-left: -50%"
            />
            <background-elements-spiral-coral
              v-if="showSvg && fields.bgsvg.value === 'spiral-coral'"
              w="200%"
              h="200%"
              style="margin-top: -50%; margin-left: -50%"
            />
            <background-elements-spiral-coral-web
              v-if="showSvg && fields.bgsvg.value === 'spiral-coral-web'"
              w="200%"
              h="200%"
              style="margin-top: -50%; margin-left: -50%"
            />
          </div>
        </div>
        <div class="relative w-full md:w-3/5 z-10 overflow-hidden">
          <GeneralSwiper :devise="devise">
            <VoixMedia
              v-if="fields.image1.value && fields.image1.value.length"
              :field="fields.image1"
              class="w-full aspect-[16/11]"
            />
            <VoixMedia
              v-if="fields.image2.value && fields.image2.value.length"
              :field="fields.image2"
              class="w-full aspect-[16/11]"
            />
            <VoixMedia
              v-if="fields.image3.value && fields.image3.value.length"
              :field="fields.image3"
              class="w-full aspect-[16/11]"
            />
            <VoixMedia
              v-if="fields.image4.value && fields.image4.value.length"
              :field="fields.image4"
              class="w-full aspect-[16/11]"
            />
            <VoixMedia
              v-if="fields.image5.value && fields.image5.value.length"
              :field="fields.image5"
              class="w-full aspect-[16/11]"
            />
          </GeneralSwiper>
          <div v-if="fields.included.enabled" class="included absolute z-100">
            <div
              class="flex justify-center items-center uppercase text-sm"
              :style="{
                backgroundColor: fields.includedBackground.value,
                color: fields.includedForeground.value,
              }"
            >
              <div class="font-bold">
                {{ fields.included.value }}
              </div>
            </div>
          </div>

          <room-tour
            v-if="fields.tourUrl.enabled"
            class="absolute left-0 bottom-0 bg-zilara-cancun-grey-dark p-4 pb-2 m-4 rounded-lg"
            :tour-url="fields.tourUrl.value"
          >
            <img width="50" height="26" src="/imgs/360-icon.png">
          </room-tour>
        </div>
        <background-elements-swoosh-white-right
          v-if="fields.imagesOnLeft.value === false && $voix.breakpoint !== 'default'"
          class="absolute z-0 pointer-events-none"
          w="100%"
          h="100%"
        />
        <background-elements-swoosh-white-left
          v-if="fields.imagesOnLeft.value === true && $voix.breakpoint !== 'default'"
          class="absolute z-0 pointer-events-none"
          w="100%"
          h="100%"
        />
      </div>
    </div>
  </div>
</template>
