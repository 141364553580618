<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },

  mounted() {
    defineSlice({
      name: { label: 'Check In Out Times', group: 'playaresorts', layouts: ['*'] },
      tags: ['Text', 'Info'],
      fields: {
        checkin: {
          type: 'text',
          label: 'Check In',
          default: '3:00 p.m.',
          name: 'checkin',
        },
        checkout: {
          type: 'text',
          label: 'Check out',
          default: '12:00 p.m.',
          name: 'checkout',
        },
      },
      slots: [],
    })
  },
}
</script>

<template>
  <div class="flex justify-center">
    <div class="py-4 container">
      <div class="flex justify-center flex-col md:flex-row items-stretch -mx-8">
        <div class="lg:w-1/5 md:w-1/2 text-center">
          <p class="text-lg pb-1 uppercase">
            Check-in Time
          </p>
          <icons-icon-check-in :w="100" :h="100" color="#333" />
          <p>{{ fields.checkin.value }}</p>
        </div>
        <div class="lg:w-1/5 md:w-1/2 text-center">
          <p class="text-lg pb-1 uppercase">
            Check-Out Time
          </p>
          <icons-icon-check-out :w="100" :h="100" color="#333" />
          <p>{{ fields.checkout.value }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
