<script setup lang="ts">
import type { VoixCheckboxFieldInterface, VoixColorFieldInterface, VoixMediaFieldInterface, VoixSelectFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

const props = defineProps<{
  slice: any
  fields: {
    direction: VoixSelectFieldInterface
    image: VoixMediaFieldInterface
    logo: VoixMediaFieldInterface
    svgLogo: VoixSelectFieldInterface
    description: VoixWysiwygFieldInterface
    adultsOnly: VoixCheckboxFieldInterface
    backgroundColor: VoixColorFieldInterface
    linkBorderColor: VoixColorFieldInterface
    linkTextColor: VoixColorFieldInterface
  }
}>()
defineSlice({
  name: {
    label: 'Brand Resort Filters',
    group: 'blocks',
    layouts: ['Blocks'],
  },
  tags: ['Brand', 'Resort', 'Filters'],
  preview: 'SlicesBlocksBrandResortFilters.jpg',
  fields: {

  },
  slots: [{ name: 'default', allowedElements: ['Brand Resort Listing'] }],
})

const currentMode = ref('all') // all, adults-only, family-friendly

const activeSubSlices = computed(() => {
  if (currentMode.value === 'adults-only') {
  // Return only slices with adultsOnly === true
    return props.slice.elements.filter((slice: any) => {
      // Find adults only field
      const adultsOnlyField = slice.fields.find((field: any) => field.name === 'adultsOnly')
      // Return only slices with adultsOnly === true
      return adultsOnlyField && adultsOnlyField.checkbox === true
    })
  }
  if (currentMode.value === 'family-friendly') {
  // Return only slices with adultsOnly === false
    return props.slice.elements.filter((slice: any) => {
      // Find adults only field
      const adultsOnlyField = slice.fields.find((field: any) => field.name === 'adultsOnly')

      // adultsOnlyField wasn't selected
      // or it's been set to false
      return !adultsOnlyField || adultsOnlyField.checkbox === false
    })
  }

  return props.slice.elements
})
</script>

<template>
  <section id="brand-resort-filters" class="">
    <div class="flex justify-center items-center flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-4 py-8">
      <button
        class="text-center py-2 px-6 uppercase border-2  font-medium w-full max-w-[300px]"
        :class="currentMode === 'all' ? 'bg-ecru text-white' : 'text-black dark:text-white'"
        @click="currentMode = 'all'"
      >
        All
      </button>
      <button
        class="text-center py-2 px-6 uppercase border-2 font-medium w-full max-w-[300px]"
        :class="currentMode === 'adults-only' ? 'bg-ecru text-white' : 'text-black dark:text-white'"
        @click="currentMode = 'adults-only'"
      >
        Adults Only
      </button>
      <button
        class="text-center py-2 px-6 uppercase border-2 font-medium w-full max-w-[300px]"
        :class="currentMode === 'family-friendly' ? 'bg-ecru text-white' : 'text-black dark:text-white'"
        @click="currentMode = 'family-friendly'"
      >
        Family Friendly
      </button>
    </div>
    <VoixSliceLoop v-for="s in activeSubSlices" :key="s.id" :element="s" />
  </section>
</template>
