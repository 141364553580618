<script setup lang="ts">
import type { VoixCheckboxFieldInterface, VoixColorFieldInterface, VoixMediaFieldInterface, VoixSelectFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

const props = defineProps<{
  fields: {
    direction: VoixSelectFieldInterface
    image: VoixMediaFieldInterface
    logo: VoixMediaFieldInterface
    svgLogo: VoixSelectFieldInterface
    description: VoixWysiwygFieldInterface
    adultsOnly: VoixCheckboxFieldInterface
    backgroundColor: VoixColorFieldInterface
    linkBorderColor: VoixColorFieldInterface
    linkTextColor: VoixColorFieldInterface
  }
}>()
defineSlice({
  name: {
    label: 'Brand Resort Listing',
    group: 'blocks',
    layouts: ['Blocks'],
  },
  tags: ['Brand', 'Resort'],
  preview: 'SlicesBlocksBrandResortListing.jpg',
  fields: {
    direction: { type: 'select', label: 'Direction', options: { left: 'Left', right: 'Right' }, default: 'left', name: 'direction' },
    image: {
      type: 'media',
      label: 'Image',
      breakpoints: {
        default: { width: 900, height: 600 },
      },
      name: 'image',
    },
    logo: { type: 'media', label: 'Logo', breakpoints: { default: { width: 200, height: 150 } }, name: 'logo' },
    svgLogo: {
      type: 'select',
      label: 'SVG Logo',
      options: {
        None: 'None',
        AgentCashPlus: 'AgentCashPlus',
        Hilton: 'Hilton',
        HyattZilara: 'HyattZilara',
        HyattZiva: 'HyattZiva',
        HyattZivaZilara: 'HyattZivaZilara',
        Jewel: 'Jewel',
        JewelGrande: 'JewelGrande',
        MarriotBonvoy: 'MarriotBonvoy',
        Playa: 'Playa',
        Sanctuary: 'Sanctuary',
        Sandpiper: 'Sandpiper',
        Seadust: 'Seadust',
        TapestryCollection: 'TapestryCollection',
        Turquoize: 'Turquoize',
        Wyndham: 'Wyndham',
      },
    },
    description: { type: 'wysiwyg', label: 'Description', name: 'description' },
    adultsOnly: { type: 'checkbox', label: 'Adults Only?', name: 'adultsOnly' },
    backgroundColor: { type: 'color', label: 'Background Color', name: 'backgroundColor' },
    linkBorderColor: { type: 'color', label: 'Link Border Color', name: 'linkBorderColor' },
    linkTextColor: { type: 'color', label: 'Link Text Color', name: 'linkTextColor' },
  },
  slots: [{ name: 'default', allowedElements: ['BlockLink'] }],
})
</script>

<template>
  <section id="featured-place" class="bg-ecru text-white pb-10 md:pb-40 lg:pt-40 overflow-x-hidden">
    <BlocksBrandResortListingOnLeft v-if="props.fields.direction.value === 'left'" :fields="props.fields">
      <slot />
    </BlocksBrandResortListingOnLeft>
    <BlocksBrandResortListingOnRight v-if="props.fields.direction.value === 'right'" :fields="props.fields">
      <slot />
    </BlocksBrandResortListingOnRight>
  </section>
</template>
