<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      name: { label: 'Stripped List', group: 'global', layouts: ['AgentCashPlus', 'HyattZilara', 'Blocks'] },
      tags: ['Container'],
      preview: 'SlicesGlobalContainersStrippedList.jpg',
      fields: { title: { type: 'text', label: 'Title', editorLabel: true, default: 'Title', name: 'title' } },
      slots: [{ name: 'default', allowedElements: [] }],
    })
  },
}
</script>

<template>
  <div class="w-full p-8">
    <h4 class="mb-2">
      {{ fields.title.value }}
    </h4>
    <div class="stripped">
      <slot />
    </div>
  </div>
</template>
