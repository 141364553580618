<script setup lang="ts">
import type { SliceInterface, VoixLinkFieldInterface, VoixSelectFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'
import { Swiper, SwiperSlide } from 'swiper/vue'
import Container from '@/components/blocks/Container.vue'

import 'swiper/css'

const props = defineProps({
  slice: {
    type: Object as PropType<SliceInterface>,
    required: true,
  },
  fields: {
    type: Object as PropType<{
      title: VoixTextFieldInterface
      copy: VoixWysiwygFieldInterface
      link: VoixLinkFieldInterface
      position: VoixSelectFieldInterface
    }>,
    required: true,
  },
})
defineSlice({
  name: { label: 'Image Slider With Content Card', group: 'blocks', layouts: ['Blocks'] },
  tags: ['Slider'],
  preview: 'SlicesBlocksImageSliderWithContent.jpg',
  fields: {
    title: { type: 'text', label: 'Title', name: 'title' },
    copy: { type: 'wysiwyg', label: 'Copy', name: 'copy' },
    link: { type: 'link', label: 'Link', name: 'link' },
    position: {
      type: 'select',
      label: 'Position',
      options: {
        topLeft: 'Top Left',
        topRight: 'Right',
        bottomLeft: 'Bottom Left',
        bottomRight: 'Bottom Right',
      },
      default: 'topLeft',
      name: 'position',
    },
  },
  slots: [{
    name: 'default',
    allowedElements: ['SoloMedia'],
  }],
})

const isVisible = ref(false)
function onVisibilityChange(entry: { intersectionRatio: number }) {
  if (entry.intersectionRatio * 10 > 1)
    isVisible.value = true
  else
    isVisible.value = false
}
</script>

<template>
  <Container
    v-voix-visibility="{
      callback: onVisibilityChange, threshold: [0, 0.25],
    }"
    class="relative" :class="{
      'mt-8': fields.position.value === 'topLeft' || fields.position.value === 'topRight',
      'mb-8': fields.position.value === 'bottomLeft' || fields.position.value === 'bottomRight',
    }"
  >
    <div
      class="transition-all duration-1000 ease-out" :class="{
        'opacity-0': !isVisible,
        'opacity-100': isVisible,
      }"
    >
      <ClientOnly>
        <Swiper id="swiper" :slides-per-view="1" :space-between="0" class="border-4 border-white ">
          <SwiperSlide v-for="slice in props.slice.elements" :key="slice.id">
            <VoixSlice :slice="slice as SliceInterface" />
          </SwiperSlide>
        </Swiper>
      </ClientOnly>
    </div>
    <div
      class="relative lg:absolute z-10 px-8 py-8 bg-white md:w-[60%] xl:w-[45%] transition-all duration-1000 ease-out delay-700"

      :class="{
        'top-0 left-0 lg:left-8 -mt-24': fields.position.value === 'topLeft',
        'top-0 right-0 lg:right-8 -mt-24': fields.position.value === 'topRight',
        'bottom-0 left-0 lg:left-8 -mb-12': fields.position.value === 'bottomLeft',
        'bottom-0 right-0 lg:right-8 -mb-12': fields.position.value === 'bottomRight',
        'opacity-0': !isVisible,
        'opacity-100 translate-y-0': isVisible,
        '-translate-y-24': !isVisible && (fields.position.value === 'topLeft' || fields.position.value === 'topRight'),
        'translate-y-24': !isVisible && (fields.position.value === 'bottomLeft' || fields.position.value === 'bottomRight'),
      }"
    >
      <h3 class="font-thin tracking-wider leading-tight text-gray-600 my-8 ">
        {{ fields.title.value }}
      </h3>
      <VoixWysiwyg class="font-light text-sm text-gray-500  pb-8" :field="fields.copy" />
      <VoixLink :field="fields.link" class="py-3 px-6 inline-block lg:inline text-white uppercase bg-nonPhotoBlue" />
    </div>
  </Container>
</template>
