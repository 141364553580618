<script setup lang="ts">
import type {
  VoixColorFieldInterface,
  VoixLinkFieldInterface,
  VoixMediaFieldInterface,
  VoixSelectFieldInterface,
  VoixTextFieldInterface,
  VoixWysiwygFieldInterface,
} from '@voix/types'
import type { HTMLAttributes } from 'vue'

const props = defineProps<{
  fields: {
    direction: VoixSelectFieldInterface
    media: VoixMediaFieldInterface
    title: VoixTextFieldInterface
    copy: VoixWysiwygFieldInterface
    link: VoixLinkFieldInterface
    borderColor: VoixColorFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Magazine Card', group: 'Kimpton', layouts: ['Kimpton'] },
  tags: ['Wysiwyg', 'Card'],
  description: 'Large horizontal background image with gray card on top.',
  preview: 'SlicesKimptonMagazineCard.jpg',
  fields: {
    direction: {
      type: 'select',
      label: 'Direction',
      options: {
        left: 'Left',
        right: 'Right',
      },
      default: 'left',
    },
    media: {
      type: 'media',
      label: 'Media',
      breakpoints: {
        default: {
          width: 640,
          height: 320,
        },

        md: {
          width: 1000,
          height: 570,
        },

        lg: {
          width: 1700,
          height: 714,
        },
      },
    },

    title: {
      type: 'text',
      label: 'Title',
    },

    copy: {
      type: 'wysiwyg',
      label: 'Copy',
    },

    link: {
      type: 'link',
      label: 'Link',
      enabled: true,
    },

    borderColor: {
      type: 'color',
      label: 'Border Color',
    },
  },
  templates: [
    {
      label: 'Main Example',
      fields: {
        media: {
          value:
            'https://images.unsplash.com/photo-1448375010925-a75a42ceaffa?q=80&w=1600&h=900&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        },
        title: {
          value: 'Title',
        },
        copy: {
          value:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec purus<p>',
        },
        link: {
          value: {
            text: 'Learn more',
            url: '#',
          },
        },
      },
    },
  ],
})

const borderStyle = computed(() => {
  if (!props.fields.borderColor.value)
    return {} as any

  return {
    borderColor: props.fields.borderColor.value,
  } as any
})
</script>

<template>
  <div class="p-12">
    <div class="relative flex flex-col items-start justify-center md:py-24 aspect-[3/1]" :class="{ 'cursor-pointer': fields.link.enabled, 'items-end': fields.direction.value === 'right' }">
      <div class="lg:absolute lg:inset-0">
        <VoixMedia :field="fields.media" class="object-cover w-full h-full" />
      </div>
      <div
        class="relative z-10 py-8 px-10 border-t-8 text-gray-800 w-full lg:max-w-sm lg:mx-16 bg-black bg-opacity-70"
        :style="borderStyle"
      >
        <h3 class="font-light uppercase text-2xl text-white">
          {{ fields.title.value }}
        </h3>
        <VoixWysiwyg :field="fields.copy" class="pt-4 pb-2 font-light text-white" />
        <div v-if="fields.link.enabled" class="mt-2">
          <VoixLink
            :field="fields.link"
            class="text-white hover:underline uppercase text-sm border border-gray-500 px-6 py-3"
          />
        </div>
      </div>
    </div>
  </div>
</template>
