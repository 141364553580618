<script>
import anime from 'animejs/lib/anime.es.js'

export default {
  props: { fields: { type: Object, default: () => ({}) } },

  data() {
    return {
      isVisible: false,
    }
  },

  mounted() {
    anime({
      targets: '.three-image-cards.card',
      translateY: 100,
      opacity: 0,
      duration: 0,
    })
  },

  methods: {
    onVisibilityChange(entry) {
      if (entry.intersectionRatio * 100 > 0) {
        this.isVisible = true
        anime({
          targets: '.three-image-cards.card',
          translateY: 0,
          opacity: 1,
          duration: 5000,
          easing: 'easeOutExpo',
          delay: anime.stagger(200),
        })
      }
      else {
        this.isVisible = false
        anime({
          targets: '.three-image-cards.card',
          translateY: 100,
          opacity: 0,
          duration: 2000,
        })
      }
    },
  },
}
</script>

<template>
  <div
    v-voix-visibility="{ callback: onVisibilityChange, threshold: [0, 0.25] }"
    class="w-full flex justify-center three-image-cards"
  >
    <div class="container text-center">
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-16">
        <div
          v-if="fields.card1Title.value"
          class="bg-abs-white three-image-cards card flex flex-col justify-start"
        >
          <VoixMedia class="w-full" :field="fields.card1Image" />
          <div class="flex-1 flex flex-col justify-between">
            <div class="text-center p-4">
              <div class="font-bold card-title">
                {{ fields.card1Title.value }}
              </div>
              <div
                class="card-body"
                v-html="fields.card1Description.value"
              />
            </div>
            <div
              v-if="fields.card1Link && fields.card1Link.enabled"
              class="card-body p-4"
            >
              <a
                class="flex items-center text-bold"
                :href="fields.card1Link.value.url"
              >
                {{ fields.card1Link.value.text }}
                <svg
                  class="w-4 h-4 ml-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div
          v-if="fields.card2Title.value"
          class="bg-abs-white three-image-cards card flex flex-col justify-start"
        >
          <VoixMedia class="w-full" :field="fields.card2Image" />
          <div class="flex-1 flex flex-col justify-between">
            <div class="text-center p-4">
              <div class="font-bold card-title">
                {{ fields.card2Title.value }}
              </div>
              <div
                class="card-body"
                v-html="fields.card2Description.value"
              />
            </div>
            <div
              v-if="fields.card2Link && fields.card2Link.enabled"
              class="card-body p-4"
            >
              <a
                class="flex items-center text-bold"
                :href="fields.card2Link.value.url"
              >
                {{ fields.card2Link.value.text }}
                <svg
                  class="w-4 h-4 ml-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div
          v-if="fields.card3Title.value"
          class="bg-abs-white three-image-cards card flex flex-col justify-start"
        >
          <VoixMedia class="w-full" :field="fields.card3Image" />
          <div class="flex-1 flex flex-col justify-between">
            <div class="text-center p-4">
              <div class="font-bold card-title">
                {{ fields.card3Title.value }}
              </div>
              <div
                class="card-body"
                v-html="fields.card3Description.value"
              />
            </div>
            <div
              v-if="fields.card3Link && fields.card3Link.enabled"
              class="card-body p-4"
            >
              <a
                class="flex items-center text-bold"
                :href="fields.card3Link.value.url"
              >
                {{ fields.card3Link.value.text }}
                <svg
                  class="w-4 h-4 ml-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div
          v-if="fields.card4Title.value"
          class="bg-abs-white three-image-cards card flex flex-col justify-start"
        >
          <VoixMedia class="w-full" :field="fields.card4Image" />
          <div class="flex-1 flex flex-col justify-between">
            <div class="text-center p-4">
              <div class="font-bold card-title">
                {{ fields.card4Title.value }}
              </div>
              <div
                class="card-body"
                v-html="fields.card4Description.value"
              />
            </div>
            <div
              v-if="fields.card4Link && fields.card4Link.enabled"
              class="card-body p-4"
            >
              <a
                class="flex items-center text-bold"
                :href="fields.card4Link.value.url"
              >
                {{ fields.card4Link.value.text }}
                <svg
                  class="w-4 h-4 ml-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div
          v-if="fields.card5Title.value"
          class="bg-abs-white three-image-cards card flex flex-col justify-start"
        >
          <VoixMedia class="w-full" :field="fields.card5Image" />
          <div class="flex-1 flex flex-col justify-between">
            <div class="text-center p-4">
              <div class="font-bold card-title">
                {{ fields.card5Title.value }}
              </div>
              <div
                class="card-body"
                v-html="fields.card5Description.value"
              />
            </div>
            <div
              v-if="fields.card5Link && fields.card5Link.enabled"
              class="card-body p-4"
            >
              <a
                class="flex items-center text-bold"
                :href="fields.card5Link.value.url"
              >
                {{ fields.card5Link.value.text }}
                <svg
                  class="w-4 h-4 ml-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div
          v-if="fields.card6Title.value"
          class="bg-abs-white three-image-cards card flex flex-col justify-start"
        >
          <VoixMedia class="w-full" :field="fields.card6Image" />
          <div class="flex-1 flex flex-col justify-between">
            <div class="text-center p-4">
              <div class="font-bold card-title">
                {{ fields.card6Title.value }}
              </div>
              <div
                class="card-body"
                v-html="fields.card6Description.value"
              />
            </div>
            <div
              v-if="fields.card6Link && fields.card6Link.enabled"
              class="card-body p-4"
            >
              <a
                class="flex items-center text-bold"
                :href="fields.card6Link.value.url"
              >
                {{ fields.card6Link.value.text }}
                <svg
                  class="w-4 h-4 ml-1"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
