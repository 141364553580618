<script lang="ts" setup>
defineSlice({
  name: {
    label: 'Member Booking Widget',
    group: 'Infinity Luxury',
    layouts: ['InfinityLuxury'],
  },
  tags: ['Widget'],
  templates: [{ label: 'Member Booking Widget', fields: [] }],
})

const showWidget = ref(false)
const discounts = ref('{}')

async function hash(string) {
  const utf8 = new TextEncoder().encode(string)
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map(bytes => bytes.toString(16).padStart(2, '0')).join('')
  return hashHex
}

async function getOurToken(contractNumber) {
  const d = new Date()
  let mm = d.getMonth() + 1 // month is zero-based
  let dd = d.getDate()

  if (dd < 10)
    dd = `0${dd}`
  if (mm < 10)
    mm = `0${mm}`

  const today = `${mm}/${dd}`
  const result = await hash(`INFIKEY001@${contractNumber}+${today}`)

  return result
}

if (process.client) {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  const token = urlParams.get('token')
  const contractNumber = urlParams.get('utm_term')

  if (token && contractNumber) {
    const ourToken = await getOurToken(contractNumber)
    showWidget.value = token.toLowerCase() === ourToken
  }
}

if (process.client) {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  if (urlParams.has('mil')) {
    const mil = urlParams.get('mil')?.toString()
    const map = {
      BR: 'INFINITYBR',
      SL: 'INFINITYSL',
      GL: 'INFINITYGL',
      PL: 'INFINITYPL',
      DA: 'INFINITYDA',
    }

    if (Object.prototype.hasOwnProperty.call(map, mil)) {
      const rateCode = map[mil]
      discounts.value = `{"rate":"${rateCode}"}`
    }
  }
}
</script>

<template>
  <div v-if="showWidget">
    <div class="container py-12">
      <FlightBookingForm />
    </div>
  </div>
</template>
