<script setup lang="ts">
import { usePageStore } from '@voix/store/pageStore'
import Container from '@/components/blocks/Container.vue'
import OfferCard from '@/components/blocks/OfferCard.vue'

const currentPage = usePageStore().currentPage

const { data: specials } = await useVoixAsyncData(
  `/api/playa/page-specials/${currentPage.id}/SandpiperSpecialSlide`,
)

const mode = ref('all')

const filteredSpecials = computed(() => {
  if (mode.value === 'all') {
    return specials.value
  }
  else {
    if (mode.value === 'adults') {
      return specials.value.filter((special) => {
        return special.resort_is_adults_only === true
      })
    }

    return specials.value.filter((special) => {
      return special.resort_is_adults_only === false
    })
  }
})
defineSlice({
  name: { label: 'Specials Block Layout', group: 'blocks' },
  preview: 'SlicesBlocksBlockSpecials.jpg',
  slots: [],
})
</script>

<template>
  <section id="special-offers" class="py-12">
    <Container>
      <div class="flex justify-center justify-wrap space-x-4 text-slate-500 pb-12">
        <button class="border-slate-700 px-2" :class="{ 'border-b-2 text-slate-600': mode === 'all' }" @click="mode = 'all'">
          View All
        </button>
        <button class="border-slate-700 px-2" :class="{ 'border-b-2 text-slate-600': mode === 'adults' }" @click="mode = 'adults'">
          Adults Only
        </button>
        <button class="border-slate-700 px-2" :class="{ 'border-b-2 text-slate-600': mode === 'children' }" @click="mode = 'children'">
          All Ages
        </button>
      </div>
      <div
        v-for="special in filteredSpecials"
        :key="special.id"
        class="px-4 w-full xl:w-9/12 mx-auto mb-20"
      >
        <OfferCard
          :main-color="special.brand_primary_color || '#1b449c'"
          :accent-color="special.brand_secondary_color || '#aeaeae'"
          :title="special.title"
          :expiration-date="special.end_date"
          :description="special.content"
          :photo="special.image"
          :is-adults-only="special.resort_is_adults_only"
        />
      </div>
    </Container>
  </section>
</template>
