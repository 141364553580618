<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      show: false,
      mn: null,
    }
  },
  mounted() {
    defineSlice({
      name: {
        label: 'Member Booking Widget',
        group: 'Playa Collection',
        layouts: ['PlayaCollection'],
      },
      tags: ['Widget'],
      templates: [{ label: 'Member Booking Widget', fields: [] }],
    })

    if (process.client) {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      if (urlParams.has('mn')) {
        this.show = true
        this.mn = urlParams.get('mn')
      }

      if (urlParams.has('rciid')) {
        const rciid = urlParams.get('rciid')
        this.$store.commit('promoCodes', {
          promo: null,
          coupon: null,
          rate: rciid,
          group: null,
        })
      }
    }
  },
}
</script>

<template>
  <div v-if="show" class="flex justify-center bg-grey-lighter py-12">
    <div class="p-8 md:p-12 container mx-auto flex justify-center">
      <PlayaBooking :inline="true" class="bg-white w-2/3" :mn="mn" />
    </div>
  </div>
</template>
