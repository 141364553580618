<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  mounted() {
    defineSlice({
      name: { label: 'Slider Image', group: 'global', layouts: ['*'] },
      tags: ['Slider', 'Image'],
      fields: {
        image: {
          type: 'media',
          label: 'Background Image',
          editorLabel: true,
          breakpoints: {
            lg: { width: 1800, height: 800 },
            default: { width: 600, height: 600 },
          },
          name: 'image',
        },
        layerTwoImage: {
          type: 'media',
          label: 'Foreground Image',
          breakpoints: { default: { width: 1800 } },
          name: 'layerTwoImage',
        },
        layerTwoPosition: {
          type: 'select',
          label: 'Layer Two Position',
          options: {
            tl: 'Top Left',
            t: 'Top Center',
            tr: 'Top Right',
            ml: 'Middle Left',
            m: 'Middle Center',
            mr: 'Middle Right',
            bl: 'Bottom Left',
            b: 'Bottom Center',
            br: 'Bottom Right',
          },
          name: 'layerTwoPosition',
        },
        link: { type: 'link', label: 'Optional Link', enabled: false, name: 'link' },
      },
      slots: [{ name: 'default', allowedElements: [] }],
    })
  },
  methods: {
    link() {
      if (this.fields.link.enabled)
        window.location.href = this.fields.link.value.href
    },
  },
}
</script>

<template>
  <div
    v-if="fields.image.value"
    class="aspect-video h-[80vh] w-full"
    :class="{
      'cursor-pointer': fields.link.enabled,
    }"
    @click="link"
  >
    <div class="relative w-full h-full">
      <div
        class="w-1/4 absolute inset-0 z-10"
        :class="{
          'top-0 left-0 md:mt-12 md:ml-12': fields.layerTwoPosition.value === 'tl',
          'top-0 md:mt-12 inset-0-x text-center': fields.layerTwoPosition.value === 't',
          'top-0 right-0 md:mt-12 md:mr-12': fields.layerTwoPosition.value === 'tr',
          'pin-y left-0 md:ml-12 flex items-center':
            fields.layerTwoPosition.value === 'ml',
          'pin-y inset-0-x text-center flex justify-center items-center':
            fields.layerTwoPosition.value === 'm',
          'pin-y right-0 md:mr-12 flex justify-end items-center':
            fields.layerTwoPosition.value === 'mr',
          'bottom-0 left-0 md:mb-12 md:ml-12': fields.layerTwoPosition.value === 'bl',
          'bottom-0 md:mb-12 inset-0-x text-center':
            fields.layerTwoPosition.value === 'b',
          'bottom-0 right-0 md:mb-12 md:mr-12': fields.layerTwoPosition.value === 'br',
        }"
      >
        <div>
          <VoixMedia
            v-if="fields.layerTwoImage.value && fields.layerTwoImage.value.length > 0"
            :field="fields.layerTwoImage"
            loading="eager"
          />
        </div>
      </div>
      <VoixMedia
        v-if="fields.image.value && fields.image.value.length > 0"
        v-slot="slotProps"
        :field="fields.image"
        :background="true"
        loading="eager"
      >
        <div
          :style="{
            backgroundImage: `url(${slotProps.image})`,
          }"
          class="absolute inset-0 w-full bg-cover bg-center hero-slide"
        />
      </VoixMedia>
    </div>
  </div>
</template>
