<script setup lang="ts">
import type { VoixLinkFieldInterface, VoixMediaFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

const props = defineProps<{
  fields: {
    media: VoixMediaFieldInterface
    title: VoixTextFieldInterface
    copy: VoixWysiwygFieldInterface
    link: VoixLinkFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Image Card', group: 'Kimpton', layouts: ['Kimpton'] },
  description: 'Vertical card with image in the background with white copy on top',
  preview: 'SlicesKimptonImageCard.jpg',
  fields: {
    media: {
      type: 'media',
      label: 'Media',
      breakpoints: {
        default: {
          width: 340,
          height: 420,
        },
      },
    },

    title: {
      type: 'text',
      label: 'Title',
    },

    copy: {
      type: 'wysiwyg',
      label: 'Copy',
    },

    link: {
      type: 'link',
      label: 'Link',
      enabled: true,
    },
  },
  templates: [{
    label: 'Main Example',
    fields: {
      media: {
        value: 'https://images.unsplash.com/photo-1448375010925-a75a42ceaffa?q=80&w=1600&h=900&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      title: {
        value: 'Title',
      },
      copy: {
        value: '<p>Copy</p>',
      },
      link: {
        value: {
          link_type: 'Web',
          url: '#',
        },
      },
    },
  }],
})

function goToLink() {
  if (props.fields.link.enabled && props.fields.link.value) {
    window.location = props.fields.link.value.url as unknown as Location
  }
}
</script>

<template>
  <div class="relative max-w-lg text-white flex items-end justify-center min-h-[420px]" :class="{ 'cursor-pointer': fields.link.enabled }" @click="goToLink">
    <div class="absolute inset-0">
      <VoixMedia :field="fields.media" class="object-cover w-full h-full" />

      <div class="absolute inset-0 bg-gradient-to-t from-kimpton-secondary/50 via-kimpton-secondary/0 to-kimpton-secondary/0" />
    </div>

    <div class="p-8 relative z-10 text-center">
      <h3 class="font-light uppercase text-2xl">
        {{ fields.title.value }}
      </h3>
      <VoixWysiwyg :field="fields.copy" />
    </div>
  </div>
</template>
