<script>
import Icons from '../../mixins/Icons'

export default {
  name: 'IconBoardroom',
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len */ -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 57.7 60.9"
    aria-labelledby="IconBoardRoom"
    role="presentation"
  >
    <title id="IconBoardRoom">Board Room Icon</title>
    <g id="Layer_3_1_">
      <g>
        <path
          :fill="color"
          class="st3"
          d="M19.6,14.4c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5C18.9,13,19.6,13.6,19.6,14.4
"
        />
        <path
          :fill="color"
          class="st3"
          d="M19.6,19.8c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5C18.9,18.4,19.6,19,19.6,19.8
"
        />
        <path
          :fill="color"
          class="st3"
          d="M19.6,25.2c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5
C18.9,23.8,19.6,24.4,19.6,25.2"
        />
        <path
          :fill="color"
          class="st3"
          d="M19.6,30.6c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5
C18.9,29.2,19.6,29.8,19.6,30.6"
        />
        <path
          :fill="color"
          class="st3"
          d="M19.6,36.1c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5
C18.9,34.6,19.6,35.2,19.6,36.1"
        />
        <path
          :fill="color"
          class="st3"
          d="M19.6,41.5c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5C18.9,40,19.6,40.7,19.6,41.5
"
        />
        <path
          :fill="color"
          class="st3"
          d="M19.6,46.9c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5
C18.9,45.4,19.6,46.1,19.6,46.9"
        />
        <path
          :fill="color"
          class="st3"
          d="M39,14.4c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5C38.3,13,39,13.6,39,14.4"
        />
        <path
          :fill="color"
          class="st3"
          d="M39,19.8c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5C38.3,18.4,39,19,39,19.8"
        />
        <path
          :fill="color"
          class="st3"
          d="M39,25.2c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5C38.3,23.8,39,24.4,39,25.2"
        />
        <path
          :fill="color"
          class="st3"
          d="M39,30.6c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5C38.3,29.2,39,29.8,39,30.6"
        />
        <path
          :fill="color"
          class="st3"
          d="M39,36.1c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5C38.3,34.6,39,35.2,39,36.1"
        />
        <path
          :fill="color"
          class="st3"
          d="M39,41.5c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5C38.3,40,39,40.7,39,41.5"
        />
        <path
          :fill="color"
          class="st3"
          d="M39,46.9c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5C38.3,45.4,39,46.1,39,46.9"
        />
        <path
          :fill="color"
          class="st3"
          d="M29.3,8.4c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5C28.5,7,29.2,7.6,29.3,8.4"
        />
        <path
          :fill="color"
          class="st3"
          d="M29.3,53.2c0,0.8-0.6,1.5-1.4,1.5c-0.8,0-1.5-0.6-1.5-1.4c0-0.8,0.6-1.5,1.4-1.5
C28.5,51.8,29.2,52.4,29.3,53.2"
        />
        <rect
          :fill="color"
          x="21.9"
          y="11.8"
          class="st3"
          width="11.9"
          height="37.9"
        />
      </g>
    </g>
  </svg>
</template>
