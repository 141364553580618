<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      deprecated: true,
      name: { label: 'Full Width Container', group: 'global', layouts: ['*'] },
      tags: ['Container'],
      preview: 'SlicesGlobalContainersFullWidthContainer.jpg',
      fields: { centerItems: { type: 'checkbox', label: 'Center Items', default: true, name: 'centerItems' }, centerText: { type: 'checkbox', label: 'Center Text', name: 'centerText' } },
      slots: [{ name: 'default', allowedElements: [] }],
    })
  },
}
</script>

<template>
  <div
    class="flex" :class="{
      'text-center': fields.centerText.value,
      'justify-center': fields.centerItems.value,
    }"
  >
    <slot />
  </div>
</template>
