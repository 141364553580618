<script setup lang="ts">
import type {
  SliceInterface,
  VoixCheckboxFieldInterface,
  VoixSelectFieldInterface,
  VoixTextFieldInterface,
} from '@voix/types'

import { usePageStore } from '@voix/store/pageStore'
import SlicesHyattSpecialOffer from '@/components/slices/hyatt/SlicesHyattSpecialOffer.vue'
import SlicesHyattTwentyTwentyTwoSpecialOffer from '@/components/slices/hyatt/TwentyTwentyTwo/SlicesHyattTwentyTwentyTwoSpecialOffer.vue'
import SlicesPlayaresortsSpecialOffersSpecialOfferHorizontal from '@/components/slices/playaresorts/special-offers/SlicesPlayaresortsSpecialOffersSpecialOfferHorizontal.vue'
import SlicesPlayaresortsSpecialOffersSpecialOfferCard from '@/components/slices/playaresorts/special-offers/SlicesPlayaresortsSpecialOffersSpecialOfferCard.vue'

const props = defineProps({
  slice: {
    type: Object as PropType<SliceInterface>,
    required: true,
  },
  fields: {
    type: Object as PropType<{
      title: VoixTextFieldInterface
      titleAbove: VoixCheckboxFieldInterface
      pageSpecialSlice: VoixSelectFieldInterface
    }>,
    required: true,
  },
})

defineSlice({
  name: {
    label: 'Special Offer Grid',
    group: 'playaresorts',
    layouts: ['*'],
  },
  tags: ['Specials', 'Grid'],
  fields: {
    title: {
      type: 'text',
      label: 'Title',
      editorLabel: true,
      default: 'Special Offers',
      name: 'title',
    },
    titleAbove: {
      type: 'checkbox',
      label: 'Title above Image?',
      default: false,
      name: 'titleAbove',
    },
    pageSpecialSlice: {
      label: 'Page Special Slice',
      type: 'select',
      options: {
        SliceData: 'Use Slice Data',
        SlicesHyattSpecialOffer: 'Hyatt Special Offer',
        SlicesHyattTwentyTwentyTwoSpecialOffer: 'Hyatt 2022 Special Offer',
        SlicesPlayaresortsSpecialOffersSpecialOfferHorizontal: 'Special Offer Horizontal',
        SlicesPlayaresortsSpecialOffersSpecialOfferCard: 'Special Offer Card',
      },
      default: 'SliceData',
    },
  },
  slots: [{ name: 'default', allowedElements: [] }],
})

const pageStore = usePageStore()
const currentPage = pageStore.currentPage
const { data: pageSpecials } = await useVoixAsyncData(
  `/api/playa/page-specials/${currentPage.id}/${props.fields.pageSpecialSlice.value}`,
)

const specialSlice = computed(() => {
  switch (props.fields.pageSpecialSlice.value) {
    case 'SlicesHyattSpecialOffer':
      return SlicesHyattSpecialOffer
    case 'SlicesHyattTwentyTwentyTwoSpecialOffer':
      return SlicesHyattTwentyTwentyTwoSpecialOffer
    case 'SlicesPlayaresortsSpecialOffersSpecialOfferHorizontal':
      return SlicesPlayaresortsSpecialOffersSpecialOfferHorizontal
    case 'SlicesPlayaresortsSpecialOffersSpecialOfferCard':
      return SlicesPlayaresortsSpecialOffersSpecialOfferCard
  }
  return null
})

const colsClass = computed(() => {
  return {
    'grid-cols-1 lg:grid-cols-2':
      props.slice.elements.length
      && props.slice.elements[0].elements.length
      && props.slice.elements[0].elements.length > 1
      && props.slice.elements[0].elements[0].component
      !== 'SlicesPlayaresortsSpecialOffersSpecialOfferHorizontal'
      && props.slice.elements[0].elements[0].component
      !== 'SlicesPlayaresortsSpecialOffersSpecialOfferBlock',
  }
})
</script>

<template>
  <div>
    <div v-if="slice.elements && slice.elements.length > 0" class="flex justify-center">
      <div class="md:p-8 p-8 px-0 md:px-16 text-center flex flex-col items-center w-full">
        <div class="uppercase title text-4xl" v-html="fields.title.value" />
        <div class="grid relative p-6 lg:p-12 w-full" :class="colsClass">
          <template v-if="slice.elements[0].type === 'query'">
            <VoixSlice v-for="s in slice.elements[0].elements" :key="s.id" :slice="s" />
          </template>
          <slot v-else />
        </div>
      </div>
    </div>
    <div
      v-if="
        fields.pageSpecialSlice.value && fields.pageSpecialSlice.value !== 'SliceData'
      "
      class="flex justify-center"
    >
      <div class="md:p-8 p-8 px-0 md:px-16 text-center flex flex-col items-center w-full">
        <div class="uppercase title text-4xl" v-html="fields.title.value" />
        <div class="grid relative p-6 lg:p-12 w-full" :class="colsClass">
          <component
            :is="specialSlice"
            v-for="special in pageSpecials"
            :fields="special"
          />
        </div>
      </div>
    </div>
  </div>
</template>
