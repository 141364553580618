<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  mounted() {
    defineSlice({
      name: {
        label: 'Download Link',
        group: 'global',
        layouts: ['HyattZiva', 'AgentCashPlus', 'Playaresorts', 'PlayaCares'],
      },
      tags: ['Link'],
      fields: { button: { type: 'link', label: 'File URL', editorLabel: true, name: 'button' } },
      slots: [],
    })
  },
}
</script>

<template>
  <p class="my-2 mx-1">
    <a
      class="text-grey-darker"
      :href="fields.button.value.href"
      :target="fields.button.value.target"
    >
      <icons-icon-download
        class="mr-2 inline"
        :width="15"
        :height="15"
      />
      {{ fields.button.value.text }}
    </a>
  </p>
</template>
