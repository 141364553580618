<script setup lang="ts">
import type { VoixSelectFieldInterface } from '@logicbomb-media/voix/dist/runtime/types'

defineProps<{
  fields: {
    style: VoixSelectFieldInterface
  }
}>()

const { data: factSheetResort } = await useVoixAsyncData(
  '/api/devise/fact-sheets/all',
)

defineSlice({
  name: {
    label: 'Fact Sheets',
    group: 'global',
    layouts: ['Blocks', 'AgentCashPlus'],
  },
  tags: ['Fact Sheets'],
  fields: {
    style: {
      type: 'select',
      label: 'Display Style',
      options: {
        both: 'Show Both Sheet Types',
        corp_only: 'Show MICE Only',
      },
      default: 'both',
      name: 'style',
    },
  },
  slots: [],
})
</script>

<template>
  <div class="py-8 p-8 md:p-12container mx-auto text-center items-center flex flex-col relative z-10">
    <div v-for="(group, key) in factSheetResort.data" :key="key" class="w-full mb-24">
      <div class="flex justify-center">
        <h6 class="uppercase text-center pb-2 px-8 mb-8 font-bold tracking-widest border-b border-gray-400">
          {{ group.name }}
        </h6>
      </div>
      <div class="w-full flex flex-row flex-grow justify-start  flex-wrap items-start">
        <div v-for="(resort, key) in group.sheets" :key="key" class="w-full lg:w-1/3 px-8 mb-8">
          <NuxtImg :src="resort.resort_logo" class="max-h-[70px] mb-3 mx-auto h-full object-cover" />

          <div
            v-if="fields.style.value === 'both' && resort.sheets.length > 0"
            class="w-full mb-2 bg-gray-200 rounded-full text-xs text-left p-1 pl-3"
            style="font-size: 11px"
          >
            RESORT FACT SHEET
            <div
              v-for="(sheet, skey) in resort.sheets" :key="skey"
              class="inline-block bg-gray-500 rounded-full ml-1"
              style="padding: 1px 3px 0; "
            >
              <a
                class="text-abs-white underline text-xs m-0" style="font-size: 10px;line-height: 0;"
                :href="sheet.file"
              >{{ sheet.language }}</a>
            </div>
          </div>

          <div
            v-if="resort.corporate_sheets.length > 0"
            class="w-full mb-2 bg-gray-200 rounded-full text-xs text-left p-1 pl-3"
            style="font-size: 11px"
          >
            MICE FACT SHEET
            <div
              v-for="(sheet, skey) in resort.corporate_sheets"
              :key="skey"
              class="inline-block bg-gray-500 rounded-full ml-1"
              style="padding: 1px 3px 0; "
            >
              <a
                class="text-abs-white underline text-xs m-0" style="font-size: 10px;line-height: 0;"
                :href="sheet.file"
              >{{ sheet.language }}</a>
            </div>
          </div>

          <div
            v-if="resort.room_sheets.length > 0"
            class="w-full mb-2 bg-gray-200 rounded-full text-xs text-left p-1 pl-3"
            style="font-size: 11px"
          >
            ROOM FACT SHEET
            <div
              v-for="(sheet, skey) in resort.room_sheets"
              :key="skey"
              class="inline-block bg-gray-500 rounded-full ml-1"
              style="padding: 1px 3px 0; "
            >
              <a
                class="text-abs-white underline text-xs m-0" style="font-size: 10px;line-height: 0;"
                :href="sheet.file"
              >{{ sheet.language }}</a>
            </div>
          </div>
          <div
            v-if="fields.style.value === 'corp_only' && resort.description"
            class="pt-4 text-sm text-left fact-sheet-description"
            v-html="resort.description"
          />
        </div>
      </div>
    </div>
  </div>
</template>
