<script setup lang="ts">
import type { VoixMediaFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

defineProps<{
  fields: {
    image: VoixMediaFieldInterface
    title: VoixTextFieldInterface
    subtitle: VoixTextFieldInterface
    content: VoixWysiwygFieldInterface
    hideBookNow: VoixTextFieldInterface
    terms: VoixTextFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Full Width Special Offer', group: 'Sanctuary', layouts: ['Sanctuary2024'] },
  preview: 'SlicesSanctuaryFullWidthSpecialOffer.jpg',
  fields: {
    image: {
      label: 'Media',
      type: 'media',
      breakpoints: {
        default: { width: 800 },
        lg: { width: 1600 },
      },
      name: 'image',
    },
    title: { label: 'Title', type: 'text', name: 'title' },
    subtitle: { label: 'Subtitle', type: 'text', enabled: true, name: 'subtitle' },
    content: { label: 'Content', type: 'wysiwyg', enabled: true, name: 'content' },
    hideBookNow: { label: 'Extra Savings', type: 'checkbox', name: 'hideBookNow' },
    terms: { label: 'Terms and Conditions', type: 'wysiwyg', name: 'terms', enabled: true },
  },
  slots: [],
})

const terms = 'Offer valid for a discount off the standard rate for bookings made through June 30th, 2024, for stays starting July 1st, 2024, made through December 17th, 2024. Reservations are subject to availability and must be made in advance. A limited number of rooms are allocated to this offer. Offer not combinable with other consumer saving promotions. Not valid with groups, conventions, tour packages or special rate programs. Additional charges may apply for additional guests or room-type upgrades. Promotional blackout periods may apply due to seasonal periods or special events, and normal arrival/departure restrictions apply. Offer not valid in conjunction with previously booked or held stays and may not be combined with other offers. Offer may be changed or withdrawn at any time. Not responsible for errors or omissions. Other restrictions may apply. ©2024 Playa Management USA, LLC (operator of Sanctuary Cap Cana, a Luxury Collection Adult All–Inclusive Resort). Luxury Collection® trademark and related marks are trademarks of Marriott International and/or its affiliates. All rights reserved.'
</script>

<template>
  <div class="pb-20 text-sanctuary-primary">
    <div class="relative aspect-[2/1]">
      <VoixMedia
        v-if="fields.image.value"
        :field="fields.image"
        class="object-cover object-center h-full w-full"
      />
    </div>
    <div class="pt-12 max-w-xl mx-auto text-center">
      <div class="text-3xl font-serif">
        {{ fields.title.value }}
      </div>
      <div class="font-bold text-xs pt-2">
        {{ fields.subtitle.value }}
      </div>
      <VoixWysiwyg :field="fields.content" class="mt-8  leading-[2em]" />
      <BookNowButton class="mt-8 text-light bg-sanctuary-primary text-sanctuary-secondary px-6 py-4 text-xs uppercase">
        {{ $t('book-now') }}
      </BookNowButton>
      <Terms v-if="fields.terms.enabled" class="mt-4 text-xs font-light" :terms="fields.terms?.value ?? terms">
        {{ $t('terms-and-conditions') }}
      </Terms>
    </div>
  </div>
</template>
