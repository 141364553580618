<script setup lang="ts">
import type { VoixLinkFieldInterface, VoixMediaFieldInterface, VoixSelectFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

const props = defineProps<{
  fields: {
    cfId: VoixTextFieldInterface
    media: VoixMediaFieldInterface
    textOverlay: VoixTextFieldInterface
    link: VoixLinkFieldInterface
    title: VoixTextFieldInterface
    copy: VoixWysiwygFieldInterface
    cardPosition: VoixSelectFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Background Image with Card Overlay', group: 'Bonita', layouts: ['Bonita'] },
  tags: ['Media', 'Card'],
  description: 'A background image with a card on top that has a title, copy and link',
  preview: 'SlicesBonitaBackgroundImageWithCard.jpg',
  fields: {
    cfId: {
      type: 'text',
      label: 'CloudFlare Video ID',
      enabled: false,
    },
    media: {
      label: 'Media',
      type: 'media',
      breakpoints: {
        default: {
          width: 640,
          height: 640,
        },
        md: {
          width: 1024,
          height: 576,
        },
        lg: {
          width: 1280,
          height: 720,
        },
        xl: {
          width: 1600,
          height: 900,
        },
      },
    },
    cardPosition: {
      label: 'Card Position',
      type: 'select',
      options: {
        'top-left': 'Top Left',
        'top-right': 'Top Right',
        'bottom-left': 'Bottom Left',
        'bottom-right': 'Bottom Right',
      },
      default: 'top-left',
    },
    title: {
      label: 'Text Overlay',
      type: 'text',
    },
    copy: {
      label: 'Copy',
      type: 'wysiwyg',
    },
    link: {
      label: 'Link',
      type: 'link',
      enabled: true,
    },
  },
  templates: [{
    label: 'Main Example',
    fields: {
      media: {
        value: 'https://images.unsplash.com/photo-1448375010925-a75a42ceaffa?q=80&w=1705&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      cardPosition: { value: 'bottom-right' },
      title: { value: 'LOREM IPSUM DOLAR' },
      copy: { value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec purus feugiat, vestibulum ligula at, lacinia ipsum. Nulla nec purus feugiat, vestibulum ligula at, lacinia ipsum.' },
      link: { value: {
        url: '#',
        text: 'Read More',
      }, enabled: true },
    },
  }],
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)

const videoUrl = computed(() => {
  if (props.fields.cfId.enabled && props.fields.cfId.value) {
    return (
      `https://iframe.videodelivery.net/${
        props.fields.cfId.value
      }?muted=true&autoplay=true&controls=false`
    )
  }
  return ''
})
</script>

<template>
  <div class="relative aspect-[16/6]" :class="{ 'my-24': isVoixTemplatizer }">
    <VoixMedia v-if="videoUrl === '' && fields.cardPosition.value?.includes('bottom')" :field="fields.media" class="w-full h-full object-cover" />
    <div v-if="videoUrl !== '' && fields.cardPosition.value?.includes('bottom')" class="aspect-[16/6] overflow-hidden">
      <iframe
        title="Video"
        allow="autoplay"
        :src="videoUrl"
        class="-mt-[10%] w-[100vw] aspect-video object-cover"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        frameborder="0"
      />
    </div>

    <div class="lg:absolute lg:inset-0 max-w-[1200px] mx-auto w-full">
      <div
        class="lg:absolute lg:z-10 w-full"
        :class="{
          'lg:top-0 lg:left-0 lg:-translate-y-12': fields.cardPosition.value === 'top-left' || fields.cardPosition.value === 'top-right',
          'lg:bottom-0 lg:left-0 lg:translate-y-12': fields.cardPosition.value === 'bottom-left' || fields.cardPosition.value === 'bottom-right',
        }"
      >
        <div class="max-w-[1200px] mx-auto grid lg:grid-cols-12 lg:gap-16">
          <div
            class="col-span-5 bg-bonita-primary text-bonita-inverse" :class="{

              'col-start-9 ': fields.cardPosition.value === 'top-right' || fields.cardPosition.value === 'bottom-right',
            }"
          >
            <div class="p-8  max-w-2xl">
              <h2 class="text-3xl font-bonita-title">
                {{ fields.title.value }}
              </h2>
              <VoixWysiwyg :field="fields.copy" class="mt-4 font-light font-bonita-body" />
              <VoixLink :field="fields.link" class="block mt-4 text-xs uppercase tracking-widest underline underline-offset-4" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <VoixMedia v-if="videoUrl === '' && fields.cardPosition.value?.includes('top')" :field="fields.media" class="w-full h-full object-cover" />
    <div v-if="videoUrl !== '' && fields.cardPosition.value?.includes('top')" class="aspect-[16/6] overflow-hidden">
      <iframe
        title="Video"
        allow="autoplay"
        :src="videoUrl"
        class="-mt-[10%] w-[100vw] aspect-video object-cover"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        frameborder="0"
      />
    </div>
  </div>
</template>
