<script>
import anime from 'animejs/lib/anime.es.js'
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isVisible: false,
      filter: 'accommodations',
      iconWidth: 40,
      iconHeight: 50,
      color: 'currentcolor',
    }
  },
  computed: {
    showIcons() {
      return {
        bath: this.fields.bath.value,
        wifi: this.fields.wifi.value,
        roomService: this.fields.roomService.value,
        bed: this.fields.bed.value,
        bar: this.fields.bar.value,
        tv: this.fields.tv.value,
        magicBox: this.fields.magicBox.value,
        robe: this.fields.robe.value,
        mirror: this.fields.mirror.value,
        bathroom: this.fields.bathroom.checked,
        safe: this.fields.safe.value,
        patio: this.fields.patio.value,
        phone: this.fields.phone.value,
        coffee: this.fields.coffee.value,
        ac: this.fields.ac.value,
        hairdryer: this.fields.hairdryer.value,
        ironingBoard: this.fields.ironingBoard.value,
        alarm: this.fields.alarm.value,
        balcony: this.fields.balcony.value,
        radio: this.fields.radio.value,
        turndown: this.fields.turndown.value,
        bluetooth: this.fields.bluetooth.value,
        butler: this.fields.butler.value,
        liquor: this.fields.liquor.value,
        showers: this.fields.showers.value,
        slippers: this.fields.slippers.value,
        transfers: this.fields.transfers.value,
      }
    },
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage })
    },
    iconStyles() {
      return {
        marginLeft: '5px',
        marginRight: '5px',
        marginTop: '5px',
        marginBottom: '5px',
        textAlign: 'center',
        fontSize: '13px',
        width: `${this.iconWidth + 40}px`,
        height: `${this.iconHeight + 40}px`,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }
    },
    showButtons() {
      let buttonCount = 0
      if (this.fields.accommodationsTitle.value)
        buttonCount++

      if (this.fields.diningTitle.value)
        buttonCount++

      if (this.fields.ttdTitle.value)
        buttonCount++

      return buttonCount > 1
    },
  },

  mounted() {
    defineSlice({
      name: {
        label: 'Features',
        group: 'playa-collection',
        layouts: ['PlayaCollection', 'HiltonYucatan', 'Hilton'],
      },
      tags: ['Features'],
      fields: {
        accommodationsImage1: {
          type: 'media',
          label: 'Accommodations Image 1 (Bigger Background)',
          breakpoints: { default: { width: 690 }, lg: { width: 690, height: 460 } },
          name: 'accommodationsImage1',
        },
        accommodationsImage2: {
          type: 'media',
          label: 'Accommodations Image 2 (Landscape)',
          breakpoints: { lg: { width: 391, height: 290 } },
          name: 'accommodationsImage2',
        },
        accommodationsTitle: {
          type: 'text',
          label: 'Accommodations Title',
          editorLabel: true,
          name: 'accommodationsTitle',
        },
        accommodationsDescription: {
          type: 'wysiwyg',
          label: 'Accommodations Description',
          name: 'accommodationsDescription',
        },
        diningImage1: {
          type: 'media',
          label: 'Dining Image 1 (Bigger Background)',
          breakpoints: { lg: { width: 690, height: 460 } },
          name: 'diningImage1',
        },
        diningImage2: {
          type: 'media',
          label: 'Dining Image 2 (Landscape)',
          breakpoints: { lg: { width: 391, height: 290 } },
          name: 'diningImage2',
        },
        diningTitle: { type: 'text', label: 'Dining Title', name: 'diningTitle' },
        diningDescription: {
          type: 'wysiwyg',
          label: 'Dining Description',
          name: 'diningDescription',
        },
        ttdImage1: {
          type: 'media',
          label: 'TTD Image 1 (Bigger Background)',
          breakpoints: { lg: { width: 690, height: 460 } },
          name: 'ttdImage1',
        },
        ttdImage2: {
          type: 'media',
          label: 'TTD Image 2 (Landscape)',
          breakpoints: { lg: { width: 391, height: 290 } },
          name: 'ttdImage2',
        },
        ttdTitle: { type: 'text', label: 'TTD Title', name: 'ttdTitle' },
        ttdDescription: {
          type: 'wysiwyg',
          label: 'TTD Description',
          name: 'ttdDescription',
        },
        bath: { type: 'checkbox', label: 'Show Bath?', default: true, name: 'bath' },
        wifi: { type: 'checkbox', label: 'Show WiFi?', default: true, name: 'wifi' },
        roomService: {
          type: 'checkbox',
          label: 'Show Room Service?',
          default: true,
          name: 'roomService',
        },
        bed: { type: 'checkbox', label: 'Show Pillow?', default: true, name: 'bed' },
        bar: { type: 'checkbox', label: 'Show Bar?', default: true, name: 'bar' },
        tv: { type: 'checkbox', label: 'Show TV?', default: true, name: 'tv' },
        magicBox: {
          type: 'checkbox',
          label: 'Show Magic Box?',
          default: true,
          name: 'magicBox',
        },
        robe: { type: 'checkbox', label: 'Show Robe?', default: true, name: 'robe' },
        mirror: {
          type: 'checkbox',
          label: 'Show Mirror?',
          default: true,
          name: 'mirror',
        },
        bathroom: {
          type: 'checkbox',
          label: 'Show Bathroom?',
          default: true,
          name: 'bathroom',
        },
        safe: { type: 'checkbox', label: 'Show Safe?', default: true, name: 'safe' },
        patio: { type: 'checkbox', label: 'Show Patio?', default: true, name: 'patio' },
        phone: { type: 'checkbox', label: 'Show Phone?', default: true, name: 'phone' },
        coffee: {
          type: 'checkbox',
          label: 'Show Coffee?',
          default: true,
          name: 'coffee',
        },
        ac: { type: 'checkbox', label: 'Show AC?', default: true, name: 'ac' },
        hairdryer: {
          type: 'checkbox',
          label: 'Show Hair Dryer?',
          default: true,
          name: 'hairdryer',
        },
        ironingBoard: {
          type: 'checkbox',
          label: 'Show Ironing Board?',
          default: true,
          name: 'ironingBoard',
        },
        alarm: { type: 'checkbox', label: 'Show Alarm?', default: true, name: 'alarm' },
        balcony: {
          type: 'checkbox',
          label: 'Show Balcony?',
          default: true,
          name: 'balcony',
        },
        radio: { type: 'checkbox', label: 'Show Radio?', default: true, name: 'radio' },
        turndown: {
          type: 'checkbox',
          label: 'Show Turndown?',
          default: true,
          name: 'turndown',
        },
        bluetooth: {
          type: 'checkbox',
          label: 'Show Bluetooth?',
          default: true,
          name: 'bluetooth',
        },
        butler: {
          type: 'checkbox',
          label: 'Show Butler?',
          default: true,
          name: 'butler',
        },
        liquor: {
          type: 'checkbox',
          label: 'Show Beer and Wine?',
          default: true,
          name: 'liquor',
        },
        showers: {
          type: 'checkbox',
          label: 'Show Showers?',
          default: true,
          name: 'showers',
        },
        slippers: {
          type: 'checkbox',
          label: 'Show Slippers?',
          default: true,
          name: 'slippers',
        },
        transfers: {
          type: 'checkbox',
          label: 'Show Transfers?',
          default: true,
          name: 'transfers',
        },
      },
      slots: [],
    })
  },

  methods: {
    visibilityChanged(entry) {
      if (entry.intersectionRatio * 100 > 0) {
        anime({
          targets: ['.feature-dining-image1', '.feature-dining-image2'],
          opacity: 1,
          duration: 3000,
          easing: 'easeOutExpo',
          delay: anime.stagger(1000),
        })
      }
      else {
        anime({
          targets: ['.feature-dining-image1', '.feature-dining-image2'],
          opacity: 0,
          duration: 2000,
        })
      }
    },
  },
}
</script>

<template>
  <div
    v-voix-visibility="{ callback: visibilityChanged, threshhold: [0, 2.5] }"
    class="py-12"
  >
    <div v-if="showButtons" class="flex justify-center text-grey-dark">
      <button
        class="uppercase text-sm py-4 px-6 focus:outline-none"
        :class="{
          'bg-collection-teal text-white ': filter === 'accommodations',
          'border border-grey-lighter ': filter !== 'accommodations',
        }"
        @click="filter = 'accommodations'"
      >
        Accommodations
      </button>
      <button
        class="mx-6 uppercase text-sm py-4 px-6 focus:outline-none"
        :class="{
          'bg-collection-teal text-white ': filter === 'dining',
          'border border-grey-lighter ': filter !== 'dining',
        }"
        @click="filter = 'dining'"
      >
        Dining &amp; Drinks
      </button>
      <button
        class="uppercase text-sm py-4 px-6 focus:outline-none"
        :class="{
          'bg-collection-teal text-white ': filter === 'ttd',
          'border border-grey-lighter ': filter !== 'ttd',
        }"
        @click="filter = 'ttd'"
      >
        Things To Do
      </button>
    </div>
    <div class="w-full flex justify-center">
      <div v-if="filter" class="container flex flex-col md:flex-row py-12">
        <div class="relative p-4 md:p-0 w-full md:w-1/2" style="min-height: 400px">
          <div v-if="filter === 'accommodations'" key="accommodations">
            <div class="absolute top-0 left-0 feature-dining-image1">
              <VoixMedia
                v-if="fields.accommodationsImage1.value"
                :field="fields.accommodationsImage1"
                width="600"
                height="338"
                fit="cover"
              />
            </div>
            <div
              class="hidden md:block absolute -top-4 -left-4 -ml-16 mt-48 feature-dining-image2 shadow-2xl"
            >
              <VoixMedia
                v-if="fields.accommodationsImage2.value"
                :field="fields.accommodationsImage2"
                width="600"
                height="338"
                fit="cover"
              />
            </div>
          </div>
          <div v-if="filter === 'dining'" key="dining">
            <div class="absolute top-0 left-0">
              <VoixMedia v-if="fields.diningImage1.value" :field="fields.diningImage1" />
            </div>
            <div class="absolute top-0 left-0 -ml-8 mt-18">
              <VoixMedia v-if="fields.diningImage2.value" :field="fields.diningImage2" />
            </div>
          </div>
          <div v-if="filter === 'ttd'" key="ttd">
            <div class="absolute top-0 left-0">
              <VoixMedia v-if="fields.ttdImage1.value" :field="fields.ttdImage1" />
            </div>
            <div class="absolute top-0 left-0 -ml-8 mt-18">
              <VoixMedia v-if="fields.ttdImage2.value" :field="fields.ttdImage2" />
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/2 p-4 md:p-12">
          <transition mode="out-in" tag="div" name="fade">
            <div v-show="filter === 'accommodations'" key="accommodations-text">
              <h3
                v-if="fields.accommodationsTitle.value"
                class="font-sans-lato font-bold text-3xl mb-4 title"
              >
                {{ fields.accommodationsTitle.value }}
              </h3>
              <div class="font-light" v-html="fields.accommodationsDescription.value" />
              <div class="grid grid-cols-4 md:grid-cols-5">
                <div v-if="showIcons.bath" :style="iconStyles">
                  <div>
                    <IconsBath
                      :color="color"
                      :w="iconWidth * 1.1"
                      :h="iconHeight * 1.1"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('double-jetted-tub')" />
                </div>
                <div v-if="showIcons.wifi" :style="iconStyles">
                  <div>
                    <IconsWifi
                      :color="color"
                      :w="iconWidth * 0.8"
                      :h="iconHeight * 0.8"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('wifi')" />
                </div>
                <div v-if="showIcons.roomService" :style="iconStyles">
                  <div>
                    <IconsTwentyFourHourRoomService
                      :color="color"
                      :w="iconWidth * 0.8"
                      :h="iconHeight * 0.8"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('room-service')" />
                </div>
                <div v-if="showIcons.bed" :style="iconStyles">
                  <div>
                    <IconsBed :color="color" :w="iconWidth * 0.9" :h="iconHeight * 0.9" />
                  </div>
                  <div class="mt-1" v-html="$t('pillow-menu')" />
                </div>
                <div v-if="showIcons.bar" :style="iconStyles">
                  <div>
                    <IconsBar :color="color" :w="iconWidth * 0.8" :h="iconHeight * 0.9" />
                  </div>
                  <div class="mt-1" v-html="$t('mini-bar')" />
                </div>
                <div v-if="showIcons.tv" :style="iconStyles">
                  <div>
                    <IconsTv :color="color" :w="iconWidth * 0.8" :h="iconHeight * 0.8" />
                  </div>
                  <div class="mt-1" v-html="$t('flat-screen-tv')" />
                </div>
                <div v-if="showIcons.magicBox" :style="iconStyles">
                  <div>
                    <IconsMagicBox
                      :color="color"
                      :w="iconWidth * 0.8"
                      :h="iconHeight * 0.8"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('magic-box')" />
                </div>
                <div v-if="showIcons.robe" :style="iconStyles">
                  <div>
                    <IconsRobe
                      :color="color"
                      :w="iconWidth * 0.8"
                      :h="iconHeight * 0.8"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('robes')" />
                </div>
                <div v-if="showIcons.mirror" :style="iconStyles">
                  <div>
                    <IconsMirror
                      :color="color"
                      :w="iconWidth * 0.8"
                      :h="iconHeight * 0.8"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('mirror')" />
                </div>
                <div v-if="showIcons.bathroom" :style="iconStyles">
                  <div>
                    <IconsBathroom
                      :color="color"
                      :w="iconWidth * 0.9"
                      :h="iconHeight * 0.9"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('bathroom-amenaties')" />
                </div>
                <div v-if="showIcons.safe" :style="iconStyles">
                  <div>
                    <IconsSave
                      :color="color"
                      :w="iconWidth * 0.8"
                      :h="iconHeight * 0.8"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('in-room-safe')" />
                </div>

                <div v-if="showIcons.patio" :style="iconStyles">
                  <div>
                    <IconsPatio
                      :color="color"
                      :w="iconWidth * 0.8"
                      :h="iconHeight * 0.8"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('furnished-patio')" />
                </div>
                <div v-if="showIcons.phone" :style="iconStyles">
                  <div>
                    <IconsPhoneAmenity
                      :color="color"
                      :w="iconWidth * 0.8"
                      :h="iconHeight * 0.8"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('telephone')" />
                </div>
                <div v-if="showIcons.coffee" :style="iconStyles">
                  <div>
                    <IconsCoffee
                      :color="color"
                      :w="iconWidth * 0.8"
                      :h="iconHeight * 0.8"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('coffee-maker')" />
                </div>
                <div v-if="showIcons.ac" :style="iconStyles">
                  <div>
                    <IconsAC :color="color" :w="iconWidth * 1" :h="iconHeight * 1" />
                  </div>
                  <div class="mt-1" v-html="$t('air-conditioning')" />
                </div>
                <div v-if="showIcons.hairdryer" :style="iconStyles">
                  <div>
                    <IconsHairdryer
                      :color="color"
                      :w="iconWidth * 0.8"
                      :h="iconHeight * 0.8"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('hairdryer')" />
                </div>
                <div v-if="showIcons.ironingBoard" :style="iconStyles">
                  <div>
                    <IconsIroningBoard
                      :color="color"
                      :w="iconWidth * 0.8"
                      :h="iconHeight * 0.8"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('iron-and-ironing-board')" />
                </div>
                <div v-if="showIcons.alarm" :style="iconStyles">
                  <div>
                    <IconsAlarm
                      :color="color"
                      :w="iconWidth * 0.9"
                      :h="iconHeight * 0.9"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('alarm-clock')" />
                </div>
                <div v-if="showIcons.balcony" :style="iconStyles">
                  <div>
                    <IconsBalcony
                      :color="color"
                      :w="iconWidth * 0.9"
                      :h="iconHeight * 0.9"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('private-balcony')" />
                </div>
                <div v-if="showIcons.radio" :style="iconStyles">
                  <div>
                    <IconsRadio
                      :color="color"
                      :w="iconWidth * 0.8"
                      :h="iconHeight * 0.8"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('radio')" />
                </div>
                <div v-if="showIcons.turndown" :style="iconStyles">
                  <div>
                    <IconsTurndown
                      :color="color"
                      :w="iconWidth * 0.8"
                      :h="iconHeight * 0.8"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('turndown-service')" />
                </div>
                <div v-if="showIcons.bluetooth" :style="iconStyles">
                  <div>
                    <IconsBluetoothSpeaker
                      :color="color"
                      :w="iconWidth * 0.8"
                      :h="iconHeight * 0.8"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('bluetooth-speaker')" />
                </div>
                <div v-if="showIcons.butler" :style="iconStyles">
                  <div>
                    <IconsButler
                      :color="color"
                      :w="iconWidth * 0.8"
                      :h="iconHeight * 0.8"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('butler-service')" />
                </div>
                <div v-if="showIcons.liquor" :style="iconStyles">
                  <div>
                    <IconsLiquor
                      :color="color"
                      :w="iconWidth * 0.8"
                      :h="iconHeight * 0.8"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('beer-wine')" />
                </div>
                <div v-if="showIcons.showers" :style="iconStyles">
                  <div>
                    <IconsShowers
                      :color="color"
                      :w="iconWidth * 0.8"
                      :h="iconHeight * 0.8"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('rainfall-showers')" />
                </div>
                <div v-if="showIcons.slippers" :style="iconStyles">
                  <div>
                    <IconsSlippers
                      :color="color"
                      :w="iconWidth * 0.8"
                      :h="iconHeight * 0.8"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('robes-slippers')" />
                </div>
                <div v-if="showIcons.transfers" :style="iconStyles">
                  <div>
                    <IconsTransfers
                      :color="color"
                      :w="iconWidth * 0.8"
                      :h="iconHeight * 0.8"
                    />
                  </div>
                  <div class="mt-1" v-html="$t('transfers')" />
                </div>
              </div>
            </div>
          </transition>
          <transition mode="out-in" tag="div" name="fade">
            <div v-show="filter === 'dining'" key="dining-text">
              <h3
                v-if="fields.diningTitle.value"
                class="font-sans-lato font-bold text-3xl mb-4"
              >
                {{ fields.diningTitle.value }}
              </h3>
              <div v-html="fields.diningDescription.value" />
            </div>
          </transition>
          <transition mode="out-in" tag="div" name="fade">
            <div v-show="filter === 'ttd'" key="tdd-text">
              <h3
                v-if="fields.ttdTitle.value"
                class="font-sans-lato font-bold text-3xl mb-4"
              >
                {{ fields.ttdTitle.value }}
              </h3>
              <div v-html="fields.ttdDescription.value" />
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
