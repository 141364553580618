<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      description: 'Weddings Contact Details',
      preview: 'SlicesWeddingsContactDetails.jpg',
      fields: {
        title: {
          type: 'wysiwyg',
          label: 'Title',
          editorLabel: true,
          default: 'Plan Your Wedding',
        },
        phone: {
          type: 'wysiwyg',
          label: 'Phone',
          default:
            '<p style="text-align:center;"><strong>Phone</strong><br>USA/CN:<br>(800) 935-2403</p>',
        },
        email: {
          type: 'wysiwyg',
          label: 'Email',
          default:
            '<p style="text-align:center;"><strong>Email Us</strong><br>weddings@playaresorts.com</p>',
        },
        international: {
          type: 'wysiwyg',
          label: 'International',
          default:
            '<p style="text-align:center;"><strong>International</strong><br>+ 52 (998) 848-7017</p>',
        },
      },
      name: {
        label: 'Weddings Contact Details',
        group: 'Weddings',
        layouts: ['*'],
      },
      tags: ['Contact'],
      templates: [],
    })
  },
}
</script>

<template>
  <div class="flex flex-col justify-center py-8 md:p-8 px-0 md:px-12">
    <div
      class="text-center font-light mb-1 text-5xl md:text-4xl"
      v-html="fields.title?.value"
    />
    <div
      class="py-8 md:py-12 px-24 mx-auto container text-center flex flex-col md:flex-row justify-between"
    >
      <div class="px-8 text-center md:text-left leading-loose">
        <div class="m-auto w-12 h-12">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="m-auto feather-phone"
          >
            <path
              d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
            />
          </svg>
        </div>
        <div class="wysiwyg text-center" v-html="fields.phone?.value" />
      </div>
      <div class="px-8 text-center md:text-left leading-loose">
        <div class="m-auto w-12 h-12">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="m-auto feather-mail"
          >
            <path
              d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
            />
            <polyline points="22,6 12,13 2,6" />
          </svg>
        </div>
        <div class="wysiwyg text-center" v-html="fields.email?.value" />
      </div>
      <div class="px-8 text-center md:text-left leading-loose">
        <div class="m-auto w-12 h-12">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="m-auto feather-globe"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="2" y1="12" x2="22" y2="12" />
            <path
              d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
            />
          </svg>
        </div>
        <div class="wysiwyg text-center" v-html="fields.international?.value" />
      </div>
    </div>
  </div>
</template>
