<script setup lang="ts">
import type { VoixLinkFieldInterface, VoixMediaFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

import Container from '@/components/blocks/Container.vue'

const props = defineProps<{
  fields: {
    image: VoixMediaFieldInterface
    subtitle: VoixTextFieldInterface
    title: VoixTextFieldInterface
    description: VoixWysiwygFieldInterface
    logo: VoixMediaFieldInterface
    link: VoixLinkFieldInterface
  }
}>()
defineSlice({
  name: {
    label: 'Featured Place Block',
    group: 'blocks',
  },
  preview: 'SlicesBlocksFeaturedPlaceSection.jpg',
  fields: {
    image: {
      type: 'media',
      label: 'Image',
      breakpoints: { default: { width: 700, height: 570 } },
      name: 'image',
    },
    subtitle: { type: 'text', label: 'subtitle', name: 'subtitle' },
    title: { type: 'text', label: 'Title', name: 'title' },
    logo: {
      type: 'media',
      label: 'Logo',
      breakpoints: { default: { width: 500, height: 225 } },
      name: 'logo',
    },
    description: { type: 'wysiwyg', label: 'Description', name: 'description' },
    link: { type: 'link', label: 'Link', name: 'link' },
  },
  slots: [],
})

const isVisible = ref(false)
function onVisibilityChange(entry: { intersectionRatio: number }) {
  if (entry.intersectionRatio * 10 > 1)
    isVisible.value = true
  else
    isVisible.value = false
}
</script>

<template>
  <section
    id="featured-place"
    v-voix-visibility="{ callback: onVisibilityChange, threshold: [0, 0.25] }"
    class="bg-ecru text-white"
  >
    <Container class="flex justify-end relative" align-right>
      <div class="img-caption">
        <div
          class="flex justify-end transition-all duration-500" :class="{
            'opacity-0': !isVisible,
            'opacity-100': isVisible,
          }"
        >
          <div class="top-text">
            <h2>NEW</h2>
            <p>TO THE PLAYA FAMILY</p>
          </div>
        </div>

        <VoixMedia
          :field="props.fields.image" class="w-full md:hidden "
        >
          <div class="mt-6 sm:mt-10 lg:mt-0 justify-self-end text-right">
            <p class="tracking-[0.2rem] text-xs font-light md:text-lg">
              {{ props.fields.subtitle.value }}
            </p>
            <h3 class="text-2xl sm:text-4xl xl:text-4xl font-medium tracking-widest">
              {{ props.fields.title.value }}
            </h3>
          </div>
        </VoixMedia>
      </div>

      <div
        class="hidden md:block w-[60%] xl:w-[70%] aspect-video transform duration-1000 ease-out"
        :class="{
          'translate-x-full opacity-0': !isVisible,
          'translate-x-0 opacity-100': isVisible,
        }"
      >
        <VoixMedia
          :field="props.fields.image"
          class="object-cover  w-full h-full"
        />
      </div>
    </Container>

    <Container align-right>
      <div
        class="flex flex-col transition-all duration-500 delay-500" :class="{
          'opacity-0': !isVisible,
          'opacity-100': isVisible,
        }"
      >
        <div class="flex flex-col md:flex-row items-center">
          <div class="max-w-[80%] xl:max-w-[30%] pr-12">
            <VoixMedia v-if="fields.logo" :field="fields.logo" />
          </div>
          <div v-if="props.fields.description.value" class="description md:min-w-[60%] xl:min-w-[70%] md:pr-[10%] lg:pr-[10%]">
            <VoixWysiwyg :field="props.fields.description" class="text-white md:border-l-4 border-white pl-10 font-light" />
          </div>
        </div>
        <div class="flex justify-end pt-4 lg:pl-20">
          <div class="w-[60%] xl:w-[70%] md:pr-[10%] lg:pr-[10%]">
            <a v-if="props.fields.link.value" :href="props.fields.link.value.href" class="uppercase bg-azul px-6 py-3">
              {{ props.fields.link.value.text || 'Explore Resort' }}
            </a>
          </div>
        </div>
      </div>
    </Container>
  </section>
</template>

<style scoped>
section {
  @apply py-8 md:py-16 overflow-x-hidden;

  img {
    max-width: unset;
  }

  .img-caption {
    @apply flex flex-col justify-between grow self-stretch;

    @screen md {
      @apply pr-10;
    }

    .top-text {
      @apply mb-6 sm:mb-10 lg:mb-0 md:pt-20 lg:pt-[30%] text-center;

      h2 {
        @apply text-6xl lg:text-8xl font-medium tracking-widest;
      }

      p {
        @apply tracking-widest font-thin md:text-lg lg:text-xl;
      }
    }

  }
}
</style>
