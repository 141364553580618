<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  mounted() {
    defineSlice({
      name: {
        label: 'Left Right Block Suite',
        group: 'playaresorts',
        layouts: ['Blocks', 'PlayaCares'],
      },
      tags: ['Content'],
      fields: {
        tagline: { type: 'text', label: 'Tagline', enabled: false, name: 'tagline' },
        title: { type: 'text', label: 'Title', editorLabel: true, name: 'title' },
        copy: { type: 'wysiwyg', label: 'Copy', enabled: false, name: 'copy' },
        left: { type: 'checkbox', label: 'Text on left', default: true, name: 'left' },
        image: {
          type: 'media',
          label: 'Image 1',
          breakpoints: {
            md: { width: 434, height: 300 },
            lg: { width: 651, height: 450 },
            xl: { width: 940, height: 650 },
          },
          name: 'image',
        },
        image2: {
          type: 'media',
          label: 'Image 2',
          breakpoints: {
            md: { width: 434, height: 300 },
            lg: { width: 651, height: 450 },
            xl: { width: 940, height: 650 },
          },
          enabled: false,
          name: 'image2',
        },
        image3: {
          type: 'media',
          label: 'Image 3',
          breakpoints: {
            md: { width: 434, height: 300 },
            lg: { width: 651, height: 450 },
            xl: { width: 940, height: 650 },
          },
          enabled: false,
          name: 'image3',
        },
        image4: {
          type: 'media',
          label: 'Image 4',
          breakpoints: {
            md: { width: 434, height: 300 },
            lg: { width: 651, height: 450 },
            xl: { width: 940, height: 650 },
          },
          enabled: false,
          name: 'image4',
        },
        image5: {
          type: 'media',
          label: 'Image 5',
          breakpoints: {
            md: { width: 434, height: 300 },
            lg: { width: 651, height: 450 },
            xl: { width: 940, height: 650 },
          },
          enabled: false,
          name: 'image5',
        },
        image6: {
          type: 'media',
          label: 'Image 6',
          breakpoints: {
            md: { width: 434, height: 300 },
            lg: { width: 651, height: 450 },
            xl: { width: 940, height: 650 },
          },
          enabled: false,
          name: 'image6',
        },
        image7: {
          type: 'media',
          label: 'Image 7',
          breakpoints: {
            md: { width: 434, height: 300 },
            lg: { width: 651, height: 450 },
            xl: { width: 940, height: 650 },
          },
          enabled: false,
          name: 'image7',
        },
        image8: {
          type: 'media',
          label: 'Image 8',
          breakpoints: {
            md: { width: 434, height: 300 },
            lg: { width: 651, height: 450 },
            xl: { width: 940, height: 650 },
          },
          enabled: false,
          name: 'image8',
        },
      },
      slots: [{ name: 'default', allowedElements: [] }],
    })
  },
}
</script>

<template>
  <div class="relative block">
    <div class="playa-left-right-block-suite">
      <div class="flex justify-center bg-cover bg-center w-full">
        <div class="p-2 py-10 container">
          <div
            class="left-right-block-suite flex flex-col-reverse md:flex-row items-stretch md:mx-8 bg-abs-white shadow"
            :class="{ 'md:flex-row-reverse': fields.left.value === true }"
          >
            <div class="md:w-1/2 p-8 text-center md:text-left">
              <p v-if="fields.tagline.enabled" class="text-lg pb-1 wysiwyg">
                {{ fields.tagline.value }}
              </p>
              <h4>
                {{ fields.title.value }}
              </h4>
              <div
                v-if="fields.copy.enabled"
                class="my-4 prose"
                v-html="fields.copy.value"
              />

              <div class="mt-8">
                <slot />
              </div>
            </div>
            <div class="md:w-1/2 text-center overflow-hidden">
              <general-swiper :pagination="false">
                <VoixMedia class="w-full bg-cover aspect-[16/11]" :field="fields.image" />
                <VoixMedia
                  v-if="fields.image2.enabled"
                  class="w-full h-full bg-cover"
                  :field="fields.image2"
                />
                <VoixMedia
                  v-if="fields.image3.enabled"
                  class="w-full h-full bg-cover"
                  :field="fields.image3"
                />
                <VoixMedia
                  v-if="fields.image4.enabled"
                  class="w-full h-full bg-cover"
                  :field="fields.image4"
                />
                <VoixMedia
                  v-if="fields.image5.enabled"
                  class="w-full h-full bg-cover"
                  :field="fields.image5"
                />
                <VoixMedia
                  v-if="fields.image6.enabled"
                  class="w-full h-full bg-cover"
                  :field="fields.image6"
                />
                <VoixMedia
                  v-if="fields.image7.enabled"
                  class="w-full h-full bg-cover"
                  :field="fields.image7"
                />
                <VoixMedia
                  v-if="fields.image8.enabled"
                  class="w-full h-full bg-cover"
                  :field="fields.image8"
                />
              </general-swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
