<script setup lang="ts">
import { ref } from 'vue'

const { $playa } = useNuxtApp()
const filter = ref('all')

const brands = await $fetch(`${$playa.rest_api_base_url}/brands/by-age-restrictions`)

defineSlice({
  name: {
    label: 'Rows With Filters',
    group: 'playaresorts',
    layouts: ['AgentCashPlus'],
  },
  tags: ['Rows', 'Filters'],
  slots: [],
})
</script>

<template>
  <div>
    <div class="flex justify-center">
      <div class="p-12 container flex flex-col md:flex-row justify-center text-center">
        <a
          href="#"
          class="btn px-12 m-4"
          :class="[filter === 'adults' ? '' : 'btn-ghost']"
          @click.prevent="filter = 'adults'"
        >ADULTS</a>
        <a
          href="#"
          class="btn px-12 m-4"
          :class="[filter === 'all_ages' ? '' : 'btn-ghost']"
          @click.prevent="filter = 'all_ages'"
        >ALL AGES</a>
        <a
          href="#"
          class="btn px-12 m-4"
          :class="[filter === 'all' ? '' : 'btn-ghost']"
          @click.prevent="filter = 'all'"
        >VIEW ALL</a>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <div v-show="filter === 'all' || filter === 'adults'">
        <div class="flex justify-center">
          <div class="container flex flex-col justify-center text-center">
            <h2 class="mb-8 run-through uppercase">
              Adults Only
            </h2>
          </div>
        </div>

        <div v-for="brand in brands?.adults" :key="brand.id" class="flex justify-center">
          <div class="mb-8 bg-abs-white container text-center flex flex-col md:flex-row">
            <div class="md:w-2/5 text-center">
              <VoixMedia
                :field="brand.featured_image"
                class="relative bottom left right sp0"
                width="100%"
              />
            </div>
            <div class="md:w-3/5 pt-6 pb-8 px-8 text-center md:text-left">
              <VoixMedia
                style="max-height: 100px; max-width: 300px"
                :field="brand.logo"
                class="mb-6"
              />

              <div class="pb-6">
                <p>{{ brand.short_description }}</p>
              </div>

              <p class="pt-6">
                <a
                  v-for="resort in brand.resorts"
                  :key="resort.id"
                  :href="brand.url + resort.url_partial"
                  class="btn btn-ghost mb-4 mr-4 inline-block"
                >{{ resort.link_text }}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade" mode="out-in">
      <div v-show="filter === 'all' || filter === 'all_ages'">
        <div class="flex justify-center">
          <div class="mt-8 container flex flex-col justify-center text-center">
            <h2 class="mt-8 mb-8 run-through uppercase">
              All Ages
            </h2>
          </div>
        </div>

        <div v-for="brand in brands?.all" :key="brand.id" class="flex justify-center">
          <div class="mb-8 bg-abs-white container text-center flex flex-col md:flex-row">
            <div class="md:w-2/5 text-center">
              <VoixMedia
                :field="brand.featured_image"
                class="relative bottom left right sp0"
                width="100%"
                alt="Brand Featured Image"
              />
            </div>
            <div class="md:w-3/5 pt-6 pb-8 px-8 text-center md:text-left">
              <VoixMedia
                style="max-height: 100px; max-width: 300px"
                :field="brand.logo"
                class="mb-6"
              />

              <div class="pb-6">
                <p>{{ brand.short_description }}</p>
              </div>

              <p class="pt-6">
                <a
                  v-for="resort in brand.resorts"
                  :key="resort.id"
                  :href="brand.url + resort.url_partial"
                  class="btn btn-ghost mb-4 mr-4 inline-block"
                >{{ resort.link_text }}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
