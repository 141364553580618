<script>
export default {
  inject: ['loadZoom'],
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {}
  },
  computed: {
    widthClass() {
      if (
        this.$voix.breakpoint.value === 'md'
        || this.$voix.breakpoint.value === 'lg'
        || this.$voix.breakpoint.value === 'xl'
        || this.$voix.breakpoint.value === '2xl'
      ) {
        return this.fields.width.value
      }
      else {
        return 'w-full'
      }
    },
    dataGroup() {
      return `["${this.fields.category.value}"]`
    },
  },
  mounted() {
    defineSlice({
      preview: 'SlicesGlobalGalleriesGridGalleryElementsGridImage.jpg',
      description:
        'Grid gallery images can take up 1\/4, 1\/2, 3\/4, or the full width of the image gallery.',
      fields: {
        image: {
          type: 'media',
          label: 'Image',
          editorLabel: true,
          breakpoints: {
            lg: { width: 500, height: 500 },
            xl: { width: 900, height: 900 },
            md: { width: 800, height: 800 },
          },
        },
        width: {
          type: 'select',
          label: 'Width',
          options: {
            'w-1\/4': 'Small (1\/4)',
            'w-1\/2': 'Medium (1\/2)',
            'w-3\/4': 'Large (3\/4)',
            'w-full': 'Full (1\/1)',
          },
        },
        category: {
          label: 'Category',
          type: 'select',
          options: {
            'category-resort': 'Resort',
            'category-activities': 'Activities',
            'category-pools': 'Pools',
            'category-rooms': 'Rooms',
            'category-dining': 'Dining',
            'category-celebrations': 'Celebrations',
            'category-meetings': 'Meetings',
          },
        },
      },
      name: { label: 'Grid Gallery Image', group: 'Global', layouts: ['*'] },
      tags: ['Gallery'],
      templates: [
        {
          label: 'Image',
          fields: {
            image: {
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/styled\/sanctuarycapcana.com\/Sanctuary\/Sanctuary_GeneralResort\/Sanctuary-Cap-Cana-Infinity-Pool-Yoga2-d26176095f38dc8cefd18232bc73a8ee.jpg',
            },
            width: { value: 'w-1\/2' },
          },
        },
      ],
    })
  },

  methods: {
    requestLoadZoom() {
      // Rebuild the image this.fields.image.value array to be an object keyed off the breakpoint property of that array entry
      const imageValues = this.fields.image.value.reduce((acc, cur) => {
        acc[cur.breakpoint] = cur
        return acc
      }, {})

      //  set image to either xl, md, or default in that order
      const image = imageValues.xl || imageValues.md || imageValues.default

      if (image)
        this.loadZoom(image)
    },
  },
}
</script>

<template>
  <div
    :class="[widthClass]"
    :data-groups="dataGroup"
    class="bg-cover cursor-pointer aspect-square"
    @click="requestLoadZoom"
  >
    <VoixMedia v-slot="slotProps" :field="fields.image" background>
      <div
        class="w-full h-full bg-cover bg-center"
        :style="{ backgroundImage: `url(${slotProps.image})` }"
      >
        <div
          v-if="$voix.breakpoint === 'default'"
          class="p-4 text-abs-white"
          style="text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5)"
        >
          {{ slotProps?.description }}
        </div>
      </div>
    </VoixMedia>
  </div>
</template>
