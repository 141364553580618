<script lang="ts" setup>
import { usePageStore } from '@voix/store/pageStore'
import { useSiteUtils } from '@voix/composables/useSiteUtils'
import { onMounted } from 'vue'

defineProps<{
  fields: object
}>()

const pageStore = usePageStore()
const currentPage = pageStore.currentPage

defineSlice({
  name: { label: 'Id Me', group: 'Global', layouts: ['*'] },
  tags: ['Form'],
  preview: 'SlicesGlobalFormsIdMe.jpg',
  description: 'Displays the id.me button and once logged in a user will get the promo in the booking widget on page.',
  fields: {
    title: { type: 'text', label: 'Success Title', default: '"Congratulations, You have been verified!"' },
    subtitle: { type: 'text', label: 'Success Description', default: '"You now have access to your special discounted rates."' },
    promo: { type: 'text', label: 'Promo Code', editorLabel: true },
    coupon: { type: 'text', label: 'Coupon Code' },
    rate: { type: 'text', label: 'Rate Code' },
    group: { type: 'text', label: 'Group Code' },
  },
  templates: [{ label: 'Id Me', fields: { rate: { value: 'WEBRESPONDER' } } }],
})
const route = useRoute()
const showBooking = useCookie('idme-sucess')
const { getCurrentDomain } = useSiteUtils()
const domain = getCurrentDomain()
const redirectUrl = `https://${domain}/idme/callback?from=${route.path}`

onMounted(async () => {
  // add script to body
  const style = document.createElement('link')
  style.href = 'https://s3.amazonaws.com/idme/developer/idme-buttons/assets/css/unified/button.css'
  style.rel = 'stylesheet'
  style.text = 'text/css'
  document.head.appendChild(style)

  const script = document.createElement('script')
  script.src = 'https://s3.amazonaws.com/idme/developer/idme-buttons/assets/js/unified/export.js'
  script.onload = function () {
    const button = document.getElementById('idme-wallet-button')
    if (button) {
      const scope = button.getAttribute('data-scope')
      const client = button.getAttribute('data-client-id')
      const redirect = button.getAttribute('data-redirect')
      const response = button.getAttribute('data-response')
      const state = button.getAttribute('data-state')
      const display = button.getAttribute('data-display')
      const logo = button.getAttribute('data-logo')
      const hero = button.getAttribute('data-hero')
      const text = button.getAttribute('data-text')
      const verify = button.getAttribute('data-show-verify')
      const hlp = button.getAttribute('data-hlp')
      const type = button.getAttribute('data-type')
      const country = button.getAttribute('data-country')
      const language = button.getAttribute('data-language')
      const appId = button.getAttribute('data-app-id')
      const multiple = scope.split(',').length > 1

      // build widget parameters
      let params = ''

      if (scope && multiple)
        params += `&scopes=${scope}`
      else
        params += `&scope=${scope}`

      if (hlp)
        params += `&hlp=${hlp}`
      if (hero)
        params += `&hero_url=${hero}`
      if (logo)
        params += `&logo_url=${logo}`
      if (state)
        params += `&state=${state}`
      if (client)
        params += `&client_id=${client}`
      if (redirect)
        params += `&redirect_uri=${redirect}`
      if (response)
        params += `&response_type=${response}`
      if (country)
        params += `&country=${country}`
      if (language)
        params += `&language=${language}`
      if (appId)
        params += `&appid=${appId}`

      if (type)
        params += `&type=${type}`
      else
        params += '&type=button'

      params += '&source=' + 'idme_widget_old'
      params += `&current_url=${window.location.origin}${window.location.pathname}`

      let buttonLink = null

      if (hlp)
        buttonLink = hlp
      else if (multiple)
        buttonLink = `https://groups.id.me/?${params}`
      else
        buttonLink = `https://api.id.me/oauth/authorize?${params}`

      const container = document.createElement('span')
      const trigger = document.createElement('div')
      const buttonElem = document.createElement('a')
      // var description = document.createElement("span");

      container.id = 'idme-verification'
      trigger.className = 'idme-trigger'
      buttonElem.className = 'idme-trigger-link idme-unify-button'
      // description.className = "idme-description"

      // description.innerHTML = 'Verification by ID.me • <a href="https://www.id.me/about" target="_new">What is ID.me?</a>'

      // display offer text if present
      if (text) {
        const offer = document.createElement('span')

        offer.className = 'idme-text'
        offer.innerHTML = text

        trigger.appendChild(offer)
      }

      // toggle popup user experience
      if (display === 'popup') {
        const clickHandler = function () {
          new IDme.Util.Popup(buttonLink, 775, 850, window.innerWidth, window.innerHeight)
        }

        buttonElem.href = 'javascript:void(0);'
        buttonElem.onclick = clickHandler
      }
      else {
        buttonElem.href = buttonLink
        buttonElem.target = '_parent'
      }

      buttonElem.innerHTML = `<img src="https://s3.amazonaws.com/idme/developer/idme-buttons/assets/img/verify.svg" alt="ID.me Logo"/>`

      trigger.appendChild(buttonElem)

      // display what is ID.me show verify true
      if (verify) {
        const description = document.createElement('span')

        description.className = 'idme-description'
        description.innerHTML = 'Verification by ID.me • <a href="https://www.id.me/about" target="_new">What is ID.me?</a>'

        trigger.appendChild(description)
      }

      container.appendChild(trigger)

      // insert after widget
      button.parentNode.insertBefore(container, button.nextSibling)

      // remove widget
      button.remove()
    }
  }
  document.body.appendChild(script)
})

// Hilton
let clientId = 'e78b0d79bf69ee4d4c454bd8214fef28'

// Jewel Grande
if (currentPage.site_id === 6)
  clientId = '0bdb470c8a1737c63c2e45e38f401aa3'

// Jewel Resorts
if (currentPage.site_id === 4)
  clientId = 'e2ea11c588f8bae06354d1972e97cf41'

// Resorts By Hyatt
if (currentPage.site_id === 8)
  clientId = '57bbcebfdb70890d113f77cf954238f6'

// Sanctuary Cap Cana
if (currentPage.site_id === 2)
  clientId = 'ff531e0bf396a38b1c0c9f37658e0282'

// Wyndham Alltra
if (currentPage.site_id === 14)
  clientId = '484cf92dabda5a4590e6d7cef7532273'
</script>

<template>
  <div v-if="showBooking">
    <div class="relative px-8 z-50 flex justify-center inline-flight-booking pt-20">
      <FlightBookingForm :discounts="{ rate: fields.rate.value }" />
    </div>
  </div>
  <div v-else class="relative pt-10 md:pt-10 container text-center flex flex-col items-center z-100 idme-wrap">
    <span
      id="idme-wallet-button"
      class="relative"
      data-scope="military,government,nurse,responder,teacher,student,medical,doctor_canada,hospital_employee,military_canada,responder_canada,student_canada,teacher_canada,government_canada,nurse_canada"
      :data-client-id="clientId"
      :data-redirect="redirectUrl"
      data-response="code"
      data-show-verify="true"
      data-type="popup"
    />
  </div>
</template>
