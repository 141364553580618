<script setup lang="ts">
import type { VoixLinkFieldInterface } from '@voix/types'

defineProps<{
  fields: {
    link: VoixLinkFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Sanctuary Contact', group: 'Sanctuary', layouts: ['Sanctuary2024'] },
  preview: 'SlicesSanctuaryContact.jpg',
  slots: [{ name: 'default', allowedElements: [] }],
  description: 'Contact information for the Sanctuary Hotel',
  fields: {
    link: {
      type: 'link',
      label: 'Link',
      default: {
        text: 'All Contact Information',
        href: '#',
      },
    },
  },
  templates: [{
    label: 'Slider Container',
    fields: {

    },
  }],
})

const showContactInformation = ref(false)
</script>

<template>
  <div class="container mx-auto grid grid-cols-1 lg:grid-cols-12 gap-12 pt-20">
    <div class="lg:col-span-6 lg:col-start-2">
      <div class="grid grid-cols-4 gap-4 lg:gap-16">
        <div><img src="https://playa-cms-assets.s3.amazonaws.com/media/awards/sanctuary/tripadvisor115x100.png"></div>
        <div><img src="https://playa-cms-assets.s3.amazonaws.com/media/awards/sanctuary/golfawards115x100.png"></div>
        <div><img src="https://playa-cms-assets.s3.amazonaws.com/media/awards/sanctuary/4diamond115x100.png"></div>
        <div><img src="https://playa-cms-assets.s3.amazonaws.com/media/awards/greenglobe115x100.jpg"></div>
      </div>

      <div class="mt-8 text-xs text-sanctuary-primary">
        <div>Resort Contact</div>
        <div>BOULEVARD ZONA HOTELERA, PUNTA CANA 23302, DOMINICAN REPUBLIC</div>
        <VoixLink :field="fields.link" class="mt-2 inline-block text-sm border border-sanctuary-tertiary text-sanctuary-primary px-4 py-3">
          All Contact Information
        </VoixLink>
      </div>

      <div v-if="showContactInformation" />
    </div>
    <div class="lg:col-span-3 lg:col-start-9 lg:row-start-1 space-y-4 ">
      <div class="flex justify-center">
        <LogosSanctuary mode="brandmark" class="text-sanctuary-primary h-32" :fill="false" />
      </div>
      <div class="flex justify-center text-2xs uppercase font-medium text-sanctuary-primary">
        {{ $t('connect-with-us') }}
      </div>
      <div class="grid grid-cols-5 gap-4">
        <div class="flex justify-center">
          <a href="https://www.facebook.com/SanctuaryResortCapCana" class="p-1 border-2 border-black rounded-full">
            <IconsFacebook class="w-6 h-6" />
          </a>
        </div>
        <div class="flex justify-center">
          <a href="https://www.youtube.com/channel/UCcpHFzfEtwb1tFiik7UbKtg" class="p-1 border-2 border-black rounded-full">
            <IconsYouTube class="w-6 h-6" />
          </a>
        </div>
        <div class="flex justify-center">
          <a href="https://twitter.com/SanctuaryCap" class="p-1 border-2 border-black rounded-full">
            <IconsTwitter class="w-6 h-6" />
          </a>
        </div>
        <div class="flex justify-center">
          <a href="https://www.instagram.com/sanctuarycapcana/" class="p-1 border-2 border-black rounded-full">
            <IconsInstagram class="w-6 h-6" />
          </a>
        </div>
        <div class="flex justify-center">
          <a href="https://www.instagram.com/sanctuarycapcana/" class="p-1 border-2 border-black rounded-full">
            <Icon name="mdi:pinterest" class="w-6 h-6" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
