<script setup lang="ts">
import Hilton from '@playa/components/logos/Hilton.vue'
import HyattZilara from '@playa/components/logos/HyattZilara.vue'
import HyattZiva from '@playa/components/logos/HyattZiva.vue'
import HyattZivaZilara from '@playa/components/logos/HyattZivaZilara.vue'
import Jewel from '@playa/components/logos/Jewel.vue'
import JewelGrande from '@playa/components/logos/JewelGrande.vue'
import MarriotBonvoy from '@playa/components/logos/MarriotBonvoy.vue'
import Sanctuary from '@playa/components/logos/Sanctuary.vue'
import TapestryCollection from '@playa/components/logos/TapestryCollection.vue'
import Turquoize from '@playa/components/logos/Turquoize.vue'
import Wyndham from '@playa/components/logos/Wyndham.vue'

const props = defineProps({
  logo: {
    type: String,
    required: true,
  },
  w: {
    type: [Number, String],
    default: 18,
  },
  h: {
    type: [Number, String],
    default: 18,
  },
  fill: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
  },
})

defineOptions({
  inheritAttrs: false,
})

const Logo = computed(() => {
  switch (props.logo) {
    case 'Hilton':
      return Hilton
    case 'HyattZilara':
      return HyattZilara
    case 'HyattZiva':
      return HyattZiva
    case 'HyattZivaZilara':
      return HyattZivaZilara
    case 'Jewel':
      return Jewel
    case 'JewelGrande':
      return JewelGrande
    case 'MarriotBonvoy':
      return MarriotBonvoy
    case 'Sanctuary':
      return Sanctuary
    case 'TapestryCollection':
      return TapestryCollection
    case 'Turquoize':
      return Turquoize
    case 'Wyndham':
      return Wyndham

    default:
      return null
  }
})
</script>

<template>
  <Component v-bind="$attrs" :is="Logo" :w="w" :h="h" :fill="fill" :label="label" />
</template>
