<script setup lang="ts">
import type { SliceInterface, VoixColorFieldInterface, VoixLinkFieldInterface, VoixMediaFieldInterface, VoixSelectFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

const props = defineProps<{
  slice: SliceInterface
  fields: {
    media: VoixMediaFieldInterface
    imageAboveCopy: VoixMediaFieldInterface
    imageAboveCopyBlendingMode: VoixSelectFieldInterface
    copyPosition: VoixSelectFieldInterface
    title: VoixTextFieldInterface
    copy: VoixWysiwygFieldInterface
    bookingButton: VoixTextFieldInterface
    termsButton: VoixTextFieldInterface
    termsCopy: VoixWysiwygFieldInterface
    backgroundPosition: VoixSelectFieldInterface
    background: VoixColorFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Image Flanking Title Copy', group: 'Bonita', layouts: ['Bonita'] },
  tags: ['Media'],
  description: 'Image on the left or right opposing a title, copy and link with an optional background block of color.',
  preview: 'SlicesBonitaImageFlankingTitleCopy.jpg',
  fields: {
    media: {
      label: 'Primary Image',
      type: 'media',
      breakpoints: {
        default: {
          width: 600,
        },
      },
    },
    imageAboveCopy: {
      label: 'Secondary Image',
      type: 'media',
      breakpoints: {
        default: {
          width: 600,
        },
      },
      enabled: false,
    },
    imageAboveCopyBlendingMode: {
      label: 'Secondary Image Blending Mode',
      type: 'select',
      options: {
        'mix-blend-normal': 'None',
        'mix-blend-multiply': 'Multiply',
        'mix-blend-screen': 'Screen',
        'mix-blend-overlay': 'Overlay',
        'mix-blend-darken': 'Darken',
        'mix-blend-lighten': 'Lighten',
        'mix-blend-color-dodge': 'Color Dodge',
        'mix-blend-color-burn': 'Color Burn',
        'mix-blend-hard-light': 'Hard Light',
        'mix-blend-soft-light': 'Soft Light',
        'mix-blend-difference': 'Difference',
        'mix-blend-exclusion': 'Exclusion',
        'mix-blend-hue': 'Hue',
        'mix-blend-saturation': 'Saturation',
        'mix-blend-color': 'Color',
        'mix-blend-luminosity': 'Luminosity',
        'mix-blend-plus-darker': 'Plus Darker',
        'mix-blend-plus-lighter': 'Plus Lighter',
      },
      default: 'normal',
    },
    copyPosition: {
      label: 'Copy Position',
      type: 'select',
      options: { left: 'Left', right: 'Right' },
      default: 'left',
    },
    title: { label: 'Title', type: 'text' },
    copy: { label: 'Copy', type: 'wysiwyg' },
    bookingButton: { label: 'Booking Button', type: 'text', default: 'Book Now', enabled: false },
    termsButton: { label: 'Terms Button', type: 'text', default: 'Terms & Conditions', group: 'Terms', enabled: false },
    termsCopy: { label: 'Terms Copy', type: 'wysiwyg', group: 'Terms' },
    background: { label: 'Background', type: 'color' },
    backgroundPosition: {
      label: 'Background Position',
      type: 'select',
      options: {
        top: 'Top',
        full: 'Full',
        bottom: 'Bottom',
      },
    },
  },
  templates: [
    {
      label: 'Example with Logo',
      fields: {
        media: {
          value: 'https://images.unsplash.com/photo-1719952167449-33940005b007?q=80&w=600&h=700&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        },
        imageAboveCopy: {
          value: '/imgs/bonita/logo-placeholder.png',
          enabled: true,
        },
        imageAboveCopyBlendingMode: { value: 'mix-blend-darken' },
        copyPosition: { value: 'left' },
        title: { value: 'Escape From Reality' },
        copy: { value: '<p>Lorem ipsum dolor sit amet consectetur. Cras lacus eget ut integer viverra magna feugiat consequat. Tellus in tempor vitae tortor turpis et mauris in vulputate. Auctor cursus at pulvinar pellentesque at lobortis posuere purus. Sed tellus aliquam semper ut risus feugiat id. Sed donec non enim feugiat lacus blandit a ipsum integer. Commodo ut elit mauris vel condimentum. Non eu massa lorem ac lacus tincidunt.</p>' },
        background: { value: '#FA9CA2' },
        backgroundPosition: { value: 'full' },
      },
    },
    {
      label: 'Main Example',
      fields: {
        media: {
          value: 'https://images.unsplash.com/photo-1719952167449-33940005b007?q=80&w=600&h=700&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        },
        copyPosition: { value: 'right' },
        title: { value: 'Escape From Reality' },
        copy: { value: '<p>Lorem ipsum dolor sit amet consectetur. Cras lacus eget ut integer viverra magna feugiat consequat. Tellus in tempor vitae tortor turpis et mauris in vulputate. Auctor cursus at pulvinar pellentesque at lobortis posuere purus. Sed tellus aliquam semper ut risus feugiat id. Sed donec non enim feugiat lacus blandit a ipsum integer. Commodo ut elit mauris vel condimentum. Non eu massa lorem ac lacus tincidunt.</p>' },
        background: { value: '#DF6548' },
        backgroundPosition: { value: 'top' },
      },
    },
  ],
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const colorOverride: ComputedRef<null | string> = computed(() => {
  if (props.slice.properties?.settings && props.slice.properties.settings[0]?.color) {
    return props.slice.properties.settings[0]?.color
  }
  return null
})
const terms = ref({
  display: false,
  fade_in: false,
})
function toggleTerms() {
  terms.value.display = !terms.value.display
  setTimeout(() => terms.value.fade_in = !terms.value.fade_in)
}
</script>

<template>
  <div
    class="relative text-white"
    :class="{
      'pt-[300px]': isVoixTemplatizer && fields.imageAboveCopy.enabled,
      'pt-6': fields.backgroundPosition.value === 'top',
      '-mb-6': fields.backgroundPosition.value === 'bottom',
    }"
  >
    <div
      class="relative py-24 px-8"
    >
      <div
        class="relative z-10"
      >
        <div class="max-w-[1200px] mx-auto grid lg:grid-cols-12 lg:gap-16">
          <div
            class="relative lg:col-span-6 row-start-1 flex"
            :class="{
              ' items-center': fields.backgroundPosition.value === 'full',
              ' items-start': fields.backgroundPosition.value === 'top',
              ' items-end': fields.backgroundPosition.value === 'bottom',
              'lg:col-start-7': fields.copyPosition.value === 'left',
            }"
          >
            <div class="relative">
              <div
                class="absolute z-0 top-0 -translate-y-24 -translate-x-1/3" :class="[
                  fields.imageAboveCopyBlendingMode.value,
                  {
                    'right-0': fields.copyPosition.value === 'left',
                    'left-0': fields.copyPosition.value === 'right',
                  },
                ]"
              >
                <VoixMedia :field="fields.imageAboveCopy" class=" w-[600px]" />
              </div>
              <VoixMedia class="relative z-10" :field="fields.media" />
            </div>
          </div>
          <div
            class="lg:col-span-6 flex mt-12 lg:mt-0 items-center" :class="{
              'lg:col-start-1': fields.copyPosition.value === 'left',
            }"
          >
            <div
              class="relative prose mb-3" :style="{ color: colorOverride || '' }"
              :class="{
                'pt-12': fields.backgroundPosition.value === 'top',
                'pb-12': fields.backgroundPosition.value === 'bottom',
              }"
            >
              <div class="text-3xl font-bonita-title font-bold mb-6">
                {{ fields.title.value }}
              </div>

              <div v-html="fields.copy.value" />

              <!-- CTA -->
              <div class="flex flex-col relative">
                <div class="flex flex-col sm:flex-row justify-between items-center space-y-8 sm:space-y-0 my-4">
                  <!-- Book Now -->
                  <PlayaBooking v-if="fields.bookingButton.enabled">
                    <button
                      class="btn text-sm bg-bonita-primary text-bonita-inverse uppercase min-w-60"
                    >
                      {{ fields.bookingButton.value }}
                    </button>
                  </PlayaBooking>

                  <!-- Terms & Conditions -->
                  <div
                    v-if="fields.termsButton.enabled"
                    class="text-xs uppercase tracking-widest underline underline-offset-4 cursor-pointer"
                    @click="toggleTerms"
                  >
                    {{ fields.termsButton.value }}
                  </div>
                </div>

                <!-- Terms Copy -->
                <VoixWysiwyg
                  v-show="terms.display"
                  :field="fields.termsCopy"
                  class="text-sm tracking-widest transition-all duration-500 opacity-0"
                  :class="{
                    'opacity-100': terms.fade_in,
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="absolute inset-0 z-0"
        :class="{
          'flex items-end': fields.backgroundPosition.value === 'bottom',
        }"
      >
        <div
          class="w-full h-full  "
          :class="{
            'lg:-translate-y-8': fields.backgroundPosition.value === 'top',
            'lg:-translate-y-6': fields.backgroundPosition.value === 'bottom',
            'lg:h-24': fields.backgroundPosition.value === 'top' || fields.backgroundPosition.value === 'bottom',
          }"
          :style="{ backgroundColor: fields.background.value ? fields.background.value : 'transparent' }"
        />
      </div>
    </div>
  </div>
</template>
