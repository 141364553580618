<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  mounted() {
    defineSlice({
      name: {
        label: 'Full Width Countries',
        group: 'playa-cares',
        layouts: ['PlayaCares'],
      },
      tags: ['Links'],
      fields: {
        drLink: {
          type: 'link',
          label: 'DR Link',
          enabled: false,
          default: { mode: 'url', text: 'see all programs' },
          name: 'drLink',
        },
        jamaicaLink: {
          type: 'link',
          label: 'Jamaica Link',
          enabled: false,
          default: { mode: 'url', text: 'see all programs' },
          name: 'jamaicaLink',
        },
        mexicoLink: {
          type: 'link',
          label: 'Mexico Link',
          enabled: false,
          default: { mode: 'url', text: 'see all programs' },
          name: 'mexicoLink',
        },
        usaLink: {
          type: 'link',
          label: 'USA Link',
          enabled: false,
          default: { mode: 'url', text: 'see all programs' },
          name: 'usaLink',
        },
      },
      slots: [],
    })
  },
  methods: {
    goto(url) {
      window.location = url
    },
  },
}
</script>

<template>
  <div class="flex justify-center">
    <div class="w-full text-center">
      <div class="flex justify-center items-center flex-col md:flex-row md:items-stretch">
        <div class="flex justify-stretch items-stretch md:mb-0 md:w-1/4">
          <div
            class="relative w-full cursor-pointer bg-playa-action overflow-hidden"
            style="min-height: 280px"
            @click="goto(fields.drLink.value.href)"
          >
            <div
              class="absolute z-10 inset-0-x top-0 pt-4 text-2xl uppercase title text mb-4 text-white"
            >
              DOMINICAN REPUBLIC
            </div>
            <img
              class="absolute z-0 right-0 bottom-0"
              src="https://playaresorts-app.nyc3.digitaloceanspaces.com/media/playacares/destinations/dr-icon-button.png"
            >
            <a
              v-if="fields.drLink.enabled"
              class="absolute left-0 bottom-0 btn btn-sm btn-inverse rounded-full mx-8 my-4"
              href="javascript:void(0)"
            >{{ fields.drLink.value.text }}</a>
          </div>
        </div>
        <div class="flex justify-stretch items-stretch md:mb-0 md:w-1/4">
          <div
            class="relative w-full cursor-pointer overflow-hidden"
            style="background-color: #71b7db; min-height: 280px"
            @click="goto(fields.jamaicaLink.value.href)"
          >
            <div
              class="absolute z-10 inset-0-x top-0 pt-4 text-2xl uppercase title text mb-4 text-white"
            >
              Jamaica
            </div>
            <img
              class="absolute z-0 right-0 bottom-0"
              src="https://playaresorts-app.nyc3.digitaloceanspaces.com/media/playacares/destinations/jamaica-icon-button.png"
            >
            <a
              v-if="fields.drLink.enabled"
              class="absolute left-0 bottom-0 btn btn-sm rounded-full mx-8 my-4"
              href="javascript:void(0)"
            >{{ fields.jamaicaLink.value.text }}</a>
          </div>
        </div>
        <div class="flex justify-stretch items-stretch md:mb-0 md:w-1/4">
          <div
            class="relative w-full cursor-pointer bg-playa-action overflow-hidden"
            style="min-height: 280px"
            @click="goto(fields.mexicoLink.value.href)"
          >
            <div
              class="absolute z-10 inset-0-x top-0 pt-4 text-2xl uppercase title text mb-4 text-white"
            >
              Mexico
            </div>
            <img
              class="absolute z-0 right-0 bottom-0"
              src="https://playaresorts-app.nyc3.digitaloceanspaces.com/media/playacares/destinations/mexico-icon-button.png"
            >
            <a
              v-if="fields.drLink.enabled"
              class="absolute left-0 bottom-0 btn btn-sm btn-inverse rounded-full mx-8 my-4"
              href="javascript:void(0)"
            >{{ fields.mexicoLink.value.text }}</a>
          </div>
        </div>
        <div class="flex justify-stretch items-stretch md:mb-0 md:w-1/4">
          <div
            class="relative w-full cursor-pointer overflow-hidden"
            style="background-color: #71b7db; min-height: 280px"
            @click="goto(fields.usaLink.value.href)"
          >
            <div
              class="absolute z-10 inset-0-x top-0 pt-4 text-2xl uppercase title text mb-4 text-white"
            >
              USA
            </div>
            <img
              class="absolute z-0 right-0 bottom-0"
              src="https://playaresorts-app.nyc3.digitaloceanspaces.com/media/playacares/destinations/usa-icon-button.png"
            >
            <a
              v-if="fields.drLink.enabled"
              class="absolute left-0 bottom-0 btn btn-sm rounded-full mx-8 my-4"
              href="javascript:void(0)"
            >{{ fields.usaLink.value.text }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
