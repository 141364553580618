<script setup lang="ts">
import type { PropType } from 'vue'
import type { SliceInterface, VoixCheckboxFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, EffectFade } from 'swiper/modules'
import Container from '@/components/blocks/Container.vue'

import 'swiper/css'
import 'swiper/css/effect-fade'

const props = defineProps({
  slice: {
    type: Object as PropType<SliceInterface>,
    required: true,
  },
  fields: {
    type: Object as PropType<{
      title: VoixTextFieldInterface
      copy: VoixWysiwygFieldInterface
      description: VoixWysiwygFieldInterface
      showCheckBox: VoixCheckboxFieldInterface
    }>,
    required: true,
  },
})
defineSlice({
  name: { label: 'Features Section', group: 'blocks', layouts: ['Blocks'] },
  tags: ['Features'],
  preview: 'SlicesBlocksFeaturesSection.jpg',
  fields: {
    title: { type: 'text', label: 'Title', name: 'title' },
    description: { type: 'wysiwyg', label: 'Description', name: 'description' },
    showCheckBox: { type: 'checkbox', label: 'Show Checkbox', default: false, name: 'showCheckBox' },
  },
  slots: [{
    name: 'default',
    allowedElements: ['SlicesBlocksSoloMedia'],
  }],
})

const isVisible = ref(false)
function onVisibilityChange(entry: { intersectionRatio: number }) {
  if (entry.intersectionRatio * 10 > 1)
    isVisible.value = true
  else
    isVisible.value = false
}
</script>

<template>
  <section id="features" v-voix-visibility="{ callback: onVisibilityChange, threshold: [0, 0.25] }">
    <Container class="max-w-[1600px]">
      <div class="grid grid-cols-1 lg:grid-cols-2 overflow-hidden">
        <div
          class="flex flex-col justify-center px-6 lg:px-0 duration-1000 ease-out delay-500" :class="{
            'opacity-0': !isVisible,
            'opacity-100': isVisible,
          }"
        >
          <div class="flex space-x-2 items-center ">
            <div class="border-2 border-indigoDye w-8">
              <svg
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6 translate-x-3 -translate-y-2"
              >
                <path
                  stroke-width="10" stroke="white" stroke-linecap="round" stroke-linejoin="round"
                  d="M4.5 12.75l6 6 9-13.5"
                />
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
            </div>
            <h2 class="text-indigoDye text-3xl">
              {{ props.fields.title.value }}
            </h2>
          </div>
          <VoixWysiwyg
            :field=" fields.description" class="prose-p:mb-3 font-light"
          />
        </div>
        <div
          class="duration-1000 ease-out" :class="{
            'translate-x-full opacity-0': !isVisible,
            'translate-x-0 opacity-100': isVisible,
          }"
        >
          <ClientOnly>
            <Swiper id="swiper" :modules="[Autoplay, EffectFade]" effect="fade" :autoplay="{ delay: 4000, disableOnInteraction: false }" :slides-per-view="1" :space-between="0" class="border-4 border-white">
              <SwiperSlide v-for="slice in props.slice.elements" :key="slice.id">
                <VoixSlice :slice="slice as SliceInterface" class="aspect-video  w-full h-full object-contain" />
              </SwiperSlide>
            </Swiper>
          </ClientOnly>
        </div>
      </div>
    </Container>
  </section>
</template>

<style lang="scss">
#features-list {

  ul {
    @apply list-disc pl-16;
  }
}
</style>
