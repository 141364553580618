<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len no-mixed-spaces-and-tabs -->
  <svg
    :width="w"
    :height="h"
    role="presentation"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 52 44"
    style="enable-background:new 0 0 52 44;"
    xml:space="preserve"
  >
    <path
      :fill="color"
      d="M48,0H4C1.8,0,0,1.8,0,4v36c0,2.2,1.8,4,4,4h44c2.2,0,4-1.8,4-4V4C52,1.8,50.2,0,48,0z M50,40c0,1.1-0.9,2-2,2
H4c-1.1,0-2-0.9-2-2V4c0-1.1,0.9-2,2-2h44c1.1,0,2,0.9,2,2V40z M46,10.2V7c0-0.6-0.4-1-1-1H7C6.4,6,6,6.4,6,7v30c0,0.6,0.4,1,1,1h38
c0.6,0,1-0.4,1-1v-3.2c1.2-0.4,2-1.5,2-2.8v-2c0-1.3-0.8-2.4-2-2.8v-8.4c1.2-0.4,2-1.5,2-2.8v-2C48,11.7,47.2,10.6,46,10.2z M44,36
H8V8h36v2.2c-1.2,0.4-2,1.5-2,2.8v2c0,1.3,0.8,2.4,2,2.8v8.4c-1.2,0.4-2,1.5-2,2.8v2c0,1.3,0.8,2.4,2,2.8V36z M46,31
c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1v-2c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1V31z M46,15c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1v-2
c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1V15z M22,12c-5.5,0-10,4.5-10,10c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10C32,16.5,27.5,12,22,12z
 M22,30c-4.4,0-8-3.6-8-8c0-4.4,3.6-8,8-8c4.4,0,8,3.6,8,8C30,26.4,26.4,30,22,30z M22,16c-3.3,0-6,2.7-6,6c0,3.3,2.7,6,6,6
c3.3,0,6-2.7,6-6C28,18.7,25.3,16,22,16z M22,26c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4C26,24.2,24.2,26,22,26z"
    />
  </svg>
</template>
