<script>
export default {
  name: 'WyndhamIcon',

  props: {
    icon: {
      type: String,
      default: 'drink',
    },
  },
}
</script>

<template>
  <svg v-if="icon === 'drink'" viewBox="0 0 140 140">
    <g fill="currentcolor">
      <path
        d="M67.67,48.06a3.31,3.31,0,0,0-3.46-3.41h-16a3.4,3.4,0,0,0-3.29,4.27L56,91.29a3.47,3.47,0,0,0,4.15,2.43,3.39,3.39,0,0,0,2.44-4.16l-10-38.09H64.29A3.29,3.29,0,0,0,67.67,48.06Z"
      />
      <path
        d="M109.11,48.76A28.51,28.51,0,0,0,117,28.82,28.2,28.2,0,0,0,102.59,4a28.48,28.48,0,0,0-29-.15A3.39,3.39,0,0,0,72.07,6a3.33,3.33,0,0,0,.37,2.58,3.39,3.39,0,0,0,4.66,1.2A21.59,21.59,0,0,1,87.93,6.82,22.51,22.51,0,0,1,99.27,10a21.52,21.52,0,0,1,10.9,18.89,21.73,21.73,0,0,1-6,15.2,3.35,3.35,0,0,0-1,2.42,3.41,3.41,0,0,0,1,2.39A3.49,3.49,0,0,0,109.11,48.76Z"
      />
      <path
        d="M92.42,38.83A12.87,12.87,0,0,0,80,22.51H45.09L42.6,13A17.25,17.25,0,0,0,25.91.08H3.38A3.28,3.28,0,0,0,0,3.48,3.31,3.31,0,0,0,3.46,6.89H25.91A10.45,10.45,0,0,1,36,14.69l2,7.82H23.63A12.86,12.86,0,0,0,11.25,38.83c.27,1,.63,2.18,1.05,3.57,4.35,13.87,5.24,18.89,3.27,32.93-.92,7.11-1.59,12.27,3,22.57,4.56,11,17.32,16.92,28,17.93l1.93.18v14.76H25a3.28,3.28,0,0,0-3.38,3.4,3.32,3.32,0,0,0,3.47,3.41H78.69a3.29,3.29,0,0,0,3.38-3.41,3.32,3.32,0,0,0-3.47-3.41H55.24V116l1.93-.18c10.64-1,23.4-6.93,27.94-17.89,4.57-10.34,3.9-15.5,3-22.63-2-14-1.08-19,3.24-32.81C91.76,41.08,92.13,39.85,92.42,38.83ZM85.85,37l-.14.47c-5.06,16.58-6.77,22.17-4.38,38.68.87,6.75,1.35,10.44-2.44,19-6,13.52-23.59,14.2-27.06,14.2s-21.09-.68-27.06-14.2c-3.79-8.55-3.31-12.25-2.44-19,2.39-16.53.56-22.55-4.36-38.63L17.81,37a6,6,0,0,1,5.82-7.68H80A6,6,0,0,1,85.85,37Z"
      />
    </g>
  </svg>
  <svg v-else-if="icon === 'fire'" viewBox="0 0 140 140">
    <g fill="currentcolor">
      <path
        d="M12.74,3.5V9a3.41,3.41,0,0,0,6.82,0V3.42A3.28,3.28,0,0,0,16.15,0,3.3,3.3,0,0,0,12.74,3.5Z"
      />
      <path
        d="M4.8,40.06a3.41,3.41,0,0,0,3.4-3.41V31.11a3.27,3.27,0,0,0-3.4-3.37A3.3,3.3,0,0,0,1.38,31.2v5.45A3.41,3.41,0,0,0,4.8,40.06Z"
      />
      <path
        d="M117.75,132.53a3.39,3.39,0,0,0-2-1.65l-110-33a3.25,3.25,0,0,0-1-.14,3.41,3.41,0,0,0-1,6.67l110,33a3.46,3.46,0,0,0,4.25-2.28A3.37,3.37,0,0,0,117.75,132.53Z"
      />
      <path
        d="M48.13,38.64a3.52,3.52,0,0,0-1-.13A3.41,3.41,0,0,0,43.9,41C42.47,45.82,41,51.55,41,57.1A16.79,16.79,0,0,0,45.8,68.94a16.43,16.43,0,0,0,11.79,4.91,18,18,0,0,0,17.26-13A3.41,3.41,0,1,0,68.29,59a11,11,0,0,1-11.93,7.92c-5-.61-8.35-4.41-8.55-9.68,0-4.8,1.34-10,2.63-14.36A3.42,3.42,0,0,0,48.13,38.64Z"
      />
      <path
        d="M4.39,137.41l20.49-6.14A3.42,3.42,0,0,0,27.17,127a3.41,3.41,0,0,0-3.26-2.44,3.28,3.28,0,0,0-1,.15l-20.5,6.14a3.41,3.41,0,0,0-2.28,4.25A3.44,3.44,0,0,0,4.39,137.41Z"
      />
      <path
        d="M27.6,25.45C22,36.59,18.83,47.87,18.83,57.2A38.24,38.24,0,0,0,30.24,84.76,37.36,37.36,0,0,0,57.27,96c17.5-.54,31.83-9.71,38.34-24.52,6.68-15.21,3.74-32.88-7.67-46.1a10.1,10.1,0,0,0-16.2,2l-1.92,3.72-1.88-3.74c-2.64-5.27-5.86-12.7-6.24-18.19A9.75,9.75,0,0,0,56.15,1a9.86,9.86,0,0,0-10,.78C39.75,6.21,33,14.84,27.6,25.45ZM54.9,9.66c.8,11.68,10.88,28.08,12,29.91a3.38,3.38,0,0,0,2.94,1.6,3.44,3.44,0,0,0,2.89-1.69c4.29-7.35,4.61-7.92,4.74-8.29A3.23,3.23,0,0,1,83,30c4,4.39,9.56,15.28,9.56,24.35A35,35,0,0,1,57.33,89.19c-17.76,0-31.68-14-31.68-32C25.06,42.09,38.06,15.5,50.06,7.38A3.11,3.11,0,0,1,54.9,9.66Z"
      />
      <path
        d="M95.37,12.36A3.41,3.41,0,0,0,98.77,9V3.42A3.28,3.28,0,0,0,95.37,0,3.31,3.31,0,0,0,92,3.5V9A3.41,3.41,0,0,0,95.37,12.36Z"
      />
      <path
        d="M92.1,107.56a3.44,3.44,0,0,0,4.25,2.29l18-5.4a3.37,3.37,0,0,0,2-1.64A3.41,3.41,0,0,0,115,98.19a3.27,3.27,0,0,0-1.61-.41,3.63,3.63,0,0,0-1,.14l-18,5.4A3.41,3.41,0,0,0,92.1,107.56Z"
      />
      <path
        d="M113.37,40.06a3.41,3.41,0,0,0,3.41-3.41V31.11a3.41,3.41,0,1,0-6.82.09v5.45A3.41,3.41,0,0,0,113.37,40.06Z"
      />
    </g>
  </svg>
  <svg v-else-if="icon === 'food'" viewBox="0 0 140 140">
    <g fill="currentcolor">
      <path
        d="M61.31,107.72a3.4,3.4,0,0,0,2.35,1c.69,0,17.38.17,33.72-5.75a3.23,3.23,0,0,0,.72-.36l1.51-1,32,34.8a3.36,3.36,0,0,0,2.36,1.09,3.18,3.18,0,0,0,2.45-.9,3.37,3.37,0,0,0,1.1-2.36,3.41,3.41,0,0,0-.9-2.45L18.43,3.39a10.5,10.5,0,0,0-14-1.2,10.78,10.78,0,0,0-4,6,10.46,10.46,0,0,0,1.23,7.88l5.71,9.76C25.66,57.25,46.46,92.87,61.31,107.72ZM8.45,7.65a3.59,3.59,0,0,1,5.09.49L95,96.65l-3.22,1a102.47,102.47,0,0,1-25.71,4.21l-1,0-.66-.72C50.29,85.91,29.71,50.68,13.17,22.37L7.46,12.6A3.59,3.59,0,0,1,8.45,7.65Z"
      />
      <path
        d="M75.41,38.58a23.12,23.12,0,0,0,6.8,16.71l0,0A23.5,23.5,0,0,0,99,62.2h0a24,24,0,0,0,17.13-7.08c8-8,20.5-22.48,20.62-22.63a3.4,3.4,0,0,0-.35-4.8,3.42,3.42,0,0,0-4.81.35c-1.33,1.55-12.91,14.9-20.27,22.26a17.26,17.26,0,0,1-12.15,5.08,16.74,16.74,0,0,1-17-17,17.26,17.26,0,0,1,5.08-12.11C94.62,18.9,108,7.32,109.47,6a3.41,3.41,0,0,0-1.92-6h-.26a3.36,3.36,0,0,0-2.22.83C102.54,3,89.85,14,82.44,21.44A23.76,23.76,0,0,0,75.41,38.58Z"
      />
      <path
        d="M98,35.07a3.41,3.41,0,1,0,4.85,4.79l20.49-20.77a3.41,3.41,0,0,0,0-4.81,3.78,3.78,0,0,0-2.41-1,3.39,3.39,0,0,0-2.4,1Z"
      />
      <path
        d="M3.39,137.56h0a3.36,3.36,0,0,0,2.41-1L36.6,105.78a3.29,3.29,0,0,0,0-4.8,3.41,3.41,0,0,0-2.39-1A3.6,3.6,0,0,0,31.72,101L1,131.75a3.31,3.31,0,0,0-.71,3.7A3.32,3.32,0,0,0,3.39,137.56Z"
      />
    </g>
  </svg>
  <svg v-else-if="icon === 'heart'" viewBox="0 0 140 140">
    <g fill="currentcolor">
      <path
        d="M105.6,61A35.71,35.71,0,0,0,58,7.82,35.71,35.71,0,0,0,10.44,61L45.91,96.42a17.12,17.12,0,0,0,24.22,0ZM58,93.16a8.78,8.78,0,0,1-6.25-2.59L16.3,55.1A27.44,27.44,0,1,1,55.1,16.3a4.13,4.13,0,0,0,5.85,0,27.44,27.44,0,0,1,38.8,38.8L64.28,90.57A8.79,8.79,0,0,1,58,93.16Z"
      />
    </g>
  </svg>
  <svg v-else-if="icon === 'pool'" viewBox="0 0 140 140">
    <g fill="currentcolor">
      <path
        d="M5.4,94.16a17.21,17.21,0,0,1,3.52-2l-.86.36a17,17,0,0,1,4.36-1.2l-.95.13a17.68,17.68,0,0,1,4.48,0L15,91.27a16.83,16.83,0,0,1,4.35,1.2l-.86-.36a17.74,17.74,0,0,1,2.68,1.45c.81.54,1.56,1.16,2.4,1.64a19.42,19.42,0,0,0,6.29,2.42,20.75,20.75,0,0,0,13.79-2.06,24.77,24.77,0,0,0,2.65-1.75,17.41,17.41,0,0,1,3.07-1.71l-.85.36a16.83,16.83,0,0,1,4.35-1.2l-.95.13a17.68,17.68,0,0,1,4.48,0l-.95-.13a16.83,16.83,0,0,1,4.35,1.2l-.86-.36a16.47,16.47,0,0,1,2.68,1.45c.81.54,1.56,1.15,2.4,1.64a19.74,19.74,0,0,0,6.29,2.42,20.79,20.79,0,0,0,13.8-2.06,27,27,0,0,0,2.65-1.75,16.39,16.39,0,0,1,3.06-1.71l-.85.36a17.25,17.25,0,0,1,4.35-1.21l-.95.14a17.68,17.68,0,0,1,4.48,0l-.95-.14a17.25,17.25,0,0,1,4.35,1.21l-.85-.36a17.92,17.92,0,0,1,3.52,2,3.46,3.46,0,0,0,2.74.36,3.57,3.57,0,0,0,2.49-4.39A4.22,4.22,0,0,0,106.51,88a20.41,20.41,0,0,0-22.37-1c-.81.48-1.53,1.08-2.32,1.59A17.25,17.25,0,0,1,79.15,90l.85-.36a16.83,16.83,0,0,1-4.35,1.2l.95-.13a17.68,17.68,0,0,1-4.48,0l.95.13a16.83,16.83,0,0,1-4.35-1.2l.86.36a16.47,16.47,0,0,1-2.68-1.45c-.81-.54-1.56-1.15-2.4-1.64a19.74,19.74,0,0,0-6.29-2.42,20.79,20.79,0,0,0-13.8,2.06,25.81,25.81,0,0,0-2.65,1.75A16.16,16.16,0,0,1,38.69,90l.86-.36a17.25,17.25,0,0,1-4.35,1.21l.95-.13a17.68,17.68,0,0,1-4.48,0l.95.13a17.25,17.25,0,0,1-4.35-1.21l.85.36a28.48,28.48,0,0,1-4.36-2.65,20.18,20.18,0,0,0-17-2.4,19.72,19.72,0,0,0-6,3A4,4,0,0,0,.12,90.11,3.57,3.57,0,0,0,2.61,94.5a3.47,3.47,0,0,0,2.79-.34Z"
      />
      <path
        d="M53.15,18.24A18.4,18.4,0,0,0,42.56,1.67,19,19,0,0,0,31.79.23a17.2,17.2,0,0,0-7.65,3.24,18.29,18.29,0,0,0-5.71,6.78,18.8,18.8,0,0,0-1.85,8c0,2.52,0,5,0,7.57V73.56a3.57,3.57,0,1,0,7.13,0V30.73c0-4.16,0-8.31,0-12.47a16.79,16.79,0,0,1,.13-2c0,.32-.08.63-.13.95a15.64,15.64,0,0,1,1-3.69l-.35.85A14.48,14.48,0,0,1,25.67,12c.14-.19.28-.38.41-.59.27-.39-.27.34-.27.35s.19-.24.25-.31a13.13,13.13,0,0,1,1-1c.31-.31.64-.6,1-.89l.31-.25c.31-.26-.69.5-.34.28l.57-.41A15.19,15.19,0,0,1,31,7.75l-.85.35a15.18,15.18,0,0,1,3.69-1l-1,.13a16.41,16.41,0,0,1,3.9,0l-.95-.13a15.12,15.12,0,0,1,3.79,1l-.85-.36A15.69,15.69,0,0,1,41.2,9.07c.21.13.41.28.6.41l-.29-.22.29.22c.32.28.64.56.94.87s.59.62.87.94l.22.29-.22-.29c.14.2.28.39.41.6a15.69,15.69,0,0,1,1.31,2.42L45,13.46a15.43,15.43,0,0,1,1,3.79c0-.32-.09-.63-.13-.95A17.69,17.69,0,0,1,46,18.22a3.57,3.57,0,0,0,7.14,0Z"
      />
      <path
        d="M101.49,18.24A18.42,18.42,0,0,0,90.9,1.67,19,19,0,0,0,80.12.23a17.21,17.21,0,0,0-7.64,3.24,18.2,18.2,0,0,0-5.71,6.78,19,19,0,0,0-1.86,8c0,2.53,0,5.08,0,7.61V73.56a3.57,3.57,0,1,0,7.14,0V30.77c0-4.18,0-8.35,0-12.52a16.79,16.79,0,0,1,.13-2c-.05.32-.08.63-.13.95a15.38,15.38,0,0,1,1-3.7l-.36.86A15.86,15.86,0,0,1,74,11.93c.13-.19.27-.38.4-.58.28-.39-.27.33-.27.35s.19-.24.25-.31c.3-.36.62-.7,1-1s.64-.6,1-.89l.31-.25c.31-.26-.69.5-.35.27l.57-.4a14.94,14.94,0,0,1,2.49-1.34l-.86.35a14.93,14.93,0,0,1,3.7-1l-1,.13a16.41,16.41,0,0,1,3.9,0l-.95-.13A15.21,15.21,0,0,1,88,8.1l-.86-.35a14.57,14.57,0,0,1,2.43,1.31l.59.41c.3.22.19.14-.28-.22l.28.22a11.13,11.13,0,0,1,.94.87c.3.3.6.62.87.94a3.25,3.25,0,0,1,.23.29c-.37-.49-.44-.59-.23-.29s.29.39.42.59a16.27,16.27,0,0,1,1.31,2.43l-.36-.86a15.61,15.61,0,0,1,1,3.8c0-.32-.08-.63-.13-.95a15.48,15.48,0,0,1,.13,1.92,3.62,3.62,0,0,0,3.57,3.57,3.58,3.58,0,0,0,3.58-3.54Z"
      />
      <path
        d="M64.91,36.13V61.41l3.57-3.57H26.1c-2,0-3.92-.06-5.88,0h-.09l3.57,3.57V36.13L20.13,39.7H62.51c2,0,3.93,0,5.89,0h.08a3.57,3.57,0,0,0,0-7.14H26.1c-2,0-3.92,0-5.88,0h-.09a3.62,3.62,0,0,0-3.56,3.57V61.41A3.62,3.62,0,0,0,20.13,65H62.51c2,0,3.93,0,5.89,0h.08a3.62,3.62,0,0,0,3.57-3.57V36.13a3.57,3.57,0,1,0-7.14,0Z"
      />
      <path
        d="M5.4,112.94a16.72,16.72,0,0,1,3.52-2l-.86.36a17,17,0,0,1,4.36-1.2l-.95.13a17.68,17.68,0,0,1,4.48,0l-.95-.13a16.83,16.83,0,0,1,4.35,1.2l-.86-.36a16.9,16.9,0,0,1,2.68,1.45c.81.54,1.56,1.15,2.4,1.64a19.65,19.65,0,0,0,6.29,2.42,20.75,20.75,0,0,0,13.79-2.06,24.77,24.77,0,0,0,2.65-1.75,16.8,16.8,0,0,1,3.07-1.71l-.85.35a17.66,17.66,0,0,1,4.35-1.2l-.95.13a17.68,17.68,0,0,1,4.48,0l-.95-.13a17.66,17.66,0,0,1,4.35,1.2l-.86-.35a16.47,16.47,0,0,1,2.68,1.45c.81.53,1.56,1.15,2.4,1.64a19.49,19.49,0,0,0,6.29,2.41,20.73,20.73,0,0,0,13.8-2,28.87,28.87,0,0,0,2.65-1.75,16.39,16.39,0,0,1,3.06-1.71l-.85.35a17.66,17.66,0,0,1,4.35-1.2l-.95.13a17.68,17.68,0,0,1,4.48,0L95.9,110a17.66,17.66,0,0,1,4.35,1.2l-.85-.35a17.44,17.44,0,0,1,3.52,2,3.55,3.55,0,0,0,2.74.36,3.57,3.57,0,0,0,2.49-4.39,4.18,4.18,0,0,0-1.64-2.13,20.43,20.43,0,0,0-22.37-1c-.81.49-1.53,1.09-2.32,1.6a18.13,18.13,0,0,1-2.67,1.45l.85-.36a17.23,17.23,0,0,1-4.35,1.2l.95-.13a17.68,17.68,0,0,1-4.48,0l.95.13a17.23,17.23,0,0,1-4.35-1.2l.86.36a17.27,17.27,0,0,1-2.68-1.45c-.81-.54-1.56-1.16-2.4-1.64a19.51,19.51,0,0,0-6.29-2.42,20.79,20.79,0,0,0-13.8,2.06,25.81,25.81,0,0,0-2.65,1.75,16.73,16.73,0,0,1-3.07,1.71l.86-.36a16.83,16.83,0,0,1-4.35,1.2l.95-.13a17.68,17.68,0,0,1-4.48,0l.95.13a16.83,16.83,0,0,1-4.35-1.2l.85.36a28.48,28.48,0,0,1-4.36-2.65,20.18,20.18,0,0,0-17-2.4,19.93,19.93,0,0,0-6,3A4,4,0,0,0,.12,108.9a3.57,3.57,0,0,0,2.49,4.39,3.51,3.51,0,0,0,2.79-.35Z"
      />
    </g>
  </svg>
  <svg v-else-if="icon === 'scuba'" viewBox="0 0 140 140">
    <g fill="currentcolor">
      <path
        d="M31.38,97.48a7.67,7.67,0,1,1,0-15.34h37.4a7.67,7.67,0,0,1,.09,15.34,3.41,3.41,0,1,0,0,6.82,14.49,14.49,0,0,0-.08-29H31.38C21.46,75.73,17,82.89,17,89.81s4.48,14.07,14.49,14.49a3.41,3.41,0,0,0-.09-6.82Z"
      />
      <path
        d="M122.91,99.25V51.07a3.3,3.3,0,0,0-3.41-3.42h0a3.3,3.3,0,0,0-3.41,3.46V99.25a31.39,31.39,0,0,1-62.74,1.48A3.41,3.41,0,0,0,50,97.48h-.16A3.42,3.42,0,0,0,46.54,101a38.2,38.2,0,0,0,76.37-1.79Z"
      />
      <path
        d="M100.79,29.61l-.15-2A25.51,25.51,0,0,0,83.56,5.48a102.85,102.85,0,0,0-66.35,0A25.57,25.57,0,0,0,0,29.68v6A24.39,24.39,0,0,0,24.18,60.24c5.08,0,9.72-2.47,14.22-4.86,4.15-2.2,8-4.27,11.82-4.27,3.6,0,7,1.86,10.93,4,4.41,2.41,9.42,5.13,15.39,5.13a24.26,24.26,0,0,0,24-21.9l.2-1.91h7.64a14.51,14.51,0,0,0,14.49-14.49V3.43A3.3,3.3,0,0,0,119.5,0h0a3.31,3.31,0,0,0-3.41,3.47V21.94a7.68,7.68,0,0,1-7.67,7.67ZM93.9,35.69A17.58,17.58,0,0,1,76.54,53.43c-4.22,0-8.05-2.08-12.09-4.29-11-6.47-17.53-6.41-29.2.2-4,2.11-7.69,4.09-11.07,4.09A17.58,17.58,0,0,1,6.81,35.69v-6A18.75,18.75,0,0,1,19.43,12a96,96,0,0,1,61.9,0A18.72,18.72,0,0,1,93.9,29.62Z"
      />
    </g>
  </svg>
  <svg v-else-if="icon === 'serving-dish'" viewBox="0 0 160 140">
    <g fill="currentcolor">
      <path
        d="M151.43,88h-6.17V86.31a67.74,67.74,0,0,0-56-66.86l-2.18-.38.92-2a11.62,11.62,0,1,0-22.21-4.8,11.37,11.37,0,0,0,1,4.8l.92,2-2.19.38a67.74,67.74,0,0,0-56,66.86V88H3.35a2.73,2.73,0,0,0,0,5.46H151.43a2.73,2.73,0,0,0,0-5.46Zm-74-81.93a6.16,6.16,0,1,1-6.16,6.16A6.17,6.17,0,0,1,77.39,6.09ZM139.81,88H15V86.31A62.52,62.52,0,0,1,53.1,28.79a62.89,62.89,0,0,1,48.59,0,62.4,62.4,0,0,1,38.12,57.52V88Z"
      />
      <path
        d="M151.43,94.1H3.35a3.35,3.35,0,1,1,0-6.7H8.9V86.3A68.36,68.36,0,0,1,65.43,18.84l1.4-.25-.59-1.29a12,12,0,0,1-1.09-5.05,12.25,12.25,0,1,1,24.49,0,12,12,0,0,1-1.1,5.05L88,18.59l1.39.25A68.35,68.35,0,0,1,145.88,86.3v1.1h5.55a3.35,3.35,0,0,1,0,6.7ZM3.35,88.64a2.11,2.11,0,0,0,0,4.22H151.43a2.11,2.11,0,0,0,0-4.22h-6.79V86.3a67.11,67.11,0,0,0-55.5-66.24l-3-.52,1.25-2.75a10.88,10.88,0,0,0,1-4.54,11,11,0,1,0-22,0,10.87,10.87,0,0,0,1,4.54l1.25,2.75-3,.52A67.12,67.12,0,0,0,10.14,86.3v2.34Zm137.08,0H14.35V86.3a63.14,63.14,0,0,1,38.5-58.08,63.39,63.39,0,0,1,49.08,0,63,63,0,0,1,38.5,58.08ZM15.59,87.4h123.6V86.3a61.88,61.88,0,0,0-37.75-56.94,62.07,62.07,0,0,0-48.1,0A61.77,61.77,0,0,0,15.59,86.3ZM77.39,19a6.78,6.78,0,1,1,6.79-6.78A6.79,6.79,0,0,1,77.39,19Zm0-12.32a5.54,5.54,0,1,0,5.55,5.54A5.55,5.55,0,0,0,77.39,6.71Z"
      />
    </g>
  </svg>
  <svg v-else-if="icon === 'star'" viewBox="0 0 140 140">
    <g fill="currentcolor">
      <path
        d="M98.29,117.7a2.83,2.83,0,0,1-1.29-.32L61.8,98.87,26.59,117.38a2.76,2.76,0,0,1-4-2.9l6.72-39.21L.83,47.51a2.75,2.75,0,0,1,1.53-4.7l39.36-5.72L59.32,1.42a2.87,2.87,0,0,1,4.95,0l17.6,35.67,39.36,5.72a2.75,2.75,0,0,1,1.53,4.7L94.28,75.27,101,114.48a2.77,2.77,0,0,1-2.72,3.22ZM61.8,93a2.64,2.64,0,0,1,1.28.32L94.62,109.9l-6-35.13a2.77,2.77,0,0,1,.79-2.44l25.52-24.87L79.64,42.34a2.77,2.77,0,0,1-2.07-1.51L61.8,8.87,46,40.83A2.77,2.77,0,0,1,44,42.34L8.69,47.46,34.2,72.33a2.78,2.78,0,0,1,.8,2.44L29,109.9,60.51,93.32A2.68,2.68,0,0,1,61.8,93Z"
      />
    </g>
  </svg>
  <svg v-else-if="icon === 'sun'" viewBox="0 0 140 140">
    <g fill="currentcolor">
      <path
        d="M126.78,33.63a3.31,3.31,0,0,0-.93-.13,3.44,3.44,0,0,0-1.65.43l-4.83,2.7a3.41,3.41,0,0,0-1.32,4.63,3.47,3.47,0,0,0,4.63,1.31l4.83-2.69a3.4,3.4,0,0,0-.73-6.25Z"
      />
      <path
        d="M68.57,27.67A41.45,41.45,0,0,0,31.78,87.41a3.45,3.45,0,0,0,4.57,1.5h0a3.4,3.4,0,0,0,1.5-4.57A34.33,34.33,0,1,1,102.8,68.71,33.63,33.63,0,0,1,99,84.05a3.4,3.4,0,0,0,4.09,4.78,3.33,3.33,0,0,0,2-1.7,40.35,40.35,0,0,0,4.52-18.42A41.09,41.09,0,0,0,68.57,27.67Z"
      />
      <path
        d="M128.62,65.31a3.4,3.4,0,1,0,0,6.8h5.54a3.4,3.4,0,1,0,0-6.8Z"
      />
      <path
        d="M3.54,72.11H9.08a3.4,3.4,0,1,0,0-6.8H3.54a3.4,3.4,0,0,0,0,6.8Z"
      />
      <path
        d="M134,102.11h0a3.37,3.37,0,0,0-2.4,1,7.12,7.12,0,0,1-9.77,0,13.18,13.18,0,0,0-19.2,0,7.13,7.13,0,0,1-4.83,2,7.66,7.66,0,0,1-5-2,13.24,13.24,0,0,0-19.19,0,7,7,0,0,1-9.86,0,13.82,13.82,0,0,0-19.2,0,7.29,7.29,0,0,1-5,2,7.13,7.13,0,0,1-4.81-2,13.79,13.79,0,0,0-19.25,0,6.88,6.88,0,0,1-9.62,0,3.41,3.41,0,0,0-2.41-1h0a3.46,3.46,0,0,0-2.41,1,3.42,3.42,0,0,0,0,4.82,13.81,13.81,0,0,0,19.25,0,6.86,6.86,0,0,1,9.62,0,13.83,13.83,0,0,0,9.56,4,14.37,14.37,0,0,0,9.91-4,6.81,6.81,0,0,1,9.59,0,13.77,13.77,0,0,0,19.47,0,7.14,7.14,0,0,1,4.8-2.33A7.12,7.12,0,0,1,88,107.93a14,14,0,0,0,9.76,4v2.13l.12-2.14a13.92,13.92,0,0,0,9.6-4c2.9-2.91,6.61-3,9.58,0a14.14,14.14,0,0,0,19.38,0,3.41,3.41,0,0,0-2.4-5.82Z"
      />
      <path
        d="M39.14,10.45a3.45,3.45,0,0,0-3-1.7,3.32,3.32,0,0,0-1.7.46,3.36,3.36,0,0,0-1.59,2.06,3.41,3.41,0,0,0,.34,2.58L36,18.65a3.48,3.48,0,0,0,4.65,1.24,3.41,3.41,0,0,0,1.25-4.64Z"
      />
      <path
        d="M18.4,36.41,13.58,33.7a3.42,3.42,0,0,0-1.67-.44,3.25,3.25,0,0,0-.92.13,3.4,3.4,0,0,0-.75,6.24l4.82,2.72a3.47,3.47,0,0,0,4.63-1.3h0A3.39,3.39,0,0,0,20,38.46,3.44,3.44,0,0,0,18.4,36.41Z"
      />
      <path
        d="M107.46,133.39c2.91-2.91,6.61-3,9.58,0a14.14,14.14,0,0,0,19.38,0,3.41,3.41,0,0,0-2.4-5.82h0a3.37,3.37,0,0,0-2.4,1,7.12,7.12,0,0,1-9.77,0,13.71,13.71,0,0,0-9.56-4.32h0a14,14,0,0,0-9.63,4.32,7.18,7.18,0,0,1-4.83,2.05h0a7.24,7.24,0,0,1-5-2.05,13.25,13.25,0,0,0-19.19,0,7,7,0,0,1-9.86,0,13.79,13.79,0,0,0-9.6-4,13.5,13.5,0,0,0-9.6,4,7,7,0,0,1-9.86,0,13.79,13.79,0,0,0-19.25,0,6.88,6.88,0,0,1-9.62,0,3.41,3.41,0,0,0-2.41-1,3.46,3.46,0,0,0-2.41,1,3.42,3.42,0,0,0,0,4.82,13.81,13.81,0,0,0,19.25,0,6.86,6.86,0,0,1,9.62,0,13.9,13.9,0,0,0,9.56,4,13.73,13.73,0,0,0,9.91-4,6.81,6.81,0,0,1,9.59,0,13.77,13.77,0,0,0,19.47,0c2.94-2.95,6.62-3,9.58,0a14,14,0,0,0,9.75,4h0l.18,2.13,0-2.13A13.9,13.9,0,0,0,107.46,133.39Z"
      />
      <path
        d="M64.62,3.4V8.94a3.4,3.4,0,0,0,6.8,0V3.4a3.4,3.4,0,0,0-6.8,0Z"
      />
      <path
        d="M96.69,18.59a3.42,3.42,0,0,0,1.53,2.11,3.47,3.47,0,0,0,4.68-1.13l2.89-4.72a3.41,3.41,0,0,0-1.13-4.68,3.34,3.34,0,0,0-1.76-.5A3.4,3.4,0,0,0,100,11.3L97.09,16A3.36,3.36,0,0,0,96.69,18.59Z"
      />
    </g>
  </svg>
  <svg v-else-if="icon === 'surf'" viewBox="0 0 140 140">
    <g fill="currentcolor">
      <path
        d="M136.56,133.48a3.41,3.41,0,1,0-4.82-4.82,6.82,6.82,0,0,1-9.59,0,13.75,13.75,0,0,0-19.49,0,6.83,6.83,0,0,1-9.59,0,13.76,13.76,0,0,0-19.5,0,7,7,0,0,1-4.82,2.05A6.46,6.46,0,0,1,64,128.65a3.41,3.41,0,1,0-4.81,4.83,13.64,13.64,0,0,0,9.53,4.05h.13a13.91,13.91,0,0,0,9.56-4.05,7.29,7.29,0,0,1,5-2,7.17,7.17,0,0,1,4.88,2,13.54,13.54,0,0,0,9.59,4.05,13.78,13.78,0,0,0,9.63-4.05,7,7,0,0,1,9.87,0A13.84,13.84,0,0,0,136.56,133.48Z"
      />
      <path
        d="M41,133.23a7.59,7.59,0,0,0,1.76-6.09,341.24,341.24,0,0,1-2.72-38.51c0-21.78,3.78-42.88,10.62-59.49,6.38-15.44,13.64-22.36,18-22.36S80.34,13.7,86.78,29.15C93.69,45.78,97.5,66.87,97.5,88.56c0,4.43-.29,12.92-.93,22a3.41,3.41,0,0,0,6.8.48c.57-8.06.95-17.09.95-22.46,0-22.63-4-44.66-11.25-62.09C89.82,18.73,86,12.2,82.05,7.65c-8.88-10.21-17.91-10.2-26.73,0-3.94,4.55-7.72,11.08-10.94,18.88C37.19,44,33.24,66,33.24,88.56a359.36,359.36,0,0,0,2.34,36.24l.57,4.51-3.83-2.44a13.58,13.58,0,0,0-7.43-2.26,13.86,13.86,0,0,0-9.5,4.06,6.83,6.83,0,0,1-9.58,0,3.35,3.35,0,0,0-2.4-1h0A3.41,3.41,0,0,0,1,133.48a13.75,13.75,0,0,0,19.23,0h0a6.94,6.94,0,0,1,4.77-2,7.86,7.86,0,0,1,5.08,2.33,7.28,7.28,0,0,0,5.16,2.1A7.63,7.63,0,0,0,41,133.23Z"
      />
      <path
        d="M69.28,114.44h0l5.43-1.26a10.44,10.44,0,0,0,5.6-16.91l-5.12-6a14,14,0,0,1-3.28-9v-51a3.41,3.41,0,1,0-6.82,0v51A20.75,20.75,0,0,0,70,94.62l5.12,6a3.62,3.62,0,0,1-1.94,5.87l-5.43,1.27a3.4,3.4,0,1,0,1.55,6.63Z"
      />
    </g>
  </svg>
  <svg v-else-if="icon === 'weight'" viewBox="0 0 200 200">
    <g fill="currentcolor" stroke-width="20">
      <path
        d="M58.27,104H34.6A10.69,10.69,0,0,1,23.93,93.35V68.87a3,3,0,0,1,5.95,0V93.35a4.74,4.74,0,0,0,4.72,4.73H58.27A4.73,4.73,0,0,0,63,93.35V10.67A4.72,4.72,0,0,0,58.27,6H34.6a4.73,4.73,0,0,0-4.72,4.72V32.81a3,3,0,0,1-5.95,0V10.67A10.68,10.68,0,0,1,34.6,0H58.27A10.67,10.67,0,0,1,68.94,10.67V93.35A10.68,10.68,0,0,1,58.27,104Z"
      />
      <path
        d="M26.91,91.59H10.67A10.68,10.68,0,0,1,0,80.92V23.1A10.68,10.68,0,0,1,10.67,12.43H26.91a3,3,0,0,1,0,5.95H10.67A4.73,4.73,0,0,0,6,23.1V80.92a4.73,4.73,0,0,0,4.72,4.72H26.91a3,3,0,0,1,0,6Z"
      />
      <path
        d="M157.53,104H133.86a10.69,10.69,0,0,1-10.68-10.68V10.67A10.68,10.68,0,0,1,133.86,0h23.67a10.68,10.68,0,0,1,10.68,10.67V32.81a3,3,0,0,1-6,0V10.67A4.73,4.73,0,0,0,157.53,6H133.86a4.73,4.73,0,0,0-4.73,4.72V93.35a4.74,4.74,0,0,0,4.73,4.73h23.67a4.74,4.74,0,0,0,4.73-4.73V68.87a3,3,0,0,1,6,0V93.35A10.69,10.69,0,0,1,157.53,104Z"
      />
      <path
        d="M181.46,91.59H165.23a3,3,0,0,1,0-6h16.23a4.73,4.73,0,0,0,4.73-4.72V23.1a4.73,4.73,0,0,0-4.73-4.72H165.23a3,3,0,0,1,0-5.95h16.23A10.68,10.68,0,0,1,192.14,23.1V80.92A10.68,10.68,0,0,1,181.46,91.59Z"
      />
      <path
        d="M126.16,41.91H66A3,3,0,1,1,66,36h60.19a3,3,0,0,1,0,5.94Z"
      />
      <path
        d="M126.16,63.48H66a3,3,0,1,1,0-5.95h60.19a3,3,0,0,1,0,5.95Z"
      />
    </g>
  </svg>
  <svg v-else-if="icon === 'wifi'" viewBox="0 0 140 140">
    <g fill="currentcolor">
      <path
        d="M71.55,83.07a9.34,9.34,0,1,0,6.61,2.73A9.33,9.33,0,0,0,71.55,83.07Z"
      />
      <path
        d="M40.33,65.35h0l-1.23,1.2a4.71,4.71,0,0,0,6.65,6.65l.09-.1L46.92,72,47,72a36.51,36.51,0,0,1,50.48,1.06,4.71,4.71,0,0,0,6.66-6.66,45.84,45.84,0,0,0-63.79-1.06Z"
      />
      <path
        d="M71.55,35.33A63.57,63.57,0,0,1,116.63,54a4.67,4.67,0,1,0,6.84-6.35l-.24-.24a73.13,73.13,0,0,0-103.33,0A4.67,4.67,0,0,0,26.47,54,63.57,63.57,0,0,1,71.55,35.33Z"
      />
      <path
        d="M71.55,8.68a90.37,90.37,0,0,1,64.12,26.55,4.35,4.35,0,0,0,6.6-5.66,4.89,4.89,0,0,0-.45-.46A99.48,99.48,0,0,0,1.25,29a4.32,4.32,0,0,0,6.12,6.11A90.31,90.31,0,0,1,71.55,8.68Z"
      />
    </g>
  </svg>
</template>
