<script setup>
const fields = inject('fields')

defineSlice({ name: { label: 'Logo', group: 'global' }, fields: { logo: { type: 'media', label: 'Logo', editorLabel: true, breakpoints: { default: { width: 400 } }, name: 'logo' } }, slots: [] })
</script>

<template>
  <div class="w-24 h-24 overflow-hidden">
    <VoixMedia
      :field="fields.logo"
      class="object-contain w-full h-full"
    />
  </div>
</template>
