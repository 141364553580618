<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      description: 'Hides the Wyndham Inline Booking Widget',
      name: {
        label: 'Hide Wyndham Inline Booking Widget',
        group: 'Wyndham',
        layouts: ['WyndhamPlaya', 'Wyndham', 'WyndhamCancun'],
      },
      tags: ['Widget'],
      templates: [{ label: 'Hide Wyndham Inline Booking Widget', fields: [] }],
    })
    setTimeout(() => {
      window.bus.$emit('wyndham-inline-booking-widget-hide')
    }, 500)
  },
}
</script>

<template>
  <div />
</template>
