<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    titleSize() {
      return {
        'text-4xl':
          !this.fields.titleSize.value || this.fields.titleSize.value === 'text-4xl',
        'text-3xl': this.fields.titleSize.value === 'text-3xl',
        'text-2xl': this.fields.titleSize.value === 'text-2xl',
      }
    },
  },
  mounted() {
    defineSlice({
      description:
        'Title with one small subtitle above and one larger sub-title below it. Both subtitles are optional. The titles are followed by a wysiwyg description',
      fields: {
        title: { type: 'text', label: 'Title', editorLabel: true },
        introduction: { type: 'wysiwyg', label: 'Introduction' },
        titleSize: {
          type: 'select',
          label: 'Title Size',
          options: { 'text-4xl': 'XX large', 'text-3xl': 'X Large', 'text-2xl': 'Large' },
        },
        textCenter: { type: 'checkbox', label: 'Text Center?', default: true },
      },
      name: {
        label: 'Color Bar Title Paragraph',
        group: 'Playa Collection',
        layouts: ['PlayaCollection'],
      },
      tags: ['Title', 'Paragraph'],
      templates: [
        {
          label: 'Color Bar Title Paragraph',
          fields: {
            introduction: {
              value:
                '<p>Playa Hotels &amp; Resorts N.V. (NASDAQ: PLYA, "Playa") is a leading owner, operator and developer of all-inclusive resorts in prime beachfront locations in popular vacation destinations in&nbsp;Mexico&nbsp;and the&nbsp;Caribbean. Playa owns and\/or manages a total portfolio consisting of 22 resorts (8,366 rooms) located in&nbsp;Mexico,&nbsp;Jamaica, and the&nbsp;Dominican Republic. Playa leverages years of all-inclusive resort operating expertise and relationships with globally recognized hospitality brands to provide a best in class experience and exceptional value to our guests, while building a direct relationship to improve customer acquisition cost and drive repeat business. Playa owns and manages 17 resorts (6,295 rooms) located throughout&nbsp;Mexico,&nbsp;Jamaica&nbsp;and the&nbsp;Dominican Republic. Playa also owns two resorts in the&nbsp;Dominican Republic&nbsp;that are managed by a third party and manages three resorts on behalf of third-party owners.<\/p>',
            },
            title: { value: 'About Playa Hotels & Resorts' },
          },
        },
      ],
    })
  },
}
</script>

<template>
  <div class="flex justify-center title-paragraph bg-collection-teal">
    <div
      class="py-4 p-8 md:p-8 container flex flex-col"
      :class="{ 'text-center  items-center': fields.textCenter.value }"
    >
      <div
        v-if="fields.title.value"
        class="titles flex flex-col"
        :class="{
          'items-center': fields.textCenter.value,
          'mb-4': fields.introduction.value,
        }"
      >
        <div
          :class="titleSize"
          class="font-bold text-white font-sans-lato"
          v-html="fields.title.value"
        />
      </div>
      <div class="mbt-8 wysiwyg text-white" v-html="fields.introduction.value" />
    </div>
  </div>
</template>
