<template>
    <div
        class="container mx-auto px-12 flex flex-col md:flex-row items-center font-light"
    >
        <div class="mt-6 md:mt-0 md:w-1/2 md:pr-20">
            <div class="flex space-x-2 text-gray-500">
                <svg
                    v-if="!devise.fields.hideCheckIcon.value"
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="41"
                    viewBox="0 0 46 41"
                >
                    <path
                        id="Fill_1"
                        data-name="Fill 1"
                        d="M38,41H0V3H27.636V6.455H3.454v31.09H34.546V23.728H38V41ZM23.849,27h0L14,13.945l4.054-3.08,6.223,8.257L42.309,0,46,3.5,23.85,27Z"
                        transform="translate(0)"
                        fill="currentColor"
                    />
                </svg>
                <div class="uppercase text-4xl tracking-wider font-thin mb-4">
                    {{ devise.fields.title.value?devise.fields.title.value:"It'sAllIncluded" }}
                </div>
            </div>
            <div v-html="devise.fields.copy.value"></div>
        </div>
        <div
            class="md:w-1/2 md:columns-2 list"
            v-html="devise.fields.includedItems.value"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'ItsAllIncluded',

    props: {
        devise: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.list {
    li {
        @apply list-disc pb-2;

        p {
            @apply pb-0 #{!important};
        }
    }
}
</style>
