<script setup lang="ts">
import type { SliceInterface, VoixCheckboxFieldInterface } from '@voix/types'

import type { Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination } from 'swiper/modules'

import 'swiper/css'

const props = defineProps<{
  fields: {
    fullBleed: VoixCheckboxFieldInterface
  }
  slice: SliceInterface
}>()

defineSlice({
  name: { label: 'Bonita Magazine Slider', group: 'Bonita Galleries', layouts: ['Bonita'] },
  tags: ['Slider'],
  slots: [{ name: 'default', allowedElements: ['SlicesGlobalGalleriesMagazineSlide'] }],
  description: 'Optionally Full bleed slider for magazine images featuring Bonita branded background.',
  preview: 'SlicesBonitaGalleriesMagazineSlider.jpg',
  fields: {
    fullBleed: {
      label: 'Full Bleed',
      type: 'checkbox',
      default: false,
    },
  },
  templates: [{
    label: 'Main Example',
    fields: {
      fullBleed: { value: true },
    },
  }],
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)

const exampleSlides = [
  {
    media: {
      value: 'https://images.unsplash.com/photo-1448375010925-a75a42ceaffa?q=80&w=1705&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    textOverlay: { value: 'LOREM IPSUM DOLAR' },
  },
  {
    media: {
      value: 'https://images.unsplash.com/photo-1445964047600-cdbdb873673d?q=80&w=1892&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    textOverlay: { value: 'A Real Title' },
  },
  {
    media: {
      value: 'https://images.unsplash.com/photo-1446329813274-7c9036bd9a1f?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    textOverlay: { value: 'ALL CAPS TITLE' },
  },
  {
    media: {
      value: 'https://images.unsplash.com/photo-1440755130913-8711f8195bdf?q=80&w=1909&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    },
    textOverlay: { value: 'A WRAPPING TITLE THAT GOES ON AND ON' },
  },
] as any[]

const subSlices = props.slice.elements as SliceInterface[]

let numberOfSlides = subSlices.length
if (numberOfSlides === 0 && isVoixTemplatizer) {
  numberOfSlides = exampleSlides.length
}

const swiperRef: Ref<SwiperType | null> = ref(null)
function setSwiperRef(swiper: SwiperType) {
  swiperRef.value = swiper
}

function next() {
  if (swiperRef.value)
    swiperRef.value.slideNext()
}

function prev() {
  if (swiperRef.value)
    swiperRef.value.slidePrev()
}
</script>

<template>
  <div class="relative py-16 md:p-16" :class="{ 'max-w-[1600px] mx-auto': !fields.fullBleed.value }">
    <div class="absolute inset-0">
      <NuxtImg src="/imgs/bonita/background-1.jpg" class="object-cover w-full h-full" />
    </div>
    <ClientOnly>
      <div class="relative z-0">
        <Swiper id="swiper" :modules="[Navigation, Pagination]" :slides-per-view="1" :space-between="0" class="border-y-4 md:border-4 border-white" @swiper="setSwiperRef">
          <SwiperSlide v-for="slice in subSlices" :key="slice.id">
            <VoixSlice :slice="slice" />
          </SwiperSlide>
          <template v-if="isVoixTemplatizer">
            <SwiperSlide v-for="(slide, key) in exampleSlides" :key="key">
              <SlicesGlobalGalleriesMagazineSlide :fields="slide" />
            </SwiperSlide>
          </template>
        </Swiper>
      </div>

      <div
        v-if="numberOfSlides > 1"
        class="absolute z-10 inset-0 pointer-events-none flex justify-between items-center p-4"
      >
        <button class="pointer-events-auto" @click="prev">
          <Icon class="w-16 h-16 text-gray-200" name="heroicons:chevron-left-20-solid" />
        </button>
        <button class="pointer-events-auto" @click="next">
          <Icon class="w-16 h-16 text-gray-200" name="heroicons:chevron-right-20-solid" />
        </button>
      </div>

      <div
        v-if="numberOfSlides > 1"
        class="absolute z-20 inset-0 flex justify-center items-end p-8 pointer-events-none"
      >
        <div class="flex items-center space-x-2 pointer-events-auto">
          <template
            v-for="index in numberOfSlides"
            :key="index"
          >
            <button
              class=" rounded-full border border-white opacity-50 hover:opacity-100 duration-200"
              :class="{
                'bg-white border-0 w-1.5 h-1.5': swiperRef?.activeIndex !== index - 1,
                'border-2 w-2 h-2': swiperRef?.activeIndex === index - 1,
              }"
              @click="swiperRef?.slideTo(index - 1)"
            />
          </template>
        </div>
      </div>
    </ClientOnly>
  </div>
</template>
