<script setup lang="ts">
import { useBookingWidgetStore } from '@playa/store/bookingWidgetStore'
import { v4 as uuidv4 } from 'uuid'

import type {
  VoixColorFieldInterface,
  VoixLinkFieldInterface,
  VoixMediaFieldInterface,
  VoixSelectFieldInterface,
  VoixTextFieldInterface,
  VoixWysiwygFieldInterface,
} from '@voix/types'

defineProps<{
  fields: {
    title: VoixTextFieldInterface
    copy: VoixWysiwygFieldInterface
    link: VoixLinkFieldInterface
    image1: VoixMediaFieldInterface
    image2: VoixMediaFieldInterface
    background: VoixSelectFieldInterface
    backgroundColor: VoixColorFieldInterface
    positioning: VoixSelectFieldInterface
  }
}>()

const bookingWidgetStore = useBookingWidgetStore()

const instanceId = uuidv4()
const { toggleBookingModal } = bookingWidgetStore

defineSlice({
  name: { label: 'Copy Beside Images', group: 'sandpiper', layouts: ['Sandpiper'] },
  tags: ['Text', 'Image'],
  preview: 'SlicesSandpiperCopyBesideImages.jpg',
  fields: {
    title: { label: 'Title', type: 'text', name: 'title' },
    copy: { label: 'Copy', type: 'wysiwyg', enabled: true, name: 'copy' },
    link: { label: 'Link', type: 'link', enabled: false, name: 'link' },
    image1: {
      label: 'Image 1',
      type: 'media',
      breakpoints: {
        default: { width: 300, height: 200 },
        md: { width: 800, height: 600 },
      },
      name: 'image1',
    },
    image2: {
      label: 'Image 2',
      type: 'media',
      enabled: false,
      breakpoints: { default: { width: 800, height: 600 } },
      name: 'image2',
    },
    positioning: {
      label: 'Copy Positioning',
      type: 'select',
      options: { left: 'Left', right: 'Right' },
      default: 'left',
      name: 'positioning',
    },
  },
  slots: [],
})
</script>

<template>
  <div>
    <div class="relative px-4 md:container py-16 overflow-hidden">
      <div class="opacity-70">
        <SandpiperBackgroundStar
          opacity="1"
          class="absolute pointer-events-none top-0 w-[70vw] aspect-square text-white"
          :class="{
            'left-0': fields.positioning.value === 'left',
            'right-0': fields.positioning.value === 'right',
          }"
        />
      </div>
      <!-- Content -->
      <div class="relative z-10 grid md:grid-cols-2 gap-8 md:gap-20">
        <div
          class="flex flex-col justify-center"
          :class="{
            'md:col-start-2': fields.positioning.value === 'right',
          }"
        >
          <div class="text-midnight font-sandpiper-display text-2xl">
            {{ fields.title.text }}
          </div>
          <VoixWysiwyg :field="fields.copy" class="text-sm prose" />
          <div v-if="fields.link.enabled" class="pt-4">
            <VoixLink
              v-if="fields.link.value?.url !== '#book-now'"
              :field="fields.link"
              class="text-xs font-sandpiper-display uppercase font-bold text-white bg-shocking px-6 py-2.5 rounded"
            />
            <button
              v-else
              type="button"
              class="text-xs font-sandpiper-display uppercase font-bold text-white bg-shocking px-6 py-2.5 rounded"
              @click.prevent="toggleBookingModal(instanceId)"
            >
              Book Now
            </button>
          </div>
        </div>
        <div
          class="row-start-1 flex flex-col justify-center"
          :class="{
            'md:col-start-1': fields.positioning.value === 'right',
            'pr-12': fields.image2.enabled,
          }"
        >
          <div>
            <VoixMedia
              :field="fields.image1"
              :class="{ 'shadow-2xl': !fields.image2.enabled }"
            />
          </div>
          <div
            v-if="fields.image2.enabled"
            class="-translate-y-12"
            :class="{
              '-translate-x-10': fields.positioning.value === 'left',
              'translate-x-10': fields.positioning.value === 'right',
            }"
          >
            <VoixMedia :field="fields.image2" class="shadow-2xl border border-white" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
