<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    cells() {
      return this.fields.content.value.split('|')
    },
  },
  mounted() {
    defineSlice({
      description:
        'Dynamic table rows that can be created by providing a pipe (|) between content',
      fields: {
        content: {
          type: 'text',
          label: 'Row Cells',
          instructions: '"Separate each cell by \\"|\\". 6 cells max."',
          editorLabel: true,
          default: '""',
        },
      },
      name: { label: 'Text Row', group: 'Global', layouts: ['Jewel', 'JewelGrande'] },
      tags: ['Table', 'Row', 'Text'],
      templates: [{ label: 'Text Row', fields: [] }],
    })
  },
  methods: {
    cellClasses(i) {
      const size = this.cells.length
      return {
        'w-full': size === 1,
        'w-1/2': size === 2,
        'w-1/3': size === 3,
        'w-1/4': size === 4,
        'w-1/5': size === 5,
        'w-1/6': size === 6,
        'text-center': i > 0,
      }
    },
  },
}
</script>

<template>
  <div class="table-row">
    <div
      v-for="(text, i) in cells"
      :key="i"
      class="table-cell py-4 px-8"
      :class="cellClasses(i)"
      v-html="text"
    />
  </div>
</template>
