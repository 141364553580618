<script>
import { usePageStore } from '@voix/store/pageStore'
import axios from 'axios'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      countryFilter: 'all',
      ageFilter: 'all',
      resortsByLocation: {},
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    filteredResorts() {
      if (this.countryFilter !== 'all' || this.ageFilter !== 'all') {
        const filtered = {}
        for (const locationName in this.resortsByLocation) {
          const newList = this.resortsByLocation[locationName].filter((el) => {
            let countryMatch = false
            if (this.countryFilter === 'all' || el.country === this.countryFilter) {
              countryMatch = true
            }

            let ageMatch = false
            if (this.ageFilter !== 'all') {
              if (this.ageFilter === 'all_ages') {
                if (!el.booking_settings.adults_only)
                  ageMatch = true
                else ageMatch = false
              }
              if (this.ageFilter === 'adults') {
                if (el.booking_settings.adults_only)
                  ageMatch = true
                else ageMatch = false
              }
            }
            else {
              ageMatch = true
            }
            return countryMatch && ageMatch
          })

          if (newList.length > 0)
            filtered[locationName] = newList
        }

        return filtered
      }

      return this.resortsByLocation
    },
  },
  mounted() {
    defineSlice({
      description:
        'Adults only and All Ages that features the resorts with Filters at the top.',
      database: true,
      name: {
        label: 'Hotel List',
        group: 'Playa Collection',
        layouts: ['PlayaCollection', 'AgentCashPlus'],
      },
      tags: ['Features', 'List'],
      templates: [{ label: 'Hotel List', fields: [] }],
    })

    $voixFetch(`/api/resorts/by-country?site_id=${this.currentPage.site_id}`).then(
      (response) => {
        this.resortsByLocation = response
      },
    )
  },
  methods: {
    countryTab(country) {
      if (this.countryFilter === country) {
        if (this.currentPage && this.currentPage.site_id === 12)
          return 'bg-collection-teal text-white'
        else return 'bg-playa-action text-white'
      }
      else {
        return 'bg-white text-grey-dark'
      }
    },
    ageTab(age) {
      if (this.ageFilter === age) {
        if (this.currentPage && this.currentPage.site_id === 12)
          return 'bg-collection-teal text-white'
        else return 'bg-playa-action text-white'
      }
      else {
        return 'bg-white text-grey-dark'
      }
    },
  },
}
</script>

<template>
  <div class="bg-grey-light">
    <div class="p-8 lg:p-12">
      <div class="flex justify-center">
        <div class="w-full flex bg-white">
          <div class="p-8 flex flex-col xl:flex-row justify-center text-center w-full">
            <div class="flex w-full flex-wrap xl:w-3/5">
              <a
                href="#"
                class="py-4 px-3 xl:px-4 text-sm border-grey-light border-2 lg:border-r-0 px-4 mr-1 mb-1 lg:mb-0 lg:mr-0"
                :class="countryTab('all')"
                @click.prevent="countryFilter = 'all'"
              >VIEW ALL</a>
              <a
                href="#"
                class="py-4 px-3 xl:px-4 text-sm border-grey-light border-2 lg:border-r-0 px-4 mr-1 mb-1 lg:mb-0 lg:mr-0"
                :class="countryTab('Dominican Republic')"
                @click.prevent="countryFilter = 'Dominican Republic'"
              >DOMINICAN REPUBLIC</a>
              <a
                href="#"
                class="py-4 px-3 xl:px-4 text-sm border-grey-light border-2 lg:border-r-0 px-4 mr-1 mb-1 lg:mb-0 lg:mr-0"
                :class="countryTab('Jamaica')"
                @click.prevent="countryFilter = 'Jamaica'"
              >JAMAICA</a>
              <a
                href="#"
                class="py-4 px-3 xl:px-4 text-sm border-grey-light border-2 px-4 mr-1 mb-1 lg:mb-0 lg:mr-0"
                :class="countryTab('Mexico')"
                @click.prevent="countryFilter = 'Mexico'"
              >MEXICO</a>
            </div>
            <div
              class="flex justify-start border-t lg:border-t-0 border-grey-lighter pt-4 lg:pt-0 mt-4 xl:mt-0 xl:justify-end flex-wrap w-full xl:w-2/5"
            >
              <a
                href="#"
                class="py-4 px-3 xl:px-4 text-sm border-grey-light border-2 lg:border-r-0 px-4 mr-1 mb-1 lg:mb-0 lg:mr-0"
                :class="ageTab('all')"
                @click.prevent="ageFilter = 'all'"
              >VIEW ALL</a>
              <a
                href="#"
                class="py-4 px-3 xl:px-4 text-sm border-grey-light border-2 lg:border-r-0 px-4 mr-1 mb-1 lg:mb-0 lg:mr-0"
                :class="ageTab('all_ages')"
                @click.prevent="ageFilter = 'all_ages'"
              >FAMILY</a>
              <a
                href="#"
                class="py-4 px-3 xl:px-4 text-sm border-grey-light border-2 px-4 mr-1 mb-1 lg:mb-0 lg:mr-0"
                :class="ageTab('adults')"
                @click.prevent="ageFilter = 'adults'"
              >ADULTS ONLY</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition-group name="fade">
      <div
        v-for="(resorts, name) in filteredResorts"
        :key="resorts"
        class="p-8 lg:p-12 pt-0"
      >
        <div class="flex justify-center">
          <div class="flex flex-col justify-center text-center">
            <div
              class="text-5xl text-playa-action title mb-8 uppercase font-extrabold font-sans-lato"
            >
              {{ name }}
            </div>
          </div>
        </div>

        <div v-for="(resort, i) in resorts" :key="i" class="flex justify-center">
          <div class="w-full mb-8 bg-abs-white text-center flex md:flex-row flex-col">
            <div
              class="md:w-1/2 text-center overflow-hidden p-8"
              style="min-height: 500px"
            >
              <div
                :style="{
                  backgroundImage: `url(${resort.playa_collection_image})`,
                }"
                class="bg-cover relative bottom left right sp0 w-full h-full bg-center"
                :alt="resort.name"
              >
                &nbsp;
              </div>
            </div>
            <div class="md:w-1/2 pt-8 pb-8 px-8 md:pl-0 text-left text-center">
              <img
                :src="resort.playa_collection_logo"
                :width="resort.color_logo_width"
                alt=""
                class="mb-6 w-1/2"
              >

              <p v-html="resort.short_description" />

              <p
                v-if="
                  currentPage && currentPage.site_id === 12 && resort.playa_collection_url
                "
                class="pt-4"
              >
                <a
                  :href="resort.playa_collection_url"
                  class="inline-block py-4 px-6 bg-collection-teal text-white text-sm uppercase"
                >MORE INFO</a>
              </p>

              <p
                v-if="currentPage && currentPage.site_id === 9 && resort.agent_cash_url"
                class="pt-4"
              >
                <a
                  :href="resort.agent_cash_url"
                  class="inline-block py-4 px-6 bg-collection-teal text-white text-sm uppercase"
                >MORE INFO</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: translate opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  --tw-translate-y: -3rem;
}
</style>
