<script setup lang="ts">
import type {
  VoixMediaFieldInterface,
  VoixTextFieldInterface,
  VoixWysiwygFieldInterface,
} from '@voix/types'

defineProps<{
  fields: {
    feature: VoixMediaFieldInterface
    title: VoixTextFieldInterface
    subTitle: VoixTextFieldInterface
    colOneTitle: VoixTextFieldInterface
    colOneContent: VoixWysiwygFieldInterface
    colTwoTitle: VoixTextFieldInterface
    colTwoContent: VoixWysiwygFieldInterface
    colThreeTitle: VoixTextFieldInterface
    colThreeContent: VoixWysiwygFieldInterface
    colFourTitle: VoixTextFieldInterface
    colFourContent: VoixWysiwygFieldInterface
  }
}>()

defineSlice({
  preview: 'SlicesKimptonAllIncluded.jpg',
  fields: {
    title: {
      type: 'text',
      label: 'Title',
      default: 'It\'s All Included',
    },
    subTitle: {
      type: 'text',
      label: 'SubTitle',
      default: 'AT RIVIERA MAYA',
    },
    image: {
      type: 'media',
      label: 'Image',
      breakpoints: {
        default: { width: 800 },
        lg: { width: 1200 },
      },
    },
    colOneTitle: {
      type: 'text',
      label: 'Title',
      group: 'Column One',
      default: 'DINING & DRINKS',
    },
    colOneContent: {
      type: 'wysiwyg',
      label: 'Content',
      group: 'Column One',
      default:
        '<ul><li>Unlimited Dining</li><li>Endless Drinks</li><li>Restaurants</li><li>Bars & Lounges</li><li>Premium Top Shelf Wines</li><li>& Liquors</li><li>Oceanfront Dining</li><li>Minibar stocked daily</li></ul>',
    },
    colTwoTitle: {
      type: 'text',
      label: 'Title',
      group: 'Column Two',
      default: 'ACTIVITIES',
    },
    colTwoContent: {
      type: 'wysiwyg',
      label: 'Content',
      group: 'Column Two',
      default:
        '<ul><li>Infinity Pool</li><li>State-of-the-art Sky Gym</li><li>Non-Motorized Watersports</li><li>Scuba School</li><li>Water Volleyball</li><li>Aqua Gym Class</li><li>Cooking Demos</li><li>Live Entertainment</li></ul>',
    },
    colThreeTitle: {
      type: 'text',
      label: 'Title',
      group: 'Column Three',
      default: 'RELAXATION',
    },
    colThreeContent: {
      type: 'wysiwyg',
      label: 'Content',
      group: 'Column Three',
      default:
        '<ul><li>Double Jetted Tub</li><li>Romance Concierge</li><li>24-hour Room Service</li><li>Bathrobes & Slippers</li></ul>',
    },
    colFourTitle: {
      type: 'text',
      label: 'Title',
      group: 'Column Four',
      default: 'SERVICE',
    },
    colFourContent: {
      type: 'wysiwyg',
      label: 'Content',
      group: 'Column Four',
      default:
        '<ul><li>All tips, taxes and gratuities</li><li>Roundtrip airport transfers</li><li>WIFI in rooms and across</li><li>the resort</li><li>KIMPTON TRES RIOS</li><li>KIVIERA MAYA</li><li>ALL-INCLUSIVE RESORT</li><li>About Kimpton Hotels</li><li>Rewards Club</li><li>FLA</li><li>Kimpton Blog: Life is Suite</li><li>Careers</li><li>Contact Us</li><li>Privacy + Legal</li></ul>',
    },
  },
  name: { label: 'All Included', group: 'Kimpton', layouts: ['Kimpton'] },
  tags: ['Content', 'Wysiwyg'],
  templates: [],
})

const isVisible = ref(false)

function onVisibilityChange(entry) {
  isVisible.value = entry.intersectionRatio * 100 > 0
}
</script>

<template>
  <div class="container mx-auto">
    <VoixMedia class="w-full" :field="fields.image" />
    <div
      class="-mt-4 flex justify-evenly animate-fade-in duration-1000 ease-out"
      :class="{ 'opacity-0 -mt-10': !isVisible }"
    >
      <div class="hidden md:flex my-8 px-2 lg:px-4 justify-between flex-grow">
        <Icon name="ph:popsicle" class="w-8 h-8" />
        <Icon name="mynaui:wine" class="w-8 h-8" />
        <Icon name="la:swimming-pool" class="w-8 h-8" />
        <Icon name="akar-icons:wifi" class="w-8 h-8" />
      </div>
      <div class="mx-4 lg:mx-12">
        <div class="relative overflow-visible">
          <p
            class="text-center text-xl bg-kimpton-action h-full text-white px-8 pt-4 pb-2 uppercase font-bold"
          >
            {{ fields.title.value }}
            <br>
            <span class="text-sm font-light opacity-80">{{ fields.subTitle.value }}</span>
          </p>
          <svg
            class="-mt-[1px] w-full text-kimpton-action"
            version="1.2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 490 61"
          >
            <path
              fill="currentColor"
              class="bg-kimpton-action"
              d="m245 61l245-60.7h-490z"
            />
          </svg>
        </div>
      </div>
      <div class="hidden md:flex my-8 px-2 lg:px-4 justify-between flex-grow">
        <Icon name="mage:star" class="w-8 h-8" />
        <Icon name="hugeicons:surfboard" class="w-8 h-8" />
        <Icon name="ph:barbell-light" class="w-8 h-8" />
        <Icon name="ep:sunrise" class="w-8 h-8" />
      </div>
    </div>
    <div
      class="flex md:hidden my-4 w-full justify-evenly animate-fade-in duration-1000 ease-out"
      :class="{ 'opacity-0': !isVisible }"
    >
      <Icon name="ph:popsicle" class="w-8 h-8" />
      <Icon name="mynaui:wine" class="w-8 h-8" />
      <Icon name="la:swimming-pool" class="w-8 h-8" />
      <Icon name="akar-icons:wifi" class="w-8 h-8" />
      <Icon name="mage:star" class="w-8 h-8" />
      <Icon name="hugeicons:surfboard" class="w-8 h-8" />
      <Icon name="ph:barbell-light" class="w-8 h-8" />
      <Icon name="ep:sunrise" class="w-8 h-8" />
    </div>
    <div
      v-voix-visibility="{ callback: onVisibilityChange, threshold: [0, 0.25] }"
      class="flex flex-wrap justify-between"
    >
      <div
        class="md:w-1/2 lg:w-1/4 mx-2 md:mx-0 mt-6 prose animate-fade-in duration-1000 ease-out"
        :class="{ 'opacity-0': !isVisible }"
      >
        <h3 class="ml-8 my-0 py-0">
          {{ fields.colOneTitle.value }}
        </h3>
        <VoixWysiwyg :field="fields.colOneContent" />
      </div>
      <div
        class="md:w-1/2 lg:w-1/4 mx-2 md:mx-0 mt-6 prose animate-fade-in duration-1000 ease-out delay-100"
        :class="{ 'opacity-0': !isVisible }"
      >
        <h3 class="ml-8 my-0 py-0">
          {{ fields.colTwoTitle.value }}
        </h3>
        <VoixWysiwyg :field="fields.colTwoContent" />
      </div>
      <div
        class="md:w-1/2 lg:w-1/4 mx-2 md:mx-0 mt-6 prose animate-fade-in duration-1000 ease-out delay-500"
        :class="{ 'opacity-0': !isVisible }"
      >
        <h3 class="ml-8 my-0 py-0">
          {{ fields.colThreeTitle.value }}
        </h3>
        <VoixWysiwyg :field="fields.colThreeContent" />
      </div>
      <div
        class="md:w-1/2 lg:w-1/4 mx-2 md:mx-0 mt-6 prose animate-fade-in duration-1000 ease-out delay-1000"
        :class="{ 'opacity-0': !isVisible }"
      >
        <h3 class="ml-8 my-0 py-0">
          {{ fields.colFourTitle.value }}
        </h3>
        <VoixWysiwyg :field="fields.colFourContent" />
      </div>
    </div>
  </div>
</template>

<style  lang="scss" scoped>
::v-deep ul li p {
  display: inline;
}
</style>
