<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Skewed Three Lines', group: 'global', layouts: ['Blocks'] },
      tags: ['Text', 'Info'],
      fields: {
        lineOneLeft: {
          type: 'text',
          label: 'Line One Left',
          default: 'ENTER',
          name: 'lineOneLeft',
        },
        lineOneRight: {
          type: 'text',
          label: 'Line One Right',
          default: 'TO WIN!',
          name: 'lineOneRight',
        },
        lineTwo: {
          type: 'text',
          label: 'Line Two',
          default: '3 NIGHT STAY',
          name: 'lineTwo',
        },
        lineThree: {
          type: 'text',
          label: 'Line Three',
          default: 'for two guests',
          name: 'lineThree',
        },
      },
      slots: [],
    })
  },
}
</script>

<template>
  <div id="voix-slice-3573" class="flex justify-center title-paragraph relative" style="">
    <div
      class="py-8 p-8 md:p-12 container mx-auto text-center items-center flex flex-col relative z-10"
    >
      <div
        class="w-full lg:w-3/4 mb-8 titles flex flex-col items-center text-white"
        style="transform: skew(0deg, -8deg)"
      >
        <div class="text-5xl p-4 px-8" style="background-color: #16467a">
          {{ fields.lineOneLeft.value }}
          <span class="font-bold">{{ fields.lineOneRight.value }}</span>
        </div>
        <div class="text-7xl px-8 pb-6 font-bold" style="background-color: #3fc2cc">
          {{ fields.lineTwo.value }}
        </div>
        <div
          class="text-4xl pt-1 pb-2 px-8 -mt-5 font-bold"
          style="background-color: #8cc5e6"
        >
          {{ fields.lineThree.value }}
        </div>
      </div>
    </div>
  </div>
</template>
