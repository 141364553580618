<script setup lang="ts">
import dayjs from 'dayjs'

const salesData = ref({})
const mbrData = useCookie('mbrAgentData')

if (mbrData.value) {
  const { data } = await useFetch(
    `https://hpdc.hiltonbyplaya.com/agents-sales?iata=${mbrData.value?.IATANumber}&email=${mbrData.value?.Email}`,
  )

  salesData.value = data.value.data
}

const upcomingReservations = computed(() => {
  if (salesData.value?.Sales) {
    return salesData.value.Sales.filter((sale) => {
      return dayjs(sale.arrival).isAfter(dayjs())
    })
  }
  return []
})

const pastReservations = computed(() => {
  if (salesData.value?.Sales) {
    return salesData.value.Sales.filter((sale) => {
      return dayjs(sale.arrival).isBefore(dayjs())
    })
  }
  return []
})

function formatDate(date) {
  return dayjs(date).format('MM/DD/YYYY')
}

function goToHotelReserveSystem(sale) {
  window.open(
    `https://www.reservhotel.com/win/owa/IBE5_EXT.check_itin?hotel=${sale.hotel}&lang=1&conf=${sale.resnum}&lastname=${sale['lead pax last name']}`,
    '_blank',
  )
}

defineSlice({
  name: { label: 'Dashboard', group: 'Agents Portal', layouts: ['AgentCashPlus'] },
  preview: 'SlicesAgentsPortalDashboard.jpg',
  description: 'Agent Cash + Dashboard',
  fields: [],
  templates: [{ label: 'Dashboard', fields: [] }],
})
</script>

<template>
  <div class="max-w-[100vw] overflow-x-scroll">
    <div
      v-if="mbrData"
      class="bg-gradient-to-r from-[#012343] to-[#044e81] text-white p-16"
    >
      <div>
        <div class="text-4xl font-bold">
          Welcome {{ mbrData.FirstName }}
        </div>
        <div class="mt-2 font-light text-xl tracking-wide">
          IATA: {{ mbrData.IATANumber }}
        </div>
      </div>

      <div
        class="mt-16 flex flex-col md:flex-row justify-start items-start space-y-8 md:space-y-0 md:space-x-20"
      >
        <div class="flex flex-col items-start justify-start">
          <div class="text-xl">
            Total Bookings YTD
          </div>
          <div
            class="mt-4 flex justify-center items-center p-6 text-lg w-auto md:p-8 md:text-3xl lg:p-12 lg:text-5xl bg-white text-[#012343] font-black rounded-xl"
          >
            {{ salesData?.YTD?.agency_bookings }}
          </div>
        </div>
        <div class="flex flex-col items-start justify-start">
          <div class="text-xl">
            Total RN's YTD
          </div>
          <div
            class="mt-4 flex justify-center items-center p-6 text-lg w-auto md:p-8 md:text-3xl lg:p-12 lg:text-5xl bg-white text-[#012343] font-black rounded-xl"
          >
            {{ salesData?.YTD?.agency_room_nights }}
          </div>
        </div>
        <div class="flex flex-col items-start justify-start">
          <div class="text-xl">
            Total Booked Revenue ($) YTD
          </div>
          <div
            class="mt-4 flex justify-center items-center p-6 text-lg w-auto md:p-8 md:text-3xl lg:p-12 lg:text-5xl bg-white text-[#012343] font-black rounded-xl"
          >
            {{ salesData?.YTD?.agency_revenue_booked }}
          </div>
        </div>
        <div class="flex flex-col items-start justify-start">
          <div class="text-xl">
            Estimated Commission ($) YTD
          </div>
          <div
            class="mt-4 flex justify-center items-center p-6 text-lg w-auto md:p-8 md:text-3xl lg:p-12 lg:text-5xl bg-white text-[#012343] font-black rounded-xl"
          >
            {{ salesData?.YTD?.agency_commission }}
          </div>
        </div>
      </div>
    </div>

    <div class="p-4 lg:p-12">
      <div class="p-4 text-xl font-bold">
        Upcoming Reservations
      </div>
      <div class="max-w-[90vw] overflow-x-scroll">
        <table class="w-full text-sm min-w-[1000px]">
          <tbody>
            <tr>
              <th
                class="px-4 py-3 bg-gray-200 text-gray-800 font-bold text-left"
              >
                Status
              </th>
              <th
                class="px-4 py-3 bg-gray-200 text-gray-800 font-bold text-left"
              >
                Book Date
              </th>
              <th
                class="px-4 py-3 bg-gray-200 text-gray-800 font-bold text-left"
              >
                Reservation #
              </th>
              <th
                class="px-4 py-3 bg-gray-200 text-gray-800 font-bold text-left"
              >
                Resort
              </th>
              <th
                class="px-4 py-3 bg-gray-200 text-gray-800 font-bold text-left"
              >
                Guest Name
              </th>
              <th
                class="px-4 py-3 bg-gray-200 text-gray-800 font-bold text-left"
              >
                Arrival Date
              </th>
              <th
                class="px-4 py-3 bg-gray-200 text-gray-800 font-bold text-left"
              >
                Revenue ($)
              </th>
              <th
                class="px-4 py-3 bg-gray-200 text-gray-800 font-bold text-left"
              >
                Est. Commission ($)
              </th>
              <th
                class="py-3 bg-gray-200 text-gray-800 font-bold text-left"
              >
                          &nbsp;
              </th>
            </tr>
            <tr v-for="(sale, key) in upcomingReservations" :key="key">
              <td
                class="px-4 py-4 text-gray-600 border-b border-gray-300"
              >
                <svg
                  v-if="sale.status === 'Cancelled'"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 text-red-500"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                  />
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 text-green-500"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </td>
              <td
                class="px-4 py-4 text-gray-600 border-b border-gray-300"
              >
                {{ formatDate(sale.booking_date) }}
              </td>
              <td
                class="px-4 py-4 text-gray-600 border-b border-gray-300"
              >
                {{ sale.resnum }}
              </td>
              <td
                class="px-4 py-4 text-gray-600 border-b border-gray-300"
              >
                {{ sale.hotel_name }}
              </td>
              <td
                class="px-4 py-4 text-gray-600 border-b border-gray-300 capitalize"
              >
                {{ sale['lead pax first name'].toLowerCase() }}
                {{ sale['lead pax last name'].toLowerCase() }}
              </td>
              <td
                class="px-4 py-4 text-gray-600 border-b border-gray-300"
              >
                {{ formatDate(sale.arrival) }}
              </td>
              <td
                class="px-4 py-4 text-gray-600 border-b border-gray-300"
              >
                ${{ sale.hotel_total }}
              </td>
              <td
                class="px-4 py-4 text-gray-600 border-b border-gray-300"
              >
                ${{ sale.commission_total }}
              </td>
              <td
                class="py-4 text-gray-600 border-b border-gray-300 cursor-pointer"
                @click="goToHotelReserveSystem(sale)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                  />
                </svg>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="p-4 lg:p-12">
      <div class="p-4 text-xl font-bold">
        Stayed Reservations
      </div>
      <div class="max-w-[90vw] overflow-x-scroll">
        <table class="w-full text-sm min-w-[1000px]">
          <tbody>
            <tr>
              <th
                class="px-4 py-3 bg-gray-200 text-gray-800 font-bold text-left"
              >
                Status
              </th>
              <th
                class="px-4 py-3 bg-gray-200 text-gray-800 font-bold text-left"
              >
                Book Date
              </th>
              <th
                class="px-4 py-3 bg-gray-200 text-gray-800 font-bold text-left"
              >
                Reservation #
              </th>
              <th
                class="px-4 py-3 bg-gray-200 text-gray-800 font-bold text-left"
              >
                Resort
              </th>
              <th
                class="px-4 py-3 bg-gray-200 text-gray-800 font-bold text-left"
              >
                Guest Name
              </th>
              <th
                class="px-4 py-3 bg-gray-200 text-gray-800 font-bold text-left"
              >
                Arrival Date
              </th>
              <th
                class="px-4 py-3 bg-gray-200 text-gray-800 font-bold text-left"
              >
                Revenue ($)
              </th>
              <th
                class="px-4 py-3 bg-gray-200 text-gray-800 font-bold text-left"
              >
                Est. Commission ($)
              </th>
              <th
                class="py-3 bg-gray-200 text-gray-800 font-bold text-left"
              >
                          &nbsp;
              </th>
            </tr>
            <tr v-for="(sale, key) in pastReservations" :key="key">
              <td
                class="px-4 py-4 text-gray-600 border-b border-gray-300"
              >
                <svg
                  v-if="sale.status === 'Cancelled'"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 text-red-500"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                  />
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-5 h-5 text-green-700"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </td>
              <td
                class="px-4 py-4 text-gray-600 border-b border-gray-300"
              >
                {{ formatDate(sale.booking_date) }}
              </td>
              <td
                class="px-4 py-4 text-gray-600 border-b border-gray-300"
              >
                {{ sale.resnum }}
              </td>
              <td
                class="px-4 py-4 text-gray-600 border-b border-gray-300"
              >
                {{ sale.hotel_name }}
              </td>
              <td
                class="px-4 py-4 text-gray-600 border-b border-gray-300 capitalize"
              >
                {{ sale['lead pax first name'].toLowerCase() }}
                {{ sale['lead pax last name'].toLowerCase() }}
              </td>
              <td
                class="px-4 py-4 text-gray-600 border-b border-gray-300"
              >
                {{ formatDate(sale.arrival) }}
              </td>
              <td
                class="px-4 py-4 text-gray-600 border-b border-gray-300"
              >
                ${{ sale.hotel_total }}
              </td>
              <td
                class="px-4 py-4 text-gray-600 border-b border-gray-300"
              >
                ${{ sale.commission_total }}
              </td>
              <td
                class="py-4 text-gray-600 border-b border-gray-300 cursor-pointer"
                @click="goToHotelReserveSystem(sale)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  class="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                  />
                </svg>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
