<script setup lang="ts">
import type { SliceInterface, VoixLinkFieldInterface, VoixMediaFieldInterface, VoixSelectFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

import type { Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination } from 'swiper/modules'
import Modal from '@/components/utilities/Modal.vue'

import 'swiper/css'

const props = defineProps<{
  fields: {
    media: VoixMediaFieldInterface
    title: VoixTextFieldInterface
    copy: VoixWysiwygFieldInterface
    link: VoixLinkFieldInterface
    background: VoixTextFieldInterface
    backgroundPosition: VoixSelectFieldInterface
  }
  slice: SliceInterface
}>()

defineSlice({
  name: { label: 'Features Block', group: 'Bonita Feature', layouts: ['Bonita'] },
  slots: [
    { name: 'default', allowedElements: ['SlicesBonitaFeature'] },
    { name: 'gallery', allowedElements: ['SlicesBonitaFeatureGalleryImage'] },
  ],
  description: 'A features block',
  preview: 'SlicesBonitaFeaturesBlock.jpg',
  fields: {
    media: {
      label: 'Media',
      type: 'media',
      breakpoints: {
        default: {
          width: 640,
          height: 640,
        },
      },
    },

    title: { label: 'Title', type: 'text' },
    copy: { label: 'Copy', type: 'wysiwyg' },
    link: { label: 'Link', type: 'link' },
  },
  templates: [{
    label: 'Main Example',
    fields: {
      media: { value: 'https://images.unsplash.com/photo-1448375010925-a75a42ceaffa?q=80&w=1705&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
      title: { value: 'Title' },
      copy: { value: '<p>Copy</p>' },
      link: { value: { text: 'Book Now', url: '#' } },
    },
  }],
})

// Templatizer Stuff
const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
const templatizerFeatures: any = [
  { feature: { value: 'Feature 1' } },
  { feature: { value: 'Feature 2' } },
  { feature: { value: 'Feature 3' } },
  { feature: { value: 'Feature 4' } },
  { feature: { value: 'Feature 5' } },
]
const templatizerGallery: any = [
  { media: { value: 'https://plus.unsplash.com/premium_photo-1670044658988-fca844bce315?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' } },
  { media: { value: 'https://images.unsplash.com/photo-1719813916163-ada504fd9660?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' } },
  { media: { value: 'https://images.unsplash.com/photo-1695128782705-e8a6a49ea5ed?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' } },
]

// Gallery Stuff
const showGallery = ref(false)
const gallerySubSlices = props.slice.elements?.filter((element: any) => element.slot === 'gallery') as SliceInterface[]

let numberOfSlides = gallerySubSlices.length
if (numberOfSlides === 0 && isVoixTemplatizer) {
  numberOfSlides = templatizerGallery.length
}

const swiperRef: Ref<SwiperType | null> = ref(null)
function setSwiperRef(swiper: SwiperType) {
  swiperRef.value = swiper
}

function next() {
  if (swiperRef.value)
    swiperRef.value.slideNext()
}

function prev() {
  if (swiperRef.value)
    swiperRef.value.slidePrev()
}
</script>

<template>
  <div class="relative py-24 px-8 bg-bonita-secondary">
    <div class="relative z-10">
      <div class="max-w-[1200px] mx-auto grid lg:grid-cols-12 lg:gap-16">
        <div
          class="lg:col-span-6 flex mt-12 lg:mt-0"
        >
          <div class="text-bonita-inverse w-full">
            <div class="text-3xl font-bonita-title mb-6">
              {{ fields.title.value }}
            </div>
            <div class="prose mb-3 prose-p:text-bonita-inverse" v-html="fields.copy.value" />

            <div class="flex flex-col border-t border-bonita-inverse">
              <slot />
              <template v-if="isVoixTemplatizer">
                <SlicesBonitaFeature
                  v-for="(feature, key) in templatizerFeatures" :key="key" class="py-1.5 border-b border-bonita-inverse text-sm" :fields="feature"
                />
              </template>
            </div>

            <VoixLink :field="fields.link" class="inline-block mt-4 text-xs uppercase tracking-widest bg-white py-3 px-12 text-bonita-primary" />
          </div>
        </div>
        <div class="relative row-start-1 lg:col-start-7 lg:col-span-6">
          <VoixMedia :field="fields.media" class="w-full h-full object-cover" />

          <button class="absolute top-4 right-4 bg-bonita-action text-bonita-inverse rounded-full w-8 h-8 flex justify-center items-center outline-bonita-inverse" @click="showGallery = true">
            <Icon name="heroicons:camera-20-solid" class="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>

    <ClientOnly>
      <Teleport to="#portal-destination">
        <Modal
          v-if="showGallery"
          key="features-gallery"
          @close="showGallery = false"
        >
          <div class="absolute inset-0 p-8 bg-bonita-primary w-full h-full ">
            <div class="aspect-video">
              <Swiper id="swiper" :modules="[Navigation, Pagination]" :slides-per-view="1" :space-between="0" class="border-4 border-white " @swiper="setSwiperRef">
                <SwiperSlide v-for="slice in gallerySubSlices" :key="slice.id">
                  <VoixSlice :slice="slice" />
                </SwiperSlide>
                <template v-if="isVoixTemplatizer">
                  <SwiperSlide v-for="(slide, key) in templatizerGallery" :key="key">
                    <SlicesBonitaFeatureGalleryImage :fields="slide" />
                  </SwiperSlide>
                </template>
              </Swiper>
            </div>
            <div class="aspect-[4/3] absolute top-0 left-0 right-0">
              <div class="absolute inset-0 ">
                <div
                  v-if="numberOfSlides > 1"
                  class="absolute z-10 inset-0 pointer-events-none flex justify-between items-center p-4 px-8"
                >
                  <button class="pointer-events-auto" @click="prev">
                    <Icon class="w-16 h-16 text-gray-200" name="heroicons:chevron-left-20-solid" />
                  </button>
                  <button class="pointer-events-auto" @click="next">
                    <Icon class="w-16 h-16 text-gray-200" name="heroicons:chevron-right-20-solid" />
                  </button>
                </div>
                <div
                  v-if="numberOfSlides > 1"
                  class="absolute z-20 inset-0 flex justify-center items-end p-8 pointer-events-none"
                >
                  <div class="flex items-center space-x-2 pointer-events-auto">
                    <template
                      v-for="index in numberOfSlides"
                      :key="index"
                    >
                      <button
                        class=" rounded-full border border-white opacity-50 hover:opacity-100 duration-200"
                        :class="{
                          'bg-white border-0 w-1.5 h-1.5': swiperRef?.activeIndex !== index - 1,
                          'border-2 w-2 h-2': swiperRef?.activeIndex === index - 1,
                        }"
                        @click="swiperRef?.slideTo(index - 1)"
                      />
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </Teleport>
    </ClientOnly>
  </div>
</template>
