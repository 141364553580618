<script lang="ts" setup>
import { computed } from 'vue'
import { format } from 'date-fns'

import BrandLogo from '../logos/BrandLogo.vue'

const props = defineProps({
  mainColor: {
    type: String,
    default: '#c93161ff',
  },
  accentColor: {
    type: String,
    default: '#85235eff',
  },
  title: {
    type: String,
    required: true,
  },
  isAdultsOnly: {
    type: Boolean,
    required: true,
  },
  photo: {
    type: String,
    required: true,
  },
  logo: {
    type: String,
    required: false,
  },
  description: {
    type: String,
    required: true,
  },
  expirationDate: {
    type: String,
    required: true,
  },
})

const expirationDate = computed(() => {
  return format(new Date(props.expirationDate), 'MMMM d, yyyy')
})
</script>

<template>
  <div
    class="offer-card"
    :style="{
      backgroundColor: props.mainColor,
    }"
  >
    <div class="photo" :style="{ backgroundImage: `url(${props.photo})` }">
      <div
        class="age-group"
        :style="{
          backgroundColor: props.accentColor,
        }"
      >
        {{ props.isAdultsOnly ? 'Adults Only' : 'All Ages' }}
      </div>
    </div>

    <div class="description">
      <div v-if="logo" class="logo">
        <BrandLogo :logo="logo" class="fill-current text-white w-[200px]" :fill="false" />
      </div>

      <h4>{{ props.title }}</h4>

      <div class="offer-description text-white">
        <div v-html="props.description" />
      </div>

      <p class="font-medium mt-4">
        <span class="font-medium">&checkmark;</span>Book Direct & Receive*:
      </p>

      <!-- For text like: "Save Up to 20% off nightly rates -->
      <div class="before-footer">
        <slot name="before-footer" />
      </div>

      <div class="footer my-8">
        <button
          :style="{
            backgroundColor: props.accentColor,
          }"
          class="py-2 px-8 text-white uppercase font-bold"
        >
          Book Now
        </button>

        <br>

        <div class="text-center text-xs mt-3">
          <p>TERMS & CONDITIONS</p>
          <p>Offer Expires: {{ expirationDate }}</p>
        </div>
      </div>

      <div class="" />
    </div>
  </div>
</template>

<style scoped>
.offer-card {
  @apply relative pt-10 pb-16 mt-[300px] flex flex-col xl:flex-row xl:mt-0;

  .photo {
    @apply bg-cover bg-center bg-no-repeat absolute xl:relative;
    @apply w-[92%] left-[4%] -top-[250px] h-[400px];

    @screen xl {
      @apply w-[55%] h-auto -ml-[6%] left-[unset] top-[unset];
    }

    .age-group {
      @apply w-10/12 mx-auto -mt-5 text-xl text-white text-center uppercase font-extralight py-2 tracking-wider;

      @screen xl {
        @apply absolute w-full mt-0 mx-0 -top-4 right-16;
      }
    }
  }

  .description {
    @apply px-4 xs:px-8 sm:px-10 pt-40 text-white font-extralight;

    @screen xl {
      @apply py-16 pl-12 pr-24 w-[70%];
    }

    .logo {
      @apply mb-10;
    }

    h4 {
      @apply mb-8 text-xl md:text-3xl tracking-widest font-thin;
    }

    p.offer-description {
      @apply max-md:text-sm;
    }

    ul {
      @apply list-disc pl-5 mt-4;
    }

    .footer {
      @apply w-max;

      .btn {
        @apply font-light uppercase px-14 md:px-[4.5rem];
      }

      p:nth-child(2) {
        @apply font-bold;
      }
    }
  }
}
</style>
