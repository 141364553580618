<script>
import { usePageStore } from '@voix/store/pageStore'
import { SwiperSlide } from 'swiper/vue'

export default {
  components: {
    SwiperSlide,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign(
        {},
        { fields: this.fields, page: this.currentPage, slice: this.slice },
      )
    },
  },
  mounted() {
    defineSlice({
      slots: [{ name: 'default' }],
      description:
        'More blocky layouts with an image flanking a title, subtitle, description, and sub-slices.',
      fields: {
        title: { type: 'text', label: 'Title', editorLabel: true },
        subtitle: { type: 'text', label: 'Sub-Title' },
        description: { type: 'wysiwyg', label: 'Description' },
        imagesOnLeft: {
          type: 'checkbox',
          label: 'Images on Left?',
          instructions: '"Images on right by default"',
          default: false,
        },
        image1: {
          type: 'media',
          label: 'Image 1',
          breakpoints: {
            lg: { width: 631, height: 630 },
            md: { width: 400, height: 400 },
            sm: { width: 250, height: 250 },
          },
        },
        image2: {
          type: 'media',
          label: 'Image 2',
          breakpoints: {
            lg: { width: 631, height: 630 },
            md: { width: 400, height: 400 },
            sm: { width: 250, height: 250 },
          },
        },
        image3: {
          type: 'media',
          label: 'Image 3',
          breakpoints: {
            lg: { width: 631, height: 630 },
            md: { width: 400, height: 400 },
            sm: { width: 250, height: 250 },
          },
        },
        image4: {
          type: 'media',
          label: 'Image 4',
          breakpoints: {
            lg: { width: 631, height: 630 },
            md: { width: 400, height: 400 },
            sm: { width: 250, height: 250 },
          },
        },
        link: { type: 'link', label: 'Link' },
      },
      name: { label: 'Left Right Half Block', group: 'Hyatt', layouts: ['HyattZilara'] },
      tags: ['Content', 'Wysiwyg'],
      templates: [
        {
          label: 'Left Right Half Block',
          fields: {
            description: {
              value:
                '<p>Sink into divine relaxation in our unique, multi-step hydro circuit. Detoxify, rejuvenate, and reconnect mind, body, and soul as contrasting water temperatures soothe and stimulate your circulatory system. Inspiring private garden views will rekindle your inner peace.&nbsp;</p>',
            },
            image1: {
              value:
                'https://playa-cms-assets.s3.amazonaws.com/media/Sanctuary/2023/general/sanctuary-cap-cana-lifestyle-sanctuary-spa-hydrotherapy-3-copy.jpg',
            },
            image2: {
              value:
                'https://playa-cms-assets.s3.amazonaws.com/media/Sanctuary/2023/general/sanctuary-cap-cana-lifestyle-sanctuary-spa-hydrotherapy-3-copy.jpg',
            },
            title: { value: 'HYDROTHERAPY JOURNEY' },
          },
        },
      ],
    })
  },
}
</script>

<template>
  <div class="flex justify-center md:px-8 relative overflow-hidden">
    <div
      class="container text-center flex flex-col md:flex-row"
      :class="{ 'md:flex-row-reverse': fields.imagesOnLeft.value === true }"
    >
      <div class="text-left md:w-1/2 p-8 relative z-10 self-center">
        <div class="relative z-10">
          <div class="text-3xl uppercase title font-light mb-1">
            {{ fields.title.value }}
          </div>
          <h2 v-if="fields.subtitle.value" class="font-thin mb-4">
            {{ fields.subtitle.value }}
          </h2>
          <div class="leading-loose" v-html="fields.description.value" />
          <div class="mt-8">
            <slot />
          </div>
        </div>
      </div>
      <div class="w-full h-full md:w-1/2 relative z-10 flex bg-red-500">
        <GeneralSwiper :devise="devise" :component="component">
          <SwiperSlide v-if="fields.image1.value?.length" class="h-full w-full">
            <VoixMedia
              :field="fields.image1"
              class="shadow-lg object-cover w-full h-full"
            />
          </SwiperSlide>
          <SwiperSlide v-if="fields.image2.value?.length" class="h-full w-full">
            <VoixMedia
              :field="fields.image2"
              class="shadow-lg object-cover w-full h-full"
            />
          </SwiperSlide>
          <SwiperSlide v-if="fields.image3.value?.length" class="h-full w-full">
            <VoixMedia
              :field="fields.image3"
              class="shadow-lg object-cover w-full h-full"
            />
          </SwiperSlide>
          <SwiperSlide v-if="fields.image4.value?.length" class="h-full w-full">
            <VoixMedia
              :field="fields.image4"
              class="shadow-lg object-cover w-full h-full"
            />
          </SwiperSlide>
          <template #previous>
            <IconsLeftArrow color="#fff" :w="50" :h="50" />
          </template>
          <template #next>
            <IconsRightArrow color="#fff" :w="50" :h="50" />
          </template>
        </GeneralSwiper>
      </div>
    </div>
  </div>
</template>
