<script setup lang="ts">
import type { VoixLinkFieldInterface, VoixMediaFieldInterface } from '@voix/types'
import type { PropType } from 'vue'

const props = defineProps({
  fields: {
    type: Object as PropType<{
      image: VoixMediaFieldInterface
      title: {
        value: string
      }
      subtitle: {
        value: string
      }
      copy: {
        value: string
      }
      link: VoixLinkFieldInterface
    }>,
    required: true,
  },
})
defineSlice({
  name: { label: 'Blocks Over Image', group: 'blocks', layouts: ['Blocks'] },
  tags: ['Media'],
  preview: 'SlicesBlocksBlockOverImage.jpg',
  fields: {
    image: {
      type: 'media',
      label: 'Image',
      breakpoints: {
        default: { width: 600 },
        md: { width: 800 },
        lg: { width: 1200 },
        xl: { width: 1600 },
      },
      name: 'image',
    },
    title: { type: 'text', label: 'Title', default: 'This is the title', name: 'title' },
    subtitle: { type: 'text', label: 'Subtitle', default: 'This is the subtitle', name: 'subtitle' },
    copy: { type: 'wysiwyg', label: 'Copy', default: 'This is the copy', name: 'copy' },
    link: { type: 'link', label: 'Link', name: 'link' },
  },
  slots: [],
})

const isVisible = ref(false)
function onVisibilityChange(entry: { intersectionRatio: number }) {
  if (entry.intersectionRatio * 10 > 1)
    isVisible.value = true
  else
    isVisible.value = false
}
</script>

<template>
  <section
    id="meetings-celebrations"
    v-voix-visibility="{
      callback: onVisibilityChange, threshold: [0, 0.25],
    }"
    class="pb-20 pt-10"
  >
    <div class="relative lg:pt-24">
      <div
        class="lg:absolute lg:top-0 lg:right-0 lg:-mt-16  px-6 py-12 bg-white w-full lg:w-[75%] transition-all duration-1000 ease-out"
        :class="{
          '-translate-y-24 opacity-0': !isVisible,
          'translate-y-0 opacity-100': isVisible,
        }"
      >
        <div class="lg:w-1/2">
          <h3 class="font-extralight tracking-widest leading-tight text-gray-500">
            {{ fields.title.value }}
          </h3>
          <h4 class="font-thin text-2xl tracking-widest leading-tight text-gray-600 my-8">
            {{ fields.subtitle.value }}
          </h4>
          <VoixWysiwyg :field="fields.copy" class="font-light text-sm text-gray-500 md:w-full mb-8" />
          <VoixLink
            v-if="fields.link.value"
            :field="fields.link" class="py-3 px-6 text-white uppercase bg-nonPhotoBlue"
          />
        </div>
      </div>
      <VoixMedia :field="fields.image" class="w-full" />
    </div>
  </section>
</template>
