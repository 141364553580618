<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    showIcons() {
      return {
        bath: this.fields.bath.value,
        wifi: this.fields.wifi.value,
        roomService: this.fields.roomService.value,
        bed: this.fields.bed.value,
        bar: this.fields.bar.value,
        tv: this.fields.tv.value,
        magicBox: this.fields.magicBox.value,
        robe: this.fields.robe.value,
        mirror: this.fields.mirror.value,
        bathroom: this.fields.bathroom.value,
        safe: this.fields.safe.value,
        patio: this.fields.patio.value,
        phone: this.fields.phone.value,
        coffee: this.fields.coffee.value,
        ac: this.fields.ac.value,
        hairdryer: this.fields.hairdryer.value,
        ironingBoard: this.fields.ironingBoard.value,
        alarm: this.fields.alarm.value,
        balcony: this.fields.balcony.value,
        radio: this.fields.radio.value,
        turndown: this.fields.turndown.value,
        bluetooth: this.fields.bluetooth.value,
        butler: this.fields.butler.value,
        liquor: this.fields.liquor.value,
        showers: this.fields.showers.value,
        slippers: this.fields.slippers.value,
        transfers: this.fields.transfers.value,
      }
    },
  },
  mounted() {
    defineSlice({
      description:
        'The stats of a given room. The view, Square ft, max occupancy, bedding',
      fields: {
        title: {
          type: 'text',
          label: 'Title',
          editorLabel: true,
          default: 'All Suites Include',
        },
        bath: { type: 'checkbox', label: 'Show Bath?', default: true },
        wifi: { type: 'checkbox', label: 'Show WiFi?', default: true },
        roomService: { type: 'checkbox', label: 'Show Room Service?', default: true },
        bed: { type: 'checkbox', label: 'Show Pillow?', default: true },
        bar: { type: 'checkbox', label: 'Show Bar?', default: true },
        tv: { type: 'checkbox', label: 'Show TV?', default: true },
        magicBox: { type: 'checkbox', label: 'Show Magic Box?', default: true },
        robe: { type: 'checkbox', label: 'Show Robe?', default: true },
        mirror: { type: 'checkbox', label: 'Show Mirror?', default: true },
        bathroom: { type: 'checkbox', label: 'Show Bathroom?', default: true },
        safe: { type: 'checkbox', label: 'Show Safe?', default: true },
        patio: { type: 'checkbox', label: 'Show Patio?', default: true },
        phone: { type: 'checkbox', label: 'Show Phone?', default: true },
        coffee: { type: 'checkbox', label: 'Show Coffee?', default: true },
        ac: { type: 'checkbox', label: 'Show AC?', default: true },
        hairdryer: { type: 'checkbox', label: 'Show Hair Dryer?', default: true },
        ironingBoard: { type: 'checkbox', label: 'Show Ironing Board?', default: true },
        alarm: { type: 'checkbox', label: 'Show Alarm?', default: true },
        balcony: { type: 'checkbox', label: 'Show Balcony?', default: true },
        radio: { type: 'checkbox', label: 'Show Radio?', default: true },
        turndown: { type: 'checkbox', label: 'Show Turndown?', default: true },
        bluetooth: { type: 'checkbox', label: 'Show Bluetooth?', default: true },
        butler: { type: 'checkbox', label: 'Show Butler?', default: true },
        liquor: { type: 'checkbox', label: 'Show Beer and Wine?', default: true },
        showers: { type: 'checkbox', label: 'Show Showers?', default: true },
        slippers: { type: 'checkbox', label: 'Show Slippers?', default: true },
        transfers: { type: 'checkbox', label: 'Show Transfers?', default: true },
      },
      name: { label: 'Amenaties', group: 'Hyatt', layouts: ['*'] },
      tags: ['Icons', 'Info'],
      templates: [{ label: 'Amenaties', fields: [] }],
    })
  },
}
</script>

<template>
  <div class="flex justify-center">
    <div class="container md:px-8">
      <h4 class="mb-4 px-2 z-10 uppercase text-center">
        {{ fields.title.value }}
      </h4>
      <amenaties
        :show-icons="showIcons"
        :margin-x="30"
        :margin-y="30"
        :icon-width="40"
        :icon-height="40"
      />
    </div>
  </div>
</template>
