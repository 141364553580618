<script setup lang="ts">

</script>

<template>
  <svg
    id="Layer_1" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 76.51 248.99" style="enable-background:new 0 0 76.51 248.99;" xml:space="preserve"
  >
    <g>
      <polygon fill="currentColor" points="0,0 0,248.99 38.45,219.78 76.51,248.99 76.51,0 	" />
    </g>
  </svg>
</template>
