<script setup lang="ts">
import type { SliceInterface, VoixCheckboxFieldInterface, VoixSelectFieldInterface, VoixTextFieldInterface } from '@voix/types'

import { usePageStore } from '@voix/store/pageStore'
import { resolveFieldsArray } from '@voix/composables/useField'

const props = defineProps({
  slice: {
    type: Object as PropType<SliceInterface>,
    required: true,
  },
  fields: {
    type: Object as PropType<{
      title: VoixTextFieldInterface
      titleAbove: VoixCheckboxFieldInterface
      pageSpecialSlice: VoixSelectFieldInterface
    }>,
    required: true,
  },
})

const selectedRegion = ref('')
const selectedBrand = ref('')
const sliceFieldsById = ref([])

const pageStore = usePageStore()
const currentPage = pageStore.currentPage

const { data: pageSpecials } = await useVoixAsyncData(
  `/api/playa/page-specials/${currentPage.id}/${props.slice.component}`,
)

// onCreated() {
//   this.$store.dispatch('pageStore/getCurrentPage')
// },

function groupBy(array, key) {
  const obj = {}

  // group the array of objects by the key and add them to obj
  array.forEach((item) => {
    const fields = item.fields
    let keyValue = null
    for (const itemKey in item) {
      if (itemKey === key)
        keyValue = item[itemKey].value
    }

    if (keyValue) {
      if (!Object.prototype.hasOwnProperty.call(obj, keyValue))
        obj[keyValue] = []
      obj[keyValue].push(item)
    }
  })

  return obj
}

const filteredSlices = computed(() => {
  let specials = pageSpecials.value

  if (selectedRegion.value) {
    specials = specials.filter(
      special => special.country.value === selectedRegion.value,
    )
  }

  if (selectedBrand.value) {
    specials = specials.filter(
      special => special.brand.value === selectedBrand.value,
    )
  }

  return groupBy(specials, 'country')
})

const countries = computed(() => {
  return groupBy(pageSpecials.value, 'country')
})

const brands = computed(() => {
  return groupBy(pageSpecials.value, 'brand')
})

defineSlice({
  slots: [{ name: 'default' }],

  description: 'Specials filtered via resort data',
  database: true,
  fields: {
    image: { type: 'image', label: 'Image', name: 'Image' },
    resortLogo: { type: 'image', label: 'Resort Logo', name: 'Resort logo' },
    subtitle: { type: 'text', label: 'Subtitle', name: 'SubTitle' },
    content: { type: 'wysiwyg', label: 'Content', name: 'Content' },
    terms: { type: 'wysiwyg', label: 'Terms', name: 'Terms' },
    prettyEndDate: { type: 'text', label: 'Pretty End Date', name: 'Pretty End Date' },
  },
  name: { label: 'Special Offer Grid', group: 'Agents Portal', layouts: ['AgentCashPlus'] },
  tags: ['Specials'],
  templates: [{
    label: 'Special Offer Grid',
    fields: [],
  }],
})

if (import.meta.client) {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)

  const region = urlParams.get('region')?.toLowerCase()

  if (typeof region !== 'undefined' && region !== null) {
    const countryNames = Object.keys(countries.value)
    const lowercaseCountryNames = countryNames.map(name => name.toLowerCase())
    const countryNameIndex = lowercaseCountryNames.findIndex((r) => { return r === region.toLowerCase() })
    if (countryNameIndex > -1)
      selectedRegion.value = countryNames[countryNameIndex]
  }

  const brand = urlParams.get('brand')?.toLowerCase()
  if (typeof brand !== 'undefined' && brand !== null) {
    const brandNames = Object.keys(brands.value)
    const lowercaseBrandNames = brandNames.map(name => name.toLowerCase())
    const brandNameIndex = lowercaseBrandNames.findIndex((b) => { return b === brand.toLowerCase() })
    if (brandNameIndex > -1)
      selectedBrand.value = brandNames[brandNameIndex]
  }
}
</script>

<template>
  <div class="pb-8">
    <div class="flex flex-col lg:flex-row justify-center items-center space-x-0 lg:space-x-16 py-12">
      <div class="w-3/4 lg:w-auto my-2 relative">
        <select
          v-model="selectedRegion"
          class="appearance-none border-b border-gray-600 text-gray-800 focus:outline-none w-[200px]"
        >
          <option value="">
            REGION
          </option>
          <option
            v-for="(slices, region) in countries"
            :key="region"
            :value="region"
          >
            {{ region }}
          </option>
        </select>
      </div>
      <div class="w-3/4 lg:w-auto my-2 relative">
        <select
          v-model="selectedBrand"
          class="appearance-none border-b border-gray-600 text-gray-800 focus:outline-none w-[200px]"
        >
          <option value="">
            BRAND
          </option>
          <option
            v-for="(key, brand) in brands"
            :key="key"
            :value="brand"
          >
            {{ brand }}
          </option>
        </select>
      </div>
    </div>

    <div class="flex flex-col space-y-40">
      <div
        v-for="(countrySpecials, country) in filteredSlices"
        :key="country"
      >
        <div class="font-cursive-allison text-[72px] text-center pb-8">
          {{ country }}
        </div>
        <div class="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12 px-12 lg:px-32">
          <div v-for="special in countrySpecials" :key="special.id.value">
            <div class="flex flex-col space-y-8">
              <VoixMedia v-slot="slotProps" :field="special.image" background>
                <div
                  class="w-full min-h-[300px] bg-cover bg-center"
                  :style="{ backgroundImage: `url(${slotProps.image})` }"
                />
              </VoixMedia>
              <div class="w-full text-center mb-4">
                <VoixMedia :field="special.resortLogo" class="mx-auto lg:h-[100px] max-w-[75%] lg:max-w-[60%] max-h-[100px]" style="height:80px" />
                <div class="mt-4 font-bold text-2xl uppercase">
                  {{ special.subtitle.value }}
                </div>
                <div class="mt-1" v-html="special.content.value" />
                <div class="flex justify-center mb-4">
                  <PlayaBooking
                    v-if="
                      currentPage.slug != '/agent-offers'
                        && currentPage.slug != '/es/agent-offers'
                    // && !devise.hideBookNow
                    "
                  >
                    <button
                      class="inline-block mt-2 text-sm font-medium uppercase text-white bg-[#ff6d00] rounded-full px-8 py-3"
                    >
                      Book Now
                    </button>
                  </PlayaBooking>
                </div>

                <terms :terms="special.terms.value">
                  <button class="btn btn-text mb-2">
                    {{ $t('terms-and-conditions') }}
                  </button>
                </terms>
                <div v-if="special.prettyEndDate.value">
                  {{ $t('offer-expires') }}: {{ special.prettyEndDate.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
