<script>
import dayjs from 'dayjs'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    clock() {
      return {
        end_date: this.fields.date.value,
      }
    },
  },
  mounted() {
    defineSlice({
      name: {
        label: 'Clock',
        group: 'global',
        layouts: ['*'],
      },
      tags: ['Countdown'],
      fields: { title: { type: 'text', label: 'Title', default: 'Act Fast! You have a limited time!', enabled: true, name: 'title' }, date: { type: 'text', label: 'Date', settings: { date: true, time: true }, name: 'date' } },
      slots: [],
    })

    const today = dayjs(new Date())
    this.show = (this.clock.end_date && !dayjs(this.clock.end_date).isBefore(today))
  },
}
</script>

<template>
  <div v-if="show">
    <div class="py-8">
      <div v-if="fields.title.enabled" class="py-4 px-8 text-center">
        <h2 class="text-4xl mb-4">
          {{ fields.title.value }}
        </h2>
      </div>

      <flip-clock :clock="clock" />
    </div>
  </div>
</template>
