<script setup lang="ts">
import type { SliceInterface, VoixSelectFieldInterface } from '@voix/types'

import { usePageStore } from '@voix/store/pageStore'
import SlicesHyattSpecialOffer from '@/components/slices/hyatt/SlicesHyattSpecialOffer.vue'
import SlicesHyattTwentyTwentyTwoSpecialOffer from '@/components/slices/hyatt/TwentyTwentyTwo/SlicesHyattTwentyTwentyTwoSpecialOffer.vue'
import SlicesPlayaresortsSpecialOffersSpecialOfferHorizontal from '@/components/slices/playaresorts/special-offers/SlicesPlayaresortsSpecialOffersSpecialOfferHorizontal.vue'
import SlicesPlayaresortsSpecialOffersSpecialOfferCard from '@/components/slices/playaresorts/special-offers/SlicesPlayaresortsSpecialOffersSpecialOfferCard.vue'

const props = defineProps({
  slice: {
    type: Object as PropType<SliceInterface>,
    required: true,
  },
  fields: {
    type: Object as PropType<{
      pageSpecialSlice: VoixSelectFieldInterface
    }>,
    required: true,
  },
})

defineSlice({
  name: { label: 'Page Specials', group: 'containers', layouts: ['*'] },
  tags: ['Specials', 'Container'],
  fields: {
    pageSpecialSlice: {
      label: 'Page Special Slice',
      type: 'select',
      options: {
        SlicesHyattSpecialOffer: 'Hyatt Special Offer',
        SlicesHyattTwentyTwentyTwoSpecialOffer: 'Hyatt 2022 Special Offer',
        SlicesPlayaresortsSpecialOffersSpecialOfferHorizontal: 'Special Offer Horizontal',
        SlicesPlayaresortsSpecialOffersSpecialOfferCard: 'Special Offer Card',
      },
    },
  },
  slots: [],
})

const pageStore = usePageStore()
const currentPage = pageStore.currentPage
const { data: pageSpecials } = await useVoixAsyncData(
  `/api/playa/page-specials/${currentPage.id}/${props.fields.pageSpecialSlice.value}`,
)

const specialSlice = computed(() => {
  switch (props.fields.pageSpecialSlice.value) {
    case 'SlicesHyattSpecialOffer':
      return SlicesHyattSpecialOffer
      break
    case 'SlicesHyattTwentyTwentyTwoSpecialOffer':
      return SlicesHyattTwentyTwentyTwoSpecialOffer
      break
    case 'SlicesPlayaresortsSpecialOffersSpecialOfferHorizontal':
      return SlicesPlayaresortsSpecialOffersSpecialOfferHorizontal
      break
    case 'SlicesPlayaresortsSpecialOffersSpecialOfferCard':
      return SlicesPlayaresortsSpecialOffersSpecialOfferCard
      break
  }
  return null
})
</script>

<template>
  <div>
    <div v-for="special in pageSpecials">
      <component :is="specialSlice" :fields="special" />
    </div>
  </div>
</template>
