<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      preview: 'SlicesWeddingsVenueSlide.jpg',
      fields: {
        image: {
          type: 'media',
          label: 'Image',
          breakpoints: {
            lg: {
              width: 1000,
              height: 550,
            },
            default: {
              width: 500,
              height: 275,
            },
          },
        },
        name: {
          type: 'text',
          label: 'Venue Name',
          default: 'Beach Gazebo',
        },
        receptionAmnt: {
          type: 'text',
          label: 'Reception Amount',
          default: '600',
        },
        ceremonyAmnt: {
          type: 'text',
          label: 'Ceremony Amount',
          default: '600',
        },
        cocktailAmnt: {
          type: 'text',
          label: 'Cocktail Amount',
          default: '600',
        },
      },
      name: { label: 'Weddings Venue Slide', group: 'Weddings', layouts: ['*'] },
      tags: ['Slide'],
      templates: [],
    })
  },
}
</script>

<template>
  <div
    class="flex flex-col justify-between relative mb-10 md:mb-0 pb-8 w-full px-4 md:px-12"
  >
    <div>
      <div class="relative overflow-hidden">
        <VoixMedia v-slot="slotProps" :field="fields.image" background>
          <div
            :style="{ backgroundImage: `url(${slotProps.image})` }"
            class="bg-cover bg-center relative h-64"
            style="min-height: 500px"
          />
        </VoixMedia>
      </div>

      <div class="flex justify-between py-6">
        <div class="text-xl uppercase my-4 text-left">
          <span class="font-bold">{{ $t("venue") }}:</span><br>
          {{ fields.name?.value }}
        </div>
        <div class="flex">
          <div class="text-center text-sm mx-2">
            <icon-wedding-reception :w="40" :h="40" />
            {{ $t("reception") }}<br>{{ fields.receptionAmnt?.value }}
            {{ $t("guests") }}
          </div>
          <div class="text-center text-sm mx-2">
            <icon-wedding-ceremony :w="40" :h="40" />
            {{ $t("ceremony") }} <br>{{ fields.ceremonyAmnt?.value }} {{ $t("guests") }}
          </div>
          <div class="text-center text-sm mx-2">
            <icon-wedding-cocktail :w="40" :h="40" />
            Cocktail <br>{{ fields.cocktailAmnt?.value }} {{ $t("guests") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
