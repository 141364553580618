<script setup lang="ts">
defineProps<{
  fields: {
    title: VoixTextFieldInterface
    description: VoixTextareaFieldInterface
    link: VoixLinkFieldInterface
    imagesOnLeft: VoixCheckboxFieldInterface
    image1: VoixMediaFieldInterface
    image2: VoixMediaFieldInterface
    image3: VoixMediaFieldInterface
    image4: VoixMediaFieldInterface
    image5: VoixMediaFieldInterface
  }
}>()

const { $voix } = useNuxtApp()
const cardStyles = ref({})
function calculateCardStyles() {
  if (
    $voix.breakpoint.value === 'default'
    || $voix.breakpoint.value === 'sm'
    || $voix.breakpoint.value === 'md'
  ) {
    cardStyles.value = {
      width: '100%',
    }
  }
  else {
    cardStyles.value = {
      width: '300px',
      transform: 'translate(-50%, -50%)',
    }
  }
}

watch($voix.breakpoint, () => {
  calculateCardStyles()
})

defineSlice({
  slots: [{ name: 'default' }],
  description: 'Swooshy image flanking a title, subtitle, description and sub-slices',
  fields: {
    title: { type: 'text', label: 'Title', editorLabel: true },
    description: { type: 'wysiwyg', label: 'Description' },
    link: { type: 'link', label: 'Link', defaults: { enabled: true }, enabled: false },
    imagesOnLeft: {
      type: 'checkbox',
      label: 'Images on Left?',
      instructions: '"Images on right by default"',
      default: false,
    },
    image1: {
      type: 'media',
      label: 'Image 1',
      breakpoints: {
        default: { width: 251, height: 220 },
        md: { width: 401, height: 280 },
        lg: { width: 900, height: 420 },
      },
    },
    image2: {
      type: 'media',
      label: 'Image 2',
      breakpoints: {
        default: { width: 250, height: 220 },
        md: { width: 400, height: 280 },
        lg: { width: 900, height: 420 },
      },
    },
    image3: {
      type: 'media',
      label: 'Image 3',
      breakpoints: {
        default: { width: 250, height: 220 },
        md: { width: 400, height: 280 },
        lg: { width: 900, height: 420 },
      },
    },
    image4: {
      type: 'media',
      label: 'Image 4',
      breakpoints: {
        default: { width: 250, height: 220 },
        md: { width: 400, height: 280 },
        lg: { width: 900, height: 420 },
      },
    },
    image5: {
      type: 'media',
      label: 'Image 5',
      breakpoints: {
        default: { width: 250, height: 220 },
        md: { width: 400, height: 280 },
        lg: { width: 900, height: 420 },
      },
    },
  },
  name: {
    label: 'Card Over Images',
    group: 'Wyndham',
    layouts: ['Wyndham', 'WyndhamCancun', 'WyndhamPlaya'],
  },
  tags: ['Card', 'Image'],
  templates: [
    {
      label: 'Card Over Images',
      fields: {
        description: {
          value:
            '<p><strong>Opening Soon<\/strong><br>Explore a hidden wonder within the Dominican Republic at Wyndham Alltra Samana. Located on a secluded beach, our resort is a paradisiacal escape unlike any other, offering a serene and relaxing experience for guests of all ages. Minutes away from natural preserves, cascading waterfalls, exotic beaches, remote islands and fascinating wildlife, guests are invited to reconnect with themselves and the world around them.<\/p>',
        },
        image1: {
          value:
            'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/alltra_samana\/ttd\/wyndham-alltra-samana-pool-02.jpg',
        },
        image2: {
          value:
            'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/alltra_samana\/ttd\/wyndham-alltra-samana-pool-02.jpg',
        },
        link: {
          value: {
            text: 'Learn More',
            target: '_self',
            relationship: null,
            href: '\/new-page',
          },
        },
        title: { value: 'Wyndham Alltra Samana' },
      },
    },
  ],
})

onMounted(() => {
  calculateCardStyles()
})
</script>

<template>
  <div class="card-over-images flex justify-center my-6 bg-grey-lightest">
    <div class="w-full" style="max-width: 1600px">
      <div
        class="relative flex flex-col-reverse justify-end p-0 lg:p-8 overflow-hidden w-full"
        :class="{
          'lg:flex-row-reverse': fields.imagesOnLeft.value,
          'lg:flex-row': !fields.imagesOnLeft.value,
        }"
      >
        <div class="flex items-center">
          <div class="relative">
            <div
              class="lg:absolute bg-abs-white z-20 text-center lg:text-left w-full px-6 py-8"
              :style="cardStyles"
            >
              <h2 class="font-light mb-2 text-3xl bold title text-grey-dark">
                {{ fields.title.value }}
              </h2>

              <div class="leading-loose description" v-html="fields.description.value" />

              <div class="flex justify-center lg:justify-start">
                <a
                  v-if="fields.link.enabled"
                  :href="fields.link.value?.href"
                  class="leading-loose link flex items-center mt-4"
                >
                  {{ fields.link.value?.text }}
                  <svg
                    class="w-3 h-3"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>

              <div class="mt-8">
                <slot />
              </div>
            </div>
          </div>
        </div>

        <div
          class="w-full lg:w-4/5 relative z-10 overflow-hidden"
          :class="{
            'lg:pl-8': fields.imagesOnLeft.value,
            'lg:pr-8': !fields.imagesOnLeft.value,
          }"
        >
          <GeneralSwiper>
            <template #previous>
              <Icon color="#fff" class="w-8 h-8 ml-4" name="heroicons:arrow-left" />
            </template>
            <template #next>
              <Icon color="#fff" class="w-8 h-8 mr-4" name="heroicons:arrow-right" />
            </template>

            <VoixMedia
              v-if="fields.image1.value?.length"
              :field="fields.image1"
              height="420"
              class="w-full"
            />

            <VoixMedia
              v-if="fields.image2.value?.length"
              :field="fields.image2"
              height="420"
              class="w-full"
            />

            <VoixMedia
              v-if="fields.image3.value?.length"
              :field="fields.image3"
              height="420"
              class="w-full"
            />

            <VoixMedia
              v-if="fields.image4.value?.length"
              :field="fields.image4"
              height="420"
              class="w-full"
            />

            <VoixMedia
              v-if="fields.image5.value?.length"
              :field="fields.image5"
              height="420"
              class="w-full"
            />
          </GeneralSwiper>
        </div>
      </div>
    </div>
  </div>
</template>
