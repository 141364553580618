<script setup lang="ts">
import { computed, ref } from 'vue'
import { usePageStore } from '@voix/store/pageStore'

const pageStore = usePageStore()
const currentPage = pageStore.currentPage

const inIframe = computed(() => {
  try {
    return window.self !== window.top
  }
  catch (e) {
    return true
  }
})

const target = computed(() => {
  return inIframe.value ? '_blank' : '_self'
})

function bgColor(i) {
  return i % 2 ? 'white' : ''
}

const url = ref(`/api/press-releases?lang=${currentPage?.language_code}`)

if (currentPage.path.includes('press-releases-work-zone'))
  url.value = `/api/press-releases?lang=${currentPage?.language_code}&take=4`

const { data: pressReleases } = await $voixNuxtApi(url.value)

defineSlice({
  name: {
    label: 'Press Releases List',
    group: 'playaresorts',
    layouts: ['Blocks', 'NoChrome'],
  },
  tags: ['List'],
  slots: [],
})
</script>

<template>
  <div
    v-for="(pressRelease, i) in pressReleases"
    :key="i"
    class="flex justify-center"
    :style="{ 'background-color': bgColor(i) }"
  >
    <div class="p-4 lg:p-8 px-4 container text-center flex flex-row">
      <div class="w-1/5">
        <img
          :src="pressRelease.thumbnail"
          class="relative bottom left right sp0"
          :alt="`${pressRelease.title} featured image`"
        >
      </div>
      <div class="w-4/5 pl-8 text-left">
        <div v-if="pressRelease.pretty_date" class="mb-2">
          {{ pressRelease.pretty_date }}
        </div>
        <div class="text-xl lg:text-2xl text-3xl uppercase title mb-2 font-light">
          {{ pressRelease.title }}
        </div>
        <div class="pb-6">
          <p>{{ pressRelease.short_description }}</p>
        </div>
        <p class="pt-0 lg:pt-6">
          <a
            v-if="pressRelease.pdf"
            :href="pressRelease.pdf_file"
            class="btn raised mr sp20"
            target="_blank"
          >View PDF</a>

          <a
            v-if="pressRelease.language_id === 1"
            :href="`/press-releases/${pressRelease.slug}`"
            :target="target"
            class="btn"
          >Read More</a>
          <a
            v-if="pressRelease.language_id === 2"
            :href="`/es/prensa/${pressRelease.slug}`"
            :target="target"
            class="btn"
          >Read More</a>
        </p>
      </div>
    </div>
  </div>
</template>
