<script setup lang="ts">
import { computed, ref } from 'vue'
import { usePageStore } from '@voix/store/pageStore'
import IconsDownTriangle from '~/components/icons/IconsDownTriangle.vue'
import IconsCaberet from '~/components/icons/IconsCaberet.vue'
import IconsUShape from '~/components/icons/IconsUShape.vue'
import IconsTheater from '~/components/icons/IconsTheater.vue'
import IconsBoardRoom from '~/components/icons/IconsBoardRoom.vue'
import IconsReception from '~/components/icons/IconsReception.vue'
import IconsClassroom from '~/components/icons/IconsClassroom.vue'

const currentPage = computed(() => {
  const pageStore = usePageStore()
  return pageStore.currentPage
})

const showOptions = ref(false)
const currentResort = ref(null)

const resorts = await $voixFetch(`/api/resorts/group-details`)

function selectResort(resort: any) {
  currentResort.value = resort
  showOptions.value = false
}

function isEven(i: number) {
  return i % 2
}

if (resorts && resorts?.length > 0)
  currentResort.value = resorts[0]

defineSlice({
  name: {
    label: 'Meeting Facilities Explorer',
    group: 'playaresorts',
    layouts: ['HyattZilara', 'HyattZiva'],
  },
  tags: ['Content'],
  slots: [],
})
</script>

<template>
  <div v-if="currentResort">
    <div class="flex justify-center bg-playa-action">
      <div class="py-4 md:px-16 container text-center flex flex-col items-center">
        <p
          class="text-white font-normal cursor-pointer"
          @click="showOptions = !showOptions"
        >
          <template v-if="resorts && resorts.length > 1">
            Explore Facilities:
            <span class="font-light ml-4">
              {{ currentResort.name }}
              <IconsDownTriangle color="#fff" :w="15" :h="15" class="inline" />
            </span>
          </template>
          <template v-else>
            Explore {{ currentResort.name }} Facilities
          </template>
        </p>
      </div>
    </div>
    <transition v-if="resorts && resorts.length > 1" name="dvs-fade">
      <div
        v-if="showOptions"
        class="flex justify-center bg-playa-action-soft absolute bottom-0ottom left-0eft w-full z-100"
      >
        <div class="py-4 md:px-16 container text-center flex flex-col items-center">
          <div class="mt-2">
            <p
              v-for="resort in resorts"
              :key="resort.id"
              class="text-white font-light cursor-pointer my-2"
              @click="selectResort(resort)"
            >
              {{ resort.name }}
            </p>
          </div>
        </div>
      </div>
    </transition>
    <div class="flex justify-center">
      <div class="py-8 px-8 container text-center flex flex-col md:flex-row">
        <div
          v-if="currentResort.group_images && currentResort.group_images.length > 0"
          class="md:w-1/2 pr-4"
          style="height: 320px"
        >
          <img
            :src="currentResort.group_images[0].defaultImage"
            :alt="currentResort.name"
            class="slither-lazy w-full"
          >
        </div>
        <div class="md:w-1/2 text-left">
          <h2 class="font-light mb-4">
            {{ currentResort.name }}
          </h2>
          <p
            v-if="currentResort.group_description"
            v-html="currentResort.group_description"
          />
        </div>
      </div>
    </div>
    <div
      v-if="
        currentResort.stats && currentResort.stats.length > 0 && currentPage.site_id !== 8
      "
      class="flex justify-center pb-8"
    >
      <div class="py-8 px-10 container items-center text-center overflow-x-scroll">
        <h3 class="mb-8 run-through">
          Technical Specs
        </h3>
        <table class="w-full text-sm">
          <tbody>
            <tr>
              <th class="pb-4">
                Location
              </th>
              <th class="pb-4">
                {{ $t("empty") }}
                <IconsEmpty
                  class="block relative mx-auto"
                  :w="30"
                  :h="30"
                  color="#606f7b"
                />
              </th>
              <th class="pb-4">
                {{ $t("rounds") }}
                <IconsCaberet
                  class="block relative mx-auto"
                  :w="30"
                  :h="30"
                  color="#606f7b"
                />
              </th>
              <th class="pb-4">
                {{ $t("u-shape") }}
                <IconsUShape
                  class="block relative mx-auto"
                  :w="30"
                  :h="30"
                  color="#606f7b"
                />
              </th>
              <th class="pb-4">
                {{ $t("theater") }}
                <IconsTheater
                  class="block relative mx-auto"
                  :w="30"
                  :h="30"
                  color="#606f7b"
                />
              </th>
              <th class="pb-4">
                {{ $t("conference") }}
                <IconsBoardRoom
                  class="block relative mx-auto"
                  :w="30"
                  :h="30"
                  color="#606f7b"
                />
              </th>
              <th class="pb-4">
                {{ $t("classroom") }}
                <IconsClassroom
                  class="block relative mx-auto"
                  :w="30"
                  :h="30"
                  color="#606f7b"
                />
              </th>
              <th class="pb-4">
                {{ $t("reception") }}
                <IconsReception
                  class="block relative mx-auto"
                  :w="30"
                  :h="30"
                  color="#606f7b"
                />
              </th>
            </tr>
            <tr
              v-for="(stat, i) in currentResort.stats"
              :key="i"
              :class="{ 'bg-grey-lightest': !isEven(i) }"
            >
              <td class="p-2 text-left">
                {{ stat.name }}
              </td>
              <td class="p-2">
                {{ stat.empty != "-" ? `${stat.empty}sqft` : "N/A" }}
              </td>
              <td class="p-2">
                {{ stat.rounds > -1 ? stat.rounds : "N/A" }}
              </td>
              <td class="p-2">
                {{ stat.ushape > -1 ? stat.ushape : "N/A" }}
              </td>
              <td class="p-2">
                {{ stat.theater > -1 ? stat.theater : "N/A" }}
              </td>
              <td class="p-2">
                {{ stat.conference > -1 ? stat.conference : "N/A" }}
              </td>
              <td class="p-2">
                {{ stat.classroom > -1 ? stat.classroom : "N/A" }}
              </td>
              <td class="p-2">
                {{ stat.reception > -1 ? stat.reception : "N/A" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
