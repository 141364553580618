<script setup lang="ts">
import { usePageStore } from '@voix/store/pageStore'
import type {
  VoixMediaFieldInterface,
  VoixTextFieldInterface,
  VoixWysiwygFieldInterface,
} from '@voix/types'
import type { Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'

const props = defineProps<{
  fields: {
    image1: VoixMediaFieldInterface
    image2: VoixMediaFieldInterface
    image3: VoixMediaFieldInterface
    image4: VoixMediaFieldInterface
    title: VoixTextFieldInterface
    copy: VoixWysiwygFieldInterface
    view: VoixTextFieldInterface
    squareFootage: VoixTextFieldInterface
    maxOccupancy: VoixTextFieldInterface
    bedding: VoixTextFieldInterface
  }
}>()

defineSlice({
  name: {
    label: 'Room Listing',
    group: 'Sanctuary',
    layouts: ['Kimpton', 'Sanctuary2024'],
  },
  tags: ['Content', 'List'],
  preview: 'SlicesSanctuaryRoomListing.jpg',
  slots: [{ name: 'default', allowedElements: [] }],
  description: 'Slideshow of images with room details',
  fields: {
    image1: {
      type: 'media',
      label: 'Image 1',
      group: 'Images',
      breakpoints: {
        default: { width: 600 },
        md: { width: 800 },
      },
    },
    image2: {
      type: 'media',
      label: 'Image 2',
      group: 'Images',
      enabled: false,
      breakpoints: {
        default: { width: 600 },
        md: { width: 800 },
      },
    },
    image3: {
      type: 'media',
      label: 'Image 3',
      group: 'Images',
      enabled: false,
      breakpoints: {
        default: { width: 600 },
        md: { width: 800 },
      },
    },
    image4: {
      type: 'media',
      label: 'Image 4',
      group: 'Images',
      enabled: false,
      breakpoints: {
        default: { width: 600 },
        md: { width: 800 },
      },
    },
    title: {
      type: 'text',
      label: 'Title',
    },
    copy: {
      type: 'wysiwyg',
      label: 'Copy',
    },
    view: {
      type: 'text',
      label: 'View',
    },
    squareFootage: {
      type: 'text',
      label: 'Square Footage',
    },
    maxOccupancy: {
      type: 'text',
      label: 'Max Occupancy',
    },
    bedding: {
      type: 'text',
      label: 'Bedding',
    },
  },
  templates: [
    {
      label: 'Basic Example',
      fields: {
        image1: {
          value:
            'https://images.unsplash.com/photo-1709582034820-d83c63ea4d0a?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        },
        title: {
          value: 'CASTLE JUNIOR SUITE OCEANFRONT',
        },
        copy: {
          value:
            'The Castle Junior Suite Oceanfront is a spacious suite with a king bed, separate living area, and a private balcony with stunning ocean views.',
        },
        view: {
          value: 'Ocean',
        },
        squareFootage: {
          value: '646',
        },
        maxOccupancy: {
          value: '3',
        },
        bedding: {
          value: 'King or 2 Queen Beds',
        },
      },
    },
  ],
})

const swiperRef: Ref<SwiperType | null> = ref(null)

function setSwiperRef(swiper: SwiperType) {
  swiperRef.value = swiper
}

const numberOfSlides = computed(() => {
  return (
    [props.fields.image2, props.fields.image3, props.fields.image4].filter(
      image => image.enabled,
    ).length + 1
  )
})

const currentPage = computed(() => {
  const pageStore = usePageStore()
  return pageStore.currentPage
})

function next() {
  if (swiperRef.value)
    swiperRef.value.slideNext()
}

function prev() {
  if (swiperRef.value)
    swiperRef.value.slidePrev()
}
</script>

<template>
  <div class="container mx-auto">
    <div class="grid grid-cols-1 lg:grid-cols-12">
      <div
        class="relative lg:col-span-7 w-full sm:w-auto flex flex-col items-left md:items-center px-8 lg:px-0 py-4"
      >
        <ClientOnly>
          <Swiper
            :loop="true"
            :slides-per-view="1"
            :space-between="0"
            :modules="[Pagination, Navigation]"
            class="w-full aspect-[4/3]"
            @swiper="setSwiperRef"
          >
            <SwiperSlide v-if="fields.image1.enabled">
              <VoixMedia :field="fields.image1" class="object-cover w-full h-full" />
            </SwiperSlide>
            <SwiperSlide v-if="fields.image2.enabled">
              <VoixMedia :field="fields.image2" class="object-cover w-full h-full" />
            </SwiperSlide>
            <SwiperSlide v-if="fields.image3.enabled">
              <VoixMedia :field="fields.image3" class="object-cover w-full h-full" />
            </SwiperSlide>
            <SwiperSlide v-if="fields.image4.enabled">
              <VoixMedia :field="fields.image4" class="object-cover w-full h-full" />
            </SwiperSlide>
          </Swiper>

          <div
            v-if="numberOfSlides > 1"
            class="absolute z-10 inset-0 pointer-events-none flex justify-between items-center p-4"
          >
            <button class="pointer-events-auto" @click="prev">
              <Icon
                class="w-16 h-16 text-gray-200"
                name="heroicons:chevron-left-20-solid"
              />
            </button>
            <button class="pointer-events-auto" @click="next">
              <Icon
                class="w-16 h-16 text-gray-200"
                name="heroicons:chevron-right-20-solid"
              />
            </button>
          </div>
        </ClientOnly>
      </div>
      <div class="lg:col-span-5 flex items-end">
        <div class="px-8 lg:pr-0 max-w-lg self-end">
          <h4
            class="font-serif text-2xl pb-3"
            :class="{
              'text-black': currentPage?.site_id === 43,
              'text-sanctuary-primary': currentPage?.site_id !== 43,
            }"
          >
            {{ fields.title.value }}
          </h4>
          <VoixWysiwyg :field="fields.copy" class="md:w-full mb-8 leading-[1.8em]" />
          <div class="grid grid-cols-2 gap-4 py-4 text-2xs">
            <div v-if="fields.view.value" class="flex flex-col">
              <span class="uppercase font-bold block">{{ $t("view") }}</span>
              {{ fields.view.value }}
            </div>
            <div v-if="fields.squareFootage.value" class="flex flex-col">
              <span class="uppercase font-bold block">Sq. Ft.</span>
              {{ fields.squareFootage.value }}
            </div>
            <div v-if="fields.maxOccupancy.value" class="flex flex-col">
              <span class="uppercase font-bold block">{{ $t("max-occupancy") }}</span>
              {{ fields.maxOccupancy.value }}
            </div>
            <div v-if="fields.bedding.value" class="flex flex-col">
              <span class="uppercase font-bold block">{{ $t("bedding") }}</span>
              {{ fields.bedding.value }}
            </div>
          </div>
          <BookNowButton
            class="text-light px-6 py-4 text-xs uppercase"
            :class="{
              'bg-kimpton-action text-white': currentPage?.site_id === 43,
              'bg-sanctuary-primary text-sanctuary-secondary':
                currentPage?.site_id !== 43,
            }"
          >
            {{ $t("book-now") }}
          </BookNowButton>
          <div class="text-2xs italic pt-1">
            Suite view and decor may differ from images
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
