<script>
import Icons from '../../mixins/Icons'

export default {
  name: 'IconCaberet',
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len */ -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 200 200"
    aria-labelledby="IconCaberet"
    role="presentation"
  >
    <title id="IconCaberet">Caberet Icon</title>
    <g id="caberet">
      <g display="inline">
        <g>
          <defs>
            <rect
              id="SVGID_1_"
              x="15.3"
              y="31.2"
              width="169.7"
              height="137.4"
            />
          </defs>
          <clipPath id="SVGID_2_">
            <use xlink:href="#SVGID_1_" overflow="visible" />
          </clipPath>
          <path
            clip-path="url(#SVGID_2_)"
            :fill="color"
            d="M159.5,115.9c0,6.8,5.8,12.6,12.6,12.6c7.1,0,12.6-5.8,12.6-12.6
c0-7.1-5.8-12.6-12.6-12.6C165.3,103.3,159.5,109.1,159.5,115.9"
          />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_3_"
              x="15.3"
              y="31.2"
              width="169.7"
              height="137.4"
            />
          </defs>
          <clipPath id="SVGID_4_">
            <use xlink:href="#SVGID_3_" overflow="visible" />
          </clipPath>
          <path
            clip-path="url(#SVGID_4_)"
            :fill="color"
            d="M15.3,115.9c0,6.8,5.8,12.6,12.6,12.6s12.6-5.8,12.6-12.6
c0-7.1-5.8-12.6-12.6-12.6C20.7,103.3,15.3,109.1,15.3,115.9"
          />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_5_"
              x="15.3"
              y="31.2"
              width="169.7"
              height="137.4"
            />
          </defs>
          <clipPath id="SVGID_6_">
            <use xlink:href="#SVGID_5_" overflow="visible" />
          </clipPath>
          <path
            clip-path="url(#SVGID_6_)"
            :fill="color"
            d="M100,168.6c28.9,0,52.7-23.5,52.7-52.7c0-28.9-23.5-52.7-52.7-52.7
s-52.7,23.5-52.7,52.7C47.3,145.1,71.1,168.6,100,168.6"
          />
        </g>
      </g>
      <g display="inline">
        <g>
          <defs>
            <rect
              id="SVGID_7_"
              x="15.3"
              y="31.2"
              width="169.7"
              height="137.4"
            />
          </defs>
          <clipPath id="SVGID_8_">
            <use xlink:href="#SVGID_7_" overflow="visible" />
          </clipPath>
          <path
            clip-path="url(#SVGID_8_)"
            :fill="color"
            d="M100,56.4c6.8,0,12.6-5.8,12.6-12.6c0-7.1-5.8-12.6-12.6-12.6
S87.4,37,87.4,43.8C87.4,50.9,93.2,56.4,100,56.4"
          />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_9_"
              x="15.3"
              y="31.2"
              width="169.7"
              height="137.4"
            />
          </defs>
          <clipPath id="SVGID_10_">
            <use xlink:href="#SVGID_9_" overflow="visible" />
          </clipPath>
          <path
            clip-path="url(#SVGID_10_)"
            :fill="color"
            d="M57.8,74.1c5.1-5.1,5.1-12.9,0-18s-12.9-4.8-17.7,0s-4.8,12.9,0,18
C44.9,78.8,53,78.8,57.8,74.1"
          />
        </g>
        <g>
          <defs>
            <rect
              id="SVGID_11_"
              x="15.3"
              y="31.2"
              width="169.7"
              height="137.4"
            />
          </defs>
          <clipPath id="SVGID_12_">
            <use xlink:href="#SVGID_11_" overflow="visible" />
          </clipPath>
          <path
            clip-path="url(#SVGID_12_)"
            :fill="color"
            d="M142.1,74.1c5.1,5.1,12.9,5.1,17.7,0c4.8-5.1,4.8-12.9,0-18
c-5.1-4.8-12.9-4.8-17.7,0C137,61.1,137,69,142.1,74.1"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
