<script>
import Icons from '../../mixins/Icons'

export default {
  name: 'IconEmpty',
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len */ -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 200 200"
    aria-labelledby="EmptyIcon"
    role="presentation"
  >
    <title id="EmptyIcon">Empty Icon</title>
    <g>
      <path
        display="inline"
        :fill="color"
        d="M153.1,181H46.6V18.2h106.6V181z M76.3,151.3h47.2V47.9H76.3V151.3z"
      />
    </g>
  </svg>
</template>
