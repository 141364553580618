<script>
import Icons from '../../mixins/Icons'

export default {
  name: 'IconUShape',
  mixins: [Icons],
}
</script>

<template>
  <!-- eslint-disable max-len */ -->
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 57.7 60.9"
    aria-labelledby="IconUShape"
    role="presentation"
  >
    <title id="IconUShape">Icon of a U-Shaped Table</title>
    <g id="Layer_2_2_">
      <g>
        <path
          :fill="color"
          class="st3"
          d="M38.8,50.9c0-0.8,0.6-1.4,1.3-1.4c0.8,0,1.4,0.6,1.4,1.3c0,0.8-0.6,1.4-1.3,1.4
C39.5,52.3,38.9,51.7,38.8,50.9"
        />
        <path
          :fill="color"
          class="st3"
          d="M46.1,43c0-0.8,0.6-1.4,1.3-1.4c0.8,0,1.4,0.6,1.4,1.3c0,0.8-0.6,1.4-1.3,1.4C46.8,44.4,46.2,43.8,46.1,43"
        />
        <path
          :fill="color"
          class="st3"
          d="M46.1,37.9c0-0.8,0.6-1.4,1.3-1.4c0.8,0,1.4,0.6,1.4,1.3c0,0.8-0.6,1.4-1.3,1.4
C46.8,39.3,46.2,38.7,46.1,37.9"
        />
        <path
          :fill="color"
          class="st3"
          d="M46.1,32.8c0-0.8,0.6-1.4,1.3-1.4c0.8,0,1.4,0.6,1.4,1.3c0,0.8-0.6,1.4-1.3,1.4
C46.8,34.2,46.2,33.6,46.1,32.8"
        />
        <path
          :fill="color"
          class="st3"
          d="M46.1,27.8c0-0.8,0.6-1.4,1.3-1.4c0.8,0,1.4,0.6,1.4,1.3c0,0.8-0.6,1.4-1.3,1.4
C46.8,29.1,46.2,28.5,46.1,27.8"
        />
        <path
          :fill="color"
          class="st3"
          d="M46.1,22.7c0-0.8,0.6-1.4,1.3-1.4c0.8,0,1.4,0.6,1.4,1.3c0,0.8-0.6,1.4-1.3,1.4C46.8,24,46.2,23.4,46.1,22.7"
        />
        <path
          :fill="color"
          class="st3"
          d="M46.1,17.6c0-0.8,0.6-1.4,1.3-1.4c0.8,0,1.4,0.6,1.4,1.3c0,0.8-0.6,1.4-1.3,1.4
C46.8,18.9,46.2,18.3,46.1,17.6"
        />
        <path
          :fill="color"
          class="st3"
          d="M46.1,12.5c0-0.8,0.6-1.4,1.3-1.4c0.8,0,1.4,0.6,1.4,1.3c0,0.8-0.6,1.4-1.3,1.4
C46.8,13.9,46.2,13.3,46.1,12.5"
        />
        <path
          :fill="color"
          class="st3"
          d="M8.2,43c0-0.8,0.6-1.4,1.3-1.4c0.8,0,1.4,0.6,1.4,1.3c0,0.8-0.6,1.4-1.3,1.4C8.9,44.4,8.3,43.8,8.2,43"
        />
        <path
          :fill="color"
          class="st3"
          d="M8.2,37.9c0-0.8,0.6-1.4,1.3-1.4c0.8,0,1.4,0.6,1.4,1.3c0,0.8-0.6,1.4-1.3,1.4C8.9,39.3,8.3,38.7,8.2,37.9"
        />
        <path
          :fill="color"
          class="st3"
          d="M8.2,32.8c0-0.8,0.6-1.4,1.3-1.4c0.8,0,1.4,0.6,1.4,1.3c0,0.8-0.6,1.4-1.3,1.4C8.9,34.2,8.3,33.6,8.2,32.8"
        />
        <path
          :fill="color"
          class="st3"
          d="M8.2,27.8c0-0.8,0.6-1.4,1.3-1.4c0.8,0,1.4,0.6,1.4,1.3c0,0.8-0.6,1.4-1.3,1.4C8.9,29.1,8.3,28.5,8.2,27.8"
        />
        <path
          :fill="color"
          class="st3"
          d="M8.2,22.7c0-0.8,0.6-1.4,1.3-1.4c0.8,0,1.4,0.6,1.4,1.3c0,0.8-0.6,1.4-1.3,1.4C8.9,24,8.3,23.4,8.2,22.7"
        />
        <path
          :fill="color"
          class="st3"
          d="M8.2,17.6c0-0.8,0.6-1.4,1.3-1.4c0.8,0,1.4,0.6,1.4,1.3c0,0.8-0.6,1.4-1.3,1.4C8.9,18.9,8.3,18.3,8.2,17.6"
        />
        <path
          :fill="color"
          class="st3"
          d="M8.2,12.5c0-0.8,0.6-1.4,1.3-1.4c0.8,0,1.4,0.6,1.4,1.3c0,0.8-0.6,1.4-1.3,1.4C8.9,13.9,8.3,13.3,8.2,12.5"
        />
        <path
          :fill="color"
          class="st3"
          d="M33.3,50.9c0-0.8,0.6-1.4,1.3-1.4c0.8,0,1.4,0.6,1.4,1.3c0,0.8-0.6,1.4-1.3,1.4C34,52.3,33.4,51.7,33.3,50.9"
        />
        <path
          :fill="color"
          class="st3"
          d="M27.8,50.9c0-0.8,0.5-1.4,1.3-1.4c0.8,0,1.4,0.6,1.4,1.3c0,0.8-0.5,1.4-1.3,1.4
C28.5,52.3,27.9,51.7,27.8,50.9"
        />
        <path
          :fill="color"
          class="st3"
          d="M22.4,50.9c0-0.8,0.6-1.4,1.3-1.4c0.8,0,1.4,0.6,1.4,1.3c0,0.8-0.6,1.4-1.3,1.4C23,52.3,22.4,51.7,22.4,50.9"
        />
        <path
          :fill="color"
          class="st3"
          d="M16.9,50.9c0-0.8,0.6-1.4,1.3-1.4c0.8,0,1.4,0.6,1.4,1.3c0,0.8-0.6,1.4-1.3,1.4
C17.5,52.3,16.9,51.7,16.9,50.9"
        />
        <polygon
          :fill="color"
          class="st3"
          points="38,8.5 44.6,8.6 44.6,47.6 13,47.6 13,8.6 19,8.6 19,41.8 37.9,41.8 "
        />
      </g>
    </g>
  </svg>
</template>
