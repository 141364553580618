<script setup lang="ts">
import type { VoixCheckboxFieldInterface, VoixColorFieldInterface, VoixMediaFieldInterface, VoixSelectFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

import LazyLogosAgentCashPlus from '~/components/logos/AgentCashPlus.vue'
import LazyLogosLazyHilton from '~/components/logos/Hilton.vue'
import LazyLogosLazyZilara from '~/components/logos/HyattZilara.vue'
import LazyLogosLazyZiva from '~/components/logos/HyattZiva.vue'
import LazyLogosLazyZivaZilara from '~/components/logos/HyattZivaZilara.vue'
import LazyLogosLazyJewel from '~/components/logos/Jewel.vue'
import LazyLogosLazyJewelGrande from '~/components/logos/JewelGrande.vue'
import LazyLogosLazyMarriotBonvoy from '~/components/logos/MarriotBonvoy.vue'
import LazyLogosLazyPlaya from '~/components/logos/Playa.vue'
import LazyLogosLazySanctuary from '~/components/logos/Sanctuary.vue'
import LazyLogosLazySandpiper from '~/components/logos/Sandpiper.vue'
import LazyLogosLazySeadust from '~/components/logos/Seadust.vue'
import LazyLogosLazyTapestryCollection from '~/components/logos/TapestryCollection.vue'
import LazyLogosLazyTurquoize from '~/components/logos/Turquoize.vue'
import LazyLogosLazyWyndham from '~/components/logos/Wyndham.vue'

const props = defineProps<{
  fields: {
    direction: VoixSelectFieldInterface
    image: VoixMediaFieldInterface
    logo: VoixMediaFieldInterface
    svgLogo: VoixSelectFieldInterface
    description: VoixWysiwygFieldInterface
    adultsOnly: VoixCheckboxFieldInterface
    backgroundColor: VoixColorFieldInterface
    linkBorderColor: VoixColorFieldInterface
    linkTextColor: VoixColorFieldInterface
  }
}>()
</script>

<template>
  <div>
    <div class="relative grid lg:grid-cols-2">
      <div class="hidden lg:block w-full h-full overflow-hidden">
        <VoixMedia :field="props.fields.image" class="w-full h-full object-cover" />
      </div>
      <div class="flex flex-col lg:justify-end items-start lg:grow lg:self-stretch lg:pl-12">
        <div class="w-full sm:w-auto flex flex-col items-left md:items-center px-8 lg:px-0 py-4">
          <template v-if="props.fields.svgLogo.value !== 'None'">
            <component :is="LazyLogosAgentCashPlus" v-if="props.fields.svgLogo.value === `AgentCashPlus`" class="w-[200px]" />
            <component :is="LazyLogosLazyHilton" v-if="props.fields.svgLogo.value === 'Hilton'" class="w-[140px] text-white" :fill="false" />
            <component :is="LazyLogosLazyZilara" v-if="props.fields.svgLogo.value === 'HyattZilara'" class="w-[200px]" :fill="false" />
            <component :is="LazyLogosLazyZiva" v-if="props.fields.svgLogo.value === 'HyattZiva'" class="w-[200px]" :fill="false" />
            <component :is="LazyLogosLazyZivaZilara" v-if="props.fields.svgLogo.value === 'HyattZivaZilara'" class="w-[200px]" :fill="false" />
            <component :is="LazyLogosLazyJewel" v-if="props.fields.svgLogo.value === 'Jewel'" class="w-[200px]" :fill="false" />
            <component :is="LazyLogosLazyJewelGrande" v-if="props.fields.svgLogo.value === 'JewelGrande'" class="w-[200px]" :fill="false" />
            <component :is="LazyLogosLazyMarriotBonvoy" v-if="props.fields.svgLogo.value === 'MarriotBonvoy'" class="w-[200px]" :fill="false" />
            <component :is="LazyLogosLazyPlaya" v-if="props.fields.svgLogo.value === 'Playa'" class="w-[200px]" :fill="false" />
            <component :is="LazyLogosLazySanctuary" v-if="props.fields.svgLogo.value === 'Sanctuary'" class="w-[200px]" :fill="false" />
            <component :is="LazyLogosLazySandpiper" v-if="props.fields.svgLogo.value === 'Sandpiper'" class="w-[200px]" :fill="false" />
            <component :is="LazyLogosLazySeadust" v-if="props.fields.svgLogo.value === 'Seadust'" class="w-[200px]" :fill="false" />
            <component :is="LazyLogosLazyTapestryCollection" v-if="props.fields.svgLogo.value === 'TapestryCollection'" class="w-[200px]" :fill="false" />
            <component :is="LazyLogosLazyTurquoize" v-if="props.fields.svgLogo.value === 'Turquoize'" class="w-[200px]" :fill="false" />
            <component :is="LazyLogosLazyWyndham" v-if="props.fields.svgLogo.value === 'Wyndham'" class="w-[200px]" :fill="false" />
          </template>
          <VoixMedia v-else :field="props.fields.logo" class="w-[300px]" />
        </div>
        <VoixMedia :field="props.fields.image" class="lg:hidden w-5/6 h-full mx-auto aspect-video" />
        <div class="px-8 lg:pl-0 lg:justify-self-end text-left max-w-lg">
          <div class="flex lg:justify-start my-6">
            <h3
              class="text-lg xl:text-xl font-medium tracking-widest uppercase border-b-2 border-white pb-2 pr-12"
            >
              {{ props.fields.adultsOnly.value ? $t('adults-only') : $t('all-ages') }}
            </h3>
          </div>
          <VoixWysiwyg :field="props.fields.description" class="text-white pb-4 font-light" />
          <div class="flex lg:justify-start">
            <h3
              class="text-lg xl:text-xl font-medium tracking-widest uppercase border-b-2 border-white pb-2 pr-12"
            >
              {{ $t('destinations') }}
            </h3>
          </div>

          <div class="space-y-2 mt-6">
            <slot :border-color="fields.linkBorderColor.value" :text-color="fields.linkTextColor.value" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
