<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  mounted() {
    defineSlice({
      name: { label: 'Full Width Wysiwyg', group: 'global', layouts: ['*'] },
      tags: ['Paragraph', 'Wysiwyg'],
      fields: { content: { type: 'wysiwyg', label: 'Content', name: 'content' } },
      slots: [],
    })
  },
}
</script>

<template>
  <div class="flex title-paragraph">
    <div class="w-full p-8 md:p-12 container mx-auto" v-html="fields.content.value" />
  </div>
</template>
