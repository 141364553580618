<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      name: {
        label: 'Content Container',
        group: 'global',
        layouts: ['Sanctuary', 'PlayaCares', 'Blocks', 'Sanctuary2024'],
      },
      tags: ['Container'],
      preview: 'SlicesGlobalContainersContentContainer.jpg',
      fields: { fullWidth: { type: 'checkbox', label: 'Content Full Width', default: true, name: 'fullWidth' } },
      slots: [{ name: 'default', allowedElements: [] }],
    })
  },
}
</script>

<template>
  <div class="flex-col flex justify-center">
    <div class="container">
      <div>
        <slot :class="{ 'w-full': fields.fullWidth.value }" />
      </div>
    </div>
  </div>
</template>
