<script>
import Icons from '../../mixins/Icons'

export default {
  name: 'IconReception',
  mixins: [Icons],
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 57.7 60.9"
    aria-labelledby="IconReception"
    role="presentation"
  >
    <title id="IconReception">Reception Icon</title>
    <polygon
      :fill="color"
      class="st3"
      points="11.3,12.2 44.4,12.2 27.6,31.9 "
    />
    <g>
      <path :fill="color" class="st3" d="M20.2,49.3h14.6 M27.6,29v20.4" />
      <polygon
        :fill="color"
        class="st3"
        points="34.8,49.8 20.2,49.8 20.2,48.8 27.1,48.8 27.1,29 28.1,29 28.1,48.8 34.8,48.8 "
      />
    </g>
  </svg>
</template>
