<script setup lang="ts">
import type { VoixTextFieldInterface } from '@voix/types'
import Container from '@/components/blocks/Container.vue'

import IconsButler from '@/components/icons/IconsButler.vue'
import IconsBed from '@/components/icons/IconsBed.vue'
import IconsBar from '@/components/icons/IconsBar.vue'
import IconsTV from '@/components/icons/IconsTv.vue'
import IconsMirror from '@/components/icons/IconsMirror.vue'
import IconsBathroom from '@/components/icons/IconsBathroom.vue'
import IconsSafe from '@/components/icons/IconsSafe.vue'
import IconsPatio from '~/components/icons/IconsPatio.vue'
import IconsPhone from '~/components/icons/IconsPhone.vue'
import IconsCoffee from '~/components/icons/IconsCoffee.vue'
import IconsAC from '~/components/icons/IconsAC.vue'
import IconsHairdryer from '~/components/icons/IconsHairdryer.vue'
import IconsIroningBoard from '~/components/icons/IconsIroningBoard.vue'
import IconsAlarm from '~/components/icons/IconsAlarm.vue'
import IconsBalcony from '~/components/icons/IconsBalcony.vue'
import IconsRadio from '~/components/icons/IconsRadio.vue'
import IconsTurndown from '~/components/icons/IconsTurndown.vue'

defineProps<{
  fields: {
    title: VoixTextFieldInterface
  }
}>()
defineSlice({
  name: {
    label: 'Included with Stay',
    group: 'Sanctuary',
    layouts: ['Sanctuary', 'Sanctuary2024'],
  },
  tags: ['Text', 'Info'],
  fields: {
    title: {
      type: 'text',
      label: 'Title',
      name: 'title',
    },
  },
  slots: [],
})

const isVisible = ref(false)
function onVisibilityChange(entry: { intersectionRatio: number }) {
  if (entry.intersectionRatio * 10 > 1)
    isVisible.value = true
  else isVisible.value = false
}

const amenities = [
  { icon: IconsButler, text: 'room-service' },
  { icon: IconsBed, text: 'pillow-menu' },
  { icon: IconsBar, text: 'mini-bar' },
  { icon: IconsTV, text: 'flat-screen-tv' },
  { icon: IconsMirror, text: 'mirror' },
  { icon: IconsBathroom, text: 'bathroom-amenaties' },
  { icon: IconsSafe, text: 'in-room-safe' },
  { icon: IconsPatio, text: 'furnished-patio' },
  { icon: IconsPhone, text: 'telephone' },
  { icon: IconsCoffee, text: 'coffee-maker' },
  { icon: IconsAC, text: 'air-conditioning' },
  { icon: IconsHairdryer, text: 'hairdryer' },
  { icon: IconsIroningBoard, text: 'iron-and-ironing-board' },
  { icon: IconsAlarm, text: 'alarm-clock' },
  { icon: IconsBalcony, text: 'private-balcony' },
  { icon: IconsRadio, text: 'radio' },
  { icon: IconsTurndown, text: 'turndown-service' },
]
</script>

<template>
  <Container
    v-voix-visibility="{ callback: onVisibilityChange, threshold: [0, 0.25] }"
    class="max-w-[1600px]"
  >
    <div
      class="flex flex-col justify-center space-y-4 pt-10 lg:pt-0 pl-4 lg:pl-8 pr-4 lg:pr-0 duration-1000 ease-out delay-500"
      :class="{
        'opacity-0': !isVisible,
        'opacity-100': isVisible,
      }"
    >
      <h4
        class="relative text-center lg:text-left text-sanctuary-primary flex justify-center"
      >
        <div class="absolute z-0 top-0 left-0 right-0">
          <div
            class="border-t border-sanctuary-primary translate-y-3 max-w-3xl mx-auto"
          />
        </div>
        <div class="relative z-10 bg-white px-8">
          {{ fields.title.value }}
        </div>
      </h4>
      <div class="flex flex-wrap justify-center text-base sm:text-sm">
        <div
          v-for="amenity in amenities"
          :key="amenity.text"
          class="mt-4 w-40 flex flex-col justify-center items-center space-y-1"
        >
          <div>
            <Component :is="amenity.icon" class="w-8 h-8 flex-none" />
          </div>
          <span class="text-center text-xs">{{ $t(amenity.text) }}</span>
        </div>
      </div>
    </div>
  </Container>
</template>
