<script setup lang="ts">
import { usePageStore } from '@voix/store/pageStore'
import type { VoixCheckboxFieldInterface } from '@voix/types'

const props = defineProps<{
  fields: {
    beachFrontAccess: VoixCheckboxFieldInterface
    oceanFrontLocation: VoixCheckboxFieldInterface
  }
}>()

defineSlice({
  description: 'Hard-coded Always included',
  name: {
    label: 'Always Included',
    group: 'Always Included',
    layouts: ['Wyndham', 'WyndhamCancun', 'WyndhamPlaya'],
  },
  tags: ['Info'],
  templates: [{ label: 'Always Included', fields: [] }],
  fields: {
    beachFrontAccess: {
      type: 'checkbox',
      label: 'Beachfront Access',
      default: true,
    },
    oceanFrontLocation: {
      type: 'checkbox',
      label: 'Oceanfront Location',
      default: false,
    },
  },
})

const currentResort = inject<any>('currentResort')

const showCamp = computed(() => {
  return false
})
const currentPage = computed(() => {
  const pageStore = usePageStore()
  return pageStore.currentPage
})
</script>

<template>
  <div>
    <div v-if="!currentResort?.name.includes('Wyndham')" class="flex justify-center">
      <div
        v-if="currentPage?.language_code === 'en'"
        class="py-8 md:p-12 container text-center flex flex-col items-center"
      >
        <h2 class="mb-8">
          <span class="sans-serif">ALL YOURS &amp; ALWAYS INCLUDED</span>
        </h2>
        <ul class="flex flex-wrap w-full">
          <li class="included-1-1 w-full ml-8 md:ml-0 md:w-1/3 p-4">
            <h4>SUNNY SEASHORES</h4>
            endless fun in the sun
          </li>
          <li class="included-1-2 w-full ml-8 md:ml-0 md:w-1/3 p-4">
            <h4>SWEET SUITES</h4>
            your personal paradise
          </li>
          <li class="included-1-3 w-full ml-8 md:ml-0 md:w-1/3 p-4">
            <h4>OUTDOOR ACTIVITIES</h4>
            fresh air, friendly competition
          </li>
          <li class="included-1-4 w-full ml-8 md:ml-0 md:w-1/3 p-4">
            <h4>PRISTINE POOLS</h4>
            with waiters, of course
          </li>
          <li class="included-2-5 w-full ml-8 md:ml-0 md:w-1/3 p-4">
            <h4>ROOM SERVICE</h4>
            breakfast in bed, anyone?
          </li>
          <li class="included-2-6 w-full ml-8 md:ml-0 md:w-1/3 p-4">
            <h4>WATER SPORTS</h4>
            make a splash
          </li>
          <li class="included-2-7 w-full ml-8 md:ml-0 md:w-1/3 p-4">
            <h4>LIVE VIBES</h4>
            evening fun for everyone
          </li>
          <li class="included-2-8 w-full ml-8 md:ml-0 md:w-1/3 p-4">
            <h4>DELECTABLE DINING</h4>
            at gourmet eateries
          </li>
          <li class="included-3-9 w-full ml-8 md:ml-0 md:w-1/3 p-4">
            <h4>INFINITE LIBATIONS</h4>
            glasses always half full
          </li>
          <li class="included-3-10 w-full ml-8 md:ml-0 md:w-1/3 p-4">
            <h4>THE GYM</h4>
            fitness first
          </li>
          <li v-if="showCamp" class="included-3-11 w-full ml-8 md:ml-0 md:w-1/3 p-4">
            <h4>CAMP ALLTRA</h4>
            little kids, big fun
          </li>
          <li class="included-3-12 w-full ml-8 md:ml-0 md:w-1/3 p-4">
            <h4>UNLIMITED WI-FI</h4>
            stay connected…or don’t
          </li>
        </ul>
      </div>
      <div
        v-if="currentPage?.language_code === 'es'"
        class="py-8 md:p-12 container text-center flex flex-col items-center"
      >
        <h2 class="mb-8">
          <span class="sans-serif">TODO TUYO Y SIEMPRE INCLUIDO</span>
        </h2>
        <ul class="flex flex-wrap">
          <li class="included-1-1 w-1/3 p-4">
            <h4>PLAYAS SOLEADAS</h4>
            interminable diversión bajo el sol
          </li>
          <li class="included-1-2 w-1/3 p-4">
            <h4>PLACENTERAS SUITES</h4>
            un paraíso, todo tuyo
          </li>
          <li class="included-1-3 w-1/3 p-4">
            <h4>AVENTURAS AL AIRE LIBRE</h4>
            infinita diversión y brisa del caribe
          </li>
          <li class="included-1-4 w-1/3 p-4">
            <h4>PISCINAS CRISTALINAS</h4>
            con playordomos, por supuesto
          </li>
          <li class="included-2-5 w-1/3 p-4">
            <h4>SERVICIO A LA HABITACIÓN</h4>
            desayuno en la cama, incluido
          </li>
          <li class="included-2-6 w-1/3 p-4">
            <h4>DEPORTES ACUÁTICOS</h4>
            sumérgete y diviértete
          </li>
          <li class="included-2-7 w-1/3 p-4">
            <h4>ENTRETENIMIENTO EN VIVO</h4>
            diversión nocturna para todos
          </li>
          <li class="included-2-8 w-1/3 p-4">
            <h4>DELICIOSOS PLATILLOS</h4>
            gastronomía gourmet
          </li>
          <li class="included-3-9 w-1/3 p-4">
            <h4>BEBIDAS SIN FIN</h4>
            vasos siempre llenos
          </li>
          <li class="included-3-10 w-1/3 p-4">
            <h4>GYM</h4>
            fitness todos los días
          </li>
          <li v-if="showCamp" class="included-3-11 w-1/3 p-4">
            <h4>CAMP ALLTRA</h4>
            huéspedes pequeños, gran diversión
          </li>
          <li class="included-3-12 w-1/3 p-4">
            <h4>WI-FI ILIMITADO</h4>
            conéctate… o elige no hacerlo
          </li>
        </ul>
      </div>
    </div>
    <div v-else class="flex justify-center">
      <div class="py-8 md:p-12 container text-center flex flex-col items-center">
        <h2 class="mb-8">
          <span v-if="currentPage?.language_code === 'en'" class="sans-serif">All Yours &amp; Always Included</span>
          <span v-if="currentPage?.language_code === 'es'" class="sans-serif">Todo Tuyo y Siempre Incluido</span>
        </h2>
        <ul class="grid grid-cols-1 md:grid-cols-3 w-full">
          <li
            v-if="fields.beachFrontAccess.value"
            class="w-full p-4 flex items-center justify-center text-left border-r border-gray-lighter"
          >
            <IconsWyndhamIcons icon="sun" class="h-8 mr-4" />
            <div>
              <h4 v-if="currentPage?.language_code === 'en'">
                Beachfront Access
              </h4>
              <h4 v-if="currentPage?.language_code === 'es'">
                Acceso directo a la playa
              </h4>
            </div>
          </li>
          <li
            v-if="fields.oceanFrontLocation.value"
            class="w-full p-4 flex items-center justify-center text-left border-r border-gray-lighter"
          >
            <IconsWyndhamIcons icon="sun" class="h-8 mr-4" />
            <div>
              <h4 v-if="currentPage?.language_code === 'en'">
                Oceanfront Location
              </h4>
              <h4 v-if="currentPage?.language_code === 'es'">
                Oceanfront Location
              </h4>
            </div>
          </li>
          <li
            class="w-full p-4 flex items-center justify-center text-left border-r border-gray-lighter"
          >
            <IconsWyndhamIcons icon="heart" class="h-8 mr-4" />
            <div>
              <h4 v-if="currentPage?.language_code === 'en'">
                Contemporary Accommodations
              </h4>
              <h4 v-if="currentPage?.language_code === 'es'">
                Habitaciones modernas
              </h4>
            </div>
          </li>
          <li class="w-full p-4 flex items-center justify-center text-left">
            <IconsWyndhamIcons icon="surf" class="h-8 mr-4" />
            <div>
              <h4 v-if="currentPage?.language_code === 'en'">
                Outdoor Activities
              </h4>
              <h4 v-if="currentPage?.language_code === 'es'">
                Aventuras Al Aire Libre
              </h4>
            </div>
          </li>
          <li
            class="w-full p-4 flex items-center justify-center text-left border-r border-gray-lighter"
          >
            <IconsWyndhamIcons icon="pool" class="h-8 mr-4" />
            <div>
              <h4 v-if="currentPage?.language_code === 'en'">
                Pristine Pools
              </h4>
              <h4 v-if="currentPage?.language_code === 'es'">
                Piscinas refrescantes
              </h4>
            </div>
          </li>
          <li
            class="w-full p-4 flex items-center justify-center text-left border-r border-gray-lighter"
          >
            <IconsWyndhamIcons icon="star" class="h-8 mr-4" />
            <div>
              <h4 v-if="currentPage?.language_code === 'en'">
                Live Entertainment
              </h4>
              <h4 v-if="currentPage?.language_code === 'es'">
                Entretenimiento en vivo
              </h4>
            </div>
          </li>
          <li class="w-full p-4 flex items-center justify-center text-left">
            <IconsWyndhamIcons icon="food" class="h-8 mr-4" />
            <div>
              <h4 v-if="currentPage?.language_code === 'en'">
                Dining &amp; Drinks
              </h4>
              <h4 v-if="currentPage?.language_code === 'es'">
                Bebidas y comidas
              </h4>
            </div>
          </li>
          <li class="w-full p-4 flex items-center justify-center text-left">
            <IconsWyndhamIcons icon="drink" class="h-8 mr-4" />
            <div>
              <h4 v-if="currentPage?.language_code === 'en'">
                Infinite Libations
              </h4>
              <h4 v-if="currentPage?.language_code === 'es'">
                Bebidas Sin Fin
              </h4>
            </div>
          </li>
          <li class="w-full p-4 flex items-center justify-center text-left">
            <IconsWyndhamIcons icon="wifi" class="h-8 mr-4" />
            <div>
              <h4 v-if="currentPage?.language_code === 'en'">
                Unlimited WI-FI
              </h4>
              <h4 v-if="currentPage?.language_code === 'es'">
                WI-FI Ilimitado
              </h4>
            </div>
          </li>
          <li
            v-if="showCamp"
            class="w-full p-4 flex items-center justify-center text-left border-r border-gray-lighter"
          >
            <IconsWyndhamIcons icon="fire" class="h-8 mr-4" />
            <div>
              <h4 v-if="currentPage?.language_code === 'en'">
                Camp Alltra
              </h4>
              <h4 v-if="currentPage?.language_code === 'es'">
                Camp Alltra
              </h4>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
