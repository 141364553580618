<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isVisible: false,
    }
  },
  mounted() {
    defineSlice({
      description: 'Slanted gallery with 3 images',
      fields: {
        image1: {
          label: 'Image 1',
          type: 'media',
          breakpoints: { default: { width: 500, height: 300 } },
        },
        image2: {
          label: 'Image 2',
          type: 'media',
          breakpoints: { default: { width: 500, height: 300 } },
        },
        image3: {
          label: 'Image 3',
          type: 'media',
          breakpoints: { default: { width: 500, height: 300 } },
        },
      },
      name: {
        label: 'Slanted Gallery',
        group: 'Hyatt',
        layouts: ['AgentCashPlus', 'HyattZilara', 'HyattZiva'],
      },
      tags: ['Gallery'],
      templates: [
        {
          label: 'Slanted Gallery',
          fields: {
            image1: {
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Hyatt_Zilara_Riviera_Maya\/dining\/hyatt-ziva-puerto-vallarta-fb-breakfast-blaze.jpg',
            },
            image2: {
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Hyatt_Zilara_Riviera_Maya\/2023\/hyatt-zilara-riviera-maya-main-pool-hero-shot-3.jpg',
            },
            image3: {
              value:
                'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Hyatt_Zilara_Riviera_Maya\/2023\/hyatt-zilara-riviera-maya-main-pool-couple-1.jpg',
            },
          },
        },
      ],
    })
  },
  methods: {
    visibilityChanged(entry) {
      if (entry.intersectionRatio * 100 > 0)
        this.isVisible = true
    },
  },
}
</script>

<template>
  <div v-voix-visibility="{ callback: visibilityChanged, threshold: 1 }" class="my-8">
    <div class="flex container mx-auto overflow-hidden">
      <div
        class="relative h-32 md:h-64 w-full -mr-[3%] transition-all duration-[1.5s] ease-out"
        :class="{
          ' opacity-0': !isVisible,
          'opacity-100': isVisible,
        }"
        style="clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%)"
      >
        <VoixMedia :field="fields.image1" class="object-cover w-full h-full" />
      </div>
      <div
        class="relative h-32 md:h-64 w-full -mx-[4%] transition-all duration-[1.5s] delay-300 ease-out"
        :class="{
          ' opacity-0': !isVisible,
          'opacity-100': isVisible,
        }"
        style="clip-path: polygon(10% 0, 100% 0, 90% 100%, 0 100%)"
      >
        <VoixMedia :field="fields.image2" class="object-cover w-full h-full" />
      </div>
      <div
        class="md:block relative h-32 md:h-64 w-full -ml-[3%] transition-all duration-[1.5s] delay-500 ease-out"
        :class="{
          ' opacity-0': !isVisible,
          'opacity-100': isVisible,
        }"
        style="clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%)"
      >
        <VoixMedia :field="fields.image3" class="object-cover w-full h-full" />
      </div>
    </div>
  </div>
</template>
