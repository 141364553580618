<script setup lang="ts">
import type { VoixLinkFieldInterface, VoixMediaFieldInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'

defineProps<{
  fields: {
    title: VoixTextFieldInterface
    copy: VoixWysiwygFieldInterface
    media: VoixMediaFieldInterface
    link: VoixLinkFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Image Title Copy Card', group: 'Bonita', layouts: ['Bonita'] },
  tags: ['Card'],
  description: 'Image Card with a title, copy and link floating meant to go inside Bonita Two Column Container.',
  preview: 'SlicesBonitaImageTitleCopyCard.jpg',
  fields: {
    media: {
      label: 'Media',
      type: 'media',
      breakpoints: {
        default: {
          width: 460,
          height: 340,
        },
      },
    },
    title: { label: 'Title', type: 'text' },
    copy: { label: 'Copy', type: 'wysiwyg' },
    link: { label: 'Link', type: 'link' },
  },
  templates: [{
    label: 'Main Example',
    fields: {
      media: {
        value: 'https://images.unsplash.com/photo-1719952167449-33940005b007?q=80&w=460&h=340&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      },
      title: { value: 'Escape From Reality' },
      copy: { value: '<p>Lorem ipsum dolor sit amet consectetur. Cras lacus eget ut integer viverra magna feugiat consequat. Tellus in tempor vitae tortor turpis et mauris in vulputate. Auctor cursus at pulvinar pellentesque at lobortis posuere purus. Sed tellus aliquam semper ut risus feugiat id. Sed donec non enim feugiat lacus blandit a ipsum integer. Commodo ut elit mauris vel condimentum. Non eu massa lorem ac lacus tincidunt.</p>' },
      link: { value: { url: '#', text: 'VIEW ALL PACKAGES & OFFERS' } },
    },
  }],
})

const isVoixTemplatizer = inject<boolean>('isVoixTemplatizer', false)
</script>

<template>
  <div>
    <div :class="{ 'max-w-lg ': isVoixTemplatizer }">
      <VoixMedia :field="fields.media" class="mb-6 w-full" />

      <h2 class="mb-3 text-3xl font-bonita-title text-bonita-primary">
        {{ fields.title.value }}
      </h2>
      <VoixWysiwyg class="mb-2 prose" :field="fields.copy" />
      <VoixLink :field="fields.link" class="text-xs uppercase tracking-widest underline underline-offset-4" />
    </div>
  </div>
</template>
