<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      preview: 'SlicesWeddingsVenueSlide.jpg',
      slots: [{ name: 'default' }],
      fields: {
        title: {
          type: 'text',
          label: 'Title',
          default: 'Wedding Venues',
        },
        description: {
          type: 'wysiwyg',
          label: 'Description',
          default:
            '<p style="text-align: center;font-size:.9em">Home to more than 100,000 square feet of breathtaking event spaces as well as dedicated onsite planning managers.</p>',
        },
      },
      name: { label: 'Weddings Venue Slider', group: 'Weddings', layouts: ['*'] },
      tags: ['Slider'],
      templates: [],
    })
  },
}
</script>

<template>
  <div>
    <div class="container mx-auto flex flex-col items-center">
      <div class="w-full md:w-5/6 md:px-16 pb-6 mx-auto flex flex-col items-center">
        <div
          class="text-center font-cursive font-light mb-1 text-5xl md:text-6xl"
          v-html="fields.title.value"
        />
        <div class="text-center font-light my-4" v-html="fields.description.value" />
      </div>
    </div>

    <div class="container px-0 lg:px-24 mx-auto">
      <GeneralSwiper class="w-full">
        <VoixSlice v-for="s in slice.elements" :key="s.id" :slice="s" />

        <template #previous>
          <IconsLeftArrow
            v-if="slice.elements && slice.elements.length > 4"
            color="#6e7275"
            :w="20"
            :h="20"
          />
          <div v-else />
        </template>
        <template #next>
          <IconsRightArrow
            v-if="slice.elements && slice.elements.length > 4"
            color="#6e7275"
            :w="20"
            :h="20"
          />
          <div v-else />
        </template>
      </GeneralSwiper>
    </div>
  </div>
</template>
