<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      slots: [{ name: 'default' }],
      description: 'Slices',
      fields: {
        title: { type: 'text', label: 'Title' },
        copy: { type: 'wysiwyg', label: 'Copy' },
        bottomBorder: { type: 'checkbox', label: 'Bottom Border' },
      },
      name: { label: 'Portal Section', group: 'Agents Portal', layouts: ['AgentCashPlus'] },
      tags: ['Container'],
      templates: [{
        label: 'Section',
        fields: {
          bottomBorder: { value: true },
          copy: { value: '<p>We are here to serve you. Get in touch and we\u2019ll be happy to help you with Playa Hotels &amp; Resorts tools and programs, so you can start earning more commissions and cash bonuses today. Don\u2019t worry: we value your privacy and we will only call you if you ask us to call you. We\u2019ll make sure you profit from our relationship.<\/p><p>For all inquiries with reservations please call toll free: 1-844-228-9501 or +786-269-2041<br>or email: <a href="https:\/\/agentcashplus.com\/playaresorts@reservhotel.com" rel="noopener noreferrer nofollow">playaresorts@reservhotel.com<\/a><\/p><p>Questions and Concerns:&nbsp;<a href="mailto:salesincentives@playaresorts.com" rel="noopener noreferrer nofollow">salesincentives@playaresorts.com<\/a><\/p>' },
          title: { value: 'Agents Portal' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="flex justify-center pb-16">
    <div class="flex flex-col items-center max-w-[800px] text-center px-8">
      <div
        class="uppercase font-bold text-2xl w-full text-center"
        :class="{ 'border-b border-gray-400 pb-3 mb-6 text-gray-500': fields.bottomBorder.value }"
      >
        {{ fields.title.value }}
      </div>
      <div v-html="fields.copy.value" />
      <slot />
    </div>
  </div>
</template>
