<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },

  mounted() {
    defineSlice({
      name: {
        label: 'Title Wysiwyg',
        group: 'playaresorts',
        layouts: ['*'],
      },
      tags: ['Title', 'Wysiwyg'],
      fields: {
        title: { type: 'text', label: 'Title', editorLabel: true, name: 'title' },
        introduction: { type: 'wysiwyg', label: 'Copy', name: 'introduction' },
      },
      slots: [],
    })
  },
}
</script>

<template>
  <div class="flex justify-center">
    <div
      class="py-8 md:p-12 container text-left flex flex-col items-center justify-center"
    >
      <div
        v-if="fields.title.value"
        class="text-3xl uppercase title text-center mb-8 font-light w-full"
        v-html="fields.title.value"
      />
      <div class="wysiwyg w-full" v-html="fields.introduction.value" />
    </div>
  </div>
</template>
