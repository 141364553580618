<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },

  mounted() {
    defineSlice({
      name: { label: 'Contact Row', group: 'playaresorts', layouts: ['Blocks'] },
      tags: ['Contact', 'Row'],
      fields: {
        contactName: {
          type: 'text',
          label: 'Contact Name',
          editorLabel: true,
          name: 'contactName',
        },
        contactPhone: { type: 'text', label: 'Contact Phone', name: 'contactPhone' },
        contactEmail: { type: 'text', label: 'Contact Email', name: 'contactEmail' },
      },
      slots: [],
    })
  },
}
</script>

<template>
  <div class="w-full flex flex-row items-stretch font-light">
    <div class="w-1/3 p-2 text-xs font-bold">
      {{ fields.contactName.value }}
    </div>
    <div class="w-1/3 p-2 text-xs font-bold">
      <a v-if="fields.contactPhone.value" :href="`tel:${fields.contactPhone.value}`">
        {{ fields.contactPhone.value }}
      </a>
    </div>
    <div class="w-1/3 p-2 text-xs font-bold">
      <a v-if="fields.contactEmail.value" :href="`mailto:${fields.contactEmail.value}`">
        {{ fields.contactEmail.value }}
      </a>
    </div>
  </div>
</template>
