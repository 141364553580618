<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      playerVars: {
        autoplay: 0,
        loop: 0,
        rel: 0,
        showinfo: 0,
      },
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage })
    },
    embedUrl() {
      const autoplay = this.autoplay.value ? '1' : '0'
      const loop = this.loop.value ? '1' : '0'
      const mute = this.mute.value ? '1' : '0'

      return `https://www.youtube.com/embed/${this.fields.youtubeId.value}?rel=0&autoplay=${autoplay}&loop=${loop}&mute=${mute}`
    },
  },

  mounted() {
    defineSlice({
      name: { label: 'Single', group: 'playaresorts', layouts: ['Blocks', 'PlayaCares'] },
      tags: ['Blog', 'Post'],
      fields: {
        title: { type: 'text', label: 'Title', name: 'title' },
        hero: {
          type: 'media',
          label: 'Hero',
          name: 'hero',
          breakpoints: { default: { width: 1600 } },
        },
        youtubeId: { type: 'text', label: 'Youtube ID', name: 'youtubeId' },
        content: { type: 'wysiwyg', label: 'Content', name: 'content' },
      },
      slots: [],
    })
  },
}
</script>

<template>
  <div>
    <VoixMedia :field="fields.hero" class="w-full" />

    <div v-if="fields.youtubeId.value" class="flex justify-center w-full">
      <div class="py-14 container">
        <div class="yt-container">
          <iframe id="ytplayer" type="text/html" :src="embedUrl" frameborder="0" />
        </div>
      </div>
    </div>

    <div class="flex justify-center">
      <div class="p-12 container items-center">
        <h1 class="pb-8 font-light text-2xl">
          {{ fields.title.value }}
        </h1>
        <div class="wysiwyg prose max-w-none" v-html="fields.content.value" />
        <div class="pt-10">
          <a
            href="javascript:void(0)"
            onclick="window.history.back();"
            class="btn"
            target="_top"
          >BACK</a>
        </div>
      </div>
    </div>
  </div>
</template>
