<script setup lang="ts">
import type {
  SliceInterface,
  VoixTextFieldInterface,
  VoixWysiwygFieldInterface,
} from '@voix/types'

defineProps<{
  fields: {
    title: VoixTextFieldInterface
    copy: VoixWysiwygFieldInterface
  }
  slice: {
    type: SliceInterface
    required: true
  }
}>()

const showAllOnMobile = ref(false)
defineSlice({
  name: { label: 'Activity Grid', group: 'sandpiper', layouts: ['Sandpiper'] },
  tags: ['Grid'],
  preview: 'SlicesSandpiperActivityGrid.jpg',
  fields: {
    title: { type: 'text', label: 'Title', name: 'title' },
    copy: { type: 'wysiwyg', label: 'Copy', name: 'copy' },
  },
  slots: [{ name: 'default', allowedElements: ['ActivityGridCard'] }],
})
</script>

<template>
  <div class="px-4 md:container mx-auto py-12">
    <div class="max-w-3xl">
      <div class="text-3xl">
        {{ fields.title.value }}
      </div>

      <VoixWysiwyg :field="fields.copy" class="text-sm" />
    </div>

    <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-3 gap-y-8">
      <div
        v-for="(subSlice, key) in slice.elements"
        :key="subSlice.id"
        :class="{ 'hidden md:block': !showAllOnMobile && key > 2 }"
      >
        <VoixSlice :slice="subSlice as SliceInterface" />
      </div>
    </div>

    <button
      v-if="!showAllOnMobile"
      type="button"
      class="md:hidden mt-4 text-sm w-full font-sandpiper-display uppercase font-bold bg-midnight text-white px-6 py-4 rounded"
      @click.prevent="showAllOnMobile = true"
    >
      Show All Activities
    </button>
  </div>
</template>
