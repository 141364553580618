<script>
import Blocker from './utilities/Blocker'
import CloseIcon from '@/components/icons/IconsX.vue'

export default {
  name: 'LearnMore',
  components: {
    CloseIcon,
    Blocker,
  },

  props: ['learnMore'],
  data() {
    return {
      showCopy: false,
    }
  },
}
</script>

<template>
  <div>
    <div class="cursor-pointer" @click="showCopy = true">
      <slot />
    </div>

    <Teleport to="#portal-destination" v-if="showCopy">
      <div key="learnmore" class="fixed inset-0 z-50">
        <Blocker @click="showCopy = false" />
        <div
          class="bg-white p-8 mx-8 fixed z-50 rounded shadow-lg wysiwyg w-4/5 my-4 overflow-y-scroll"
          style="left: 50%; top: 50%; transform: translate(-50%, -50%); max-height: 75%"
        >
          <div
            class="absolute top-0 right-0 mt-4 mr-4 cursor-pointer"
            @click="showCopy = false"
          >
            <CloseIcon />
          </div>
          <div v-html="learnMore" />
        </div>
      </div>
    </Teleport>
  </div>
</template>
