<script>
import ScribbleCircle from '~/components/background-elements/ScribbleCircle.vue'
import SpiderWeb from '~/components/background-elements/SpiderWeb.vue'
import SpiralCoral from '~/components/background-elements/SpiralCoral.vue'
import SpiralCoralWeb from '~/components/background-elements/SpiralCoralWeb.vue'

export default {
  components: {
    ScribbleCircle,
    SpiderWeb,
    SpiralCoral,
    SpiralCoralWeb,
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    showSvg() {
      if (
        this.fields.bgsvg.value === 'none'
        || this.$voix.breakpoint === 'mobile'
        || this.$voix.breakpoint === 'tablet'
      ) {
        return false
      }

      return true
    },
  },
  mounted() {
    defineSlice({
      preview: 'SlicesWeddingsPatternedGrid.jpg',
      slots: [{ name: 'default' }],
      description: 'Weddings Patterned Grid',
      fields: {
        bgsvg: {
          type: 'select',
          label: 'Content Background Graphic',
          options: {
            'none': 'None',
            'scribble-circle': 'Scribble Circle',
            'spider-web': 'Spider Web',
            'spiral-coral': 'Spiral Coral',
            'spiral-coral-web': 'Spiral Coral Web',
          },
          default: 'none',
        },
      },
      name: {
        label: 'Weddings Patterned Grid',
        group: 'Weddings',
        layouts: ['*'],
      },
      tags: ['Grid'],
      templates: [],
    })
  },
}
</script>

<template>
  <div class="relative overflow-hidden w-full">
    <div
      v-if="breakpoint !== 'mobile' && fields.bgsvg.value !== null"
      class="text-gray-200 absolute z-0 inset-0 opacity-50"
    >
      <ScribbleCircle
        v-if="showSvg && fields.bgsvg.value === 'scribble-circle'"
        w="200%"
        h="200%"
        style="margin-top: -50%; margin-left: -50%"
      />
      <SpiderWeb
        v-if="showSvg && fields.bgsvg.value === 'spider-web'"
        w="200%"
        h="200%"
        style="margin-top: -50%; margin-left: -50%"
      />
      <SpiralCoral
        v-if="showSvg && fields.bgsvg.value === 'spiral-coral'"
        w="200%"
        h="200%"
        style="margin-top: -50%; margin-left: -50%"
      />
      <SpiralCoralWeb
        v-if="showSvg && fields.bgsvg.value === 'spiral-coral-web'"
        w="200%"
        h="200%"
        style="margin-top: -50%; margin-left: -50%"
      />
    </div>

    <div class="flex p-8 lg:p-32 flex-wrap flex-grow justify-center">
      <VoixSlice
        v-for="s in slice.elements"
        :key="s.id"
        class="my-4 w-full md:w-1/2 lg:w-1/3"
        :slice="s"
      />
    </div>
  </div>
</template>
