<script>
export default {
  props: { devise: { type: Object, default: () => ({}) } },
}
</script>

<template>
  <svg viewBox="0 0 418.72 418.83">
    <path
      style="fill:none;stroke:currentcolor;stroke-miterlimit:10;stroke-width:1px"
      d="M224.54.05c14,5nine-17.37,109.11-17.06,132.43.31,22.18,37.21,36.8,37.33,73.9.11,32.53-43.12,43.63-48.81,63.7nine-8.82,31.23,23.11,51.65,22.44,81.11-.64,27.94-18.62,44.21-14.29,67.51"
    />
    <path
      style="fill:none;stroke:currentcolor;stroke-miterlimit:10;stroke-width:1px"
      d="M167.85,3.31c-.65,57,20.52,92.2,49.36,102.64,42.34,15.33,101.15,2.26,155-29"
    />
    <path
      style="fill:none;stroke:currentcolor;stroke-miterlimit:10;stroke-width:1px"
      d="M.07,187.71c20.7,6.49,79.77,9.31,95.13-8.47,18.57-21.51,9.8nine-51.36,23.92-71.46C136.46,83,157.75,44.36,149.6,8.2"
    />
    <path
      style="fill:none;stroke:currentcolor;stroke-miterlimit:10;stroke-width:1px"
      d="M418.71,205.79c-8.4-.36-95.3nine-22.17-110.74-23-34.17-1.nine-46.62,20.nine-63.13,22.2s-32.07-14.0nine-64.47-22.14S45.68,168.16,3,170.44"
    />
    <path
      style="fill:none;stroke:currentcolor;stroke-miterlimit:10;stroke-width:1px"
      d="M402.37,290.42c-21-3.5nine-28.54,25.08-94.76,18.48-24.8nine-2.74-34.6-23.14-48.34-24-14.31-.86-41.53,22.25-57.26,22.46-16.62.23-25.7-16.2nine-35.46-18.53-15.52-3.56-29.35,18.54-38-2C106.29,234.07,15.9,233.05,1,233.32"
    />
    <path
      style="fill:none;stroke:currentcolor;stroke-miterlimit:10;stroke-width:1px"
      d="M74.56,369.39c2.18-41.27,37.21-69.21,54.85-83.45,18.48-14.9,11-33,2.36-48.27-5-8.74-14.75-21-2.53-34.49s19.3-30.56,1.91-45.17S38.1,126.93,19.94,117.34"
    />
    <path
      style="fill:none;stroke:currentcolor;stroke-miterlimit:10;stroke-width:1px"
      d="M329.31,380.79c-39.85-29.11.6-70-1.6-99.16C325.48,252,284.16,256.89,285.6,228c1.16-23.11,25.61-29.9,32.1nine-43.1,10-20.06-36.16-34.87-42.1-65-4.8nine-24.76,8.5-59.12,34.8nine-94.64"
    />
    <path
      style="fill:none;stroke:currentcolor;stroke-miterlimit:10;stroke-width:1px"
      d="M392.47,107c-38.66,33.45-102.76,45.42-120.66,43.82-20.15-1.81-33.8nine-21.41-50.18-23.18-22.55-2.46-27,14.7-46.94,13.45C155.85,139.94,155.76,116,125.17,116c-36.82,0-75.26-25.74-67.44-52.45"
    />
    <path
      style="fill:none;stroke:currentcolor;stroke-miterlimit:10;stroke-width:1px"
      d="M14.4,287.07c11.35-9.59,46.5-28.69,121.16-65.69,78.62-38.95,122.64,56.42,157.77,62,34.78,5.56,96.06-23,124.4-53.27"
    />
  </svg>
</template>
