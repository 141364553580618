<script setup lang="ts">
import type {
  VoixLinkFieldInterface,
  VoixMediaFieldInterface,
  VoixTextFieldInterface,
  VoixWysiwygFieldInterface,
} from '@voix/types'

defineProps<{
  fields: {
    title: VoixTextFieldInterface
    subtitle: VoixTextFieldInterface
    image: VoixMediaFieldInterface
    image2: VoixMediaFieldInterface
    image3: VoixMediaFieldInterface
    copyColumn1: VoixWysiwygFieldInterface
    copyColumn2: VoixWysiwygFieldInterface
    link: VoixLinkFieldInterface
  }
}>()

defineSlice({
  name: {
    label: 'Two Column Copy And Image',
    group: 'Sanctuary',
    layouts: ['Sanctuary', 'Sanctuary2024'],
  },
  tags: ['Columns', 'Wysiwyg'],
  preview: 'SlicesSanctuaryTwoColumnCopyAndImage.jpg',
  fields: {
    image: {
      type: 'media',
      label: 'Image',
      breakpoints: {
        default: { width: 1600 },
      },
    },
    image2: {
      type: 'media',
      label: 'Image 2',
      breakpoints: {
        default: { width: 1600 },
      },
    },
    image3: {
      type: 'media',
      label: 'Image 3',
      breakpoints: {
        default: { width: 1600 },
      },
    },
    title: { type: 'text', label: 'Title', default: 'This is the title' },
    subtitle: { type: 'text', label: 'Subtitle', default: 'This is the subtitle' },
    copyColumn1: {
      type: 'wysiwyg',
      label: 'Copy Column 1',
      default: '<p>This is the copy column 1</p>',
    },
    copyColumn2: { type: 'wysiwyg', label: 'Copy Column 2' },
    link: {
      type: 'link',
      label: 'Link',
      enabled: false,
    },
  },
  templates: [
    {
      label: 'Main Example',
      fields: {
        image1: {
          value:
            'https://images.unsplash.com/photo-1448375010925-a75a42ceaffa?q=80&w=1600&h=900&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        },
        image2: {
          value:
            'https://images.unsplash.com/photo-1445964047600-cdbdb873673d?q=80&w=1600&h=900&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        },
        image3: {
          value:
            'https://images.unsplash.com/photo-1446329813274-7c9036bd9a1f?q=80&w=1600&h=900&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
        },
        title: {
          value: 'Scanctuary Two Column Copy And Image',
        },
        subtitle: {
          value: 'This is the subtitle',
        },
        copyColumn1: {
          value:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in turpis nec felis ultricies ultricies. Nullam in turpis nec felis ultricies ultricies. Nullam in turpis nec felis ultricies ultricies.</p>',
        },
        copyColumn2: {
          value:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in turpis nec felis ultricies ultricies. Nullam in turpis nec felis ultricies ultricies. Nullam in turpis nec felis ultricies ultricies.</p>',
        },
        link: {
          value: {
            text: 'The Link',
            url: '#',
          },
        },
      },
    },
  ],
  slots: [],
})

const isVisible = ref(false)
function onVisibilityChange(entry: { intersectionRatio: number }) {
  if (entry.intersectionRatio * 10 > 1)
    isVisible.value = true
  else isVisible.value = false
}
</script>

<template>
  <section
    v-voix-visibility="{
      callback: onVisibilityChange,
      threshold: [0, 0.25],
    }"
    class="pb-20 pt-10 container mx-auto"
  >
    <div class="w-full aspect-[2/1]">
      <GeneralSwiper :options="{ lazy: true }">
        <VoixMedia
          v-if="fields.image.value && fields.image.value.length"
          :field="fields.image"
          class="w-full h-full object-cover aspect-[2/1]"
        />
        <VoixMedia
          v-if="fields.image2.value && fields.image2.value.length"
          :field="fields.image2"
          class="w-full h-full object-cover aspect-[2/1]"
        />
        <VoixMedia
          v-if="fields.image3.value && fields.image3.value.length"
          :field="fields.image3"
          class="w-full h-full object-cover aspect-[2/1]"
        />
      </GeneralSwiper>
    </div>

    <div class="relative pt-16 text-sanctuary-primary">
      <h3 class="font-serif text-3xl font-thin">
        {{ fields.title.value }}
      </h3>
      <h5 class="font-serif pt-1">
        <span class="italic">{{ fields.subtitle.value }}</span>
      </h5>
      <div class="grid lg:grid-cols-2 lg:gap-20 pt-8">
        <div
          class="transition-all duration-1000 ease-out"
          :class="{
            '-translate-x-48 opacity-0': !isVisible,
            'translate-x-0 opacity-100': isVisible,
          }"
        >
          <VoixWysiwyg
            :field="fields.copyColumn1"
            class="text-sm md:w-full mb-8 leading-[1.8em]"
          />

          <VoixLink
            :field="fields.link"
            class="text-light bg-sanctuary-primary text-sanctuary-secondary px-6 py-4 text-xs uppercase w-auto mx-auto"
          />
        </div>
        <div
          class="transition-all duration-1000 ease-out delay-500"
          :class="{
            '-translate-x-48 opacity-0': !isVisible,
            'translate-x-0 opacity-100': isVisible,
          }"
        >
          <VoixWysiwyg
            :field="fields.copyColumn2"
            class="text-sm md:w-full mb-8 leading-[1.8em]"
          />
        </div>
      </div>
    </div>
  </section>
</template>
