<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      fields: {
        image: { type: 'media', label: 'Image' },
        title: { type: 'text', label: 'Title', editorLabel: true },
        copy: { type: 'wysiwyg', label: 'Copy' },
      },
      name: { label: 'White Content Icon Feature Card', group: 'Agents Portal', layouts: ['AgentCashPlus'] },
      tags: ['Card'],
      templates: [{
        label: 'White Content Icon Feature Card',
        fields: {
          copy: { value: '<p>Book our resorts at the best rate plus get last-room availability, including specialty suites and room types.<\/p>' },
          image: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Agents_Portal\/2023\/acp-bed-icon.gif' },
          title: { value: 'BOOK HOTEL' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="mb-8 flex justify-stretch">
    <div class="md:mx-4 relative w-full">
      <div class="flex flex-col overflow-hidden">
        <VoixMedia :field="fields.image" class="w-[100px] mb-4 mx-auto" />

        <h2 v-if="fields.title.value" class="text-white mb-2 uppercase font-bold text-xl">
          {{ fields.title.value }}
        </h2>
        <div class="text-white text-lg font-thin wysiwyg mb-4" v-html="fields.copy.value" />
      </div>
    </div>
  </div>
</template>
