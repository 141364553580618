<script setup lang="ts">
const props = defineProps<{
  fields: {
    title: VoixTextFieldInterface
    description: VoixWysiwygFieldInterface
    card1Image: VoixMediaFieldInterface
    card1Title: VoixTextFieldInterface
    card1Description: VoixWysiwygFieldInterface
    card1Link: VoixLinkFieldInterface
    card2Image: VoixMediaFieldInterface
    card2Title: VoixTextFieldInterface
    card2Description: VoixWysiwygFieldInterface
    card2Link: VoixLinkFieldInterface
    card3Image: VoixMediaFieldInterface
    card3Title: VoixTextFieldInterface
    card3Description: VoixWysiwygFieldInterface
    card3Link: VoixLinkFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Tall Three Image Cards', group: 'wyndham' },
  fields: {
    title: { type: 'text', label: 'Title', name: 'title' },
    description: { type: 'wysiwyg', label: 'Description', name: 'description' },
    card1Image: { type: 'media', label: 'Card 1 Image', breakpoints: { lg: { width: 500, height: 450 } }, name: 'card1Image' },
    card1Title: { type: 'text', label: 'Card 1 Title', name: 'card1Title' },
    card1Description: { type: 'wysiwyg', label: 'Card 1 Description', name: 'card1Description' },
    card1Link: { type: 'link', label: 'Card 1 Link', enabled: false, name: 'card1Link' },
    card2Image: { type: 'media', label: 'Card 2 Image', breakpoints: { lg: { width: 500, height: 450 } }, name: 'card2Image' },
    card2Title: { type: 'text', label: 'Card 2 Title', name: 'card2Title' },
    card2Description: { type: 'wysiwyg', label: 'Card 2 Description', name: 'card2Description' },
    card2Link: { type: 'link', label: 'Card 2 Link', enabled: false, name: 'card2Link' },
    card3Image: { type: 'media', label: 'Card 3 Image', breakpoints: { lg: { width: 500, height: 450 } }, name: 'card3Image' },
    card3Title: { type: 'text', label: 'Card 3 Title', name: 'card3Title' },
    card3Description: { type: 'wysiwyg', label: 'Card 3 Description', name: 'card3Description' },
    card3Link: { type: 'link', label: 'Card 3 Link', enabled: false, name: 'card3Link' },
  },
  slots: [],
})

const gridClasses = computed(() => {
  if (
    props.fields.card1Image.value
    && props.fields.card1Image.value.length
    && props.fields.card2Image.value
    && props.fields.card2Image.value.length
    && props.fields.card3Image.value
    && props.fields.card3Image.value.length
  )
    return 'md:grid-cols-3'

  if (
    props.fields.card1Image.value
    && props.fields.card1Image.value.length
    && props.fields.card2Image.value
    && props.fields.card2Image.value.length
  )
    return 'md:grid-cols-2'

  return 'grid-cols-1'
})
</script>

<template>
  <div
    class="w-full py-12 flex justify-center three-image-cards"
  >
    <div class="container text-center">
      <h2
        v-if="fields.title.value"
        class="mb-6 text-4xl font-sans-lato font-light"
      >
        {{ fields.title.value }}
      </h2>
      <div class="mb-4 mx-8" v-html="fields.description.value" />
      <div
        class="grid  gap-12"
        :class="[gridClasses]"
      >
        <div
          v-if="fields.card1Image.value && fields.card1Image.value.length"
          class="flex justify-center items-stretch"
        >
          <div
            class="bg-abs-white three-image-cards card flex flex-col justify-start w-full"
          >
            <div>
              <VoixMedia :field="fields.card1Image" />
            </div>
            <div class="flex-1 flex flex-col justify-between">
              <div class="text-center p-4">
                <div class="font-bold card-title">
                  {{ fields.card1Title.value }}
                </div>
                <div
                  class="card-body"
                  v-html="fields.card1Description.value"
                />
              </div>
              <div
                v-if="fields.card1Link.value && fields.card1Link.enabled"
                class="card-body p-4"
              >
                <a
                  class="flex items-center text-bold"
                  :href="fields.card1Link.value.url"
                >
                  {{ fields.card1Link.value.text }}
                  <svg
                    class="w-4 h-4 ml-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="fields.card2Image.value && fields.card2Image.value.length"
          class="flex justify-center items-stretch"
        >
          <div
            class="bg-abs-white three-image-cards card flex flex-col justify-start"
          >
            <div>
              <VoixMedia :field="fields.card2Image" />
            </div>
            <div class="flex-1 flex flex-col justify-between">
              <div class="text-center p-4">
                <div class="font-bold card-title">
                  {{ fields.card2Title.value }}
                </div>
                <div
                  class="card-body"
                  v-html="fields.card2Description.value"
                />
              </div>
              <div
                v-if="fields.card2Link.value && fields.card2Link.enabled"
                class="card-body p-4"
              >
                <a
                  class="flex items-center text-bold"
                  :href="fields.card2Link.value.url"
                >
                  {{ fields.card2Link.value.text }}
                  <svg
                    class="w-4 h-4 ml-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="fields.card3Image.value && fields.card3Image.value.length"
          class="flex justify-center items-stretch"
        >
          <div
            class="bg-abs-white three-image-cards card flex flex-col justify-start w-full"
          >
            <div>
              <VoixMedia :field="fields.card3Image" />
            </div>
            <div class="flex-1 flex flex-col justify-between">
              <div class="text-center p-4">
                <div class="font-bold card-title">
                  {{ fields.card3Title.value }}
                </div>
                <div
                  class="card-body"
                  v-html="fields.card3Description.value"
                />
              </div>
              <div
                v-if="fields.card3Link.value && fields.card3Link.enabled"
                class="card-body p-4"
              >
                <a
                  class="flex items-center text-bold"
                  :href="fields.card3Link.value.url"
                >
                  {{ fields.card3Link.value.text }}
                  <svg
                    class="w-4 h-4 ml-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
