<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      description: 'Infinity Luxury Contact Form',
      fields: {
        title: { type: 'text', label: 'Title', default: 'Contact' },
        introduction: { type: 'wysiwyg', label: 'Introduction' },
      },
      name: {
        label: 'Infinity Contact Form',
        group: 'Infinity Luxury',
        layouts: ['InfinityLuxury'],
      },
      tags: ['Form'],
      templates: [
        {
          label: 'Contact Form',
          fields: {
            introduction: {
              value:
                '<p>We look forward&nbsp;to hearing&nbsp;from you, if you have&nbsp;any questions, please leave your information and we will reach out&nbsp;to you.&nbsp;<\/p>',
            },
          },
        },
      ],
    })
  },
}
</script>

<template>
  <div class="flex justify-center bg-abs-white py-16 font-sans-montserrat">
    <div class="max-w-[960px] px-4">
      <div class="text-infinity-action text-6xl font-thin">
        {{ fields.title.value }}
      </div>
      <div class="mt-4 text-infinity-dark" v-html="fields.introduction.value" />
      <div class="mt-9 text-infinity-action font-bold text-base">
        <div>Blvd. Kukulcan Km.17, Cancun, Mexico</div>
        <div>+52 (99) 82 87 14 50</div>
        <div>contact@infinityluxurytravelclub.com</div>
      </div>
      <form action="/forms/contact" method="post">
        <input type="hidden" name="_token" value="">
        <div class="flex flex-col space-y-12 mt-12 bg-infinity-action text-white p-10">
          <div class="flex items-end space-x-6">
            <label for="name" class="text-2xl font-thin uppercase">Name: </label>
            <input
              id="name"
              type="text"
              name="name"
              class="w-full py-2 border-b border-white bg-transparent focus:outline-none focus:border-b-2"
            >
          </div>
          <div class="flex items-end space-x-6">
            <label for="email" class="text-2xl font-thin uppercase">Email: </label>
            <input
              id="email"
              type="email"
              name="email"
              class="w-full py-2 border-b border-white bg-transparent focus:outline-none focus:border-b-2"
            >
          </div>
          <div class="flex items-end space-x-6">
            <label for="subject" class="text-2xl font-thin uppercase">Subject: </label>
            <input
              id="subject"
              type="text"
              name="subject"
              class="w-full py-2 border-b border-white bg-transparent focus:outline-none focus:border-b-2"
            >
          </div>
          <div class="flex flex-col -m-4">
            <label
              for="message"
              class="block text-2xl font-thin uppercase text-infinity-action bg-white p-4"
            >Message:
            </label>
            <textarea
              id="message"
              name="message"
              class="w-full h-64 p-8 text-infinity-dark outline-infinity-action"
            />
          </div>
          <div class="mt-8 flex justify-center">
            <button
              type="submit"
              class="py-3 px-8 text-2xl font-thin uppercase text-white bg-infinity-dark"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
