<script setup lang="ts">
import { computed, ref } from 'vue'
import axios from 'axios'
import Cookies from 'js-cookie'
import { usePageStore } from '@voix/store/pageStore'

import { useI18n } from 'vue-i18n'

const props = defineProps<{
  fields: {
    formid?: {
      value: string
    }
  }
}>()

const { t } = useI18n({})

const currentPage = computed(() => {
  const pageStore = usePageStore()
  return pageStore.currentPage
})

const form = ref({
  form: currentPage.value ? currentPage.value.path : '',
  first_name: '',
  last_name: '',
  email: '',
  opt_in: 'No',
  marketing_opt_in: 'No',
})
const success = ref({
  show: false,
  message: 'success',
})
const error = ref({
  message: 'error',
})
const formInvalid = ref(false)
const firstNameInvalid = ref(false)
const lastNameInvalid = ref(false)
const emailInvalid = ref(false)
const shouldShowError = ref(false)
const agreeToTermsInvalid = ref(false)
const agreeToMarketingInvalid = ref(false)
const validationErrors = ref([])

function submit() {
  if (formValidates()) {
    const { $playa } = useNuxtApp()
    const instance = axios.create({})

    instance
      .post(`${$playa.rest_api_base_url}/anniversary-form-submission`, form.value)
      .then(() => {
        showSuccess()
      })
      .catch(() => {
        showError(t('there-was-a-problem'))
      })
  }
}

function formValidates() {
  // reset
  formInvalid.value = false
  validationErrors.value = []

  if (form.value.opt_in === 'No') {
    agreeToTermsInvalid.value = true
    formInvalid.value = true
  }
  else {
    agreeToTermsInvalid.value = false
  }
  if (form.value.marketing_opt_in === 'No') {
    agreeToMarketingInvalid.value = true
    formInvalid.value = true
  }
  else {
    agreeToMarketingInvalid.value = false
  }
  if (form.value.first_name === null || form.value.first_name === '') {
    firstNameInvalid.value = true
    formInvalid.value = true
  }
  else {
    firstNameInvalid.value = false
  }
  if (form.value.last_name === null || form.value.last_name === '') {
    lastNameInvalid.value = true
    formInvalid.value = true
  }
  else {
    lastNameInvalid.value = false
  }
  if (!validEmail()) {
    emailInvalid.value = true
    formInvalid.value = true
  }
  else {
    emailInvalid.value = false
  }

  return formInvalid.value === false
}
function validEmail() {
  return /\S[^\s@]*@\S+\.\S+/.test(form.value.email)
}
function showError(msg: string) {
  error.value.message = msg
  shouldShowError.value = true
}
function showSuccess() {
  success.value.show = true
  success.value.message = t('thank-you')
  Cookies.set(`${form.value.form}-submitted`, 'true', { expires: 99999999 })

  const dataLayer = window.dataLayer

  try {
    dataLayer.push({
      event: 'anniversary-sweepstakes',
    })
  }
  catch (e) {
    // eslint-disable-next-line no-console
    console.log(e)
  }
}

const submitted = Cookies.get(`${form.value.form}-submitted`)
if (submitted)
  showSuccess()

defineSlice({
  name: { label: 'Anniversary Sweepstakes Form', group: 'global', layouts: ['Blocks'] },
  tags: ['Form'],
  fields: { resort: { type: 'text', label: 'Resort Name', name: 'resort' }, title: { type: 'text', label: 'Form Title', default: 'Sign me up!', name: 'title' }, btnText: { type: 'text', label: 'Button Text', default: 'Sign up', name: 'btnText' }, successMsg: { type: 'text', label: 'Success Message', default: 'FORM SUCCESSFULLY SUBMITTED!', name: 'successMsg' } },
  slots: [],
})
</script>

<template>
  <div class="flex justify-center relative mb-12">
    <div
      class="w-full lg:w-3/4 shadow-lg wysiwyg mx-2 my-4"
      style="overflow-y: auto"
    >
      <div class="flex flex-col md:flex-row relative bg-abs-white">
        <img class="opacity-0 lg:opacity-100 absolute top-0 right-0" width="125" height="121" src="https://playa-cms-assets.s3.amazonaws.com/media/logos/23/10-years-of-playa.webp">
        <form class="p-4 lg:p-12 w-full text-grey-dark font-light">
          <div
            v-if="success.show"
            v-cloak
            class="success"
          >
            <div v-html="fields.successMsg.value" />
          </div>
          <template v-if="!success.show">
            <p class="text-center text-5xl mt-6 mb-12" style="color:#144679">
              ENTER TO <span class="font-bold">WIN!</span>
            </p>
            <div class="px-4 lg:px-24">
              <div class="flex flex-col lg:flex-row">
                <fieldset
                  class="flex md:mr-2 w-full mb-4"
                >
                  <label
                    style="text-transform:inherit;font-weight: 300;"
                    class="w-1/4 font-light mt-4"
                  >
                    First Name*
                  </label>
                  <div class="relative w-3/4 p-0 m-0 border-b-2 border-grey-lighter">
                    <input
                      v-model="form.first_name"
                      style="color: #7f91a3;"
                      type="text"
                      name="First Name"
                      class="w-full border-0 p-0 m-0"
                    >
                    <span
                      v-if="firstNameInvalid"
                      class="absolute top-0 right-0 ml-4 text-red"
                    >Required</span>
                  </div>
                </fieldset>
                <fieldset
                  class="flex ml-0 lg:ml-2 w-full mb-4"
                >
                  <label
                    style="text-transform:inherit;font-weight: 300;"
                    class="mb-0 leading-tight mt-4"
                  >
                    Last Name*
                  </label>
                  <div class="relative w-3/4 p-0 m-0 border-b-2 border-grey-lighter">
                    <input
                      v-model="form.last_name"
                      style="color: #7f91a3;"
                      type="text"
                      name="Last Name"
                      class="w-full border-0"
                    >
                    <span
                      v-if="lastNameInvalid"
                      class="absolute top-0 right-0 ml-4 text-red"
                    >Required</span>
                  </div>
                </fieldset>
              </div>
              <fieldset
                class="flex mt-0 lg:mt-4 md:mr-2 w-full mb-4"
              >
                <label
                  style="text-transform:inherit;font-weight: 300;"
                  class="w-[15%] lg:w-[10%] font-light mb-0 leading-tight mt-4"
                >
                  Email*
                </label>
                <div class="relative w-[85%] lg:w-[90%] p-0 m-0 border-b-2 border-grey-lighter">
                  <input
                    v-model="form.email"
                    style="color: #7f91a3;"
                    type="email"
                    name="Email"
                    class="w-full border-0"
                  >
                  <span
                    v-if="emailInvalid"
                    class="absolute top-0 right-0 ml-4 text-red"
                  >Valid Email Required</span>
                </div>
              </fieldset>
              <label class="inline-block mt-10">
                <span
                  v-if="agreeToTermsInvalid"
                  class="text-xs font-bold text-red"
                >Required</span>
                <div
                  class="flex font-normal normal-case text-2xs leading-tight"
                >
                  <div style="width:25px;">
                    <input
                      v-model="form.opt_in"
                      type="checkbox"
                      class="mt-1"
                      true-value="Yes"
                      false-value="No"
                    >
                  </div>
                  <div
                    style="width: 95%;font-size:.9em;"
                  >Yes, please enter me into Playa’s Ultimate Anniversary Sweepstakes, using the information I provide with this entry.  I have read, understand, and agree to the <a href="https://www.playaresorts.com/official-rules" target="_blank">Official Rules</a>.</div>
                </div>
              </label>
              <label class="inline-block mt-4">
                <span
                  v-if="agreeToMarketingInvalid"
                  class="text-xs font-bold text-red"
                >Required</span>
                <div
                  class="flex font-normal normal-case text-2xs leading-tight"
                >
                  <div style="width:25px;">
                    <input
                      v-model="form.marketing_opt_in"
                      type="checkbox"
                      class="mt-1"
                      true-value="Yes"
                      false-value="No"
                    >
                  </div>
                  <div
                    style="width: 95%;font-size:.9em;"
                  >I consent to the use of my e-mail address and name by Playa Hotels & Resorts N.V., and its affiliates and subsidiaries (Playa) for the purposes of sending direct marketing communications regarding special offers and promotions. I am aware that I may withdraw my consent at any time by clicking on the unsubscribe link at the end of each communication. I understand that withdrawing my consent does not affect the lawfulness of the processing of my personal data based on my consent before the withdrawal. For more information on how Playa processes your personal data, and your data protection rights, please consult our privacy notice at <a href="https://www.playaresorts.com/privacy-policy" target="_blank">https://www.playaresorts.com/privacy-policy</a>.</div>
                </div>
              </label>
            </div>
            <div class="my-12">
              <fieldset class="w-full text-center">
                <input
                  type="button"
                  class="btn"
                  style="background-color:#7ed0db;padding-left: 60px;padding-right: 60px;"
                  :value="$t('enternow')"
                  @click="submit"
                >
              </fieldset>
            </div>
          </template>
        </form>
      </div>
    </div>
  </div>
</template>
